import ChannelSelect from '@/components/ChannelSelect2';
import LoadingSpinner from '@/components/LoadingSpinner';
import { dispatchWithFeedback } from '@/utils/utils';
import { Button, DatePicker, Select } from 'antd';
import moment from 'moment-timezone';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { useMap } from '../utils/selectors';
import JourneyListing from './journey-listing';

const { RangePicker } = DatePicker;

const JourneyViewer: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [selectedSiteTimeZone, setSelectedSiteTimeZone] = useState<any>(null);
  const [selectedChannelIds, setSelectedChannelIds] = useState<any>([]);

  const sites = useSelector((state: any) => state.sites.all);
  const sitesById = useSelector((state: any) => state.sites.byID);

  const isLoading = useSelector((state: any) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['customer_journey/fetchJourneys'] ||
      loadingEffects['locations/fetchLocations']
    );
  });

  const timeRangeValueRef = useRef<moment.Moment[]>([
    // default time range is only 2min because
    // we are getting a lot of journeys for bigger ranges and API call takes time
    moment().subtract(1, 'days').subtract(2, 'minutes'),
    moment().subtract(1, 'days'),
  ]);
  const selectedMapId = useMap(selectedSite);
  const locationsInSite: any[] = selectedSite
    ? sitesById[selectedSite].Projects?.map(({ ProjectID }: any) => ProjectID)
    : [];

  // requires validation for params
  const fetchJourneyHandler = () => {
    const siteId = selectedSite;
    setSelectedSite(siteId);
    const siteTimezone = sitesById[siteId]?.Timezone;
    setSelectedSiteTimeZone(siteTimezone);
    const queryStartTime = timeRangeValueRef.current[0];
    const queryEndTime = timeRangeValueRef.current[1];

    const queryStartInSiteTimeZone = moment.tz(
      queryStartTime.format('YYYY-MM-DD HH:mm'),
      siteTimezone,
    );
    const queryEndInSiteTimeZone = moment.tz(
      queryEndTime.format('YYYY-MM-DD HH:mm'),
      siteTimezone,
    );

    const queryStartTimeInUTCSeconds = queryStartInSiteTimeZone.utc().unix();
    const queryEndTimeInUTCSeconds = queryEndInSiteTimeZone.utc().unix();

    // TODO: need to validate empty projects here

    dispatchWithFeedback(
      dispatch,
      'Fetching journeys',
      {
        type: 'customer_journey/fetchJourneys',
        payload: {
          params: {
            site_id: selectedSite,
            start_time: queryStartTimeInUTCSeconds,
            end_time: queryEndTimeInUTCSeconds,
            channel_ids: selectedChannelIds.join(','),
          },
        },
      },
      true,
    );
  };

  return (
    <div style={{ paddingRight: '16px' }}>
      <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
          margin: '16px 0px',
        }}>
        <Select
          onChange={(value) => {
            setSelectedSite(value);
            setSelectedChannelIds([]);
          }}
          style={{ width: 200 }}>
          {sites.map((site: any) => {
            return (
              <Select.Option key={site.SiteID} value={site.SiteID}>
                {site.Name}
              </Select.Option>
            );
          })}
        </Select>
        {!selectedSite || locationsInSite.length === 0 ? (
          'No Channels'
        ) : (
          <div style={{ overflow: 'hidden' }}>
            <ChannelSelect
              value={selectedChannelIds}
              multiple={true}
              filterLocationIDs={locationsInSite}
              onChange={(channelIds) => {
                setSelectedChannelIds(channelIds);
              }}
            />
          </div>
        )}
        <RangePicker
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          // @ts-ignore
          defaultValue={timeRangeValueRef.current}
          onChange={(value) => {
            // @ts-ignore
            timeRangeValueRef.current = value;
          }}
        />
        <Button onClick={fetchJourneyHandler}>Fetch journeys</Button>
      </div>
      {selectedSite && !selectedMapId ? (
        <div className="df-error-text" style={{ margin: '10px 0px' }}>
          Warning: No map configured for the selected site, you may not be able
          to load the journey details
        </div>
      ) : null}
      {isLoading ? (
        <div style={{ height: '500px' }}>
          <LoadingSpinner />
        </div>
      ) : (
        <JourneyListing
          selectedSiteTimeZone={selectedSiteTimeZone}
          locationMapId={selectedMapId}
        />
      )}
    </div>
  );
};

export default JourneyViewer;
