import { ReactComponent as ChannelIcon } from '@/assets/channelprimary.svg';
import { ReactComponent as MoreDotsIcon } from '@/assets/more-dots-black.svg';
import DateRangeText from '@/components/DateRangeText';
import ImageWithBlurBG from '@/components/ImageWithBlurBG';
import LoadingSpinner from '@/components/LoadingSpinner';
import {
  EventError,
  eventMediaProcessingGetError,
  eventMediaProcessingIsDone,
  isEventInCreatedState,
  isEventInErrorState,
  isEventInProgress,
} from '@/utils/investigation';
import Icon, { WarningOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tooltip } from 'antd';
import classnames from 'classnames';
import _ from 'lodash';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment-timezone';
import React from 'react';
import { connect, Link } from 'umi';
import AddEvent from '../add-event';
import AddEventToReport from '../add-event-to-report';
import DeleteInvestigationEvent from '../delete-event';
import UpdateInvestigationEvent from '../update-event';
import UpdateEventTime from '../update-event-time';
import styles from './style.less';

momentDurationFormatSetup(moment);

// @ts-expect-error
@connect(({ loading, locations }, { eventById }) => {
  const connect_props: any = {
    loadingInvestigationEventDependencies:
      loading.effects[
        'investigation_events/fetchInvestigationEventDependencies'
      ],
    eventMedia: [],
  };

  if (eventById) {
    const { media } = locations;
    eventById.Media.forEach((id: number) => {
      if (id in media.byId) {
        connect_props.eventMedia.push(media.byId[id]);
      }
    });
  }

  return connect_props;
})
class InvestigationEventCard extends React.Component<any, any> {
  updateEventTimeRef: any;

  constructor(props) {
    super(props);
    this.updateEventTimeRef = React.createRef();
    this.state = {
      deleteEventFlag: false,
    };
  }

  openEmail = () => {
    window.location.href = 'mailto:info@dragonfruit.ai';
  };

  getEventThumbnailSrc(event, eventMedia) {
    // difference between eventMedia (derived from event.Media) and event.LatestMedia:
    // - LatestMedia only contains one result, ie. the latest result
    // - eventMedia contains all the media for the event
    const src =
      _.get(event, 'LatestMedia[0].Thumbnail.SignedUrl', null) ||
      _.get(eventMedia, '[0].Thumbnail.SignedUrl', null);
    return src;
  }

  renderMenuEvents(investigation, event) {
    return (
      <div
        className={'df-menu-container'}
        onClick={(e) => {
          // the component is wrapped in a link. We need to prevent the link
          // from being activated when we click the dropdown.
          e.preventDefault();
          e.stopPropagation();
        }}>
        <div className={'df-menu-item-container'}>
          <UpdateInvestigationEvent investigation={investigation} event={event}>
            <div className={'df-menu-item'}>Rename</div>
          </UpdateInvestigationEvent>
        </div>
        <div className={'df-menu-item-container'}>
          <AddEvent investigation={investigation} event={event}>
            <div className={'df-menu-item'}>Duplicate</div>
          </AddEvent>
        </div>
        <div className={'df-menu-item-container'}>
          <AddEventToReport investigation={investigation} event={event}>
            <div className={'df-menu-item'}>Add to Report</div>
          </AddEventToReport>
        </div>

        {_.get(event, 'Channel.CreatedFrom') === 'FILE-UPLOAD' ? (
          <div className={'df-menu-item-container'}>
            <UpdateEventTime
              wrappedComponentRef={this.updateEventTimeRef}
              event={event}>
              <div className={'df-menu-item'}>Update Metadata</div>
            </UpdateEventTime>
          </div>
        ) : null}

        <div className={'df-menu-item-container'}>
          <DeleteInvestigationEvent
            investigation={investigation}
            event={event}
            onDelete={() => {
              this.setState({ deleteEventFlag: true });
            }}
            onCancel={() => {
              this.setState({ deleteEventFlag: false });
            }}>
            <div className={'df-menu-item'}>Delete</div>
          </DeleteInvestigationEvent>
        </div>
      </div>
    );
  }

  renderCardBodyPartError(eventError: EventError) {
    return (
      <div
        className={styles['event-task-error']}
        onClick={(e) => e.preventDefault()}>
        <Tooltip
          placement="bottomLeft"
          title={<span>{eventError.description}</span>}>
          <span className={styles['error-message']}>
            <WarningOutlined />
            &nbsp; PROCESSING ERROR
          </span>
        </Tooltip>
        <Button
          size="small"
          type="text"
          className={styles['fetch-retry-btn']}
          onClick={() => this.openEmail()}>
          Contact Us
        </Button>
      </div>
    );
  }

  renderCardBodyPartTime(event) {
    return (
      <div
        className={styles['event-time-defaulted']}
        onClick={(e) => e.preventDefault()}>
        <UpdateEventTime event={event}>
          <WarningOutlined /> START TIME ESTIMATED{' '}
          <span className={styles['update-timecode-text']}> Update </span>
        </UpdateEventTime>
      </div>
    );
  }

  renderCardBodyTaskInCreatedState(event) {
    return (
      <div
        className={styles['event-task-error']}
        onClick={(e) => e.preventDefault()}
        title={event.CustomTaskStatus && event.CustomTaskStatus.State}>
        <span className={styles['info-message']}>Fetching from VMS</span>
      </div>
    );
  }

  renderCardBodyTaskInProgress(event) {
    return (
      <div
        className={styles['event-task-error']}
        onClick={(e) => e.preventDefault()}
        title={event.CustomTaskStatus && event.CustomTaskStatus.State}>
        <span className={styles['info-message']}>Processing...</span>
      </div>
    );
  }

  renderCardBodyTaskInErrorState(investigation, event) {
    return (
      <div
        className={styles['event-task-error']}
        onClick={(e) => e.preventDefault()}
        title={event.CustomTaskStatus && event.CustomTaskStatus.State}>
        <Tooltip
          placement="bottomLeft"
          title={
            <span>
              {' '}
              {(event.CustomTaskStatus &&
                event.CustomTaskStatus.StateMessage) ||
                'Error Message'}
            </span>
          }>
          <span className={styles['error-message']}>
            <WarningOutlined />
            &nbsp; Error : No Connection
          </span>
        </Tooltip>
        <Button
          size="small"
          type="text"
          className={styles['fetch-retry-btn']}
          style={{ textTransform: 'uppercase' }}
          onClick={() => {
            this.props.dispatch({
              type: 'investigation_events/investigationRetryFetch',
              investigationID: investigation.InvestigationID,
              eventID: event.InvestigationEventID,
            });
          }}>
          Retry
        </Button>
      </div>
    );
  }

  renderCardBody(investigation, event, eventMedia) {
    const eventError = eventMediaProcessingGetError(eventMedia);
    const eventDone = eventMediaProcessingIsDone(eventMedia);

    // Status message priority order:
    // - errors > defaulted time > in progress > created
    let cardStatus;
    if (eventError) {
      cardStatus = this.renderCardBodyPartError(eventError);
    } else if (isEventInErrorState(event)) {
      cardStatus = this.renderCardBodyTaskInErrorState(investigation, event);
    } else if (event.IsTimeDefaulted) {
      cardStatus = this.renderCardBodyPartTime(event);
    } else if (isEventInProgress(event)) {
      cardStatus = this.renderCardBodyTaskInProgress(event);
    } else if (isEventInCreatedState(event)) {
      cardStatus = this.renderCardBodyTaskInCreatedState(event);
    }

    // processing text
    let cardProcessing;
    if (eventError) {
      cardProcessing = <div className={styles['processing-text']} />;
    } else if (!eventDone) {
      cardProcessing = (
        <div className={styles['processing-text']}>Processing...</div>
      );
    }

    // thumbnail
    const eventThumbnailSrc = this.getEventThumbnailSrc(event, eventMedia);

    return (
      <div className="df-tile-body">
        {cardStatus}
        {/*TIME*/}
        <div
          className={classnames(
            styles['event-time'],
            event.IsTimeDefaulted ? styles['time-defaulted-color'] : null,
          )}>
          <DateRangeText start={event.EventStart} end={event.EventEnd} long />
        </div>
        {/*IMAGE*/}
        {eventThumbnailSrc ? (
          <ImageWithBlurBG src={eventThumbnailSrc} />
        ) : (
          <Icon style={{ fontSize: 25 }} component={ChannelIcon || 'file'} />
        )}
        {/*PROCESSING*/}
        {cardProcessing}
      </div>
    );
  }

  renderCardFooter(investigation, event) {
    const header = `${_.get(event, 'Channel.Project.Name')} ${
      _.get(event, 'Channel.Name', false) ? '•' : ''
    } ${_.get(event, 'Channel.Name', '')}`;

    return (
      <div className="df-tile-footer">
        <div className={styles['event-header-text']} title={header}>
          {header}
        </div>

        <div>
          <Dropdown
            overlay={this.renderMenuEvents(investigation, event)}
            trigger={['click', 'hover']}>
            <Icon
              className={'more-dots-icon'}
              component={MoreDotsIcon}
              style={{
                float: 'right',
                position: 'absolute',
                right: '4px',
              }}
            />
          </Dropdown>
        </div>

        <div className={styles['event-name-text']} title={event.Name}>
          {event.Name}
        </div>
      </div>
    );
  }

  render() {
    const {
      investigation,
      event,
      eventMedia,
      loadingInvestigationEventDependencies,
    } = this.props;
    const { deleteEventFlag } = this.state;

    let link = `/investigations/${investigation.InvestigationID}/events/${event.InvestigationEventID}`;
    if (isEventInProgress(event)) {
      link = '#';
    }

    return (
      <div className="df-tile-container">
        <Link to={link || '#'}>
          <div
            className={classnames(
              'df-tile-content',
              _.get(event, 'Channel.CreatedFrom') === 'FILE-UPLOAD' ||
                isEventInErrorState(event) ||
                isEventInCreatedState(event) ||
                isEventInProgress(event)
                ? styles['time-defaulted']
                : '',
            )}>
            {/* LOADING */}
            {deleteEventFlag && loadingInvestigationEventDependencies ? (
              <div className={styles['loader-spinner-ctn']}>
                <LoadingSpinner />
              </div>
            ) : null}

            {this.renderCardBody(investigation, event, eventMedia)}
            {this.renderCardFooter(investigation, event)}
          </div>
        </Link>
      </div>
    );
  }
}
export default InvestigationEventCard;
