import LineDivider from '@/components/LineDivider';
import PageHeader from '@/components/PageHeader2';
import AccessRules from '@/pages/account/access-rules';
import UsersPermissions from '@/pages/account/users-permissions';
import { Tabs } from 'antd';
import { useSelector } from 'umi';
import { APP_ID } from './constants';
import Monitoring from './monitoring';

const FleetManagerApp = () => {
  const appConfig = useSelector(
    (state) => state.apps.all.filter((a) => a.AppID == APP_ID)[0],
  );
  const capabilities = _.get(appConfig, 'capabilities', []);
  let tabsToShow = FleetManagerApp.CAPABILITIES;

  if (capabilities.indexOf('*') == -1) {
    tabsToShow = tabsToShow.filter((tab) => capabilities.indexOf(tab) !== -1);
  }

  return (
    <div>
      <PageHeader title="Fleet Manager" />
      <div style={{ paddingRight: 16 }}>
        <Tabs>
          {tabsToShow.includes('users-and-roles') ? (
            <Tabs.TabPane key="users" tab="Users & Roles">
              <UsersPermissions
                hideRoleSelection
                hidePageHeader
                alwayShowAddUserOption
              />
              <LineDivider margin="16px 0" />
              <AccessRules />
            </Tabs.TabPane>
          ) : null}
          {tabsToShow.includes('monitoring') ? (
            <Tabs.TabPane key="monitoring" tab="Monitoring">
              <Monitoring />
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
};

FleetManagerApp.CAPABILITIES = ['users-and-roles', 'monitoring'];
FleetManagerApp.CAPABILITIES_LABEL_MAP = {
  'users-and-roles': 'Users & Roles',
  monitoring: 'Monitoring',
};

export default FleetManagerApp;
