import LocationsIcon from '@/assets/location-plain';
import { Icon } from '@ant-design/compatible';
import { Checkbox, Typography } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import ChannelTileAndName from './ChannelTileAndName';
import {
  ChannelGroupContainer,
  ChannelGroupHeaderContainer,
  ChannelGroupListContainer,
} from './styles';
import type { SiteChannelsListFragment$key } from './__generated__/SiteChannelsListFragment.graphql';

export const SiteChannelsListFragment = graphql`
  fragment SiteChannelsListFragment on Site {
    Name
    SiteID
    Channels(sort: [NAME_ASC]) {
      edges {
        node {
          Name
          ChannelID
          MonitorStatus
        }
      }
    }
  }
`;

interface SiteChannelsListProps {
  siteNode: SiteChannelsListFragment$key;
  allSelectedChannels?: string[];
  addChannel: (channelID: string | string[]) => void;
  removeChannel: (channelID: string | string[]) => void;
  toggleChannel: (channelID: string) => void;
  sidebarSearch?: string;
  showInactiveChannels: boolean;
}

const SiteChannelsList = ({
  siteNode,
  allSelectedChannels,
  addChannel,
  removeChannel,
  sidebarSearch,
  showInactiveChannels,
}: SiteChannelsListProps) => {
  const siteChannelsData = useFragment<SiteChannelsListFragment$key>(
    SiteChannelsListFragment,
    siteNode,
  );
  const channelEdges = siteChannelsData.Channels?.edges;
  const siteName = siteChannelsData.Name;
  const channelsToShow = (channelEdges ?? [])
    ?.filter((channel) => {
      if (!channel?.node?.ChannelID) return false; // Handle null or undefined channel and channel.node together
      const channelNode = channel.node;

      if (
        sidebarSearch &&
        !_.includes(channelNode?.Name?.toLowerCase(), sidebarSearch)
      ) {
        return false;
      }

      if (!showInactiveChannels && channelNode?.MonitorStatus === 'inactive') {
        return false;
      }

      return true;
    })
    .map((channel) => channel!.node);

  const [allSelected, setAllSelected] = useState(false);

  // Effect to calculate `allSelected` based on `selectedChannels`
  useEffect(() => {
    const allChannelIDs = channelsToShow
      .map((channel) => channel?.ChannelID)
      .filter(Boolean);
    const areAllSelected = allChannelIDs.every((channelID) =>
      allSelectedChannels?.includes(channelID as string),
    );
    setAllSelected(areAllSelected);
  }, [allSelectedChannels, channelsToShow]);

  const handleSelectAll = () => {
    const channelIDs = channelsToShow
      .map((channel) => channel?.ChannelID)
      .filter((id): id is string => id !== undefined && id !== null);

    if (allSelected) {
      removeChannel(channelIDs);
    } else {
      const channelsToAdd = channelIDs.filter(
        (channelID) => !allSelectedChannels?.includes(channelID),
      );
      addChannel(channelsToAdd);
    }
  };

  return (
    <ChannelGroupContainer>
      <ChannelGroupHeaderContainer>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon component={LocationsIcon} style={{ fontSize: 16 }} />
          <Typography style={{ fontWeight: 500 }}>{siteName}</Typography>
        </div>
        <Checkbox checked={allSelected} onChange={handleSelectAll} />
      </ChannelGroupHeaderContainer>
      <ChannelGroupListContainer>
        {channelsToShow?.map((channel) => (
          <ChannelTileAndName
            key={channel?.ChannelID}
            siteName={siteName as string}
            channelID={channel?.ChannelID as string}
            channelName={channel?.Name || ''}
            selected={
              allSelected ||
              allSelectedChannels?.includes(channel?.ChannelID as string)
            }
            onClick={() => {
              if (allSelectedChannels?.includes(channel?.ChannelID as string)) {
                removeChannel(channel?.ChannelID as string);
              } else {
                addChannel(channel?.ChannelID as string);
              }
            }}
          />
        ))}
      </ChannelGroupListContainer>
    </ChannelGroupContainer>
  );
};

export { SiteChannelsList };
