import React from 'react';
import styles from './style.less';

class PublicReportSection extends React.Component {
  render() {
    const { media, reportItem } = this.props;
    return (
      <div className={styles['report-section']}>
        <div className={styles['report-section-media']}>{media}</div>
        {reportItem.ShowInfo ? (
          <div className={styles['report-section-form']}>
            <div className={styles['report-item-title']}>
              {reportItem.Title}
            </div>
            <div className={styles['report-item-description']}>
              {(reportItem.Description || '').split('\n').map((l, k) => (
                <p key={k}>{l}</p>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default PublicReportSection;
