import LoadingSpinner from '@/components/LoadingSpinner';
import { recordTransaction } from '@/monitoring';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.less';
import { negotiate, useStunServerConfig, useTurnServerConfig } from './utils';

// Track load times for webrtc streams via our monitoring system
const SUCCESS_TRANSACTION_NAME = 'webrtc_streaming_success';
const FAILURE_TRANSACTION_NAME = 'webrtc_streaming_failure';
const RELAY_TRANSACTION_NAME = 'webrtc_using_relay';
const PEER_TRANSACTION_NAME = 'webrtc_using_peers';

type WebRTCVideoPlayerProps = {
  baseStationID: string;
  channelID: string;
  playTObjAfterLoading: Function;
};

const WebRTCVideoPlayer: React.FC<WebRTCVideoPlayerProps> = ({
  baseStationID,
  channelID,
  playTObjAfterLoading,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const pcRef = useRef<RTCPeerConnection | null>(null);
  const startTimeRef = useRef<number | null>(null);
  const hasLoggedCandidateType = useRef<boolean>(false);
  const [isNegotiating, setIsNegotiating] = useState(true);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const stunServerConfig = useStunServerConfig();
  const turnServerConfig = useTurnServerConfig();

  const handleFailure = (err: string) => {
    setError(err);
    if (startTimeRef.current) {
      const duration = Date.now() - startTimeRef.current;
      recordTransaction(FAILURE_TRANSACTION_NAME, duration);
    }
  };

  const startNegotiation = async () => {
    try {
      const config = {
        sdpSemantics: 'unified-plan',
        iceServers: [stunServerConfig, turnServerConfig].filter(Boolean),
      };

      const pc = new RTCPeerConnection(config);
      pcRef.current = pc;

      pc.addEventListener('iceconnectionstatechange', () => {
        if (
          pc.iceConnectionState === 'disconnected' ||
          pc.iceConnectionState === 'failed' ||
          pc.iceConnectionState === 'closed'
        ) {
          handleFailure('Connection error');
        }
      });

      pc.addEventListener('connectionstatechange', () => {
        if (
          pc.connectionState === 'disconnected' ||
          pc.connectionState === 'failed' ||
          pc.connectionState === 'closed'
        ) {
          handleFailure('Connection error');
        }
      });

      pc.addEventListener('track', (evt) => {
        const track = evt.track;

        track.onended = () => {
          handleFailure('Live stream stopped or interrupted');
        };

        if (evt.track.kind === 'video') {
          if (videoRef.current) {
            videoRef.current.srcObject = evt.streams[0];
            videoRef.current.play();
            setIsVideoLoading(true);
          }
        }
      });

      pc.addEventListener('icecandidate', (event) => {
        if (event.candidate?.candidate && !hasLoggedCandidateType.current) {
          const candidateType = event.candidate.candidate.split(' ')[7];
          if (candidateType === 'relay') {
            recordTransaction(RELAY_TRANSACTION_NAME, 10);
          } else {
            recordTransaction(PEER_TRANSACTION_NAME, 10);
          }
          hasLoggedCandidateType.current = true;
        }
      });

      await negotiate(pc, baseStationID, channelID, startTimeRef.current);
      setIsNegotiating(false);
    } catch (e: any) {
      const message = _.get(e, 'message', 'Something went wrong');
      handleFailure(message);
      setIsNegotiating(false);
    }
  };

  const startLiveStream = () => {
    playTObjAfterLoading();
    setIsVideoLoading(false);
    setIsNegotiating(true);
    startTimeRef.current = Date.now();
    startNegotiation();
  };

  const closeConnection = () => {
    if (pcRef.current) {
      const pc = pcRef.current;

      pc.getSenders().forEach((sender) => pc.removeTrack(sender));
      pc.getReceivers().forEach((receiver) => receiver.track.stop());

      pc.close();
      pc.onicecandidate = null;
      pc.ontrack = null;
      pc.oniceconnectionstatechange = null;
      pc.onconnectionstatechange = null;
      pcRef.current = null;
    }

    if (videoRef.current && videoRef.current.srcObject) {
      (videoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    startLiveStream();
    return () => {
      closeConnection();
    };
  }, [baseStationID, channelID]);

  return (
    <div className={styles.ctn}>
      <div className={styles['video-ctn']}>
        {error && (
          <div className={styles['stream-failed-ctn']}>
            <div style={{ marginBottom: '10px' }}>{error}</div>
            <Button
              onClick={() => {
                setError(null);
                startLiveStream();
              }}
              type="primary">
              Retry
            </Button>
          </div>
        )}
        {(isNegotiating || isVideoLoading) && (
          <LoadingSpinner
            color="#FFF"
            position="absolute"
            text={'Loading Live Stream...'}
          />
        )}
        {!error && (
          <div
            style={
              isNegotiating
                ? { display: 'none' }
                : { height: '100%', width: 'auto' }
            }>
            <video
              className={styles['video-element']}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onLoadedData={() => {
                setIsVideoLoading(false);
                if (startTimeRef.current) {
                  const duration = Date.now() - startTimeRef.current;
                  recordTransaction(SUCCESS_TRANSACTION_NAME, duration);
                }
              }}
              ref={videoRef}
              width="100%"
              height="100%"
              muted={true}></video>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebRTCVideoPlayer;
