import { labelSearchFilterFunction } from '@/utils/utils';
import { Select } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'umi';
import styles from './style.less';

const LabelAndSiteSelector: React.FC<{
  app_id?: number;
  onSitesChange: (value) => unknown;
}> = ({ app_id = null, onSitesChange }) => {
  // dict of site/label objects with ids as keys
  const sites = useSelector((state) => state.sites.byID);
  const labels = useSelector((state) => state.labels.byID);

  // List of site/label objects we are allowed access to. Each object is guaranteed to contain the site/label id atleast
  const scopedSites = useSelector((state) => {
    if (app_id) {
      const app = state.apps.all.find((a) => a.AppID === app_id);
      return app.scopes.sites;
    } else {
      return state.sites.all.map((site) => {
        return { id: site.SiteID };
      });
    }
  }).sort((a, b) => sites[a.id].Name.localeCompare(sites[b.id].Name));

  const scopedLabels = useSelector((state) => {
    if (app_id) {
      const app = state.apps.all.find((a) => a.AppID === app_id);
      return app.scopes.labels;
    } else {
      return state.labels.all.map((label) => {
        return { id: label.LabelID };
      });
    }
  }).sort((a, b) => labels[a.id].Name.localeCompare(labels[b.id].Name));

  const [selectedSites, setSelectedSites] = useState<number[]>([]);

  const onSiteSelectorChange = (value) => {
    setSelectedSites(value);
    onSitesChange(value);
  };

  const addToSitesFromLabel = (sitesList: number[], label_id: number) => {
    for (const site of labels[label_id].Sites) {
      if (sitesList.indexOf(site.SiteID) === -1) {
        sitesList.push(site.SiteID);
      }
    }
  };

  const populateSitesFromLabels = (newLabels: number[]) => {
    const newSelectedSites: number[] = [];
    for (const label_id of newLabels) {
      addToSitesFromLabel(newSelectedSites, label_id);
    }
    onSiteSelectorChange(newSelectedSites);
  };

  return (
    <div className={styles['LabelAndSiteSelector']}>
      {scopedLabels.length > 0 && (
        <>
          <Select
            showSearch={true}
            filterOption={labelSearchFilterFunction}
            mode="multiple"
            placeholder="Select Site Groups..."
            className={styles['LabelAndSiteSelector-select']}
            onChange={populateSitesFromLabels}>
            {scopedLabels.map((label) => {
              return (
                <Select.Option
                  key={label.id}
                  value={label.id}
                  label={labels[label.id].Name}>
                  {labels[label.id].Name}
                </Select.Option>
              );
            })}
          </Select>
          &nbsp;&nbsp;
        </>
      )}
      <Select
        showSearch={true}
        filterOption={labelSearchFilterFunction}
        mode="multiple"
        className={styles['LabelAndSiteSelector-select']}
        placeholder="Select Sites..."
        onChange={onSiteSelectorChange}
        value={selectedSites}>
        {scopedSites.map((site) => {
          return (
            <Select.Option
              key={site.id}
              value={site.id}
              label={sites[site.id].Name}>
              {sites[site.id].Name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default LabelAndSiteSelector;
