import TimelinePlayer from '@/components/TimelinePlayer';
import { getFlexibleDateFormat, linkTo } from '@/utils/utils';
import { PlayCircleOutlined } from '@ant-design/icons';
import styles from './style.less';

const ReviewQueueCard = ({
  alert,
  alertTitle,
  controls,
  timelineIsVisible = true,
  style = undefined,
  onTimeLineClick = () => {},
}) => {
  return (
    <div key={alert.clip.id} className={styles.qcard} style={style}>
      <>
        <div className={styles['qcard-summary']}>
          <div>
            <div className={styles['qcard-left']}>
              <div>Alert for&nbsp;</div>
              {alertTitle}
            </div>
            <div style={{ fontSize: '12px' }}>
              {linkTo(
                'LOCATION',
                { locID: alert.location_obj?.ProjectID },
                alert.location,
              )}
              {' > '}
              {linkTo(
                'CHANNEL',
                {
                  locID: alert.location_obj?.ProjectID,
                  chID: alert.channel_obj?.ChannelID,
                },
                alert.channel,
              )}
            </div>
          </div>
          <div className={styles['qcard-right']}>
            <div>
              {alert.elapsed_time_str},{' '}
              {alert.clip.timeframeStartMoment?.fromNow()}
            </div>
            <div style={{ fontSize: '12px' }}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {getFlexibleDateFormat(alert.from, true)}
              </span>
              {' - '}
              <span style={{ whiteSpace: 'nowrap' }}>
                {getFlexibleDateFormat(alert.to, true)}
              </span>
            </div>
          </div>
        </div>
        <div
          className={styles['qcard-timeline']}
          style={{ borderRadius: 0 }}
          onClick={onTimeLineClick}>
          {timelineIsVisible && (
            <div className={styles['qcard-player']}>
              <TimelinePlayer
                events={_.get(alert, 'events')}
                startTime={alert.from && alert.from.valueOf() / 1000}
                endTime={alert.to && alert.to.valueOf() / 1000}
                channelIDs={[alert.clip.ChannelID]}
              />
            </div>
          )}
          {!timelineIsVisible && (
            <div className={styles['qcard-play']}>
              <PlayCircleOutlined />
            </div>
          )}
        </div>
        <div className={styles['qcard-controls']}>{controls}</div>
      </>
    </div>
  );
};

export default ReviewQueueCard;
