import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'umi';

import styles from './styles.less';

type Props = {
  onChange: any;
};

//Old code for adding time in case of an investigation sidebar is commented out
const AddComments: React.FC<Props> = (props: Props) => {
  const { onChange } = props;
  const [noteOpen, setNoteOpen] = useState(false);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects['apps/doAppOp'];
  });
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onChange(values.comment);
    // formRef.current.validateFields().then(
    //   (values) => {
    //     // add annotation info as well
    //     let toSave = {
    //       ...values,
    //       annotationTime: container.state.annotationTime,
    //     };
    //     opsProvider.addNote(opContext, toSave);
    //     container.setState({ annotationTime: null });
    //   },
    //   (err: any) => console.log('err', err),
    // );
  };

  useEffect(() => {
    if (noteOpen && !isLoading) {
      setNoteOpen(false);
      form.resetFields();
    }
  }, [isLoading]);

  return (
    <div className={styles['container']}>
      <div className={noteOpen ? styles['form-open'] : styles['form']}>
        <Form onFinish={onFinish} form={form}>
          <Form.Item
            name="comment"
            rules={[{ required: true, message: 'Please enter a comment' }]}>
            <Input.TextArea
              className={styles['input']}
              placeholder="Add a comment"
            />
          </Form.Item>
          {/* {container.getTimelineTime ? (
                container.state.annotationTime ? (
                  <div className={styles['new-note-time']}>
                    <div style={{ display: 'flex' }}>
                      <div>Annotated:</div>
                      &nbsp;
                      <div style={{ fontWeight: 500 }}>
                        {getFlexibleDateFormat(
                          1000 * container.state.annotationTime?.timestamp,
                          true,
                          false,
                          container.state.annotationTime?.timezone,
                        )}
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        container.setState({ annotationTime: null })
                      }>
                      <CloseCircleOutlined />
                    </div>
                  </div>
                ) : (
                  <Button
                    onClick={() =>
                      container.setState({
                        annotationTime: container.getTimelineTime(),
                      })
                    }
                    icon={<ClockCircleOutlined />}
                    size="small"
                    type="link">
                    Add Time Annotation
                  </Button>
                )
              ) : (target.InvestigationID ?
                <div className={styles['new-note-time']}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ClockCircleOutlined />
                    &nbsp;
                    <div>Use</div>
                    &nbsp;
                    <Link
                      to={`/investigations/${target.InvestigationID}/timeline`}>
                      Timeline
                    </Link>
                    &nbsp;
                    <div>to add time annotations.</div>
                  </div>
                </div>
                : null)} */}
          <div className={styles['buttons-row']}>
            <Button
              size="medium"
              onClick={() => {
                setNoteOpen(false);
                // setAnnotationTime(null);
              }}>
              Cancel
            </Button>
            <Button
              className={styles['save']}
              size="medium"
              type="primary"
              htmlType="submit"
              loading={isLoading}>
              Save
            </Button>
          </div>
        </Form>
      </div>
      {!noteOpen && (
        <Button
          onClick={() => {
            setNoteOpen(true);
            form.getFieldInstance('comment').focus();
          }}
          size="medium"
          className={styles['btn']}>
          Add a comment
        </Button>
      )}
    </div>
  );
};
export default AddComments;
