import { Tabs } from 'antd';
import _ from 'lodash';
import React from 'react';

import LineFilters from '@/components/SearchForm2/LineFilters';
import PathFilters from '@/components/SearchForm2/PathFilters';
import RegionFilters from '@/components/SearchForm2/RegionFilters';
import styles from './style.less';

type State = any;

export type Props = {
  baseStationVersion?: string;
  channelNode: any;
  value: any;
  onChange: Function;
  filtersToShow?: FILTERS[];
};

export enum FILTERS {
  'LINE_CALIBRATE' = 'Line_Calibrate',
  'LINE' = 'Line',
  'REGION' = 'Region',
  'PATH' = 'Path',
}

class SpatialFilters extends React.Component<Props, State> {
  static defaultProps = {
    value: undefined,
    onChange: () => {},
  };
  constructor(props: Props) {
    super(props);
    let spatialFilters = _.get(this.props, 'value');

    if (spatialFilters === undefined) {
      spatialFilters = _.get(this.props, 'channelNode.spatialFilters');
    }

    this.state = {
      lineFilters: _.get(spatialFilters, 'lineFilters', []),
      regionFilters: _.get(spatialFilters, 'regionFilters', []),
      pathFilters: _.get(spatialFilters, 'pathFilters', []),
    };
  }

  render() {
    const channelNode = _.get(this.props, 'channelNode', null);
    let media = _.get(this.props, 'channelNode.Media[0]');
    if (!media || !_.get(media, 'Thumbnail.SignedUrl')) {
      media = _.get(this.props, 'channelNode.LatestMedia[0]');
    }
    const {
      baseStationVersion,
      onChange,
      filtersToShow = [FILTERS.LINE, FILTERS.REGION, FILTERS.PATH],
    } = this.props;

    let getFilter = (type: FILTERS) => {
      if (type === FILTERS.LINE_CALIBRATE) {
        return (
          <LineFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ lineFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.lineFilters}
            hasCalibration={true}
          />
        );
      }
      if (type === FILTERS.LINE) {
        return (
          <LineFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ lineFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.lineFilters}
          />
        );
      }
      if (type === FILTERS.REGION) {
        return (
          <RegionFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ regionFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.regionFilters}
          />
        );
      }
      if (type === FILTERS.PATH) {
        return (
          <PathFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ pathFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.pathFilters}
          />
        );
      }
    };

    if (!filtersToShow.length) {
      return null;
    }

    if (filtersToShow.length == 1) {
      return (
        <div className={styles['spatial-filters-ctn']}>
          {getFilter(filtersToShow[0])}
        </div>
      );
    }
    return (
      <div className={styles['spatial-filters-ctn']}>
        <div className={styles['tab-ctn']}>
          <Tabs defaultActiveKey={filtersToShow[0]} centered>
            {filtersToShow.map((type) => {
              return (
                <Tabs.TabPane
                  tab={<span className={styles['tab-style']}>{type}</span>}
                  key={type}>
                  {getFilter(type)}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default SpatialFilters;
