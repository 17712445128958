import {
  createSummary,
  deleteInvestigationEvent,
  deleteSummary,
  getInvestigationEvent,
  getInvestigationEventDependencies,
  getInvestigationEvents,
  getSummary,
  getSummaryDependencies,
  getSummaryVideoUntilProcessed,
  retryEventFetch,
  updateInvestigationEvent,
  updateInvestigationEventSummaryName,
} from '@/services/investigation_events';
import { getCurrentCustomerID, populateSearch2Payload } from '@/utils/utils';
import { notification } from 'antd';
import _ from 'lodash';

import {
  processGetInvestigationEvent,
  processGetInvestigationEvents,
  processUpdateInvestigationEvent,
  updateEventMedia,
} from '@/types/investigations';
import type { Model } from 'dva';

const eventTarget = (() => {
  let handle: any;
  const off = () => {
    handle = undefined;
    return handle;
  };
  return {
    on(fn: any) {
      handle = fn;
      return off;
    },
    off,
    trigger(e: any) {
      if (handle) {
        handle(e);
      }
    },
  };
})();

export type InvestigationsEventsModalState = {
  all: {
    events: any[];
  };
  byID: Record<number, any>;
  summaryReqsByID: Record<number, any>;
};

const InvestigationsEventsModal: Model & {
  state: InvestigationsEventsModalState;
} = {
  namespace: 'investigation_events',
  state: {
    all: {
      events: [],
    },
    byID: {},
    summaryReqsByID: {},
  },
  subscriptions: {
    setup({ dispatch }) {
      return eventTarget.on(dispatch);
    },
  },
  effects: {
    *fetchInvestigationEvents(action, { call, put }) {
      const { investigationID, p_number, p_size } = action;
      const response = yield call(() =>
        getInvestigationEvents(investigationID, p_number, p_size),
      );
      if (response.success) {
        yield put({
          type: 'saveInvestigationEvents',
          payload: response.data,
        });
        // todo
        const investigation_data = processGetInvestigationEvents(
          _.get(response, `data.events`, []),
        );
        if (Object.keys(investigation_data).length > 0) {
          eventTarget.trigger({
            type: 'investigations/saveInvestigationsData',
            data: investigation_data,
          });
        }
        updateEventMedia(
          eventTarget.trigger,
          _.get(response, `data.events`, []),
        );
        _.get(response, 'data.events', []).forEach((event) => {
          const { SummaryIndexStatus } = event;
          if (['REQUESTED', 'QUEUED', 'RUNNING'].includes(SummaryIndexStatus)) {
            setTimeout(() => {
              eventTarget.trigger({
                type: 'getSummary',
                investigationID: event.InvestigationID,
                eventID: event.InvestigationEventID,
              });
            }, 10000);
          }
        });
      }
    },
    *fetchInvestigationEventsNoLoader(action, { call, put }) {
      const { investigationID, p_number, p_size } = action;
      const response = yield call(() =>
        getInvestigationEvents(investigationID, p_number, p_size),
      );
      if (response.success) {
        yield put({
          type: 'saveInvestigationEvents',
          payload: response.data,
        });
        // todo
        const investigation_data = processGetInvestigationEvents(
          _.get(response, `data.events`, []),
        );
        if (Object.keys(investigation_data).length > 0) {
          eventTarget.trigger({
            type: 'investigations/saveInvestigationsData',
            data: investigation_data,
          });
        }
        updateEventMedia(
          eventTarget.trigger,
          _.get(response, `data.events`, []),
        );
        _.get(response, 'data.events', []).forEach((event) => {
          const { SummaryIndexStatus } = event;
          if (['REQUESTED', 'QUEUED', 'RUNNING'].includes(SummaryIndexStatus)) {
            setTimeout(() => {
              eventTarget.trigger({
                type: 'getSummary',
                investigationID: event.InvestigationID,
                eventID: event.InvestigationEventID,
              });
            }, 10000);
          }
        });
      }
    },
    *fetchInvestigationEvent(action, { call, put }) {
      const { investigationID, eventID } = action;
      const response = yield call(() =>
        getInvestigationEvent(investigationID, eventID),
      );
      if (response.success) {
        yield put({
          type: 'saveInvestigationEventByID',
          payload: response.data,
        });
        // todo
        const investigation_data = processGetInvestigationEvent(
          _.get(response, `data`, []),
        );
        if (Object.keys(investigation_data).length > 0) {
          eventTarget.trigger({
            type: 'investigations/saveInvestigationsData',
            data: investigation_data,
          });
        }
      }
    },
    *deleteInvestigationEvent(action, { call, put }) {
      const { investigationID, eventID } = action;
      const response = yield call(() =>
        deleteInvestigationEvent(investigationID, eventID),
      );
      if (response.success) {
        yield put({
          type: 'removeEvent',
          payload: { investigationID, eventID },
        });
        yield put({
          type: 'investigations/fetchInvestigation',
          investigation: {
            InvestigationID: investigationID,
          },
        });
        yield put({ type: 'investigations/fetchInvestigations' });
      }
    },
    *updateInvestigationEvent(action, { call, put }) {
      const { investigationID, eventID, payload } = action;
      const response = yield call(() =>
        updateInvestigationEvent(investigationID, eventID, payload),
      );
      if (response.success) {
        yield put({
          type: 'modifyInvestigationEvent',
          payload: response.data,
        });
        // todo
        const investigation_data = processUpdateInvestigationEvent(
          _.get(response, `data`, []),
        );
        if (Object.keys(investigation_data).length > 0) {
          eventTarget.trigger({
            type: 'investigations/saveInvestigationsData',
            data: investigation_data,
          });
        }
        updateEventMedia(eventTarget.trigger, _.get(response, `data`, []));
      }
    },
    *updateInvestigationEventSummaryName(action, { call, put }) {
      const { investigationID, eventID, payload } = action;
      const response = yield call(() =>
        updateInvestigationEventSummaryName(investigationID, eventID, payload),
      );
      if (response.success) {
        yield put({
          type: 'modifyInvestigationEvent',
          payload: response.data,
        });
      }
    },
    *investigationRetryFetch(action, { call, put }) {
      const { investigationID, eventID } = action;
      const response = yield call(() =>
        retryEventFetch(investigationID, eventID),
      );
      if (response.success) {
        yield put({
          type: 'modifyInvestigationEvent',
          payload: response.data,
        });
      }
    },
    *createSummary(action, { call, put }) {
      const { investigationID, eventID } = action;
      const response = yield call(() =>
        createSummary(investigationID, eventID),
      );
      if (response.success) {
        yield put({
          type: 'modifyInvestigationEvent',
          payload: response.data,
        });
        notification.open({
          message: ' We will send an email when its done',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        setTimeout(() => {
          eventTarget.trigger({
            type: 'getSummary',
            investigationID,
            eventID,
          });
        }, 10000);
      }
    },
    *deleteSummary(action, { call, put }) {
      const { investigationID, eventID } = action;
      const response = yield call(() =>
        deleteSummary(investigationID, eventID),
      );
      if (response.success) {
        yield put({
          type: 'modifyInvestigationEvent',
          payload: response.data,
        });
        yield put({ type: 'investigations/fetchInvestigations' });
      }
    },
    *fetchInvestigationEventDependencies(action, { call }) {
      const { investigationID, eventID } = action;
      const response = yield call(() =>
        getInvestigationEventDependencies(investigationID, eventID),
      );
      if (response.success) {
        return _.get(response, 'data', []);
      }
      return [];
    },
    *getSummary(action, { call, put }) {
      const { investigationID, eventID } = action;
      const response = yield call(() => getSummary(investigationID, eventID));
      if (response.success) {
        yield put({
          type: 'modifyInvestigationEvent',
          payload: response.data,
        });
        const { SummaryIndexStatus } = response.data;
        if (['REQUESTED', 'QUEUED', 'RUNNING'].includes(SummaryIndexStatus)) {
          setTimeout(() => {
            eventTarget.trigger({
              type: 'getSummary',
              investigationID,
              eventID,
            });
          }, 10000);
        }
      }
    },
    *getSummaryVideo(action, { call, put, select }) {
      const { investigationID, eventID } = action;
      // we are getting state from another model
      const getSearch = yield select((state: any) => state.search2);
      const search = { ...getSearch };
      const customerID = getCurrentCustomerID();

      const summaryPayload = {
        queryID: '3',
        ESVideoStartTime: `${search.dateRange[1].format(
          'YYYY-MM-DDTHH:mm:ss.000000',
        )}Z`,
        ESVideoEndTime: `${search.dateRange[0].format(
          'YYYY-MM-DDTHH:mm:ss.000000',
        )}Z`,
        Metadata: {
          CustomerID: customerID,
          ChannelID: _.get(search, 'channelIDs', []).map((_id: any) =>
            parseInt(_id),
          ),
        },
      };
      if (search.locationID.length > 0) {
        [summaryPayload.Metadata.ProjectID] = search.locationID;
      }
      //
      populateSearch2Payload(search, summaryPayload);
      summaryPayload.queryID = '3';

      const response = yield call(() =>
        getSummaryVideoUntilProcessed(investigationID, eventID, summaryPayload),
      );
      if (response.success) {
        const summaryReq = response.data;
        yield put({
          type: 'modifySummaryRequests',
          payload: summaryReq,
        });
        return response;
      }
      return response;
    },
    *fetchSummaryDependencies(action, { call }) {
      const { investigationID, eventID } = action;
      const response = yield call(() =>
        getSummaryDependencies(investigationID, eventID),
      );
      if (response.success) {
        return _.get(response, 'data', []);
      }
      return [];
    },
  },
  reducers: {
    saveInvestigationEvents(state, action) {
      return { ...state, all: action.payload };
    },
    saveInvestigationEventByID(state, action) {
      const { byID } = state;
      byID[action.payload.InvestigationEventID] = action.payload;
      return { ...state, byID };
    },
    modifyInvestigationEvent(state, action) {
      const { byID, all } = state;
      all.events = all.events.map((event: any) => {
        if (
          event.InvestigationEventID === action.payload.InvestigationEventID
        ) {
          event = { ...event, ...action.payload };
        }
        return event;
      });
      byID[action.payload.InvestigationEventID] = action.payload;
      return { ...state, all, byID };
    },
    removeEvent(state, action) {
      const { all } = state;
      const events = all.events.filter(
        (event: any) => event.InvestigationEventID !== action.payload.eventID,
      );
      return { ...state, all: { ...all, events } };
    },
    modifySummaryRequests(state, action) {
      const { summaryReqsByID } = state;
      summaryReqsByID[action.payload.SummarizationRequestID] = action.payload;
      return { ...state, summaryReqsByID };
    },
  },
};

export default InvestigationsEventsModal;
