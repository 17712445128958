import { useSelector } from 'umi';
import type { CustomerJourneyModelState, JourneyID } from '../constants';

export const useJourneyListSelector = (): JourneyID[] => {
  return useSelector(
    (state: { customer_journey: CustomerJourneyModelState }) =>
      state.customer_journey.journeys.all,
  );
};

export const useMap = (site_id: any) => {
  const sitesById = useSelector((state: any) => state.sites.byID);
  const maps = useSelector((state: any) => state.location_maps.all);
  if (!site_id) {
    return null;
  }
  const site = sitesById[site_id];
  const projectIds =
    site?.Projects.map((project: any) => project.ProjectID) || [];
  const mapsForSite = maps
    .filter((map: any) => {
      return projectIds.includes(map.ProjectID);
    })
    .sort((a: any, b: any) => {
      // Sort in descending order by the count of map.ChannelGraph.links
      return b.ChannelGraph.links.length - a.ChannelGraph.links.length;
    });
  if (mapsForSite.length) {
    return mapsForSite[0].LocationMapID;
  }
  return null;
};
