import Image from '@/components/Image';
import LoadingSpinner from '@/components/LoadingSpinner';
import StreamTypeSelector from '@/components/StreamTypeSelector';
import { STREAM_TYPES } from '@/utils/utils';
import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';
import styles from './style.less';

type Props = any;
type State = any;

// @ts-expect-error
@connect(({ user }, { channelID }) => {
  const imageData = _.get(user, `channelImageData[${channelID}]`, {});
  return { imageData };
})
class ChannelImageStream extends React.Component<Props, State> {
  updateInterval: NodeJS.Timer;
  constructor(props: Props) {
    super(props);
    this.state = {
      count: 0,
      streamType: props.streamType || STREAM_TYPES.CIF_1,
    };
    this.requestImageStream = this.requestImageStream.bind(this);
  }

  componentDidMount() {
    this.requestImageStream();
    this.updateInterval = setInterval(() => this.forceUpdate(), 5000);
  }

  componentDidUpdate(prevProps) {
    this.setState({ count: this.state.count + 1 });
    if (prevProps.channelID !== this.props.channelID) {
      this.requestImageStream();
    }
  }

  shouldComponentUpdate(prevProps) {
    let prevImage = this.getImageData(prevProps).data;
    let newImage = this.getImageData(this.props).data;
    if (!_.isEqual(prevImage, newImage)) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
    this.unrequestImageStream();
  }

  getImageData(props) {
    props = props || this.props;
    return props.imageData || {};
  }

  unrequestImageStream() {
    let { channelID, startTime, dispatch } = this.props;
    let { streamType } = this.state;

    if (!channelID) return;

    if (startTime) {
      dispatch({
        type: 'user/archivStreamOp',
        op: 'unrequest',
        channelID,
        streamType,

        startTime,
        sessionID: this.sessionID,
      });
    } else {
      dispatch({
        type: 'user/imageStreamOp',
        op: 'unrequest',
        channelID: this.props.channelID,
        streamType,
      });
    }
  }

  requestImageStream(e = undefined) {
    let { channelID, startTime, dispatch } = this.props;
    let { streamType } = this.state;

    if (!channelID) return;

    if (startTime) {
      const sessionID = `${channelID}-${startTime}`;
      this.sessionID = sessionID;
      dispatch({
        type: 'user/archivStreamOp',
        op: 'request',
        channelID,
        streamType,

        startTime,
        sessionID,
      });
    } else {
      dispatch({
        type: 'user/imageStreamOp',
        op: 'request',
        channelID,
        streamType,
      });
    }

    this.forceUpdate();
    if (e !== undefined) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }

  getCurrentImage() {
    return this.getImageData().data;
  }

  switchStreams(newStreamType) {
    const { channelID, streamChangeCallback } = this.props;
    const { streamType } = this.state;

    if (_.isEqual(newStreamType, streamType)) {
      return;
    }

    if (streamChangeCallback) {
      streamChangeCallback(channelID, newStreamType);
    }

    // first unrequest previous type before requesting new one
    this.unrequestImageStream();
    this.setState({ streamType: newStreamType }, () =>
      this.requestImageStream(),
    );
  }

  render() {
    const {
      channelID,
      hideBadge,
      showStreamTypeSelector,
      streamSelections,
      showLoader = false,
    } = this.props;
    const { streamType } = this.state;

    if (!channelID) return <></>;
    const isStale =
      new Date().getTime() - (this.getImageData().timestamp || 0) > 15000;

    if (this.getCurrentImage() === null) {
      if (showLoader && !isStale) {
        return <LoadingSpinner />;
      }
      if (showLoader && isStale) {
        // requested and waited for 5 secs
        return (
          <div className={styles['stream-failed-ctn']}>
            <div style={{ marginBottom: '10px', textAlign: 'center' }}>
              Video did not load. Try again later.
            </div>
            <Button onClick={this.requestImageStream} type="primary">
              Retry
            </Button>
          </div>
        );
      }
    }
    if (!this.getCurrentImage()) {
      return <></>;
    }

    const src = `data:image/jpeg;base64, ${this.getCurrentImage()}`;

    return (
      <div className={styles.ctn}>
        {!hideBadge && !isStale && (
          <div onClick={this.requestImageStream} className="df-tile-badge">
            <span>
              <span style={{ fontSize: '13px', lineHeight: '13px' }}>
                &bull;{' '}
              </span>
              <span>LIVE</span>
            </span>
          </div>
        )}
        {isStale && (
          <div
            onClick={this.requestImageStream}
            className="df-tile-badge"
            style={{ cursor: 'pointer' }}>
            <span>
              <span>
                Stopped <ReloadOutlined />
              </span>
            </span>
          </div>
        )}
        <div className={styles.imagectn}>
          <Image src={src} />
        </div>
        {showStreamTypeSelector && (
          <div className={styles['res-selector']}>
            <StreamTypeSelector
              channelID={channelID}
              streamType={streamType}
              streamSelections={streamSelections}
              onChange={(newStreamType) => this.switchStreams(newStreamType)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ChannelImageStream;
