import { SubHeaderTabs } from '@/components/AppHeader/SubHeader';
import { SubHeaderTitleText } from '@/components/AppHeader/SubHeader/styles';
import { useDfSubHeader } from '@/components/AppHeader/utils';
import LoadingSpinner from '@/components/LoadingSpinner';
import { DfThemeConfigProvider } from '@/layouts/DfThemeConfigProvider';
import { useLocation } from '@umijs/max';
import { Suspense, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { MonitorAppPathname } from './constants';
import { MonitorProvider, useMonitorContext } from './MonitorContext';
import { MonitorAppContainer } from './styles';

const Monitor = () => {
  const { subHeaderAuxControls } = useMonitorContext();
  const { renderSubHeader } = useDfSubHeader();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const activeTabIndex = useMemo(() => {
    switch (pathname) {
      case `${MonitorAppPathname}/active-scene`:
        return 0;
      case `${MonitorAppPathname}/team-scenes`:
        return 1;
      // case `${MonitorAppPathname}/my-scenes`:
      //   return 1;
    }

    return 0;
  }, [pathname]);

  return (
    <MonitorAppContainer>
      {renderSubHeader({
        title: <SubHeaderTitleText>Monitor</SubHeaderTitleText>,
        tabs: (
          <SubHeaderTabs
            tabs={[
              {
                title: 'Active Scene',
                onClick: () => {
                  navigate(`${MonitorAppPathname}/active-scene`);
                },
              },
              // {
              //   title: 'My Scenes',
              //   onClick: () => {
              //     navigate(`${MonitorAppPathname}/my-scenes`);
              //   },
              // },
              {
                title: 'Team Scenes',
                onClick: () => {
                  navigate(`${MonitorAppPathname}/team-scenes`);
                },
              },
            ]}
            activeTabIndex={activeTabIndex}
          />
        ),
        auxControls: subHeaderAuxControls,
      })}
      <Outlet />
    </MonitorAppContainer>
  );
};

const MonitorWithSuspense = () => {
  return (
    <DfThemeConfigProvider>
      <Suspense fallback={<LoadingSpinner />}>
        <MonitorProvider>
          <Monitor />
        </MonitorProvider>
      </Suspense>
    </DfThemeConfigProvider>
  );
};

MonitorWithSuspense.CAPABILITIES = ['scenes'];
MonitorWithSuspense.CAPABILITIES_LABEL_MAP = { scenes: 'Scenes' };

export default MonitorWithSuspense;
