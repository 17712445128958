import { Bar, Column, Heatmap, Line, Pie } from '@ant-design/charts';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import LoadingSpinner from '@/components/LoadingSpinner';
import PlayerModal from '@/components/PlayerModal';
import TimelinePlayer from '@/components/TimelinePlayer';
import type { LocationModalState } from '@/models/location_map';
import {
  convertHeatMapInsightResponse,
  convertInsightResponse,
  generateInsight,
  getFieldLabel,
  getFieldName,
  getFormatterForMetric,
  getNameForMetric,
  isLiveInsight,
  VIZS,
} from '@/utils/insight';
import { getTimezoneForList, HeatmapCue } from '@/utils/timeline';
import styles from './style.less';

import type { InsightClass } from '@/types/insights';
import type { CH_TYPE } from '@/types/location';
import type { HEAT_MAP_DATA } from '@/utils/insight';
import type {
  ColumnConfig,
  HeatmapConfig,
  LineConfig,
} from '@ant-design/charts';
import type { ColumnType } from 'antd/lib/table/interface';

const INSIGHT_PROCESSING_SETUP_INTERVAL = 5; // 5 seconds
const LIVE_EMBEDED_SETUP_INTERVAL = 1 * 60; // 60 seconds

interface MyProps {
  insight: InsightClass;
  height?: number | string;
  showCameras?: boolean;
  isEmbed?: boolean;
  playhead?: number;
  vizSpec?: Record<string, any>;
  ch?: CH_TYPE;
  location_maps?: LocationModalState;
  loadingGenerateInsightReport?: boolean;
  dispatch?: (_any: any) => Promise<any>;
}

interface MyState {
  channelIDs: number[];
  heatmapData: HEAT_MAP_DATA | null;
  graphChartData: ColumnConfig | null;
  tableColumns: ColumnType<any>[] | null;

  playerModalProps: Record<string, any>;
}

// @ts-expect-error
@connect(
  ({ locations, location_maps, loading }) => ({
    ch: locations.ch,
    location_maps,
    loadingGenerateInsightReport:
      loading.effects['insights/generateInsightReport'],
  }),
  null,
  null,
  { forwardRef: true },
)
class InsightChart extends React.PureComponent<MyProps, MyState> {
  static defaultProps = {
    showCameras: false,
    isEmbed: false,
  };

  heatmapCue: HeatmapCue;

  setupLiveAndEmbededInterval: number | null;
  setupLiveAndEmbededLastUpdated: number;
  setupInsightInProcessing: number | null;

  playerModalRef: React.RefObject<PlayerModal>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      channelIDs: [],
      heatmapData: null,
      graphChartData: null,
      tableColumns: null,

      playerModalProps: {},
    };
    this.heatmapCue = new HeatmapCue({ data: { container: this } });

    this.setupLiveAndEmbededInterval = null;
    this.setupLiveAndEmbededLastUpdated = 0;

    this.setupInsightInProcessing = null;

    this.playerModalRef = React.createRef<PlayerModal>();
  }

  static getGroupBys(group_by_facet: Record<string, any>) {
    // backwards compatibility for legacy single groupBy
    const groupBys: any[] = [];
    if ('groupBys' in group_by_facet) {
      groupBys.push(...group_by_facet['groupBys']);
    } else if ('groupBy' in group_by_facet) {
      groupBys.push(group_by_facet['groupBy']);
    }
    return groupBys;
  }

  componentDidMount(): void {
    const insight = _.get(this.props, 'insight', null);
    if (insight) {
      this.init();
    }
  }

  componentDidUpdate(prevProps: Readonly<MyProps>): void {
    const prev_insight = _.get(prevProps, 'insight', null);
    const insight = _.get(this.props, 'insight', null);

    const prev_showCameras = _.get(prevProps, 'showCameras', null);
    const showCameras = _.get(this.props, 'showCameras', null);

    if (
      insight &&
      (!_.isEqual(
        _.omit(prev_insight, 'InsightResponse.computed_at'),
        _.omit(insight, 'InsightResponse.computed_at'),
      ) ||
        prev_showCameras !== showCameras)
    ) {
      this.init();
    }
  }

  componentWillUnmount(): void {
    if (this.setupInsightInProcessing) {
      clearInterval(this.setupInsightInProcessing);
    }
    if (this.setupLiveAndEmbededInterval) {
      clearInterval(this.setupLiveAndEmbededInterval);
    }
  }

  init() {
    this.processInsightResponse();
  }

  getChannelIDs(config: Record<string, any>) {
    // if we have maps and regions
    let channelIDs = _.chain(
      _.get(config, 'region_facet.regions', []).map(
        (region: Record<string, any>) => {
          const locMapConfigs = _.get(
            this.props.location_maps,
            `byLocID[${region['locationID']}]`,
            [],
          );
          const mapConfig = _.find(
            locMapConfigs,
            (x) =>
              _.get(x, 'LocationMapID', null) ===
              _.get(region, 'locationMapID', null),
          );
          const chID = _.get(mapConfig, 'Channels', []).map(
            (channelInfo: Object) => _.get(channelInfo, 'Channel.ChannelID'),
          );
          return chID;
        },
      ),
    )
      .flatten()
      .value();

    // if we have pure channels
    channelIDs = channelIDs.concat(
      _.get(config, 'channel_facet.channels', [])
        .map((ch: Object) => _.get(ch, 'channel_id', null))
        .filter((ch_id: any) => ch_id),
    );
    return channelIDs;
  }

  getHeatmapData(
    insightResponse: Record<string, any>,
    insightConfig: Record<string, any>,
    channelIDs: number[],
    showCameras: boolean,
  ): HEAT_MAP_DATA | null {
    // similar logic to TimelinePlayer/index.tsx
    let timezone: string | null = null;
    _.forEach(channelIDs, (ch_id) => {
      const tz = _.get(this.props, `ch.byId[${+ch_id}].Timezone`, null);
      if (timezone === null) {
        timezone = tz;
      } else if (tz && timezone !== tz) {
        timezone = moment.tz.guess();
      }
    });

    const heatmapData = convertHeatMapInsightResponse(
      _.get(insightResponse, 'data', []),
      insightConfig,
      timezone,
    );

    if (heatmapData && showCameras) {
      heatmapData['channelIDs'] = channelIDs;
    }

    return heatmapData;
  }

  getGraphChartData(
    insightResponse: Record<string, any>,
    insightConfig: Record<string, any>,
  ) {
    const { chartConfig } = convertInsightResponse(
      insightResponse,
      insightConfig,
    );

    const insight_vizSpec = this.props.vizSpec ||
      _.get(insightConfig, 'vizSpec', null) || { showLegend: true };
    const {
      goalValue = null,
      viz = null,
      showLegend = false,
      showMean = false,
    } = insight_vizSpec;

    if (showLegend) {
      const oldlegend = _.get(chartConfig, ['oldLegend'], null);
      if (oldlegend) {
        chartConfig['legend'] = oldlegend;
      } else {
        chartConfig['legend'] = true;
      }
    } else {
      // store for later
      chartConfig['oldLegend'] = chartConfig['legend'];
      chartConfig['legend'] = false;
    }

    chartConfig['annotations'] = [];
    if (!_.includes(['heatTable', 'calHeatTable'], viz)) {
      const ann = [];
      if (goalValue) {
        ann.push({
          type: 'regionFilter',
          // need -1000, 1000 etc since without that the bar
          // charts get clipped
          start: [-100000, goalValue],
          end: [100000, 'max'],
          color: '#F4664ADD',
          top: true,
        });
        ann.push({
          type: 'line',
          top: true,
          start: ['min', goalValue],
          end: ['max', goalValue],
          text: {
            content: 'Goal',
            offsetX: viz === 'bar' ? -12 : 0,
            offsetY: viz === 'bar' ? 0 : 12,
            autoRotate: false,
            style: {
              textAlign: viz === 'bar' ? 'right' : 'left',
              fontSize: 10,
              fill: 'black',
              textBaseline: 'bottom',
              zIndex: 2,
            },
          },
          style: {
            stroke: '#F4664A',
            lineDash: [4, 4],
          },
        });
      }
      if (showMean) {
        ann.push({
          type: 'line',
          top: true,
          start: ['min', 'mean'],
          end: ['max', 'mean'],
          text: {
            content: 'Average',
            offsetX: viz === 'bar' ? -12 : 30,
            offsetY: viz === 'bar' ? 30 : 12,
            autoRotate: false,
            style: {
              textAlign: viz === 'bar' ? 'right' : 'left',
              fontSize: 10,
              fill: 'black',
              textBaseline: 'bottom',
            },
          },
          style: {
            stroke: '#0045F7',
            lineDash: [4, 4],
          },
        });
      }
      chartConfig['annotations'].push(...ann);
    }

    const values: any[] = (chartConfig['data'] || [])
      .map((x: any) => x['value'])
      .filter((x: any) => x);
    let minValue = Math.min(_.min(values), 0);
    let maxValue = _.max(values); // Math.max(_.max(values), 0);

    const compareWithSeries = chartConfig['compareWithData'];
    const hasCompareWith = !_.isEmpty(compareWithSeries);

    if (hasCompareWith) {
      _.forEach(compareWithSeries, (point, idx) => {
        let nextPoint = compareWithSeries[idx + 1];
        let position = [point.xField, point.value];

        minValue = Math.min(minValue, point.value);
        maxValue = Math.max(maxValue, point.value);

        chartConfig['annotations'].push({
          type: 'line',
          top: true,
          start: position,
          end: nextPoint ? [nextPoint.xField, nextPoint.value] : position,
          text: nextPoint
            ? null
            : {
                content: point.seriesField,
                offsetX: viz === 'bar' ? -25 : 12,
                offsetY: viz === 'bar' ? 0 : -12,
                style: {
                  textAlign: 'right',
                  fontSize: 10,
                  fill: 'black',
                  textBaseline: 'bottom',
                },
              },
          style: {
            stroke: 'green',
            lineWidth: 2,
          },
        });
      });
      _.forEach(compareWithSeries, (point) => {
        chartConfig['annotations'].push({
          type: 'dataMarker',
          top: true,
          position: [point.xField, point.value],
          line: null,
          point: {
            style: {
              stroke: 'green',
            },
          },
        });
      });

      chartConfig['yAxis'] = {
        ...(chartConfig['yAxis'] || {}),
        min: minValue,
        max: maxValue,
      };
    }

    return chartConfig;
  }

  openPlayerModal(startTime, channelIDs) {
    const playerModalProps = { startTime, channelIDs };
    this.setState({ playerModalProps }, () => {
      if (this.playerModalRef.current) {
        this.playerModalRef.current.toggleModal();
      }
    });
  }

  viewInContextRenderer = (_value, row) => {
    const { channelIDs = [] } = this.state;
    const { ch } = this.props;
    let timezone = getTimezoneForList(
      channelIDs
        .map((id) => _.get(ch, ['byId', +id], null))
        .filter((item) => item),
    );

    let startTime = moment.tz(row.time, timezone).valueOf() / 1000;

    return (
      <Button
        size="small"
        onClick={() => {
          this.openPlayerModal(startTime, channelIDs);
        }}>
        View
      </Button>
    );
  };

  getTableColumns(insightConfig: Record<string, any>) {
    const { group_by_facet = {}, metrics_facet = { metric: 'occupancy' } } =
      insightConfig;

    const groupBys = InsightChart.getGroupBys(group_by_facet);
    if (groupBys.length === 0) return null;

    const tableColumns = groupBys.map((groupBy: string) => ({
      key: groupBy,
      title: getFieldName(groupBy),
      dataIndex: groupBy === 'region' ? 'region_names' : groupBy,
      render: (_value: any, row: any) =>
        getFieldLabel(groupBy, group_by_facet, row).label,
    }));

    if (_.get(metrics_facet, 'metric') === 'custom') {
      tableColumns.push({
        key: 'expr_result',
        title: _.get(metrics_facet, 'custom.name', ''),
        dataIndex: 'expr_result',
        align: 'center',
      });
    } else {
      const metric = _.get(metrics_facet, 'metric');
      tableColumns.push({
        key: metric,
        title: getNameForMetric(metric, metrics_facet),
        dataIndex: metric,
        align: 'center',
        render: getFormatterForMetric(metric),
      });
    }

    // if we're time-indexed and channel-oriented, show a column to jump to context
    if (_.get(groupBys, [0], '') === 'time') {
      tableColumns.push({
        key: 'viewInContext',
        title: 'View In Context',
        align: 'center',
        render: this.viewInContextRenderer,
      });
    }

    return tableColumns;
  }

  processInsightResponse(): void {
    const { showCameras, insight } = this.props;

    const insightConfig = insight['Config'];
    if (!insightConfig) {
      return;
    }

    const insightResponse = insight['InsightResponse'];
    if (!insightResponse) {
      return;
    }

    const states: Record<string, any> = {
      channelIDs: [],
      heatmapData: null,
      graphChartData: null,
      tableColumns: null,
    };

    const channelIDs = this.getChannelIDs(insightConfig);
    states['channelIDs'] = channelIDs;

    const insightDataFound = _.get(insightResponse, 'data', []).length > 0;
    const isHeatmap =
      _.get(insightConfig, 'metrics_facet.metric') === 'heat_map';
    if (isHeatmap) {
      const heatmapData = this.getHeatmapData(
        insightResponse,
        insightConfig,
        channelIDs,
        !!showCameras,
      );
      states['heatmapData'] = heatmapData;
    } else if (insightDataFound) {
      const graphChartData = this.getGraphChartData(
        insightResponse,
        insightConfig,
      );
      states['graphChartData'] = graphChartData as ColumnConfig;

      const tableColumns = this.getTableColumns(insightConfig);
      states['tableColumns'] = tableColumns;
    }

    this.setState(states as MyState, () => {
      this.setupForInsightInProcessing();
      this.setupForLiveAndEmbeded();
    });
  }

  setupForInsightInProcessing(): void {
    const { insight, dispatch } = this.props;

    if (this.setupInsightInProcessing) {
      clearInterval(this.setupInsightInProcessing);
    }

    if (insight && dispatch) {
      this.setupInsightInProcessing = window.setInterval(() => {
        const insightStatus = _.get(insight, 'InsightResponse.status', null);
        if (insightStatus === 'processing') {
          generateInsight(dispatch, insight['InsightID'], null, true);
        } else {
          if (this.setupInsightInProcessing) {
            clearInterval(this.setupInsightInProcessing);
          }
        }
      }, INSIGHT_PROCESSING_SETUP_INTERVAL * 1000);
    }
  }

  setupForLiveAndEmbeded(): void {
    const { playhead, insight, dispatch } = this.props;

    if (this.setupLiveAndEmbededInterval) {
      clearInterval(this.setupLiveAndEmbededInterval);
    }

    if (insight && isLiveInsight(insight) && dispatch) {
      this.setupLiveAndEmbededInterval = window.setInterval(() => {
        if (
          playhead &&
          !(
            playhead > this.setupLiveAndEmbededLastUpdated &&
            playhead - this.setupLiveAndEmbededLastUpdated >
              LIVE_EMBEDED_SETUP_INTERVAL
          )
        ) {
          return;
        }
        generateInsight(dispatch, insight['InsightID'], null, true);
      }, LIVE_EMBEDED_SETUP_INTERVAL * 1000);
    }
  }

  getInsightCSV() {
    const insight = _.get(this.props, ['insight']);
    const tableColumns = _.get(this.state, ['tableColumns']);

    if (insight && tableColumns) {
      const data = _.get(insight, 'InsightResponse.data', []).map((row) => {
        const ret: Record<string, any> = {};
        _.forEach(tableColumns, (column) => {
          const title = _.get(column, 'title', null);
          const dataIndex = _.get(column, 'dataIndex', null);
          if (title && dataIndex) {
            ret[title] = _.get(row, `${dataIndex}`);
          }
        });
        return ret;
      });
      return data;
    }
    return [];
  }

  renderHeatmap(insightConfig: Record<string, any>): React.ReactNode {
    const { heatmapData } = this.state;
    if (heatmapData) {
      const {
        group_by_facet = {},
        // metrics_facet = { metric: 'occupancy' }
      } = insightConfig;

      const groupBys = InsightChart.getGroupBys(group_by_facet);
      if (groupBys.indexOf('time') !== -1) {
        return (
          <div className={styles['heatmap-ctn']}>
            <TimelinePlayer
              {...heatmapData}
              fitEventsOnAxis={true}
              showShare={false}
              restrictHistoryToStartEnd={true}
            />
          </div>
        );
      }

      // Impliment this using tabs
      const { hmCurrent = 1 } = this.state;
      const hmTotal = heatmapData.events.length;

      if (hmTotal == 0) return <></>;

      const heatmapEvent = heatmapData.events[hmCurrent - 1];
      this.heatmapCue.data.event = heatmapEvent;
      return (
        <div className={styles['paginated-heatmap-ctn']}>
          <div className={styles['pagination-ctn']}>
            {hmTotal && (
              <div style={{ marginRight: '16px', textAlign: 'center' }}>
                <div style={{ whiteSpace: 'nowrap' }}>Grouped by</div>
                <div style={{ whiteSpace: 'nowrap' }}>
                  {heatmapData.events[0].heatmapConfig.durationStr}
                </div>
              </div>
            )}
            <div className={styles['pagination']}>
              <Pagination
                defaultCurrent={1}
                current={hmCurrent}
                total={hmTotal}
                onChange={(page) => {
                  this.setState({
                    hmCurrent: page,
                    activeHeatmapEvent: heatmapData.events[page - 1],
                  });
                }}
                defaultPageSize={1}
                pageSize={1}
                itemRender={(page, type, originalElement) => {
                  if (type === 'page') {
                    return (
                      <div style={{ margin: '0 8px' }}>
                        {heatmapData.events[page - 1].heatmapConfig.frameLabel}
                      </div>
                    );
                  } else {
                    return originalElement;
                  }
                }}
              />
            </div>
          </div>
          <div style={{ position: 'relative' }} key={hmCurrent}>
            {this.heatmapCue.data.render()}
          </div>
        </div>
      );
    }
    return <></>;
  }

  renderGraphUsingVizSpec(
    insight_vizSpec: Record<string, any>,
    graphChartData: ColumnConfig,
  ): React.ReactNode {
    const {
      // label = null,
      goalValue = null,
      viz = null,
      showLegend = false,
      // showMean = false,
    } = insight_vizSpec;

    const values: any[] = (graphChartData['data'] || []).map(
      (x: any) => x['value'],
    );

    // different chart types require the fields to be set up just a bit
    // differently. the default is the Column chart.
    if (viz === 'heatTable') {
      // note that this 'Heatmap' is different from what we call heatmap. this is:
      // https://charts.ant.design/en/examples/heatmap/basic#basic
      const chartData = {
        ...graphChartData,
        yField: 'seriesField',
        yAxis: {
          ...graphChartData['yAxis'],
          // reset this in case it's set because there is a compareWith.
          // setting min/max throws off the display.
          // min: null,
          // max: null,
          label: {
            // reset this from what we overrode this to
            formatter: undefined,
          },
        },
        colorField: 'value',
        reflect: 'y',
        heatmapStyle: (item) =>
          goalValue && item.value > goalValue ? { fill: '#F4664A' } : {},
        label: showLegend
          ? {
              style: {
                fill: '#fff',
                shadowBlur: 2,
                shadowColor: 'rgba(0, 0, 0, .45)',
              },
            }
          : false,
      } as HeatmapConfig;
      return <Heatmap {...chartData} />;
    } else if (viz === 'calHeatTable') {
      // return <Heatmap {...this.configForCalHeatTable(config, showLegend)} />;
    } else if (_.includes(['average', 'last', 'total'], viz)) {
      const stats: Record<string, any> = {};
      stats['last'] = _.last(values);
      stats['total'] = _.sum(values);
      stats['average'] = values.length
        ? (stats.total / values.length).toFixed(0)
        : 0;

      const compareWithSeries = _.get(graphChartData, ['compareWithData'], []);
      const hasCompareWith = !_.isEmpty(compareWithSeries);
      let comparatorEl;
      if (hasCompareWith) {
        const compareValues = (compareWithSeries || []).map((x) => x.value);
        stats.compareWith = {};
        stats.compareWith.total = _.sum(compareValues);
        stats.compareWith.average = compareValues.length
          ? (stats.compareWith.total / compareValues.length).toFixed(0)
          : 0;

        let comparator;
        if (viz === 'total') {
          comparator = stats.compareWith.total;
        } else {
          comparator = stats.compareWith.average;
        }

        if (comparator) {
          const diff = Math.ceil(
            ((stats[viz] - comparator) * 100) / comparator,
          );
          // we don't know what constitutes as 'good'
          // let color = diff < 0 ? 'red' : 'green';
          let color = 'black';
          comparatorEl = (
            <div style={{ display: 'flex', fontSize: 14, color }}>
              <div>
                {diff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />}
              </div>
              <div style={{ marginLeft: 4 }}>{diff}%</div>
            </div>
          );
        }
      }

      // properly interpret
      const val = _.invoke(graphChartData, 'yAxis.label.formatter', stats[viz]);
      return (
        <div className="df-center" style={{ fontSize: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <div>{val}</div>
            {comparatorEl}
          </div>
        </div>
      );
    } else if (viz === 'bar') {
      const chartData = {
        ...graphChartData,

        // swap things
        xField: graphChartData['yField'],
        yField: graphChartData['xField'],
        xAxis: graphChartData['yAxis'],
      };
      delete chartData['yAxis'];
      return <Bar {...chartData} />;
    } else if (viz === 'goalCompletion') {
      const all = values.length;
      const aboveGoal = _.filter(values, (x) =>
        goalValue ? x > goalValue : false,
      ).length;
      const percent = Math.round((100 * (all - aboveGoal)) / all).toFixed(0);
      const content = `${percent}%`;

      const chartData = {
        data: [
          { type: 'Under', value: all - aboveGoal },
          { type: 'Over', value: aboveGoal },
        ],
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        legend: !!showLegend,
        color: (item) => (item.type === 'Over' ? '#F4664A' : undefined),
        label: {
          type: 'inner',
          offset: '-50%',
          content: '{value}',
          autoRotate: false,
          style: {
            textAlign: 'center',
            fontSize: 14,
          },
        },
        statistic: {
          title: false,
          content: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            customHtml: () => (
              <div>
                <div style={{ fontSize: 30 }}>{content}</div>
                <div style={{ fontSize: 12, fontWeight: 500 }}>success</div>
              </div>
            ),
          },
        },
      };
      return <Pie {...chartData} />;
    } else if (viz === 'line') {
      const chartData = {
        ...graphChartData,
        point: {},
      } as LineConfig;
      return <Line {...chartData} />;
    }

    return null;
  }

  renderGraph(insight: InsightClass): React.ReactNode {
    const { vizSpec, isEmbed } = this.props;
    const { graphChartData, tableColumns } = this.state;

    const insight_vizSpec = vizSpec ||
      _.get(insight, 'Config.vizSpec', null) || { showLegend: true };
    const { viz = null } = insight_vizSpec;

    if (graphChartData && VIZS.map((v) => v['value']).indexOf(viz) != -1) {
      const vizSpezGraph = this.renderGraphUsingVizSpec(
        insight_vizSpec,
        graphChartData,
      );
      if (vizSpezGraph) return vizSpezGraph;
    }

    return (
      <>
        {graphChartData && <Column {...graphChartData} />}
        {!isEmbed && tableColumns && (
          <div style={{ marginTop: '30px' }}>
            <Table
              pagination={{
                hideOnSinglePage: true,
                position: ['topRight', 'bottomRight'],
              }}
              rowKey={() => Math.random()}
              size="small"
              dataSource={_.get(insight, 'InsightResponse.data', [])}
              columns={tableColumns}
            />
          </div>
        )}
      </>
    );
  }

  render() {
    const { height, insight, isEmbed, loadingGenerateInsightReport } =
      this.props;

    if (insight) {
      const insightConfig = insight['Config'];
      const insightResponse = insight['InsightResponse'];

      const insightSuccess = _.get(insightResponse, 'status') === 'success';
      // const insightDataComputed = _.get(insightResponse, 'status') !== 'data not computed';
      const insightDataFound = _.get(insightResponse, 'data', []).length > 0;

      if (insightDataFound) {
        const isHeatmap =
          _.get(insightConfig, 'metrics_facet.metric') === 'heat_map';
        const ctn_height = height || (isHeatmap ? '100%' : '60vh');

        const { group_by_facet = {} } = insightConfig;
        const groupBys = InsightChart.getGroupBys(group_by_facet);
        let renderPlayerModal = false;
        if (_.get(groupBys, [0], '') === 'time') {
          renderPlayerModal = true;
        }

        return (
          <div
            key={insight['InsightID']}
            style={{
              position: 'relative',
              width: '100%',
              height: ctn_height,
            }}>
            {isHeatmap
              ? this.renderHeatmap(insightConfig)
              : this.renderGraph(insight)}
            {!isHeatmap && renderPlayerModal ? (
              <PlayerModal
                ref={this.playerModalRef}
                showLive={true}
                {...this.state.playerModalProps}></PlayerModal>
            ) : null}
          </div>
        );
      } else if (insightSuccess) {
        return (
          <div className={styles['info-container']}>
            No data was found for this selection.
          </div>
        );
      }
    } else if (isEmbed && loadingGenerateInsightReport) {
      return <LoadingSpinner />;
    }
    return <></>;
  }
}
export default InsightChart;
