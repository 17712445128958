import TimelinePlayer from '@/components/TimelinePlayer';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useSelector } from '@umijs/max';
import { Flex } from 'antd';
import React, { useMemo } from 'react';
import type { SceneFragment$data } from '../../components/Scene/__generated__/SceneFragment.graphql';
import { useMonitorContext } from '../../MonitorContext';
import {
  useSceneMarkingFavoriteActions,
  useSceneUpdateActions,
} from '../../MonitorMutations';
import { getSceneName } from '../../utils';
import { EditableName } from './EditableName';
import { NewSceneTimelinePlaceholder } from './NewSceneTimelinePlaceholder';
import { TimelinePlayerContainer } from './styles';

interface SceneTimelineProps {
  timelinePlayerRef: React.MutableRefObject<undefined>;
}

const SceneTimeline = ({ timelinePlayerRef }: SceneTimelineProps) => {
  const { appId, selectedChannels, selectedSceneData, monitorAppQueryData } =
    useMonitorContext();

  const sceneConnectionData = {
    id: monitorAppQueryData.vmsPlusConfig?.scenesData?.__id as string,
    key: 'VMSPlusConfig_scenes',
  };

  const { markAsFavorite, unMarkAsFavorite } = useSceneMarkingFavoriteActions({
    appId,
    sceneConnectionData,
    scene: selectedSceneData as SceneFragment$data,
  });

  const { updateSceneName } = useSceneUpdateActions({
    scene: selectedSceneData as SceneFragment$data,
    appId,
  });

  const { ch } = useSelector((state) => ({
    // @ts-expect-error
    ch: state.locations.ch,
  }));

  const sceneName = useMemo(
    () =>
      selectedSceneData?.name ??
      getSceneName(selectedSceneData?.channelIDs as string[], ch),
    [selectedSceneData, ch],
  );

  const chIDs = (selectedChannels || []).map((n) => parseInt(n));

  const handleUnStarClick = () => {
    unMarkAsFavorite();
  };

  const handleStarClick = () => {
    markAsFavorite();
  };

  const handleSceneNameChange = (name: string) => {
    updateSceneName(name);
  };

  const showTimelineHeader = !!selectedSceneData;

  return (
    <Flex vertical flex={1}>
      {showTimelineHeader && (
        <Flex gap={8} align="center" justify="space-between">
          <EditableName name={sceneName} onNameChange={handleSceneNameChange} />
          {selectedSceneData?.isFavorite ? (
            <StarFilled
              onClick={handleUnStarClick}
              style={{ color: '#ff9301', fontSize: '24px' }}
            />
          ) : (
            <StarOutlined
              onClick={handleStarClick}
              style={{ fontSize: '24px' }}
            />
          )}
        </Flex>
      )}
      <TimelinePlayerContainer>
        {chIDs.length > 0 ? (
          <TimelinePlayer
            // @ts-expect-error
            innerRef={timelinePlayerRef}
            autoPlay={true}
            channelIDs={chIDs}
            showShare={true}
            showLive={true}
            showLink={false}
          />
        ) : (
          <NewSceneTimelinePlaceholder />
        )}
      </TimelinePlayerContainer>
    </Flex>
  );
};

export { SceneTimeline };
