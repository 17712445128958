import { Link } from '@umijs/max';
import { Typography } from 'antd';
import styled from 'styled-components';

export const DfSubHeaderContainer = styled.div(
  ({ theme }) => `
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  padding: 0 24px;
  width: 100%;

  @media (min-width: ${theme.token?.screenSM}px) {
    padding: 0 32px;
  }
`,
);

export const BackPathLink = styled(Link)(
  ({ theme }) => `
    font-weight: ${theme.token?.fontWeightStrong};
    color: ${theme.token?.colorText};
`,
);

export const SubHeaderTitleText = styled(Typography.Text)(
  ({ theme }) => `
  font-size: 18px;
  font-weight: ${theme.token?.fontWeightStrong};
  display: flex;
  align-items: center;
  color: ${theme.token?.colorTextBase};
`,
);

export const SubHeaderTabTitle = styled(Typography.Text)<{ active?: boolean }>(
  ({ theme, active }) => `
  padding: 5px 10px;
  color: ${active ? theme.token?.colorPrimary : theme.token?.colorTextBase};
  font-size: 14px;
  font-weight: ${active ? '700' : '500'};
  background-color: ${active ? '#F0E4FA' : 'inherit'};
  cursor: pointer;
  border-radius: ${theme.token?.borderRadiusSM}px;
`,
);
