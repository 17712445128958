import DataList from '@/components/DataList';
import {
  getAssigneeData,
  getFlexibleDateFormat,
  getHumanizedTimeDiffString,
  isInternalUser,
} from '@/utils/utils';
import { Button, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useState } from 'react';
import { history, useDispatch, useSelector } from 'umi';
import { EventType, EVENT_TYPE_LABEL_MAPPING } from '../constants';
import styles from './styles.less';

const DEFAULT_PAGE_SIZE = 100;

const getEventTypeFilters = (currentUser: any) => {
  let keysToRemove: EventType[] = [];

  if (!isInternalUser(currentUser)) {
    keysToRemove = [EventType.INCIDENT_MOVED_FROM_REVIEW_QUEUE];
  }

  return Object.values(EventType)
    .filter((key) => !keysToRemove.includes(key))
    .map((key) => ({
      text: EVENT_TYPE_LABEL_MAPPING[key],
      value: key,
    }));
};

const ActivityLog = ({
  namespace,
  alarmClickHandler,
}: {
  namespace: string;
  alarmClickHandler: Function;
}) => {
  //Local state
  const [filters, setFilters] = useState({});

  //Read data from store
  const appId = useSelector((state) => state[namespace]['app_id']);
  const isLoading = useSelector(
    ({ loading }) => loading.effects[namespace + `/fetchActivityLog`],
  );
  const events = useSelector((state) => state[namespace]['log']);
  const userNameById = useSelector((state) => {
    const byId = {};
    state.accounts.users.forEach((obj) => {
      byId[obj.User.UserID] = `${obj.User.FirstName} ${obj.User.LastName}`;
    });
    return byId;
  });
  const users = useSelector((state) => state.accounts.users);
  const currentUser = useSelector((state) => state.user.currentUser);

  const dispatch = useDispatch();
  const fetchLog = (params = {}) => {
    return dispatch({
      type: namespace + `/fetchActivityLog`,
      payload: { ...{ p_number: 1, p_size: DEFAULT_PAGE_SIZE }, ...params },
    });
  };
  const timezone = moment.tz.guess();

  const logJsx = (
    <DataList
      isLoading={isLoading}
      columns={[
        {
          title: 'Time',
          render: (t) => {
            return getFlexibleDateFormat(
              moment.unix(t).tz(timezone),
              false,
              false,
              timezone,
            );
          },
          key: 'timestamp',
          dataIndex: 'timestamp',
        },
        {
          title: 'Type',
          render: (type: EventType) => {
            return EVENT_TYPE_LABEL_MAPPING[type];
          },
          key: 'type',
          dataIndex: 'type',
          filters: getEventTypeFilters(currentUser),
        },
        {
          title: 'User',
          render: (data) => {
            const user_id = _.get(data, ['user_id']);
            const name = user_id
              ? userNameById[user_id] || 'Unknown User'
              : '-';
            return name;
          },
          dataIndex: 'data',
          key: 'user',
          filters: getAssigneeData(users, currentUser).map(
            ({ label, value }) => {
              return {
                text: label,
                value,
              };
            },
          ),
        },
        {
          title: 'Alarm',
          render: (data) => {
            const alarm_id = _.get(data, ['alarm_id']);
            const alarm_name = _.get(data, ['alarm_name']);
            return alarm_name ? (
              <span
                className="df-link"
                onClick={() => {
                  alarmClickHandler({ alarm_ids: [alarm_id] });
                }}>
                {alarm_name}
              </span>
            ) : (
              '-'
            );
          },
          key: 'alarm',
          dataIndex: 'data',
        },
        {
          title: 'Details',
          render: (data, record) => {
            const event_type = record.type;
            if (
              event_type === EventType.ENABLE_CODE_USED ||
              event_type === EventType.DISABLE_CODE_USED
            ) {
              return (
                <span>
                  {data.code_for}&nbsp;({data.code})
                </span>
              );
            } else if (
              event_type === EventType.INCIDENT_ARCHIVED &&
              data.incident_id
            ) {
              let info = null;
              let creation_timestamp = data.incident_start_time;
              let archived_timestamp = record.timestamp;
              if (creation_timestamp && archived_timestamp) {
                let time_diff_string = getHumanizedTimeDiffString(
                  creation_timestamp,
                  archived_timestamp,
                );
                info = `Archived after ${time_diff_string}`;
              }
              return (
                <div>
                  {info}&nbsp;
                  <span
                    onClick={() => {
                      history.push(
                        `/apps/${appId}?alertID=${data.incident_id}`,
                      );
                    }}
                    className="df-link">
                    Details
                  </span>
                  &nbsp;&nbsp;
                  <Popconfirm
                    title="Are you sure you want to unarchive this incident?"
                    onConfirm={() => {
                      dispatch({
                        type: `${namespace}/unarchiveIncident`,
                        payload: {
                          id: data.incident_id,
                        },
                      });
                    }}
                    okText="Yes"
                    cancelText="No">
                    <span className="df-link">Unarchive</span>
                  </Popconfirm>
                </div>
              );
            } else if (
              (event_type === EventType.INCIDENT_PRIORITY_CHANGED ||
                event_type === EventType.INCIDENT_MOVED_FROM_REVIEW_QUEUE) &&
              data.old_priority &&
              data.new_priority
            ) {
              return (
                <div>
                  <span>
                    Changed priority from {data.old_priority} to{' '}
                    {data.new_priority}
                  </span>
                  &nbsp;
                  <span
                    onClick={() => {
                      history.push(
                        `/apps/${appId}?alertID=${data.incident_id}`,
                      );
                    }}
                    className="df-link">
                    Details
                  </span>
                </div>
              );
            }
          },
          dataIndex: 'data',
          key: 'details',
        },
      ]}
      dataList={_.get(events, 'list', [])}
      onChange={(pagination = {}, newFilters = {}) => {
        let _filters = { ...filters, ...newFilters };
        setFilters(_filters);
        fetchLog({ ..._filters, ...pagination });
      }}
      pagination={{
        p_number: _.get(events, 'p_number', 1),
        p_size: DEFAULT_PAGE_SIZE,
        total_pages: _.get(events, 'total_pages', 1),
        showSizeChanger: false,
      }}
      isControlled={true}
    />
  );

  return (
    <div>
      <div>
        <div className={styles['activity-log-cta-container']}>
          <Button
            onClick={() => {
              fetchLog(filters);
            }}>
            Refresh
          </Button>
        </div>
        {logJsx}
      </div>
    </div>
  );
};

export default ActivityLog;
