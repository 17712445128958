import { listenToSocket, sendToSocket } from '@/services/sockets';
import {
  changePassword,
  completeAccountSetup,
  forgotPassword,
  getCurrentUser,
  getCustomerJoinInviteDetails,
  getResetLinkInfo,
  loginUser,
  logoutUser,
  resetPassword,
  signupUser,
  signupViaCustomerInvite,
  ssoCallback,
  ssoLogin,
} from '@/services/user';
import { getCurrentCustomerID, getIdToken, getPageQuery } from '@/utils/utils';
import { notification } from 'antd';
import _ from 'lodash';
import { stringify } from 'querystring';
import cookie from 'react-cookies';
import { history } from 'umi';
import { v4 as uuidv4 } from 'uuid';

import type { Model } from 'dva';
// import { processFrontierStreamWSmessage } from '@/services/frontier_stream';
import { setUser } from '@/monitoring';
import { processPubSubMessage } from '@/services/pubsub';
// @ts-expect-error
// window.video_stream_queue = {};

const saveCustomerID = (customerID, currentUser) => {
  localStorage.setItem('currentCustomerID', customerID);
  if (window.DD_LOGS) {
    window.DD_LOGS.addLoggerGlobalContext('user_id', currentUser.UserID);
    window.DD_LOGS.addLoggerGlobalContext('customer_id', customerID);
    window.DD_LOGS.addLoggerGlobalContext('environment', ENVIRONMENT);
  }
  let customer = _.get(currentUser, ['Customers'], []).find(
    ({ Customer }) => Customer.CustomerID == customerID,
  );
  let customerName = customer ? customer.Customer.Name : '';
  setUser({
    id: `${currentUser.UserID}-${customerID}`,
    full_name: `${currentUser.FirstName} ${currentUser.LastName}@${customerName}`,
  });
};

const setupSubscriptions = (dispatch) => {
  const customerID = getCurrentCustomerID();
  if (!customerID) return;

  dispatch({
    type: 'getAllPersistentSubs',
  }).then((subs = {}) => {
    Object.values(subs).forEach((sub) =>
      dispatch({
        type: 'setupPersistentSub',
        payload: sub,
      }),
    );
  });
};

const UserModel: Model = {
  namespace: 'user',
  state: {
    currentUser: {},
    resetLinkUser: {},
    invite: undefined,
    errors: {},
    channelImageData: {},
    channelVideoData: {},
    channelArchiveData: {},
    userNotifications: {},
    userNotificationsCounter: 0,
    pubsubSubscriptions: {},
    videoProcessingUpdate: {},
    requestingBaseStationChannelConfig: false,
    baseStationChannelConfig: {},
  },
  subscriptions: {
    socketMessage({ dispatch }) {
      const actionHandler = (event) => {
        try {
          const wsMessage = JSON.parse(event.data);
          // console.log(wsMessage.type, dd(Date.now()));
          switch (wsMessage.type) {
            case 'pubsub_message':
              dispatch({
                type: 'getPersistentSub',
                payload: { pattern: wsMessage.pattern },
              }).then((sub) => {
                if (sub && sub.callback) {
                  sub.callback(wsMessage);
                } else {
                  processPubSubMessage(wsMessage, dispatch);
                }
              });
              break;
            case 'success_message':
            case 'error_message':
              const forID = _.get(wsMessage, 'for_id');
              // there might be cases where the middle-tier subscribes
              // to streams on our behalf, so we might not have the corresponding
              // id to match up with, to find the callbacks
              dispatch({
                type: 'getPersistentSub',
                payload: { id: forID },
              }).then((sub) => sub && sub.callback && sub.callback(wsMessage));

              if (wsMessage.type === 'error_message') {
                console.log('websocket error message', wsMessage);
              }
              break;
            case 'image_stream':
              dispatch({
                type: 'updateImageForChannel',
                payload: wsMessage.data,
              });
              break;
            case 'video_stream':
              if (wsMessage.data) {
                dispatch({
                  type: 'updateVideoStreamForChannel',
                  payload: wsMessage.data,
                });
              }
              break;
            case 'archive_video_stream':
              if (wsMessage.data) {
                wsMessage.data.stream_path = wsMessage.channel;
                dispatch({
                  type: 'updateArchiveStreamForChannel',
                  payload: wsMessage.data,
                });
              }
              break;
            case 'base_station_channel_config':
              dispatch({
                type: 'baseStaionChannelConfigUpdate',
                baseStationChannelConfig: wsMessage.data,
              });
              break;
            default:
          }
        } catch (e) {
          console.log(e);
          throw new Error(
            `Invalid websocket data from ${event.origin}: ${event.data}`,
          );
        }
      };

      const openHandler = () => {
        setupSubscriptions(dispatch);
      };

      return listenToSocket(actionHandler, openHandler);
    },
  },
  effects: {
    *imageStreamOp(action, { call, put }) {
      const { op, channelID, streamType, callback } = action;
      const id = `${channelID}-${streamType.key}`;

      let payloadType = 'request_image_stream';
      let putType = 'persistSub';
      if (op === 'unrequest') {
        payloadType = 'unrequest_image_stream';
        putType = 'unpersistSub';
      }

      const payload = {
        id,
        callback,
        type: payloadType,
        data: {
          channel_ids: [channelID],
          ...streamType,
        },
      };
      // set default value
      yield put({
        type: 'channelImage',
        payload: {
          channel_id: channelID,
          ...streamType,
          image_data: null,
          timestamp: new Date().getTime(),
        },
      });
      yield put({ type: putType, payload });
      yield call(sendToSocket, payload);
    },
    *videoStreamOp(action, { call, put }) {
      const { op, channelID, streamKey, streamType, callback } = action;
      let payloadType = 'request_video_stream';
      let putType = 'persistSub';
      if (op === 'unrequest') {
        payloadType = 'unrequest_video_stream';
        putType = 'unpersistSub';
      }

      const payload = {
        id: streamKey,
        callback,
        type: payloadType,
        data: {
          channel_ids: [channelID],
          ...streamType,
        },
      };

      yield put({ type: putType, payload });
      yield call(sendToSocket, payload);
      return true;
    },
    *archiveStreamOp(action, { call, put }) {
      const {
        op,
        channelID,
        streamKey,
        streamType,
        start_time,
        end_time,
        callback,
      } = action;
      let payloadType = 'request_archive_video_stream';
      let putType = 'persistSub';
      if (op === 'unrequest') {
        payloadType = 'unrequest_archive_video_stream';
        putType = 'unpersistSub';
      }

      const payload = {
        id: streamKey,
        callback,
        type: payloadType,
        data: {
          viewport: streamType.viewport,
          fps: streamType.fps,
          channel_id: channelID, // Channel ID
          start_time: start_time, // Start time in seconds since epoch
          end_time: end_time, // End time in seconds since epoch
          session_id: start_time + '', // Unique browser session id
        },
      };
      yield put({ type: putType, payload });
      yield call(sendToSocket, payload);
      return true;
    },
    *setupPersistentSub(action, { call, put }) {
      const { payload } = action;
      yield put({ type: 'persistSub', payload });
      yield call(sendToSocket, payload);
    },
    *undoPersistentSub(action, { call, put }) {
      const { payload } = action;
      yield put({ type: 'unpersistSub', payload });
      yield call(sendToSocket, payload);
    },
    *getPersistentSub(action, { select }) {
      const { payload } = action;
      return yield select((state) => {
        if (payload.id) {
          return _.get(state.user.pubsubSubscriptions, payload.id);
        } else if (payload.pattern) {
          return _.find(
            state.user.pubsubSubscriptions,
            (sub) =>
              _.get(sub, 'data.channels', []).indexOf(payload.pattern) !== -1,
          );
        } else {
          return null;
        }
      });
    },
    *getAllPersistentSubs(action, { select }) {
      const {} = action;
      return yield select((state) => state.user.pubsubSubscriptions);
    },
    *sendMessageToSocket(action, { call }) {
      const { payload } = action;
      yield call(sendToSocket, payload);
    },
    *storeUserNotification(action, { put }) {
      const { payload } = action;
      yield put({
        type: 'addUserNotification',
        payload,
      });
    },
    *updateImageForChannel(action, { put }) {
      const { payload } = action;
      yield put({
        type: 'channelImage',
        payload,
      });
    },
    *updateVideoStreamForChannel(action, { put }) {
      const { payload } = action;
      yield put({
        type: 'channelVideoStream',
        payload,
      });
    },
    *updateArchiveStreamForChannel(action, { put }) {
      const { payload } = action;
      yield put({
        type: 'channelArchiveStream',
        payload,
      });
    },
    *switchCustomer(action, { put }) {
      const { payload } = action;
      yield put({
        type: 'setCustomerID',
        payload,
      });
    },
    *login(action, { call, put }) {
      const { payload } = action;
      const response = yield call(loginUser, payload);
      if (response.success) {
        yield put({
          type: 'setTokens',
          payload: response,
        });
        const urlParams = new URL(window.location.href);
        const params = getPageQuery();
        let { redirect } = params;

        if (redirect) {
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.origin === urlParams.origin) {
            redirect = redirect.substr(urlParams.origin.length);
            if (redirect.match(/^\/.*#/)) {
              redirect = redirect.substr(redirect.indexOf('#') + 1);
            }
          } else {
            window.location.href = redirect;
          }
        }
        history.replace(redirect || '/home');
      } else {
        yield put({
          type: 'error',
          errorKey: 'login',
          payload: response.data,
        });
      }
    },
    *logout(action, { call, put }) {
      const { payload } = action;
      const response = yield call(logoutUser, payload);
      yield put({
        type: 'removeTokens',
        payload: response,
      });
      cookie.remove('impersonate_user_email', { path: '/' });
      cookie.remove('use_admin_mode', { path: '/' });
      history.replace('/login');
      window.location.reload();
    },
    *changePassword(action, { call, put }) {
      const { payload } = action;
      const { currentpassword, password } = payload;
      yield put({
        type: 'error',
        errorKey: 'changePassword',
        payload: undefined,
      });
      const response = yield call(() => changePassword(payload));
      if (response.success) {
        yield put({
          type: 'removeTokens',
          payload: response,
        });
        notification.open({
          message: 'Password Changed Successfully! Login with new password',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        history.replace('/login');
      } else {
        yield put({
          type: 'error',
          errorKey: 'changePassword',
          payload: { currentpassword, password },
        });
      }
      return response;
    },
    *forgotPassword(action, { call, put }) {
      const { payload } = action;
      const { email } = payload;
      const response = yield call(() => forgotPassword(payload));
      if (!response.success) {
        yield put({
          type: 'error',
          errorKey: 'forgotPassword',
          payload: { email },
        });
      }
      return response;
    },
    *ssoLogin(action, { call, put }) {
      const { payload } = action;
      const { account } = payload;
      const response = yield call(() => ssoLogin(payload));
      if (!response.success) {
        yield put({
          type: 'error',
          errorKey: 'ssoLogin',
          payload: { account },
        });
      }
      return response;
    },
    *ssoCallback(action, { call, put }) {
      const { payload } = action;
      const { account } = payload;
      const response = yield call(() => ssoCallback(payload));
      if (response.success) {
        yield put({
          type: 'setTokens',
          payload: response,
        });
        history.replace('/home');
      } else {
        yield put({
          type: 'error',
          errorKey: 'ssoCallback',
          payload: { account },
        });
      }
      return response;
    },
    *resetPassword(action, { call, put }) {
      const { payload } = action;
      const { password, confirmationCode } = payload;
      const response = yield call(() => resetPassword(payload));
      if (response.success) {
        notification.open({
          message: 'Password updated, Login with new password',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        history.replace('/login');
      } else {
        yield put({
          type: 'error',
          errorKey: 'resetPassword',
          payload: { password, confirmationCode },
        });
      }
      return response;
    },
    *signup(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => signupUser(payload));
      if (response.success) {
        yield put({
          type: 'setTokens',
          payload: response,
        });
        history.push('/home');
      } else {
        yield put({
          type: 'error',
          errorKey: 'signup',
          payload: response.data,
        });
      }
      return response;
    },
    *fetchCurrent(action, { call, put }) {
      const token = yield call(getIdToken);
      if (!token) {
        const queryString = stringify({
          redirect: window.location.href,
        });
        history.push(`/login?${queryString}`);
        return;
      }
      const response = yield call(getCurrentUser);
      if (response.success) {
        yield put({
          type: 'setCurrentUser',
          payload: response.data,
        });

        yield put({
          type: 'setupPersistentSub',
          payload: {
            id: Math.random().toString(),
            type: 'psubscribe',
            data: {
              channels: [`/customer/${getCurrentCustomerID()}/*`],
            },
          },
        });

        if (window.Intercom !== undefined) {
          window.Intercom('boot', {
            app_id: 'ye890fdy',
            custom_launcher_selector: '#my_custom_link',
            hide_default_launcher: true,
            email: `${response.data.Email}`,
            name: `${response.data.FirstName} ${response.data.LastName}`,
            user_id: `${response.data.UserID}`,
          });
        }
      } else {
        const queryString = stringify({
          redirect: window.location.href,
        });
        history.push(`/login?${queryString}`);
      }
    },
    *getInviteDetails(action, { call, put }) {
      const { payload } = action;
      const response = yield call(getCustomerJoinInviteDetails, payload);
      if (response.success) {
        yield put({
          type: 'setCustomerJoinInvite',
          payload: response.data,
        });
      } else {
        yield put({
          type: 'error',
          errorKey: 'invite',
          payload: response.data,
        });
      }
      return response;
    },
    *signupViaInvite(action, { call, put }) {
      const { inviteID, payload } = action;
      const response = yield call(() =>
        signupViaCustomerInvite(inviteID, payload),
      );
      if (response.success) {
        yield put({
          type: 'setTokens',
          payload: response,
        });
        history.push('/home');
      } else {
        yield put({
          type: 'error',
          errorKey: 'signupViaInvite',
          payload: response.data,
        });
      }
    },
    *getResetLinkInfo(action, { call, put }) {
      const { payload } = action;
      const response = yield call(getResetLinkInfo, payload);
      if (response.success) {
        yield put({
          type: 'setResetLink',
          payload: response.data,
        });
      } else {
        yield put({
          type: 'error',
          errorKey: 'getResetLinkInfo',
          payload: response.data,
        });
      }
    },
    *completeAccountSetup(action, { call, put }) {
      const { linkId, payload } = action;
      const response = yield call(() => completeAccountSetup(linkId, payload));
      if (response.success) {
        yield put({
          type: 'setTokens',
          payload: response.data,
        });
        history.push('/home');
      }
    },
    *requestBaseStaionChannelConfig(action, { call, put }) {
      const { locationID } = action;
      const payload = {
        type: 'request_base_station_channel_config',
        data: {
          type: 'request_base_station_channel_config',
          customer_id: getCurrentCustomerID(),
          project_id: locationID,
        },
        id: uuidv4(),
      };
      yield call(sendToSocket, payload);
      yield put({
        type: 'requestBaseStaionChannelConfigReducer',
      });
    },
    *baseStaionChannelConfigUpdate(action, { put }) {
      const { baseStationChannelConfig } = action;
      yield put({
        type: 'updateBaseStaionChannelConfig',
        baseStationChannelConfig,
      });
    },
  },
  reducers: {
    persistSub(state, action) {
      const { payload } = action;
      const newState = {
        ...state,
        pubsubSubscriptions: {
          ...state.pubsubSubscriptions,
          [payload.id]: payload,
        },
      };
      return newState;
    },
    unpersistSub(state, action) {
      const { payload } = action;
      const subs = state.pubsubSubscriptions;
      delete subs[payload.id];

      const newState = {
        ...state,
        pubsubSubscriptions: subs,
      };
      return newState;
    },
    channelImage(state, action) {
      const { payload } = action;
      const channelID = payload.channel_id;
      const channelImageData = { ...state.channelImageData };

      // this used to key the channelImageData on
      // resolution/fps. to simplify the system, i'm removing that indirection.
      // this means that for a given channel, only one image stream will
      // exist. if there are multiple requests for diff resolutions (not currently
      // conceivable), the images from the different requests will
      // write on top of each other...
      if (!_.get(channelImageData, channelID)) {
        channelImageData[channelID] = {};
      }

      channelImageData[channelID] = {
        data: payload.image_data,
        timestamp: Date.now(),
      };

      return {
        ...state,
        channelImageData,
      };
    },
    channelVideoStream(state, action) {
      const { payload } = action;
      const channelID = payload.channel_id;
      const channelVideoData = { ...state.channelVideoData };
      if (!_.get(channelVideoData, channelID)) {
        channelVideoData[channelID] = {};
      }

      channelVideoData[channelID] = {
        error_message: payload.error_message,
        timestamp: Date.now(),
      };

      return {
        ...state,
        channelVideoData,
      };
    },
    channelArchiveStream(state, action) {
      const { payload } = action;
      const channelID = payload.channel_id;
      const channelArchiveData = { ...state.channelArchiveData };
      if (!_.get(channelArchiveData, channelID)) {
        channelArchiveData[channelID] = {};
      }

      channelArchiveData[channelID] = {
        ...payload,
      };

      return {
        ...state,
        channelArchiveData,
      };
    },
    addUserNotification(state, action) {
      const { payload } = action;
      const id = state.userNotificationsCounter;
      return {
        ...state,
        userNotifications: { ...state.userNotifications, [id]: payload },
        userNotificationsCounter: id + 1,
      };
    },
    error(state, action) {
      return {
        ...state,
        errors: { ...state.errors, [action.errorKey]: action.payload },
      };
    },
    setCustomerID(state, action) {
      const customerID = action.payload.customerID;
      const hasCustomer = _.find(
        state.currentUser.Customers,
        (x) => x.Customer.CustomerID === customerID,
      );
      if (!hasCustomer) {
        return state;
      }
      saveCustomerID(customerID, state.currentUser);
      return state;
    },
    setCurrentUser(state, action) {
      let currentCustomerID = getCurrentCustomerID();
      const currentUser = action.payload;
      const customerIDs = currentUser.Customers.map(
        (c: any) => c.Customer.CustomerID,
      );
      if (
        !currentCustomerID ||
        customerIDs.indexOf(parseInt(currentCustomerID)) === -1
      ) {
        currentCustomerID = currentUser.Customers[0].Customer.CustomerID;
      }
      saveCustomerID(currentCustomerID, currentUser);
      currentUser.Customers = _.keyBy(
        currentUser.Customers,
        (o) => o.Customer.CustomerID,
      );
      return { ...state, currentUser };
    },
    setResetLink(state, action) {
      const { payload } = action;
      return { ...state, resetLinkUser: payload.User };
    },
    setTokens(state, action) {
      const { payload } = action;
      payload.data.ExpiresAt =
        new Date().getTime() + payload.data.ExpiresIn * 1000;
      localStorage.setItem('tokens', JSON.stringify(payload.data));
      return state;
    },
    removeTokens(state, _action) {
      localStorage.removeItem('tokens');
      localStorage.removeItem('currentCustomerID');
      return { ...state, currentUser: {} };
    },
    setCustomerJoinInvite(state, action) {
      return { ...state, invite: action.payload };
    },
    setVideoProcessingUpdate(state, action) {
      const { videoProcessingUpdate } = state;
      videoProcessingUpdate[+action.channelID] = action.message.data;
      return { ...state, videoProcessingUpdate };
    },
    requestBaseStaionChannelConfigReducer(state) {
      return { ...state, requestingBaseStationChannelConfig: true };
    },
    updateBaseStaionChannelConfig(state, action) {
      const { baseStationChannelConfig } = action;
      let channels = [];
      baseStationChannelConfig.channels.forEach((ch) => {
        if (ch.FoundInDB) {
          ch.ConfigObjs = JSON.parse(ch.Configs);
          baseStationChannelConfig.db_channels.forEach((dbch) => {
            if (dbch.ChannelID == ch.ChannelID) {
              ch.DBConfigObjs = JSON.parse(ch.Configs);
            }
          });
          channels.push(ch);
        }
      });
      baseStationChannelConfig.channels = channels;
      // console.log('updateBaseStaionChannelConfig', baseStationChannelConfig);
      return {
        ...state,
        baseStationChannelConfig,
        requestingBaseStationChannelConfig: false,
      };
    },
  },
};
export default UserModel;
