import { Button, Empty } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, Link } from 'umi';

import CustomerSuccess from '@/components/CustomerSuccess';
import LineDivider from '@/components/LineDivider';
import LoadingSpinner from '@/components/LoadingSpinner';
import LocationsMap from '@/components/LocationsMap';
import LocationStatus from '@/components/LocationStatus2';
import PageHeader from '@/components/PageHeader2';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import CreateLocation from '@/pages/locations/components/create-location';
import styles from './style.less';

import type {
  CH_GRP_TYPE,
  CH_TYPE,
  LOC_TYPE,
  MEDIA_TYPE,
} from '@/types/location';
import { naturalSort } from '@/utils/natural-sort';
import { isCustomerProfileEnabled } from '@/utils/utils';

type MyProps = {
  loadingLocations?: boolean;
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  media?: MEDIA_TYPE;
  currentUser: any;
};

type MyState = {};

// @ts-expect-error
@connect(({ user, loading, locations }) => ({
  loadingLocations: loading.effects['locations/fetchLocations'],
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  media: locations.media,
  currentUser: user.currentUser,
}))
class LocationsPage extends React.Component<MyProps, MyState> {
  static defaultProps = {
    loadingLocations: false,
    loc: {
      byId: {},
      allIds: [],
    },
    ch_grp: {
      byId: {},
      allIds: [],
    },
    ch: {
      byId: {},
      allIds: [],
    },
    media: {
      byId: {},
      ch_media_map: {},
    },
  };

  componentDidMount() {
    // document.getElementById('page-container')?.scrollTop = 0;
    const page_container_DOM: HTMLElement | null =
      document.getElementById('page-container');
    if (page_container_DOM) {
      page_container_DOM.scrollTop = 0;
    }
  }

  renderLocationsCards() {
    const { loc, ch, currentUser } = this.props;
    const noOfLoc = loc?.allIds.length || 0;
    if (noOfLoc === 0) {
      return (
        <Empty
          description="No Locations"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }

    const allLocIds = naturalSort(
      _.get(loc, 'allIds', []),
      (id) => loc.byId[id].Name,
    );

    const showImagePreview = isCustomerProfileEnabled(
      currentUser,
      'fe_locations_showImagePreview',
      true,
    );

    return allLocIds.map((id: number, idx: number) => {
      const location = loc.byId[id];
      if (location) {
        const loc_ch = _.orderBy(
          Object.values(ch?.byId || {}).filter(
            (chObj) => chObj.ProjectID && +chObj.ProjectID === location.ID,
          ),
          (chObj) => chObj.RTSPconfig.monitor_status,
          ['desc'],
        );

        return (
          <div key={location.ID}>
            <div className={styles['location-name-heading']}>
              <Link to={`/locations/${location.ID}`}>{location.Name}</Link>
              <span style={{ marginLeft: '16px' }}>
                <LocationStatus loc_node={location} />
              </span>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {loc_ch.length === 0 ? (
                // has no channel
                <div className={styles['empty-location-container']}>
                  <Empty
                    description="No Cameras"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              ) : null}
              {loc_ch.splice(0, 4).map((channel) => (
                <ChannelTile
                  key={channel.ID}
                  channelID={channel.ID}
                  showTime={false}
                  preload={true}
                  showImagePreview={showImagePreview}
                />
              ))}
            </div>
            {idx < noOfLoc - 1 ? <LineDivider margin="0 16px 0 0" /> : null}
          </div>
        );
      }
      return null;
    });
  }

  render(): React.ReactNode {
    const { loadingLocations } = this.props;
    if (loadingLocations) {
      return <LoadingSpinner />;
    }
    return (
      <>
        <CustomerSuccess page="locations" />
        <div>
          <PageHeader
            title="Locations"
            right={
              <CreateLocation>
                <Button size="small">+ Location</Button>
              </CreateLocation>
            }
          />
          <div>
            <LocationsMap style={{ margin: '16px 16px 0 0' }} />
            {this.renderLocationsCards()}
          </div>
        </div>
      </>
    );
  }
}
export default LocationsPage;
