import type { PreloadedQuery } from 'react-relay';
import { usePreloadedQuery } from 'react-relay';
import { SitesWithChannelsQuery } from '../../../MonitorQueries';
import type { MonitorQueries_SitesWithChannels_Query } from '../../../__generated__/MonitorQueries_SitesWithChannels_Query.graphql';
import { SiteChannelsList } from './SiteChannelsList';
import { ChannelGroupsListContainer } from './styles';
import type { SiteChannelsListFragment$key } from './__generated__/SiteChannelsListFragment.graphql';

interface ChannelsListProps {
  queryReference: PreloadedQuery<MonitorQueries_SitesWithChannels_Query>;
  sidebarSearch: string;
  showInactiveChannels: boolean;
  selectedChannels?: string[];
  addChannel: (channelID: string | string[]) => void;
  removeChannel: (channelID: string | string[]) => void;
  toggleChannel: (channelID: string) => void;
}

const ChannelsList = ({
  queryReference,
  sidebarSearch,
  selectedChannels,
  addChannel,
  removeChannel,
  toggleChannel,
  showInactiveChannels,
}: ChannelsListProps) => {
  const queryData = usePreloadedQuery<MonitorQueries_SitesWithChannels_Query>(
    SitesWithChannelsQuery,
    queryReference,
  );

  //   const [channelsNameMap, setChannelsNameMap] = useState<
  //     Record<string, string>
  //   >({});
  //   useEffect(() => {
  //     const nameMap: Record<string, string> = {};

  //     // Iterate over each site and then over each channel in that site
  //     queryData.sites?.edges.forEach((site) => {
  //       site?.node?.Channels?.edges.forEach((channel) => {
  //         if (channel?.node?.ChannelID && channel?.node?.Name) {
  //           nameMap[channel?.node.ChannelID] = channel?.node.Name; // Store the ID: Name pair
  //         }
  //       });
  //     });
  //     setChannelsNameMap(nameMap);
  //   }, [queryData]);

  return (
    <ChannelGroupsListContainer>
      {/* <SelectedChannelList
        allSelectedChannels={selectedChannels as string[]}
        channelsNameMap={channelsNameMap}
        addChannel={addChannel}
        removeChannel={removeChannel}
        sidebarSearch={sidebarSearch}
      /> */}

      {queryData.sites?.edges.map((siteNode, index) => {
        return (
          <SiteChannelsList
            key={index}
            siteNode={siteNode?.node as SiteChannelsListFragment$key}
            allSelectedChannels={selectedChannels}
            toggleChannel={toggleChannel}
            addChannel={addChannel}
            removeChannel={removeChannel}
            sidebarSearch={sidebarSearch}
            showInactiveChannels={showInactiveChannels}
          />
        );
      })}
    </ChannelGroupsListContainer>
  );
};

export { ChannelsList };
