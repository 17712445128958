/**
 * @generated SignedSource<<376dd9ff3a3cbb95232acc1b67312c5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MonitorMutations_SceneConnectionRemove_Mutation$variables = {
  anchorTimeStamp?: string | null | undefined;
  app_id: number;
  channel_ids: ReadonlyArray<string>;
  connections: ReadonlyArray<string>;
  customer_id: number;
  isFavorite?: boolean | null | undefined;
  name?: string | null | undefined;
  timestamp: string;
};
export type MonitorMutations_SceneConnectionRemove_Mutation$data = {
  readonly createOrUpdateVmsPlusRecentChannels:
    | {
        readonly recentEdge:
          | {
              readonly node:
                | {
                    readonly anchorTimeStamp: string | null | undefined;
                    readonly channelIDs: ReadonlyArray<
                      string | null | undefined
                    >;
                    readonly id: string;
                    readonly isFavorite: boolean | null | undefined;
                    readonly name: string | null | undefined;
                    readonly timestamp: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MonitorMutations_SceneConnectionRemove_Mutation = {
  response: MonitorMutations_SceneConnectionRemove_Mutation$data;
  variables: MonitorMutations_SceneConnectionRemove_Mutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'anchorTimeStamp',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'app_id',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'channel_ids',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'connections',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'customer_id',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'isFavorite',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'name',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'timestamp',
    },
    v8 = [
      {
        fields: [
          {
            kind: 'Variable',
            name: 'anchorTimeStamp',
            variableName: 'anchorTimeStamp',
          },
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'channelIDs',
            variableName: 'channel_ids',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            kind: 'Variable',
            name: 'isFavorite',
            variableName: 'isFavorite',
          },
          {
            kind: 'Variable',
            name: 'name',
            variableName: 'name',
          },
          {
            kind: 'Variable',
            name: 'timestamp',
            variableName: 'timestamp',
          },
        ],
        kind: 'ObjectValue',
        name: 'input',
      },
    ],
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'channelIDs',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timestamp',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isFavorite',
      storageKey: null,
    },
    v14 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'anchorTimeStamp',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
        v7 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'MonitorMutations_SceneConnectionRemove_Mutation',
      selections: [
        {
          alias: null,
          args: v8 /*: any*/,
          concreteType: 'CreateOrUpdateVMSPlusRecentChannelsPayload',
          kind: 'LinkedField',
          name: 'createOrUpdateVmsPlusRecentChannels',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'VMSPlusRecentChannelsEdge',
              kind: 'LinkedField',
              name: 'recentEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'VMSPlusRecentChannels',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v9 /*: any*/,
                    v10 /*: any*/,
                    v11 /*: any*/,
                    v12 /*: any*/,
                    v13 /*: any*/,
                    v14 /*: any*/,
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v4 /*: any*/,
        v2 /*: any*/,
        v7 /*: any*/,
        v6 /*: any*/,
        v5 /*: any*/,
        v0 /*: any*/,
        v3 /*: any*/,
      ],
      kind: 'Operation',
      name: 'MonitorMutations_SceneConnectionRemove_Mutation',
      selections: [
        {
          alias: null,
          args: v8 /*: any*/,
          concreteType: 'CreateOrUpdateVMSPlusRecentChannelsPayload',
          kind: 'LinkedField',
          name: 'createOrUpdateVmsPlusRecentChannels',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'VMSPlusRecentChannelsEdge',
              kind: 'LinkedField',
              name: 'recentEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'VMSPlusRecentChannels',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v9 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      filters: null,
                      handle: 'deleteEdge',
                      key: '',
                      kind: 'ScalarHandle',
                      name: 'id',
                      handleArgs: [
                        {
                          kind: 'Variable',
                          name: 'connections',
                          variableName: 'connections',
                        },
                      ],
                    },
                    v10 /*: any*/,
                    v11 /*: any*/,
                    v12 /*: any*/,
                    v13 /*: any*/,
                    v14 /*: any*/,
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '507bac431648b5501d6c3c8292aa1f64',
      id: null,
      metadata: {},
      name: 'MonitorMutations_SceneConnectionRemove_Mutation',
      operationKind: 'mutation',
      text: 'mutation MonitorMutations_SceneConnectionRemove_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $channel_ids: [String!]!\n  $timestamp: String!\n  $name: String\n  $isFavorite: Boolean\n  $anchorTimeStamp: String\n) {\n  createOrUpdateVmsPlusRecentChannels(input: {appId: $app_id, customerId: $customer_id, timestamp: $timestamp, channelIDs: $channel_ids, name: $name, isFavorite: $isFavorite, anchorTimeStamp: $anchorTimeStamp}) {\n    recentEdge {\n      node {\n        id\n        name\n        channelIDs\n        timestamp\n        isFavorite\n        anchorTimeStamp\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '1623f6f5138408e59abe2a6ecc3c012f';

export default node;
