import DataList from '@/components/DataList';
import LabelAndSiteSelector from '@/components/LabelAndSiteSelector';
import LoadingSpinner from '@/components/LoadingSpinner';
import { PauseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tag } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'umi';
import SetupAlarm from '../setup-alarm';
import styles from './style.less';

const ITEMS_PER_PAGE = 10;

const DeleteAlarmWrapper = ({ alarm_id, children, namespace }) => {
  const dispatch = useDispatch();

  return (
    <Popconfirm
      title="Are you sure?"
      style={{ color: 'red' }}
      onConfirm={() => {
        dispatch({
          type: namespace + '/deleteAlarm',
          payload: {
            alarm_id,
          },
        });
      }}
      onCancel={(e) => e?.preventDefault()}
      okText="Yes"
      cancelText="No">
      {children}
    </Popconfirm>
  );
};

const AlarmList = ({
  app,
  filterSiteIDs,
  locations,
  sites,
  loadingAlarms,
  creatingAlarm,
  loadingLocation,
  namespace,
  alarmFilter,
  setAlarmAppState,
}) => {
  const [editAlarm, setEditAlarm] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState();
  const [alarmStatusLoading, setAlarmStatusLoading] = useState({});
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});

  const fetchAlarms = async (
    pagination = {},
    newFilters = {},
    override = false,
  ) => {
    try {
      // Hack, fix properly by changing getAlarms in services to match getIncidents
      const _filters = {
        ...(override ? {} : filters),
        ...newFilters,
      };
      const newParamsFetchAlarms = {
        pagination: {
          ...{ p_size: ITEMS_PER_PAGE, p_number: 1 },
          ...pagination,
        },
        filters: _filters,
      };
      const params = {
        ...newParamsFetchAlarms.pagination,
        ...newParamsFetchAlarms.filters,
      };
      setFilters(_filters);
      const response = await dispatch({
        type: namespace + `/fetchAlarms`,
        params,
      });
      return response;
    } catch (error) {
      console.error('Error fetching incidents:', error);
    }
  };

  useEffect(() => {
    fetchAlarms(undefined, alarmFilter, true);
  }, [alarmFilter]);

  let filteredSites = sites.all;
  if (filterSiteIDs !== undefined && filterSiteIDs.length) {
    filteredSites = sites.all.filter(
      (l) => filterSiteIDs.indexOf(l.SiteID) !== -1,
    );
  }
  let filteredAlarms = _.get(app, 'alarms.list', []).sort((a, b) =>
    a.data.name.localeCompare(b.data.name),
  );

  const duplicateAlarm = (alarm_id) => {
    dispatch({
      type: namespace + '/duplicateAlarm',
      payload: {
        alarm_id,
      },
    });
  };

  return (
    <div>
      {!editAlarm && !alarmFilter && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}>
          <LabelAndSiteSelector
            app_id={app.app_id}
            onSitesChange={(value) =>
              fetchAlarms({ p_number: 1 }, { site_ids: value })
            }
          />
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setEditAlarm(true)}>Create Alarm</Button>
          </div>
        </div>
      )}
      {editAlarm ? (
        <SetupAlarm
          creatingAlarm={creatingAlarm}
          filteredSites={filteredSites}
          loadingLocation={loadingLocation}
          alarm={selectedAlarm}
          locations={locations}
          sites={sites}
          onSubmit={() => {
            setEditAlarm(false);
            setSelectedAlarm(null);
          }}
          onCancel={() => {
            setEditAlarm(false);
            setSelectedAlarm(null);
          }}
          namespace={namespace}
        />
      ) : (
        <>
          {!loadingAlarms && alarmFilter && filteredAlarms.length > 0 && (
            <div className={styles['alarm-filter-mesage']}>
              <p className={styles['alarm-filter-mesage__text']}>
                The list is filtered for alarm name -{' '}
                <strong>{filteredAlarms[0]?.data.name}</strong>.
              </p>
              <Button
                type="link"
                onClick={() => {
                  setAlarmAppState({ alarmFilter: null });
                }}>
                Clear filter
              </Button>
            </div>
          )}
          <DataList
            isLoading={loadingAlarms}
            pagination={{
              p_number: _.get(app, 'alarms.p_number', 1),
              p_size: ITEMS_PER_PAGE,
              total_pages: _.get(app, 'alarms.total_pages', 1),
            }}
            isControlled={true}
            onChange={fetchAlarms}
            columns={[
              {
                title: 'Name',
                render: (record: any) => {
                  return (
                    <span>
                      <span>
                        {record.rule.code_state == 'disabled' ? (
                          <PauseCircleOutlined />
                        ) : null}
                        {record.step == 'error' ? <WarningOutlined /> : null}
                        &nbsp;
                      </span>
                      <span>{record.data.name}</span>
                    </span>
                  );
                },
                key: 'name',
              },
              {
                title: 'Time',
                render: (record: any) => {
                  return `${record.data.timeframe.time_range.from} - ${record.data.timeframe.time_range.to}`;
                },
                key: 'time',
              },
              {
                title: 'Override Codes',
                render: (record: any) => {
                  const { codes } = record.data;
                  if (codes) {
                    return `${codes
                      .map((c) => c.enableCode + ', ' + c.disableCode)
                      .join(', ')}`;
                  }
                  return '';
                },
                key: 'overrideCodes',
              },
              {
                title: 'Status',
                render: (record: any) => {
                  return (
                    <Tag
                      color={record.rule.enabled ? 'green' : 'red'}
                      disabled={record.step === 'error'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setAlarmStatusLoading({
                          ...alarmStatusLoading,
                          [record.alarm_id]: true,
                        });
                        dispatch({
                          type: namespace + '/toggleAlarmStatus',
                          payload: {
                            alarm_id: record.alarm_id,
                            enabled: !record.rule.enabled,
                          },
                        }).then(() => {
                          setAlarmStatusLoading({
                            ...alarmStatusLoading,
                            [record.alarm_id]: false,
                          });
                        });
                      }}>
                      <div style={{ display: 'flex' }}>
                        <div>
                          {record.rule.enabled ? 'Enabled' : 'Disabled'}
                        </div>
                        {alarmStatusLoading[record.alarm_id] && (
                          <LoadingSpinner fontSize={12} padding={'0px 3px'} />
                        )}
                      </div>
                    </Tag>
                  );
                },
                key: 'status',
              },
              {
                title: 'Actions',
                key: 'actions',
                render: (record: any) => (
                  <div>
                    <span
                      onClick={() => {
                        setSelectedAlarm(record);
                        setEditAlarm(true);
                      }}
                      className="df-link">
                      Edit
                    </span>
                    &nbsp;&nbsp;
                    <span
                      onClick={() => duplicateAlarm(record.alarm_id)}
                      className="df-link">
                      Duplicate
                    </span>
                    &nbsp;&nbsp;
                    <DeleteAlarmWrapper
                      alarm_id={record.alarm_id}
                      namespace={namespace}>
                      <span className="df-link df-error-text">Delete</span>
                    </DeleteAlarmWrapper>
                  </div>
                ),
              },
            ]}
            dataList={filteredAlarms}
            cardMap={{
              content: (record) => {
                return (
                  <div className={styles['alarm-card']}>
                    <div className={styles['alarm-card-header']}>
                      <div
                        style={{
                          fontWeight: '500',
                          fontSize: '14px',
                          padding: '4px',
                          textAlign: 'center',
                        }}>
                        {record.data.name}
                      </div>
                      <div style={{ fontSize: '10px' }}>
                        {_.get(record.rule, 'sources.channels[0].name')}
                      </div>
                      <div style={{ padding: '4px' }}>
                        <Tag
                          color={record.rule.enabled ? 'green' : 'red'}
                          style={{ cursor: 'pointer', margin: '0px' }}
                          onClick={() => {
                            setAlarmStatusLoading({
                              ...alarmStatusLoading,
                              [record.alarm_id]: true,
                            });
                            dispatch({
                              type: namespace + '/toggleAlarmStatus',
                              payload: {
                                alarm_id: record.alarm_id,
                                enabled: !record.rule.enabled,
                              },
                            }).then(() => {
                              setAlarmStatusLoading({
                                ...alarmStatusLoading,
                                [record.alarm_id]: false,
                              });
                            });
                          }}>
                          <div style={{ display: 'flex' }}>
                            <div>
                              {record.rule.enabled ? 'Enabled' : 'Disabled'}
                            </div>
                            {alarmStatusLoading[record.alarm_id] && (
                              <LoadingSpinner
                                fontSize={12}
                                padding={'0px 3px'}
                              />
                            )}
                          </div>
                        </Tag>
                      </div>
                    </div>
                    <div className={styles['alarm-card-footer']}>
                      <div style={{ fontSize: '12px', padding: '4px' }}>
                        {_.get(record, 'data.timeframe.time_range.from').slice(
                          0,
                          5,
                        )}{' '}
                        to{' '}
                        {_.get(record, 'data.timeframe.time_range.to').slice(
                          0,
                          5,
                        )}{' '}
                        {_.get(record, 'data.timeframe.days')
                          .map((d) => _.capitalize(d).slice(0, 2))
                          .join(', ')}
                      </div>
                      <div>
                        <div></div>
                        <div></div>
                      </div>
                      <div>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            setSelectedAlarm(record);
                            setEditAlarm(true);
                          }}>
                          Edit
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          onClick={() => duplicateAlarm(record.alarm_id)}
                          size="small">
                          Duplicate
                        </Button>
                        &nbsp;&nbsp;
                        <DeleteAlarmWrapper
                          alarm_id={record.alarm_id}
                          namespace={namespace}>
                          <Button size="small" type="primary" danger>
                            Delete
                          </Button>
                        </DeleteAlarmWrapper>
                      </div>
                    </div>
                  </div>
                );
              },
            }}
          />
        </>
      )}
    </div>
  );
};
export default AlarmList;
