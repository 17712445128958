import LoadingSpinner from '@/components/LoadingSpinner';
import { dispatchWithFeedback, extractAppIDFromPathname } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { Component } from 'react';
import { connect, Outlet } from 'umi';

// @ts-expect-error
@connect(({ apps }) => ({
  apps,
}))
class AppLayout extends Component {
  state = {};

  componentDidMount() {
    const appID = extractAppIDFromPathname(this.props.location.pathname);
    this.fetchApp(appID);
  }

  componentDidUpdate(prevProps) {
    const prevappID = extractAppIDFromPathname(prevProps.location.pathname);
    const appID = extractAppIDFromPathname(this.props.location.pathname);
    if (prevappID !== appID) {
      this.fetchApp(appID);
    }
  }

  fetchApp(appID) {
    dispatchWithFeedback(
      this.props.dispatch,
      'Loading app',
      {
        type: 'apps/fetchApp',
        appID,
      },
      true,
    );
  }

  render() {
    const { apps } = this.props;
    const appID = extractAppIDFromPathname(this.props.location.pathname);
    const app = apps.byID[appID];
    if (!app) {
      return <LoadingSpinner />;
    }
    return <Outlet />;
  }
}
export default withRouter(AppLayout);
