import _ from 'lodash';
import moment from 'moment-timezone';

// CLASS
export class InvestigationAttachments {
  ID: number;
  Name: string;
  InvestigationID: number;
  MimeType: string | null;
  S3SignedUrl: string | null;

  constructor(attachment: any) {
    this.ID = _.get(attachment, `InvestigationAttachmentID`, -1);
    this.Name = _.get(attachment, `Name`, '');
    this.InvestigationID = _.get(attachment, `InvestigationID`, -1);
    this.MimeType = _.get(attachment, `MimeType`, null);
    this.S3SignedUrl = _.get(attachment, `S3SignedUrl`, null);
  }

  update(attachment: InvestigationAttachments) {
    if (attachment.ID != -1) {
      this.ID = attachment.ID;
    }
    if (attachment.Name != '') {
      this.Name = attachment.Name;
    }
    if (attachment.InvestigationID != -1) {
      this.InvestigationID = attachment.InvestigationID;
    }
    if (attachment.MimeType != null) {
      this.MimeType = attachment.MimeType;
    }
    if (attachment.S3SignedUrl != null) {
      this.S3SignedUrl = attachment.S3SignedUrl;
    }
  }
}

export class InvestigationReports {
  ID: number;
  Name: string;
  Description: string;
  InvestigationID: number;
  InvestigationReportPassword: string | null;
  InvestigationShareType: string | null;

  constructor(report: any) {
    this.ID = _.get(report, 'InvestigationReportID', -1);
    this.Name = _.get(report, 'Name', '');
    this.Description = _.get(report, 'Description', '');
    this.InvestigationID = _.get(report, 'InvestigationID', -1);
    this.InvestigationReportPassword = _.get(
      report,
      'InvestigationReportPassword',
      null,
    );
    this.InvestigationShareType = _.get(report, 'InvestigationShareType', null);
  }

  update(report: InvestigationReports) {
    if (report.ID != -1) {
      this.ID = report.ID;
    }
    if (report.Name != '') {
      this.Name = report.Name;
    }
    if (report.Description != '') {
      this.Description = report.Description;
    }
    if (report.InvestigationID != -1) {
      this.InvestigationID = report.InvestigationID;
    }
    if (report.InvestigationReportPassword != null) {
      this.InvestigationReportPassword = report.InvestigationReportPassword;
    }
    if (report.InvestigationShareType != null) {
      this.InvestigationShareType = report.InvestigationShareType;
    }
  }
}

export class EventSummaries {
  ID: number;
  CreatedAt: string | null;
  Filters: Object | null;
  Percent: string;
  Status: string;
  StatusMsg: string;
  SummaryTimeline: string | null;
  SummaryVideo: string | null;

  constructor(summary: any) {
    this.ID = _.get(summary, 'SummarizationRequestID', -1);
    this.CreatedAt = _.get(summary, 'CreatedAt', null);
    this.Filters = _.get(summary, 'Filters', null);
    this.Percent = _.get(summary, 'Percent', '');
    this.Status = _.get(summary, 'Status', '');
    this.StatusMsg = _.get(summary, 'StatusMsg', '');
    this.SummaryTimeline = _.get(summary, 'SummaryTimeline', null);
    this.SummaryVideo = _.get(summary, 'SummaryVideo', null);
  }

  update(summary: EventSummaries) {
    if (summary.ID != -1) {
      this.ID = summary.ID;
    }
    if (summary.CreatedAt != null) {
      this.CreatedAt = summary.CreatedAt;
    }
    if (summary.Filters != null) {
      this.Filters = summary.Filters;
    }
    if (summary.Percent != '') {
      this.Percent = summary.Percent;
    }
    if (summary.Status != '') {
      this.Status = summary.Status;
    }
    if (summary.StatusMsg != '') {
      this.StatusMsg = summary.StatusMsg;
    }
    if (summary.SummaryTimeline != null) {
      this.SummaryTimeline = summary.SummaryTimeline;
    }
    if (summary.SummaryVideo != null) {
      this.SummaryVideo = summary.SummaryVideo;
    }
  }
}

export class InvestigationEvent {
  ID: number;
  Name: string;
  ChannelID: number;
  // CustomTaskStatus: null
  EventEnd: string; // number;
  EventStart: string; // number;
  InvestigationID: number;
  InvestigationEventUUID: string;
  Media: number[];
  // IsTimeDefaulted
  SummarizationRequests: number[];
  SummaryIndexPercent: string | null;
  SummaryIndexStatus: string | null;
  SummaryIndexStatusMsg: string | null;
  SummaryName: string | null;

  constructor(event: any) {
    this.ID = _.get(event, 'InvestigationEventID', -1);
    this.Name = _.get(event, 'Name', '');
    this.ChannelID = _.get(event, 'ChannelID', -1);
    this.EventEnd = _.get(event, 'EventEnd', '');
    this.EventStart = _.get(event, 'EventStart', '');
    this.InvestigationID = _.get(event, 'InvestigationID', -1);
    this.InvestigationEventUUID = _.get(event, 'InvestigationEventUUID', '');
    this.Media = [
      ..._.get(event, 'Media', []),
      ..._.get(event, 'LatestMedia', []),
    ]
      .map((media: any) => +_.get(media, 'id', null))
      .filter((id: number) => id);
    this.SummarizationRequests = _.get(event, `SummarizationRequests`, [])
      .map((summary: any) => summary['SummarizationRequestID'])
      .filter((id: any) => id);
    this.SummaryIndexPercent = _.get(event, 'SummaryIndexPercent', null);
    this.SummaryIndexStatus = _.get(event, 'SummaryIndexStatus', null);
    this.SummaryIndexStatusMsg = _.get(event, 'SummaryIndexStatusMsg', null);
    this.SummaryName = _.get(event, 'SummaryName', null);
  }

  update(event: InvestigationEvent) {
    if (event.ID !== -1) {
      this.ID = event.ID;
    }
    if (event.ChannelID !== -1) {
      this.ChannelID = event.ChannelID;
    }
    if (event.EventEnd !== '') {
      this.EventEnd = event.EventEnd;
    }
    if (event.EventStart !== '') {
      this.EventStart = event.EventStart;
    }
    if (event.InvestigationEventUUID !== '') {
      this.InvestigationEventUUID = event.InvestigationEventUUID;
    }
    if (event.Media.length > 0) {
      this.Media = _.uniq([...this.Media, ...event.Media]);
      // this.Media = _.values(
      //   _.merge(_.keyBy(this.Media, 'id'), _.keyBy(event.Media, 'id')),
      // );
    }
    if (event.InvestigationID !== -1) {
      this.InvestigationID = event.InvestigationID;
    }
    if (event.Name !== '') {
      this.Name = event.Name;
    }
  }

  updateMedia(medias: any[]) {
    const event_data_range = [moment(this.EventStart), moment(this.EventEnd)];
    const media_ids = medias
      .filter((m) => {
        if ('VideoStartTime' in m && 'VideoEndTime' in m) {
          if (
            event_data_range[0].valueOf() >
              moment(m['VideoEndTime']).valueOf() ||
            event_data_range[1].valueOf() <
              moment(m['VideoStartTime']).valueOf()
          ) {
            return false;
          }
          return true;
        }
        return false;
      })
      .map((m) => m['id']);
    if (media_ids.length > 0) {
      const new_media_ids = [...this.Media, ...media_ids];
      this.Media = _.uniq(new_media_ids);
    }
  }
}

export class Investigation {
  ID: number;
  InvestigationUUID: string | null;
  Name: string;
  CustomerID: number;

  InvestigationEventsCount: number;
  InvestigationEvents: number[];

  InvestigationAttachmentsCount: number;
  InvestigationAttachments: number[];

  InvestigationReportsCount: number;
  InvestigationReports: number[];

  InvestigationSummariesCount: number;
  // TimelineConfig

  constructor(investigation: any) {
    this.ID = _.get(investigation, `InvestigationID`, -1);
    this.InvestigationUUID = _.get(investigation, `InvestigationUUID`, null);
    this.Name = _.get(investigation, `Name`, '');
    this.CustomerID = _.get(investigation, `CustomerID`, -1);

    this.InvestigationEvents = _.get(investigation, `InvestigationEvents`, [])
      .map((event: any) => event['InvestigationReportID'])
      .filter((id: any) => id);
    this.InvestigationEventsCount =
      _.get(investigation, `InvestigationEventsCount`, null) ||
      this.InvestigationEvents.length;

    this.InvestigationAttachments = _.get(
      investigation,
      `InvestigationAttachments`,
      [],
    )
      .map((attchment: any) => attchment['InvestigationReportID'])
      .filter((id: any) => id);
    this.InvestigationAttachmentsCount =
      _.get(investigation, `InvestigationAttachmentsCount`, null) ||
      this.InvestigationAttachments.length;

    this.InvestigationReports = _.get(investigation, `InvestigationReports`, [])
      .map((report: any) => report['InvestigationReportID'])
      .filter((id: any) => id);
    this.InvestigationReportsCount =
      _.get(investigation, `InvestigationReportsCount`, null) ||
      this.InvestigationReports.length;

    this.InvestigationSummariesCount = _.get(
      investigation,
      `InvestigationSummariesCount`,
      0,
    );
  }

  update(investigation: Investigation) {
    if (investigation.ID != -1) {
      this.ID = investigation.ID;
    }
    if (investigation.InvestigationUUID != null) {
      this.InvestigationUUID = investigation.InvestigationUUID;
    }
    if (investigation.Name != '') {
      this.Name = investigation.Name;
    }
    if (investigation.CustomerID != -1) {
      this.CustomerID = investigation.CustomerID;
    }
    if (investigation.InvestigationEventsCount > 0) {
      this.InvestigationEventsCount = investigation.InvestigationEventsCount;
    }
    if (investigation.InvestigationEvents.length > 0) {
      this.InvestigationEvents = investigation.InvestigationEvents;
      this.InvestigationEventsCount = this.InvestigationEvents.length;
    }
    if (investigation.InvestigationAttachmentsCount > 0) {
      this.InvestigationAttachmentsCount =
        investigation.InvestigationAttachmentsCount;
    }
    if (investigation.InvestigationAttachments.length > 0) {
      this.InvestigationAttachments = investigation.InvestigationAttachments;
      this.InvestigationAttachmentsCount = this.InvestigationAttachments.length;
    }
    if (investigation.InvestigationReportsCount > 0) {
      this.InvestigationReportsCount = investigation.InvestigationReportsCount;
    }
    if (investigation.InvestigationReports.length > 0) {
      this.InvestigationReports = investigation.InvestigationReports;
      this.InvestigationReportsCount = this.InvestigationReports.length;
    }
    if (investigation.InvestigationSummariesCount != -1) {
      this.InvestigationSummariesCount =
        investigation.InvestigationSummariesCount;
    }
  }
}

// TYPE
export type INVESTIGATIONS_TYPE = {
  byId: Record<number, Investigation>;
};
export type EVENTS_TYPE = {
  byId: Record<number, InvestigationEvent>;
  mapInvestigationEvent: Record<number, number[]>;
  mapChannelEvent: Record<number, number[]>;
};
export type ATTACHMENTS_TYPE = {
  byId: Record<number, InvestigationAttachments>;
  mapInvestigationAttachment: Record<number, number[]>;
};
export type REPORTS_TYPE = {
  byId: Record<number, InvestigationReports>;
  mapInvestigationReport: Record<number, number[]>;
};
export type SUMMARIES_TYPE = {
  byId: Record<number, EventSummaries>;
  mapEventSummary: Record<number, number[]>;
};

export type InvestigationState = {
  investigations: INVESTIGATIONS_TYPE;
  events: EVENTS_TYPE;
  attachments: ATTACHMENTS_TYPE;
  reports: REPORTS_TYPE;
  summaries: SUMMARIES_TYPE;
};

export type InvestigationData = {
  investigations?: Record<number, Investigation>;
  events?: Record<number, InvestigationEvent>;
  attachments?: Record<number, InvestigationAttachments>;
  reports?: Record<number, InvestigationReports>;
  summaries?: Record<number, EventSummaries>;
};

// FUNCTION
// Investigation
export const processGetInvestigtions = (data: any): InvestigationData => {
  const investigation_data: InvestigationData = {};

  const investigations: Record<number, Investigation> = {};
  const events: Record<number, InvestigationEvent> = {};
  const attachments: Record<number, InvestigationAttachments> = {};
  const reports: Record<number, InvestigationReports> = {};
  const summaries: Record<number, EventSummaries> = {};
  if (Array.isArray(data) && data.length > 0) {
    const inv_events: any[] = [];
    const inv_attachments: any[] = [];
    const inv_reports: any[] = [];
    const evnt_summaries: any[] = [];
    data.forEach((inv: any) => {
      investigations[+inv['InvestigationID']] = new Investigation(inv);
      inv_events.push(..._.get(inv, 'InvestigationEvents', []));
      inv_attachments.push(..._.get(inv, 'InvestigationAttachments', []));
      inv_reports.push(..._.get(inv, 'InvestigationReports', []));
    });
    inv_events.forEach((evnt: any) => {
      events[+evnt['InvestigationEventID']] = new InvestigationEvent(evnt);
      evnt_summaries.push(..._.get(evnt, 'SummarizationRequests', []));
    });
    inv_attachments.forEach((attach: any) => {
      attachments[+attach['InvestigationAttachmentID']] =
        new InvestigationAttachments(attach);
    });
    inv_reports.forEach((report: any) => {
      reports[+report['InvestigationReportID']] = new InvestigationReports(
        report,
      );
    });
    evnt_summaries.forEach((summary: any) => {
      summaries[+summary['SummarizationRequestID']] = new EventSummaries(
        summary,
      );
    });
  }

  if (Object.keys(investigations).length > 0) {
    investigation_data['investigations'] = investigations;
  }
  if (Object.keys(events).length > 0) {
    investigation_data['events'] = events;
  }
  if (Object.keys(attachments).length > 0) {
    investigation_data['attachments'] = attachments;
  }
  if (Object.keys(reports).length > 0) {
    investigation_data['reports'] = reports;
  }
  if (Object.keys(summaries).length > 0) {
    investigation_data['summaries'] = summaries;
  }

  return investigation_data;
};

export const processGetInvestigtion = (data: any): InvestigationData => {
  const investigation_data: InvestigationData = processGetInvestigtions([data]);
  return investigation_data;
};

export const processCreateInvestigtion = (data: any): InvestigationData => {
  const investigation_data: InvestigationData = processGetInvestigtions([data]);
  return investigation_data;
};

export const processUpdateInvestigtion = (data: any): InvestigationData => {
  const investigation_data: InvestigationData = processGetInvestigtions([data]);
  return investigation_data;
};

// Investigation Event
export const processGetInvestigationEvents = (data: any): InvestigationData => {
  const investigation_data: InvestigationData = {};

  const events: Record<number, InvestigationEvent> = {};
  const summaries: Record<number, EventSummaries> = {};
  if (Array.isArray(data) && data.length > 0) {
    const evnt_summaries: any[] = [];
    data.forEach((evnt: any) => {
      events[+evnt['InvestigationEventID']] = new InvestigationEvent(evnt);
      evnt_summaries.push(..._.get(evnt, 'SummarizationRequests', []));
    });
    evnt_summaries.forEach((summary: any) => {
      summaries[+summary['SummarizationRequestID']] = new EventSummaries(
        summary,
      );
    });
  }

  if (Object.keys(events).length > 0) {
    investigation_data['events'] = events;
  }
  if (Object.keys(summaries).length > 0) {
    investigation_data['summaries'] = summaries;
  }

  return investigation_data;
};

export const processGetInvestigationEvent = (data: any): InvestigationData => {
  const investigation_data: InvestigationData = processGetInvestigationEvents([
    data,
  ]);
  return investigation_data;
};

export const processUpdateInvestigationEvent = (
  data: any,
): InvestigationData => {
  const investigation_data: InvestigationData = processGetInvestigationEvents([
    data,
  ]);
  return investigation_data;
};

export const updateInvestigationsData = (
  state: InvestigationState,
  investigation_data: InvestigationData,
): InvestigationState => {
  const {
    investigations = null,
    events = null,
    attachments = null,
    reports = null,
    summaries = null,
  } = investigation_data;

  if (investigations && Object.keys(investigations).length > 0) {
    Object.entries(investigations).forEach(([id, inv]) => {
      if (id in state.investigations.byId) {
        state.investigations.byId[+id].update(inv);
      } else {
        state.investigations.byId[+id] = inv;
      }
    });
  }
  if (events && Object.keys(events).length > 0) {
    Object.entries(events).forEach(([id, event]) => {
      if (id in state.events.byId) {
        state.events.byId[+id].update(event);
      } else {
        state.events.byId[+id] = event;
      }
    });
    state.events.mapInvestigationEvent = Object.entries(
      state.events.byId,
    ).reduce((acc: Record<number, number[]>, [id, event]) => {
      if (!(event.InvestigationID in acc)) {
        acc[event.InvestigationID] = [];
      }
      acc[event.InvestigationID].push(+id);
      return acc;
    }, {});
    state.events.mapChannelEvent = Object.entries(state.events.byId).reduce(
      (acc: Record<number, number[]>, [id, event]) => {
        if (!(event.ChannelID in acc)) {
          acc[event.ChannelID] = [];
        }
        acc[event.ChannelID].push(+id);
        return acc;
      },
      {},
    );
  }
  if (attachments && Object.keys(attachments).length > 0) {
    Object.entries(attachments).forEach(([id, attachment]) => {
      if (id in state.attachments.byId) {
        state.attachments.byId[+id].update(attachment);
      } else {
        state.attachments.byId[+id] = attachment;
      }
    });
  }
  if (reports && Object.keys(reports).length > 0) {
    Object.entries(reports).forEach(([id, report]) => {
      if (id in state.reports.byId) {
        state.reports.byId[+id].update(report);
      } else {
        state.reports.byId[+id] = report;
      }
    });
  }
  if (summaries && Object.keys(summaries).length > 0) {
    Object.entries(summaries).forEach(([id, summary]) => {
      if (id in state.summaries.byId) {
        state.summaries.byId[+id].update(summary);
      } else {
        state.summaries.byId[+id] = summary;
      }
    });
  }

  return state;
};

export const updateEventMedia = (
  dispatch: (_any: any) => void,
  events: any,
) => {
  if (Array.isArray(events) && events.length > 0) {
    const medias: any[] = [];
    events.forEach((evt: any) => {
      medias.push(..._.get(evt, 'Media', []), ..._.get(evt, 'LatestMedia', []));
    });

    if (medias.length > 0) {
      dispatch({
        type: 'locations/saveMedia',
        medias,
      });
    }
  }
};
