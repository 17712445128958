import { ReactComponent as EyeOutlinedIcon } from '@/assets/show-eye.svg';
import DateText from '@/components/DateText';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import {
  dispatchWithFeedback,
  doAppOp,
  getCurrentCustomerID,
} from '@/utils/utils';
import Icon, { SearchOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'umi';
import BaseApp from './BaseApp';
import InternalEditBaseStations from './components/internal-edit-base-stations';
import styles from './style.less';
const { Text } = Typography;

import type { FieldType, InputRef } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Papa from 'papaparse';
import CustomerIDEditor from './components/insights-customer-id-editor';

const { TabPane } = Tabs;

const TaskDuraton = 900; //% mins

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

// @ts-expect-error
@connect(({ loading, user }) => ({ loading, user }))
class InternalMonitoringApp extends BaseApp {
  searchInput: React.RefObject<InputRef>;
  constructor(props) {
    super(props);
    this.storageKey = `InternalMonitoringApp`;
    this.state = {
      customerName: this.getCustomerName(getCurrentCustomerID()),
      customerID: getCurrentCustomerID(),
      isOverridden: false,
      currentCustomerID: 0,
      selectedFile: null,
      selectedFileType: '',
      importedFile: '',
      bs_status_serial: '',
      bs_status_jamf: 'default',
      bs_status_jamf_error: '',
      bs_status_df_cloud: 'default',
      bs_status_df_cloud_error: '',
      bs_status_frps: 'default',
      bs_status_frps_error: '',
      bs_status_s3_upload: 'default',
      bs_status_s3_upload_error: '',
      bs_status_s3_upload_task_id: -1,
      bs_status_metrics: 'default',
      bs_status_metrics_error: '',
      bs_jamf_data_managed: '',
      bs_jamf_data_last_checkin_time: '',
      bs_jamf_data_reported_ip: '',
      check_bs_serial_numer: '',
      check_status_timeout: TaskDuraton,
      show_timer: false,
      bs_status_msg: '',
      bs_status_result: '',
      bs_project_id: 0,
      bs_customer_id: 0,
    };
    this.channelProbeForm = React.createRef();
    this.setBaseStationUpdateVersionForm = React.createRef();
    this.searchInput = React.createRef<InputRef>();

    this.timeCounterDown = null;
    this.timerCheckJAMF = null;
    this.timerCheckDFCloud = null;
    this.timerCheckFRPS = null;
    this.timerCheckFileServer = null;
    this.checkLogUploadStatus = null;
  }

  static getEditRuleComponent = (state) => {
    if (!state) {
      return null;
    }
    return (
      <div>
        <Form.Item
          label=""
          extra="A notification will be triggered for the specified condition">
          <Form.Item
            name={['delivery', 'threshold', 'entity_type']}
            style={{
              display: 'inline-block',
              paddingRight: '10px',
              width: '25%',
            }}>
            <Select>
              {[
                ['System', 'System'],
                ['Customer', 'Customer'],
                ['Location', 'Project'],
                ['Channel', 'Channel'],
              ].map((el) => (
                <Select.Option key={el[1]} value={el[1]}>
                  {el[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={['delivery', 'threshold', 'entity_ids_str']}
            style={{
              display: 'inline-block',
              width: '20%',
              paddingRight: '10px',
              marginBottom: '0',
            }}>
            <Input placeholder="IDs" />
          </Form.Item>

          <Form.Item
            name={['delivery', 'threshold', 'alert_type']}
            style={{
              display: 'inline-block',
              paddingRight: '10px',
              width: '40%',
            }}>
            <Select>
              {[
                ['Backlogged', 'backlogged'],
                ['Unprocessed', 'unprocessed'],
              ].map((el) => (
                <Select.Option key={el[1]} value={el[1]}>
                  {el[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={['delivery', 'threshold', 'metric']}
            style={{
              display: 'inline-block',
              width: '15%',
              marginBottom: '0',
            }}>
            <Input placeholder="Num" />
          </Form.Item>
        </Form.Item>
      </div>
    );
  };

  setup() {
    this.setState({
      dash: this.props.data && this.props.data.dash,
      config: this.props.data && this.props.data.config,
    });
    this.persistentSubID = this.persistentSubID || Math.random().toString();
    this.props.dispatch({
      type: 'user/setupPersistentSub',
      payload: {
        id: this.persistentSubID,
        type: 'psubscribe',
        data: {
          channels: ['/base_station/*'],
        },
        callback: (message) => {
          let handshakes = this.state.handshakes || {};

          let found = message?.channel?.match(/base_station\/(.*)\/handshake/);
          if (found) {
            let serialNumber = found[1];
            handshakes[serialNumber] = 1;
            this.setState({ handshakes });
          }
        },
      },
    });
  }
  componentDidMount(): void {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.data, this.props.data)) {
      this.setup();
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: 'user/undoPersistentSub',
      payload: {
        id: this.persistentSubID,
        type: 'punsubscribe',
        data: {
          channels: ['/base_station/*'],
        },
      },
    });
  }

  getTextFuncStatus(funcStatus) {
    switch (funcStatus) {
      case 0:
        return 'LoginSuccess';
      case 1:
        return 'VMSPluginNotStarted';
      case 2:
        return 'VMSPluginFailed';
      case -1:
        return 'LoginFailed';
      case -100:
        return 'LoginNotAttempted';
      case -2:
        return 'VideoExportFailed';
      case -3:
        return 'WritingDFFileFailed';
      case -4:
        return 'GetChannelFailed';
      case -5:
        return 'LoggedErrorOrWarning';
      case -6:
        return 'ChannelStreamingError';
      case -7:
        return 'ChannelStreamingFailed';
      case -8:
        return 'ChannelStreamingNotStarted';
      default:
        return 'Unknown';
    }
  }

  listSelector(path, dataSource) {
    let listSelected = _.get(this.state.config, path, []).sort((a, b) => a - b);
    this.setState({
      listPath: path,
      listSelectorVisibility: true,
      listSelected,
      listSource: _.cloneDeep(dataSource || [])
        .sort((a, b) => a.ID > b.ID)
        .map((x) => ({
          ...x,
          key: x.ID,
          value: x.ID,
          label: `${x.ID}: ${x.Name}`,
        })),
    });
  }

  saveList() {
    const path = this.state.listPath;
    let selections = this.state.listSelected || [];

    const config = _.cloneDeep(this.state.config);
    _.set(config, path, selections);
    this.setState({ config });

    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.saveConfig,
      { config },
    )
      .then(() => {
        this.setState({ listSelectorVisibility: false });
      })
      .then(() => {
        this.getLatestInfo();
      });
  }

  getLatestInfo() {
    // use of fetchApp will refresh the app data in props
    dispatchWithFeedback(
      this.props.dispatch,
      'Refreshing info',
      {
        type: 'apps/fetchApp',
        appID: this.props.appID,
        payload: {},
      },
      true,
    );
  }

  getBaseStations() {
    dispatchWithFeedback(
      this.props.dispatch,
      'Getting basestations',
      {
        type: 'apps/doAppOp',
        appID: this.props.appID,
        payload: {
          op: InternalMonitoringApp.OPS.getBaseStations.name,
        },
      },
      true,
    ).then((data) => {
      const baseStations = _.get(data, 'Data', []);
      const filters_Customer = new Set();
      const filters_CustomerID = new Set();
      const filters_Location = new Set();
      const filters_LocationID = new Set();
      const filters_WifiMAC = new Set();

      baseStations.forEach((bs) => {
        filters_Customer.add(_.get(bs, ['Customer', 'Name'], ''));
        filters_CustomerID.add(bs['CustomerID']);
        filters_Location.add(_.get(bs, ['Project', 'Name'], ''));
        filters_LocationID.add(bs['ProjectID']);
        filters_WifiMAC.add(bs['WifiMAC']);
      });

      this.setState({
        baseStations,
        filters_Customer: Array.from(filters_Customer)
          .filter((i) => i)
          .map((item) => ({ text: item, value: item })),
        filters_CustomerID: Array.from(filters_CustomerID)
          .filter((i) => i)
          .map((item) => ({ text: item, value: item })),
        filters_Location: Array.from(filters_Location)
          .filter((i) => i)
          .map((item) => ({ text: item, value: item })),
        filters_LocationID: Array.from(filters_LocationID)
          .filter((i) => i)
          .map((item) => ({ text: item, value: item })),
        filters_WifiMAC: Array.from(filters_WifiMAC)
          .filter((i) => i)
          .map((item) => ({ text: item, value: item })),
      });
    });
  }

  getBaseStationForProject(c, p) {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting basestations',
      {
        type: 'apps/doAppOp',
        appID: this.props.appID,
        payload: {
          op: InternalMonitoringApp.OPS.getBaseStationForProject.name,
          params: {
            customer_id: c.CustomerID,
            project_id: p.ProjectID,
          },
        },
      },
      true,
    );
  }

  getConnectedZigbeeDevices(customerID, projectID) {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting Zigbee Devices',
      {
        type: 'apps/doAppOp',
        appID: this.props.appID,
        payload: {
          op: InternalMonitoringApp.OPS.getConnectedZigbeeDevices.name,
          params: {
            customer_id: customerID,
            project_id: projectID,
          },
        },
      },
      false,
    );
  }

  handleOpenMonitoringDashBoard(c, p) {
    if (p.BaseStation) {
      this.openMonitoringDashboard(c, p);
      return;
    }

    this.getBaseStationForProject(c, p).then((data) => {
      const baseStation = _.get(data, 'Data') || {};
      p.BaseStation = baseStation;
      this.openMonitoringDashboard(c, p);
    });
  }

  handleBrowseFiles(c, p) {
    if (p.BaseStation) {
      this.browseFiles(c, p);
      return;
    }

    this.getBaseStationForProject(c, p).then((data) => {
      const baseStation = _.get(data, 'Data') || {};
      p.BaseStation = baseStation;
      this.browseFiles(c, p);
    });
  }

  handleShowBaseStationDetails(c, p) {
    if (p.BaseStation) {
      this.showBaseStationDetails(c, p);
      return;
    }

    this.getBaseStationForProject(c, p).then((data) => {
      const baseStation = _.get(data, 'Data') || {};
      p.BaseStation = baseStation;
      this.showBaseStationDetails(c, p);
    });
  }

  handleShowZigbeeDevices(c, p) {
    if (p.ZigbeeDevices) {
      this.showZigbeeDevices(c, p);
      return;
    }

    this.getConnectedZigbeeDevices(c.CustomerID, p.ProjectID).then((data) => {
      const devices = _.get(data, 'Data') || {};
      p.ZigbeeDevices = devices;
      this.showZigbeeDevices(c, p);
    });
  }

  handleRefreshBaseStationDetails(c, p) {
    this.getBaseStationForProject(c, p).then((data) => {
      const baseStation = _.get(data, 'Data') || {};
      p.BaseStation = baseStation;
      const baseStationKey = `basestation-${p.ProjectID}`;
      this.setState({ [baseStationKey]: p.BaseStation });
    });
  }

  openMonitoringDashboard(c, p) {
    if (!p.BaseStation.BaseStationID) {
      return;
    }

    if (p.BaseStation.SerialNumber != '') {
      const url = `https://${p.BaseStation.SerialNumber.toLowerCase()}-monitor.${PROXY_SUB_DOMAIN}.dragonfruit.ai`;
      window.open(url, '_blank');
    }
  }

  browseFiles(c, p) {
    if (!p.BaseStation.BaseStationID) {
      return;
    }

    if (p.BaseStation.SerialNumber != '') {
      const url = `https://${p.BaseStation.SerialNumber.toLowerCase()}.${PROXY_SUB_DOMAIN}.dragonfruit.ai/static`;
      window.open(url, '_blank');
    }
  }

  showBaseStationDetails(c, p) {
    const baseStationKey = `basestation-${p.ProjectID}`;
    this.setState({
      [baseStationKey]: this.state[baseStationKey] ? null : p.BaseStation,
    });
  }

  showZigbeeDevices(c, p) {
    const baseStationZigbeeKey = `basestation-zigbee-${p.ProjectID}`;
    this.setState({
      [baseStationZigbeeKey]: this.state[baseStationZigbeeKey]
        ? null
        : p.ZigbeeDevices,
    });
  }

  doProbe() {
    this.channelProbeForm.current.validateFields().then(
      (values) => {
        const info = this.state.setupProbeForChannel;
        doAppOp(
          this.props.appID,
          this.props.dispatch,
          InternalMonitoringApp.OPS.probeChannel,
          { channel_id: info.channelID, password: values.password },
          false,
        ).then(() => {
          return this.getLatestInfo();
        });
        this.setState({ setupProbeForChannel: null });
        notification.info({
          key: `probe-${info.channelID}`,
          message: 'Probe Started',
          description: (
            <div>
              <ul style={{ listStyle: 'initial' }}>
                <li>
                  Open 'Tasks' in the location to see progress. Remember to
                  'Refresh' periodically
                </li>
                <li>
                  Wait till the State is 'done', which might take as long as the
                  timeout, set to 180s at the time of writing
                </li>
                <li>
                  When 'done', click on the Task to dump info into the browser
                  console
                </li>
                <li>StateMessage will have ffmpeg info</li>
                <li>Datadog will have info with a 'channel_probe' tag</li>
                <li>
                  #prod-alert will have an image with the probe if the probe is
                  successful
                </li>
              </ul>
            </div>
          ),
          duration: 0,
        });
      },
      (err: any) => console.log('err', err),
    );
  }

  doGetLogs(c: any, p: any, reRequest = false) {
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.uploadLogs,
      {
        customer_id: c.CustomerID,
        project_id: p.ProjectID,
        count: 5,
        re_request: reRequest,
      },
      false,
    ).then((data) => {
      let key = Math.random().toString();
      let taskList = _.get(data, 'Data.tasks_list') || [];

      notification.info({
        key,
        message: `Logs for ${p.Name}`,
        description: (
          <div style={{ height: '70vh', overflow: 'scroll' }}>
            <div style={{ margin: '8px 0 16px' }}>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  notification.destroy(key);
                  this.doGetLogs(c, p, true);
                }}>
                Get New Logs
              </Button>
            </div>
            <h3>Logs:</h3>
            {_.isEmpty(taskList) && <div>No Logs</div>}
            {taskList.map((task) => (
              <div key={task.TaskID} style={{ marginBottom: '16px' }}>
                <h4>{task.CreatedAt}</h4>
                <div>
                  {task.UpdatedAt
                    ? `Updated at ${task.UpdatedAt}`
                    : 'Not updated yet'}
                </div>
                {!_.get(task, 'log_urls', []).length ? (
                  <div>No Log URLs</div>
                ) : (
                  <>
                    <div>Log URLs:</div>
                    <div>
                      {_.get(task, 'log_urls', []).map((urlInfo) => (
                        <div>
                          &bull;&nbsp;
                          <a
                            href={urlInfo.signed_url}
                            target="_blank"
                            rel="noreferrer">
                            {urlInfo.file_name}
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
            <div style={{ margin: '10px' }} />
            <h3>Raw Response:</h3>
            <pre style={{ whiteSpace: 'pre-wrap' }}>
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
        ),
        duration: 0,
        style: {
          maxHeight: '85vh',
        },
      });
    });
  }

  doGetLogFolder(c, p, reRequest = false) {
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.uploadLogFolder,
      {
        customer_id: c.CustomerID,
        project_id: p.ProjectID,
        count: 5,
        re_request: reRequest,
      },
      false,
    ).then((data) => {
      let key = Math.random().toString();
      let taskList = _.get(data, 'Data.tasks_list') || [];

      notification.info({
        key,
        message: `Logs for ${p.Name}`,
        description: (
          <div style={{ height: '70vh', overflow: 'scroll' }}>
            <div style={{ margin: '8px 0 16px' }}>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  notification.destroy(key);
                  this.doGetLogFolder(c, p, true);
                }}>
                Get New Logs
              </Button>
            </div>
            <h3>Logs:</h3>
            {_.isEmpty(taskList) && <div>No Logs</div>}
            {taskList.map((task) => (
              <div key={task.TaskID} style={{ marginBottom: '16px' }}>
                <h4>{task.CreatedAt}</h4>
                <div>
                  {task.UpdatedAt
                    ? `Updated at ${task.UpdatedAt}`
                    : 'Not updated yet'}
                </div>
                {!_.get(task, 'log_urls', []).length ? (
                  <div>No Log URLs</div>
                ) : (
                  <>
                    <div>Log URLs:</div>
                    <div>
                      {_.get(task, 'log_urls', []).map((urlInfo) => (
                        <div>
                          &bull;&nbsp;
                          <a
                            href={urlInfo.signed_url}
                            target="_blank"
                            rel="noreferrer">
                            {urlInfo.file_name}
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
            <div style={{ margin: '10px' }} />
            <h3>Raw Response:</h3>
            <pre style={{ whiteSpace: 'pre-wrap' }}>
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
        ),
        duration: 0,
        style: {
          maxHeight: '85vh',
        },
      });
    });
  }

  doImportBaseStations() {
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.importBaseStationFromJAMF,
      {},
      false,
    ).then(() => {
      return this.getLatestInfo();
    });
  }

  makeBaseStationUpdateRequest() {
    this.setBaseStationUpdateVersionForm.current.validateFields().then(
      (values) => {
        const info = this.state.setupBaseStationUpdate;

        let updateToVersion = '';
        if (values.updateToVersion) {
          updateToVersion = values.updateToVersion;
        }

        doAppOp(
          this.props.appID,
          this.props.dispatch,
          InternalMonitoringApp.OPS.updateBaseStaion,
          {
            customer_id: info.customerID,
            project_id: info.projectID,
            update_to_version: updateToVersion,
          },
          false,
        ).then(() => {
          return this.getLatestInfo();
        });
        this.setState({ setupBaseStationUpdate: null });
        notification.info({
          key: `location-${info.projectID}`,
          message: `Basestation update scheduled for location-${info.projectID}`,
          description: (
            <div>
              <ul style={{ listStyle: 'initial' }}>
                <li>Update can take upwards of 10 mins to complete</li>
              </ul>
            </div>
          ),
          duration: 0,
        });
      },
      (err: any) => console.log('err', err),
    );
  }

  makeBaseStationRestartRequest(c, p) {
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.restartBaseStation,
      {
        customer_id: c.CustomerID,
        project_id: p.ProjectID,
        re_request: true,
      },
      false,
    ).then(() => {
      return this.getLatestInfo();
    });

    notification.info({
      key: `location-${p.Name}`,
      message: `Basestation Restart Request Sent for location-${p.Name}`,
      description: (
        <div>
          <ul style={{ listStyle: 'initial' }}>
            <li>A restart request has been sent to the base station</li>
          </ul>
        </div>
      ),
      duration: 0,
    });
  }

  toggleMonitoringDataFederation(c, p, enable) {
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.toggleFederation,
      {
        customer_id: c.CustomerID,
        project_id: p.ProjectID,
        enable: enable,
      },
      false,
    ).then(() => {
      return this.getLatestInfo();
    });

    notification.info({
      key: `location-${p.Name}`,
      message: `Basestation data federation changed for location-${p.Name}`,
      description: (
        <div>
          <ul style={{ listStyle: 'initial' }}>
            <li>Base station monitoring data federation has been changed.</li>
          </ul>
        </div>
      ),
      duration: 0,
    });
  }

  copyToClipboard(text) {
    // Copy the text inside the text field
    navigator.clipboard.writeText(text);
  }

  renderBaseStationDetails(c, p, baseStation: any) {
    if (!baseStation) {
      return <></>;
    }
    if (!baseStation.BaseStationID) {
      return <div> A basestation is not configured for this location </div>;
    }

    return (
      <>
        <div
          className={styles.inmodule}
          style={{ color: 'black', backgroundColor: 'white' }}>
          <div style={{ margin: '5px' }}>
            <strong>Basestation Monitoring</strong>
            <span>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  this.handleOpenMonitoringDashBoard(c, p);
                }}>
                Open Dashboard
              </Button>
            </span>
            <span>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  this.handleBrowseFiles(c, p);
                }}>
                Browse Files
              </Button>
            </span>
            <span>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  this.makeBaseStationRestartRequest(c, p);
                }}>
                Restart Basestation
              </Button>
            </span>
            <span>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  this.toggleMonitoringDataFederation(
                    c,
                    p,
                    !baseStation.EnabledFederation,
                  );
                }}>
                Toggle Monitoring Data Federation
              </Button>
            </span>
            <div style={{ minHeight: '26px' }}>
              Username: captain &nbsp;{' '}
              <CopyToClipboard text="captain">
                <Button type="link" size="small">
                  copy
                </Button>
              </CopyToClipboard>
            </div>
            <div style={{ minHeight: '26px' }}>
              Password:{' '}
              {baseStation.WifiMAC ? (
                '******'
              ) : (
                <span style={{ color: 'red', fontSize: '11px' }}>
                  Password is missing, likley because basestation is not
                  configured correctly. Please contact engineering.
                </span>
              )}{' '}
              &nbsp;{' '}
              {baseStation.WifiMAC ? (
                <CopyToClipboard
                  text={(baseStation.WifiMAC || '').toLowerCase()}>
                  <Button type="link" size="small">
                    copy
                  </Button>
                </CopyToClipboard>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div style={{ margin: '5px' }}>
            <strong>Basestation Details</strong>&nbsp;{' '}
            <Button
              type="link"
              size="small"
              onClick={() => {
                this.handleRefreshBaseStationDetails(c, p);
              }}>
              refresh
            </Button>
            <div style={{ minHeight: '26px' }}>
              Monitoring Data Federation:{' '}
              {baseStation.EnabledFederation ? 'True' : 'False'}
            </div>
            <div style={{ minHeight: '26px' }}>
              Serial Number: {baseStation.SerialNumber}
              &nbsp;{' '}
              <CopyToClipboard text={baseStation.SerialNumber}>
                <Button type="link" size="small">
                  copy
                </Button>
              </CopyToClipboard>
            </div>
            <div style={{ minHeight: '26px' }}>
              Allocated On: {baseStation.AllocationDate}
            </div>
            <div style={{ minHeight: '26px' }}>
              Basestation ID: {baseStation.BaseStationID}
            </div>
            <div style={{ minHeight: '26px' }}>
              Camera Count: {(p.all_channels || []).length}
            </div>
          </div>
          <div style={{ margin: '5px' }}>
            <strong>Basestation Update</strong>
            <div style={{ minHeight: '26px' }}>
              App Instance ID: {baseStation.Installation.AppInstanceID}
              &nbsp;{' '}
              <CopyToClipboard text={baseStation.Installation.AppInstanceID}>
                <Button type="link" size="small">
                  copy
                </Button>
              </CopyToClipboard>
            </div>
            <div style={{ minHeight: '26px' }}>
              Version Number: {baseStation.Installation.AppVersion}
            </div>
            <div style={{ minHeight: '26px' }}>
              Pending Update:{' '}
              {baseStation.Installation.PendingUpdate ? 'True' : 'False'}
            </div>
            <div style={{ minHeight: '26px' }}>
              Update To Version:{' '}
              {baseStation.Installation.UpdateToVersion || '-'}
            </div>
            <div style={{ minHeight: '26px' }}>
              Update Result:{' '}
              {baseStation.Installation.UpdateResult
                ? (baseStation.Installation.UpdateResult || '').substring(0, 50)
                : '-'}
              &nbsp;{' '}
              {(baseStation.Installation.UpdateResult || '').length > 0 ? (
                <CopyToClipboard text={baseStation.Installation.UpdateResult}>
                  <Button type="link" size="small">
                    copy
                  </Button>
                </CopyToClipboard>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  renderZigbeeDevicesList(devices: any) {
    if (!devices) {
      return <></>;
    }
    if (devices.length === 0) {
      return <div>No connected Zigbee devices.</div>;
    }

    return (
      <>
        {devices.map((device) => (
          <div
            className={styles.inmodule}
            style={{ color: 'black', backgroundColor: 'white' }}>
            <div style={{ margin: '5px' }}>
              <strong>ID: {device.id}</strong>
              <div style={{ minHeight: '26px' }}>
                Model ID: {device.modelId}
              </div>
              <div style={{ minHeight: '26px' }}>
                Manufacturer: {device.manufName}
              </div>
              <div style={{ minHeight: '26px' }}>
                IEEE Address: {device.ieeeAddr}
              </div>
              <div style={{ minHeight: '26px' }}>Type: {device.type}</div>
              <div style={{ minHeight: '26px' }}>
                Power Source: {device.powerSource}
              </div>
              <div style={{ minHeight: '26px' }}>
                Check-in Interval: {device.checkinInterval}
              </div>
              <div style={{ minHeight: '26px' }}>
                Last Seen:{' '}
                {device.lastSeen
                  ? moment(device.lastSeen).format(dateTimeFormat)
                  : ''}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  renderTaskTable(tasks) {
    if (!tasks) {
      return <></>;
    }

    return (
      <Table
        dataSource={tasks}
        rowKey="TaskID"
        size="small"
        onRow={(record) => ({
          onClick: () => {
            notification.info({
              message: 'Task Details',
              description: (
                <div style={{ height: '70vh', overflow: 'scroll' }}>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {JSON.stringify(record, null, 2)}
                  </pre>
                </div>
              ),
              duration: 0,
              style: {
                maxHeight: '85vh',
              },
            });
          },
        })}>
        <Table.Column
          key="TaskID"
          style={{ minWidth: '20px', maxWidth: '25px' }}
          render={() => (
            <div className={styles['view-icon-ctn']}>
              <span className={styles['view-icon']}>
                <Icon component={EyeOutlinedIcon} />
              </span>
            </div>
          )}
        />
        <Table.Column dataIndex="TaskID" title="ID" />
        <Table.Column dataIndex="Task" title="Task" />
        <Table.Column dataIndex="State" title="State" />
        <Table.Column
          dataIndex="Data"
          title="Data"
          render={(text, record) => (
            <>
              <div>{JSON.stringify(text)}</div>
              <div>{JSON.stringify(record.StateMessage)}</div>
            </>
          )}
        />
        <Table.Column
          dataIndex="CreatedAt"
          title="CreatedAt"
          render={(text) => <DateText date={text} long={true} />}
        />

        <Table.Column
          dataIndex="UpdatedAt"
          title="UpdatedAt"
          render={(text) => (text ? <DateText date={text} long={true} /> : '-')}
        />
      </Table>
    );
  }

  renderProject(c, p) {
    const tasksKey = `tasks-${p.ProjectID}`;
    const baseStationKey = `basestation-${p.ProjectID}`;
    const baseStationZigbeeKey = `basestation-zigbee-${p.ProjectID}`;

    // schedule update for tasks if they've changed
    if (this.state[tasksKey] && this.state[tasksKey] !== p.tasks) {
      setTimeout(() => this.setState({ [tasksKey]: p.tasks }), 100);
    }

    if (
      this.state[baseStationKey] &&
      this.state[baseStationKey] !== p.BaseStation
    ) {
      setTimeout(() => this.setState({ [baseStationKey]: p.BaseStation }), 100);
    }

    // this.getBaseStationForProject(c,p)

    return (
      <div
        key={p.ProjectID}
        className={styles.inmodule}
        style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <b>{p.Name}</b>{' '}
            <span style={{ fontSize: '11px' }}>&nbsp;{p.ProjectID}</span>
            <Button
              type="link"
              size="small"
              onClick={() =>
                this.listSelector(
                  [
                    c.CustomerID.toString(),
                    p.ProjectID.toString(),
                    'ChannelIDs',
                  ],
                  _.get(p, 'all_channels'),
                )
              }>
              &nbsp; &bull; +Channel
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => this.doGetLogFolder(c, p)}>
              &bull; Get Logs Folder
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => this.doGetLogs(c, p)}>
              &bull; Get Log Files
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() =>
                this.setState({
                  [tasksKey]: this.state[tasksKey] ? null : p.tasks,
                })
              }>
              &bull; Get Tasks
            </Button>
            {/* <Button type="link" size="small">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://app.datadoghq.com/logs?query=${p.ProjectID}`}>
                &nbsp;&bull;&nbsp;dog
              </a>
            </Button> */}
            <Button
              type="link"
              size="small"
              onClick={() => {
                this.setState({
                  setupBaseStationUpdate: {
                    customerID: c.CustomerID,
                    projectID: p.ProjectID,
                  },
                });
              }}>
              &bull; Update Basetation
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                this.handleShowBaseStationDetails(c, p);
              }}>
              &bull; Monitor Basestation
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                this.handleShowZigbeeDevices(c, p);
              }}>
              &bull; Get Zigbee Devices
            </Button>
          </div>
          {this.renderBaseStationDetails(c, p, this.state[baseStationKey])}
          {this.renderZigbeeDevicesList(this.state[baseStationZigbeeKey])}
          {this.renderTaskTable(this.state[tasksKey])}
          <div className={styles.incolumns}>
            {Object.values(_.get(p, 'channel_data', {}))
              .sort((a, b) => a.Name > b.Name)
              .map((ch) => this.renderChannel(p, ch))}
          </div>
        </div>
      </div>
    );
  }

  renderFuncStatusStr(funcStatus) {
    return `${this.getTextFuncStatus(funcStatus)} (${funcStatus})`;
  }

  renderChannel(p, ch) {
    return (
      <div
        key={ch.ChannelID}
        className={styles.inmodule}
        style={{ color: 'black', backgroundColor: 'white', margin: '5px' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Tooltip
              title={() => (
                <>
                  <div>
                    ID: {ch.ChannelID}
                    <CopyToClipboard text={ch.ChannelID}>
                      <Button
                        style={{ color: 'white', textDecoration: 'underline' }}
                        type="link">
                        (Copy)
                      </Button>
                    </CopyToClipboard>
                  </div>
                  <div>Monitor Status: {ch.MonitorStatus}</div>
                  <div>
                    Func Status: {this.renderFuncStatusStr(ch.FuncStatus)}
                  </div>
                </>
              )}>
              <b
                style={{
                  cursor: 'pointer',
                  opacity: ch.MonitorStatus === 'inactive' ? 0.4 : 1,
                  color:
                    ch.FuncStatus === 0
                      ? 'green'
                      : ch.FuncStatus > 0
                      ? 'red'
                      : 'orange',
                }}>
                {ch.Name}
              </b>
              <span style={{ fontSize: '11px' }}>&nbsp;{ch.ChannelID}</span>
            </Tooltip>
            {/* <div>
              <a
                className={styles.link}
                target="_blank"
                rel="noreferrer"
                href={`https://app.datadoghq.com/logs?query=${ch.ChannelID}`}>
                &nbsp;&bull;&nbsp;dog
              </a>
            </div> */}
            <span
              className={styles.link}
              onClick={() =>
                this.setState({
                  setupProbeForChannel: {
                    channelID: ch.ChannelID,
                    projectID: p.ProjectID,
                  },
                })
              }>
              &nbsp; &bull; probe
            </span>
          </div>

          {ch.FuncStatus !== 0 && (
            <div>
              <nobr>
                Status &nbsp;
                <b>{this.renderFuncStatusStr(ch.FuncStatus)}</b>
              </nobr>
            </div>
          )}
          {ch.FuncStatus !== 0 && (
            <>
              <div>
                {moment(ch.FuncStatusChangedAt).fromNow()}: {ch.FuncStatusMsg}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  renderDataFlow() {
    return (
      <>
        <div className={styles.inmodule}>
          <div>
            <b>Uploads</b>
          </div>
        </div>
        <div className={styles.inmodule}>
          <div>
            <b>Preprocessor</b>
          </div>
        </div>
        <div className={styles.inmodule}>
          <div>
            <b>Transcoder</b>
          </div>
        </div>
        <div className={styles.inmodule}>
          <div>
            <b>Brain</b>
          </div>
        </div>
        <div className={styles.inmodule}>
          <div>
            <b>Elastic Search</b>
          </div>
        </div>
        <div className={styles.inmodule}>
          <div>
            <b>Insights</b>
          </div>
        </div>
      </>
    );
  }

  getBaseBaseStation() {
    return {};
  }

  handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
    this.setState({ serialNumberSearchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ serialNumberSearchText: '' });
  };

  renderCell = (text) => (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
      {text}
    </div>
  );

  renderBaseStations() {
    const {
      // baseStations,
      filters_Customer,
      filters_CustomerID,
      filters_Location,
      filters_LocationID,
      // filters_WifiMAC,
    } = this.state;
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginBottom: '5px',
          }}>
          <h4 />
          <div>
            <InternalEditBaseStations
              updater={() => this.getBaseStations()}
              baseStation={this.getBaseBaseStation()}>
              <Button size="small" type="default">
                New Basestation
              </Button>
            </InternalEditBaseStations>
          </div>
        </div>
        <Table
          dataSource={this.state.baseStations}
          rowKey="BaseStationID"
          loading={
            this.props.loading.effects['apps/doAppOp'] ||
            this.props.loading.effects['apps/fetchApp']
          }
          size="small">
          <Table.Column
            dataIndex="SerialNumber"
            title="Serial Number"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  ref={this.searchInput}
                  placeholder={`Search SerialNumber`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() =>
                    this.handleSearch(selectedKeys as string[], confirm)
                  }
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.handleSearch(selectedKeys as string[], confirm)
                    }
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}>
                    Search
                  </Button>
                  <Button
                    onClick={() =>
                      clearFilters && this.handleReset(clearFilters)
                    }
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                  </Button>
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      confirm({ closeDropdown: false });
                      this.setState({
                        serialNumberSearchText: (selectedKeys as string[])[0],
                      });
                    }}>
                    Filter
                  </Button>
                </Space>
              </div>
            )}
            onFilter={(value, record) =>
              _.get(record, ['SerialNumber'], '')
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
            }
            filterIcon={(filtered: boolean) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            )}
            onFilterDropdownOpenChange={(visible: boolean) => {
              if (visible) {
                setTimeout(() => this.searchInput.current?.select(), 100);
              }
            }}
            sorter={(a, b) => (a.SerialNumber < b.SerialNumber ? -1 : 1)}
            render={(text) => (
              <>
                <span style={{ color: 'green', fontSize: '20px' }}>
                  {_.get(this.state.handshakes, text) ? (
                    <Tooltip title="Receiving handshake pings">
                      <span>
                        <>&bull;</>
                      </span>
                    </Tooltip>
                  ) : (
                    ''
                  )}{' '}
                </span>
                <span>{text}</span>
              </>
            )}
          />
          <Table.Column
            dataIndex={['Customer', 'Name']}
            title="Customer"
            filters={filters_Customer}
            filterSearch={true}
            onFilter={(value, record) =>
              _.get(record, ['Customer', 'Name'], '') == value
            }
            // sorter={(a, b) => (a.CustomerID < b.CustomerID ? -1 : 1)}
            render={this.renderCell}
          />
          <Table.Column
            width="120px"
            dataIndex="CustomerID"
            title="Customer ID"
            filters={filters_CustomerID}
            filterSearch={true}
            onFilter={(value, record) => record['CustomerID'] == value}
            // sorter={(a, b) => (a.CustomerID < b.CustomerID ? -1 : 1)}
            render={this.renderCell}
          />
          <Table.Column
            dataIndex={['Project', 'Name']}
            title="Location"
            filters={filters_Location}
            filterSearch={true}
            onFilter={(value, record) =>
              _.get(record, ['Project', 'Name'], '') == value
            }
            // sorter={(a, b) => (a.CustomerID < b.CustomerID ? -1 : 1)}
            render={this.renderCell}
          />
          <Table.Column
            width="120px"
            dataIndex="ProjectID"
            title="Location ID"
            filters={filters_LocationID}
            filterSearch={true}
            onFilter={(value, record) => record['ProjectID'] == value}
            // sorter={(a, b) => (a.ProjectID < b.ProjectID ? -1 : 1)}
            render={this.renderCell}
          />
          <Table.Column
            dataIndex={['StatusDetails', 'shipment_details', 'delivery_status']}
            title="Status"
          />
          {/* <Table.Column
            dataIndex="WifiMAC"
            title="Wifi MAC"
            filters={filters_WifiMAC}
            filterSearch={true}
            onFilter={(value, record) => record['WifiMAC'] == value}
            // sorter={(a, b) => (a.WifiMAC < b.WifiMAC ? -1 : 1)}
          />*/}

          <Table.Column
            dataIndex="controls"
            title=""
            render={(text, record) => (
              <InternalEditBaseStations
                updater={() => this.getBaseStations()}
                baseStation={record}>
                <Button size="small" type="default">
                  Edit
                </Button>
              </InternalEditBaseStations>
            )}
          />
        </Table>
      </>
    );
  }

  renderCustomers() {
    let { data } = this.props;

    return (
      <>
        <div style={{ overflow: 'scroll' }} className={styles.incolumns}>
          <span
            style={{ margin: '2px' }}
            className={styles.link}
            onClick={() =>
              this.listSelector(
                ['CustomerIDs'],
                _.get(data, 'dash.all_customers'),
              )
            }>
            +Customer
          </span>
          {Object.values(_.get(data, 'dash.customer_data', {})).map((c) => {
            return (
              <div
                key={c.CustomerID}
                className={styles.inmodule}
                style={{ color: 'black', backgroundColor: 'white' }}>
                <div>
                  <b>{c.Name}</b>
                  <span style={{ fontSize: '11px' }}>&nbsp;{c.CustomerID}</span>
                  <Button
                    type="link"
                    size="small"
                    onClick={() =>
                      this.listSelector(
                        [c.CustomerID.toString(), 'ProjectIDs'],
                        _.get(c, 'all_projects'),
                      )
                    }>
                    &bull; +Location
                  </Button>
                </div>
                <div className={styles.incolumns}>
                  {Object.values(_.get(c, 'project_data', {}))
                    .sort((a, b) => a.Name > b.Name)
                    .map((p) => this.renderProject(c, p))}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  importSites(results) {
    if (this.state.currentCustomerID == 0) {
      notification.open({
        message: 'Please select a customer',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.importSites,
      {
        customer_id: this.state.currentCustomerID,
        results: results,
      },
    )
      .then((response) => {
        const message = response?.Data[0];
        notification.open({
          message: message,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      })
      .then(() => {});
  }

  importChannelsLocations(results) {
    if (this.state.currentCustomerID == 0) {
      notification.open({
        message: 'Please select a customer',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }

    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.importChannels,
      {
        customer_id: this.state.currentCustomerID,
        results: results,
      },
    )
      .then((response) => {
        if (response?.length > 0) {
          const message = response.Data[0];
          notification.open({
            message: message,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
      })
      .then(() => {});
  }

  importBurglarAlarms(results) {
    if (this.state.currentCustomerID == 0) {
      notification.open({
        message: 'Please select a customer',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }

    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.importBurglarAlarms,
      {
        customer_id: this.state.currentCustomerID,
        results: results,
      },
      false,
    )
      .then((response) => {
        if (response?.length > 0) {
          const message = response.Data[0];
          notification.open({
            message: message,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
      })
      .then(() => {});
  }

  importBaseStations(results) {
    if (this.state.currentCustomerID == 0) {
      notification.open({
        message: 'Please select a customer',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.importBasestations,
      {
        customer_id: this.state.currentCustomerID,
        results: results,
      },
    ).then((response) => {
      if (response?.length > 0) {
        const message = response.Data[0];
        notification.open({
          message: message,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      }
    });
  }

  readCSV(file: any, fileType: string) {
    this.setState({ selectedFile: file, selectedFileType: fileType });
  }

  readAndUploadCSVData() {
    if (this.state.selectedFileType == '') {
      return;
    }

    Papa.parse(this.state.selectedFile, {
      complete: (results: any) => {
        if (this.state.selectedFileType == 'Sites') {
          this.importSites(results);
        } else if (this.state.selectedFileType == 'Channels') {
          this.importChannelsLocations(results);
        } else if (this.state.selectedFileType == 'BaseStations') {
          this.importBaseStations(results);
        } else if (this.state.selectedFileType == 'BurglarAlarms') {
          this.importBurglarAlarms(results);
        }
      },
    });
  }

  getCustomerName(customerID) {
    let name = customerID;
    let { data } = this.props;
    const all_customers = _.get(data, 'dash.all_customers');

    if (all_customers) {
      const customer = all_customers.find((c) => c.ID === customerID);
      if (customer) {
        name = customer.Name;
      }
    }
    return name;
  }

  setOverrideCustomerID(customerID) {
    this.setState({ currentCustomerID: customerID });
  }

  getCustomerIDName(customerID) {
    return '(' + customerID + ') ' + this.getCustomerName(customerID);
  }

  getAllTaskStatus() {
    if (
      this.state.bs_status_jamf == 'success' &&
      this.state.bs_status_df_cloud == 'success' &&
      this.state.bs_status_frps == 'success' &&
      this.state.bs_status_s3_upload == 'success'
    ) {
      return 'success';
    } else if (
      this.state.bs_status_jamf == 'default' &&
      this.state.bs_status_df_cloud == 'default' &&
      this.state.bs_status_frps == 'default' &&
      this.state.bs_status_s3_upload == 'default'
    ) {
      return 'default';
    } else if (
      this.state.bs_status_jamf == 'error' ||
      this.state.bs_status_df_cloud == 'error' ||
      this.state.bs_status_frps == 'error' ||
      this.state.bs_status_s3_upload == 'error'
    ) {
      return 'error';
    } else {
      return 'processing';
    }
  }

  cancelCheckStatusRequest() {
    if (this.timeCounterDown != null) {
      clearInterval(this.timeCounterDown);
    }
    if (this.timerCheckJAMF != null) {
      clearInterval(this.timerCheckJAMF);
    }
    if (this.timerCheckDFCloud != null) {
      clearInterval(this.timerCheckDFCloud);
    }
    if (this.timerCheckFRPS != null) {
      clearInterval(this.timerCheckFRPS);
    }
    if (this.timerCheckFileServer != null) {
      clearInterval(this.timerCheckFileServer);
    }
    if (this.checkLogUploadStatus != null) {
      clearInterval(this.checkLogUploadStatus);
    }

    this.timeCounterDown = null;
    this.timerCheckJAMF = null;
    this.timerCheckDFCloud = null;
    this.timerCheckFRPS = null;
    this.timerCheckFileServer = null;
    this.checkLogUploadStatus = null;
  }

  checkBaseStationStatus(bs_status_serial) {
    this.cancelCheckStatusRequest();

    this.setState({
      check_bs_serial_numer: bs_status_serial,
      bs_status_jamf: 'default',
      bs_status_df_cloud: 'default',
      bs_status_frps: 'default',
      bs_status_s3_upload: 'default',
      bs_status_jamf_error: '',
      bs_status_df_cloud_error: '',
      bs_status_frps_error: '',
      bs_status_s3_upload_error: '',
      bs_jamf_data_managed: '',
      bs_jamf_data_last_checkin_time: '',
      bs_jamf_data_reported_ip: '',
      bs_status_msg: '',
      bs_status_result: '',
      show_timer: true,
      bs_project_id: 0,
      bs_customer_id: 0,
    });

    const startTime = new Date();
    const endTime = new Date(startTime.getTime() + TaskDuraton * 1000);

    // show countdown till timeout
    this.timeCounterDown = setInterval(() => {
      const now = new Date();
      const timeDiffInMilliseconds = endTime - now;
      let timeDiffInSeconds = Math.floor(timeDiffInMilliseconds / 1000);
      if (timeDiffInSeconds <= 0) {
        timeDiffInSeconds = 0;
      }
      this.setState({
        check_status_timeout: timeDiffInSeconds,
      });

      if (this.getAllTaskStatus() == 'success') {
        this.setState({
          bs_status_msg: 'Base station connection successful',
          bs_status_result: 'success',
          show_timer: false,
        });
        clearInterval(this.timeCounterDown);
        return;
      } else if (this.getAllTaskStatus() == 'error') {
        this.setState({
          bs_status_msg: 'Base station connection has failed',
          bs_status_result: 'danger',
          show_timer: false,
        });
        clearInterval(this.timeCounterDown);
        return;
      }
    }, 1000);

    const tickerDuration = 10000; // 10 seconds

    this.checkJAMFConnection(bs_status_serial);
    this.timerCheckJAMF = setInterval(() => {
      if (this.state.bs_status_jamf == 'success') {
        clearInterval(this.timerCheckJAMF);
        return;
      }
      const now = new Date();
      const timeDiffInMilliseconds = endTime - now;
      if (timeDiffInMilliseconds <= 0) {
        this.setState({
          bs_status_jamf: 'error',
        });
        clearInterval(this.timerCheckJAMF);
        return;
      }
      this.checkJAMFConnection(bs_status_serial);
    }, tickerDuration);

    this.checkDFCloudConnection(bs_status_serial);
    this.timerCheckDFCloud = setInterval(() => {
      if (this.state.bs_status_df_cloud == 'success') {
        clearInterval(this.timerCheckDFCloud);
        return;
      }
      const now = new Date();
      const timeDiffInMilliseconds = endTime - now;
      if (timeDiffInMilliseconds <= 0) {
        this.setState({
          bs_status_df_cloud: 'error',
        });
        clearInterval(this.timerCheckDFCloud);
        return;
      }
      this.checkDFCloudConnection(bs_status_serial);
    }, tickerDuration);

    this.checkFRPSConnection(bs_status_serial);
    this.timerCheckFRPS = setInterval(() => {
      if (this.state.bs_status_frps == 'success') {
        clearInterval(this.timerCheckFRPS);
        return;
      }
      const now = new Date();
      const timeDiffInMilliseconds = endTime - now;
      if (timeDiffInMilliseconds <= 0) {
        this.setState({
          bs_status_frps: 'error',
        });
        clearInterval(this.timerCheckFRPS);
        return;
      }
      this.checkFRPSConnection(bs_status_serial);
    }, tickerDuration);

    this.checkFileServerConnection(bs_status_serial);
    this.timerCheckFileServer = setInterval(() => {
      if (this.state.bs_status_s3_upload == 'success') {
        clearInterval(this.timerCheckFileServer);
        return;
      }
      const now = new Date();
      const timeDiffInMilliseconds = endTime - now;
      if (timeDiffInMilliseconds <= 0) {
        this.setState({
          bs_status_s3_upload: 'error',
        });
        clearInterval(this.timerCheckFileServer);
        return;
      }
      this.checkFileServerConnection(bs_status_serial);
    }, tickerDuration);
  }

  checkJAMFConnection(bs_status_serial) {
    if (this.state.bs_status_jamf == 'processing') {
      return;
    }
    this.setState({ bs_status_jamf: 'processing' });
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.getBaseStationStatusJamf,
      {
        serial_number: bs_status_serial,
      },
      false,
    ).then((object) => {
      const data = object?.Data;
      let managed = 'No';
      if (!data?.success) {
        this.setState({
          bs_status_jamf: 'yellow',
          bs_status_jamf_error: data?.error,
          bs_jamf_data_managed: managed,
        });
        return;
      }

      const baseStation = data?.base_station;

      if (baseStation?.response_status_code != 200) {
        this.setState({
          bs_status_jamf: 'yellow',
          bs_status_jamf_error:
            'JAMF response status code: ' + baseStation?.response_status_code,
          bs_jamf_data_managed: managed,
        });
        return;
      }

      if (baseStation?.managed == true) {
        managed = 'Yes';
      }

      this.setState({
        bs_jamf_data_managed: managed,
        bs_jamf_data_last_checkin_time: baseStation?.last_check_in_utc,
        bs_jamf_data_reported_ip: baseStation?.last_reported_ip,
      });

      // Get the current epoch time in milliseconds
      const epochTimeMillis = Date.now();
      // Convert to seconds (optional)
      const epochTimeSeconds = Math.floor(epochTimeMillis / 1000);

      let lastCheckInEpoch = parseInt(baseStation?.last_check_in_epoch) / 1000;
      let timeSinceCheckIn = epochTimeSeconds - lastCheckInEpoch;
      if (
        baseStation?.managed == true &&
        timeSinceCheckIn <= 3600 &&
        baseStation?.last_reported_ip != ''
      ) {
        this.setState({ bs_status_jamf: 'success' });
        return;
      }

      // If iis not successful change status to warning
      this.setState({ bs_status_jamf: 'yellow' });
    });
  }

  checkDFCloudConnection(bs_status_serial) {
    if (this.state.bs_status_df_cloud == 'processing') {
      return;
    }
    this.setState({ bs_status_df_cloud: 'processing' });
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.getBaseStationStatusDFCloud,
      {
        serial_number: bs_status_serial,
      },
      false,
    ).then((object) => {
      const data = object?.Data;
      if (!data.success) {
        this.setState({
          bs_status_df_cloud: 'yellow',
          bs_status_df_cloud_error: data?.error,
        });
        return;
      }

      if (data.seconds_elapsed < 3600) {
        this.setState({
          bs_status_df_cloud: 'success',
          bs_project_id: data?.base_station?.ProjectID,
          bs_customer_id: data?.base_station?.CustomerID,
        });
      }
    });
  }

  checkFRPSConnection(bs_status_serial) {
    if (this.state.bs_status_frps == 'processing') {
      return;
    }
    this.setState({ bs_status_frps: 'processing' });
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.getBaseStationStatusFRPS,
      {
        serial_number: bs_status_serial,
      },
      false,
    ).then((object) => {
      const data = object?.Data;
      if (!data.success) {
        this.setState({
          bs_status_frps: 'yellow',
          bs_status_frps_error: data?.error,
        });
        return;
      }
      this.setState({ bs_status_frps: 'success' });
    });
  }

  checkFileServerConnection(bs_status_serial) {
    if (this.state.bs_status_s3_upload == 'processing') {
      return;
    }
    this.setState({ bs_status_s3_upload: 'processing' });
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.getBaseStationStatusUploadLogs,
      {
        serial_number: bs_status_serial,
      },
      false,
    ).then((object) => {
      const data = object?.Data;
      if (!data.success) {
        this.setState({
          bs_status_s3_upload: 'yellow',
          bs_status_s3_upload_error: data?.error,
        });
        return;
      }
      const task = data?.task;
      if (task.TaskID > 0) {
        this.setState({ bs_status_s3_upload_task_id: task.TaskID });
        this.checkTaskUploadResponse(task.TaskID, data?.error);
      }
    });
  }

  checkTaskUploadResponse(taskID, err) {
    this.checkUploadTaskStatus(taskID);
    let checkTaskStatusRetry = 60; // 5 mins
    this.checkLogUploadStatus = setInterval(() => {
      checkTaskStatusRetry--;
      if (this.state.bs_status_s3_upload == 'success') {
        clearInterval(this.checkLogUploadStatus);
        return;
      }
      if (checkTaskStatusRetry == 0) {
        this.setState({
          bs_status_s3_upload: 'error',
          bs_status_s3_upload_error: err,
        });
        clearInterval(this.checkLogUploadStatus);
        return;
      }
      this.checkUploadTaskStatus(taskID);
    }, 5000);
  }

  checkUploadTaskStatus(taskID) {
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.getBaseStationStatusCheckUploadTask,
      {
        task_id: taskID,
      },
      false,
    ).then((object) => {
      const data = object?.Data;
      if (!data.success) {
        this.setState({
          bs_status_s3_upload: 'error',
          bs_status_s3_upload_error: data?.error,
        });
        return false;
      }
      const task = data?.task;
      if (task.State == 'done') {
        this.setState({ bs_status_s3_upload: 'success' });
        return true;
      }
      return false;
    });
  }

  renderImports() {
    let { data } = this.props;
    return (
      <>
        <div
          style={{
            display: 'flex',
            color: 'rgba(0,0,0,0.85)',
            fontSize: '11px',
            alignItems: 'baseline',
          }}>
          <div>
            <CustomerIDEditor
              setCustomerID={this.setOverrideCustomerID.bind(this)}
              initialValues={this.state.currentCustomerID}
              allCustomers={_.get(data, 'dash.all_customers')}
            />
            &nbsp;
            <span size="small">
              Customer: {this.getCustomerIDName(this.state.currentCustomerID)}
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            color: 'rgba(0,0,0,0.85)',
            fontSize: '11px',
            alignItems: 'baseline',
            marginTop: '10px',
            marginBottom: '10px',
          }}>
          {this.state.selectedFileType.length > 0
            ? 'Import file of type ' +
              this.state.selectedFileType +
              ': ' +
              this.state.selectedFile.name
            : ''}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginTop: '10px',
            marginBottom: '10px',
          }}>
          <div>
            <span>
              <Upload
                accept=".csv"
                showUploadList={false}
                beforeUpload={(file) => {
                  this.readCSV(file, 'Sites');
                }}>
                <Button size="small" type="default">
                  Select CSV File - Sites
                </Button>
              </Upload>
            </span>
            &nbsp;
            <span>
              <Upload
                accept=".csv"
                showUploadList={false}
                beforeUpload={(file) => {
                  this.readCSV(file, 'Channels');
                }}>
                <Button size="small" type="default">
                  Select CSV File - Channels + Locations
                </Button>
              </Upload>
            </span>
            &nbsp;
            <span>
              <Upload
                accept=".csv"
                showUploadList={false}
                beforeUpload={(file) => {
                  this.readCSV(file, 'BaseStations');
                }}>
                <Button size="small" type="default">
                  Select CSV File - Basestations
                </Button>
              </Upload>
            </span>
            &nbsp;
            <span>
              <Upload
                accept=".csv"
                showUploadList={false}
                beforeUpload={(file) => {
                  this.readCSV(file, 'BurglarAlarms');
                }}>
                <Button size="small" type="default">
                  Select CSV File - Burglar Alarms
                </Button>
              </Upload>
            </span>
            {this.state.selectedFileType != '' ? (
              <span style={{ marginLeft: '5px' }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    this.readAndUploadCSVData();
                  }}>
                  Upload Data
                </Button>
              </span>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            color: 'rgba(0,0,0,0.85)',
            fontSize: '11px',
            alignItems: 'baseline',
            marginTop: '10px',
            marginBottom: '10px',
          }}>
          {this.state.importedFile.length > 0
            ? 'Imported file: ' + this.state.importedFile
            : ''}
        </div>
      </>
    );
  }

  onFinishBasestationCheckForm: FormProps<FieldType>['onFinish'] = (values) => {
    const { bs_serial_number } = values;
    this.checkBaseStationStatus(bs_serial_number);
  };

  onFinishFailedBasestationCheckForm: FormProps<FieldType>['onFinishFailed'] = (
    errorInfo,
  ) => {
    console.log('Failed:', errorInfo);
  };

  renderBasestationCheckForm() {
    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          Check Basestation Connectivity
        </Divider>
        <Form
          name="BasestationCheckStatus"
          labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={this.onFinishBasestationCheckForm}
          onFinishFailed={this.onFinishFailedBasestationCheckForm}
          autoComplete="off">
          <Form.Item<FieldType>
            label="Basestation Serial Number"
            name="bs_serial_number"
            rules={[
              {
                required: true,
                message: 'Basestation Serial Number is missing.',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.getAllTaskStatus() == 'processing'}>
              Check Status
            </Button>
            {this.getAllTaskStatus() == 'processing' ? (
              <Badge
                status={this.getAllTaskStatus()}
                style={{ marginLeft: '10px' }}
                text={
                  <Text style={{ fontSize: '11px' }}>
                    Checking status... {this.state.check_status_timeout} seconds
                  </Text>
                }
              />
            ) : null}
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <div>
              <Badge
                status={this.state.bs_status_jamf}
                text={
                  <Text style={{ fontSize: '11px' }}>
                    Base station connected to JAMF
                  </Text>
                }
              />
              <div style={{ marginLeft: '20px' }}>
                {this.state.bs_status_jamf_error.length > 0 ? (
                  <div>
                    <Text
                      type="danger"
                      style={{ marginRight: '10px', fontSize: '11px' }}>
                      {this.state.bs_status_jamf_error}
                    </Text>
                  </div>
                ) : null}
                <div>
                  <Text
                    type="secondary"
                    style={{ marginRight: '10px', fontSize: '11px' }}>
                    Managed
                  </Text>
                  <Text style={{ fontSize: '11px' }}>
                    {this.state.bs_jamf_data_managed}
                  </Text>
                </div>
                <div>
                  <Text
                    type="secondary"
                    style={{ marginRight: '10px', fontSize: '11px' }}>
                    Last Check In
                  </Text>
                  <Text style={{ fontSize: '11px' }}>
                    {this.state.bs_jamf_data_last_checkin_time}
                  </Text>
                </div>
                <div>
                  <Text
                    type="secondary"
                    style={{ marginRight: '10px', fontSize: '11px' }}>
                    Reported IP
                  </Text>
                  <Text style={{ fontSize: '11px' }}>
                    {this.state.bs_jamf_data_reported_ip}
                  </Text>
                </div>
              </div>
              <div>
                <Badge
                  status={this.state.bs_status_df_cloud}
                  text={
                    <Text style={{ fontSize: '11px' }}>
                      Base station connected to Dragonfruit cloud
                    </Text>
                  }
                />
                <div style={{ marginLeft: '20px' }}>
                  <div>
                    <Text
                      type="secondary"
                      style={{ marginRight: '10px', fontSize: '11px' }}>
                      Customer ID
                    </Text>
                    <Text style={{ fontSize: '11px' }}>
                      {this.state.bs_customer_id}
                    </Text>
                  </div>
                  <div>
                    <Text
                      type="secondary"
                      style={{ marginRight: '10px', fontSize: '11px' }}>
                      Project ID
                    </Text>
                    <Text style={{ fontSize: '11px' }}>
                      {this.state.bs_project_id}
                    </Text>
                  </div>
                </div>
                {this.state.bs_status_df_cloud_error.length > 0 ? (
                  <div style={{ marginLeft: '20px' }}>
                    <Text
                      type="danger"
                      style={{ marginRight: '10px', fontSize: '11px' }}>
                      {this.state.bs_status_df_cloud_error}
                    </Text>
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <Badge
                status={this.state.bs_status_frps}
                text={
                  <Text style={{ fontSize: '11px' }}>
                    Base station connected to FRPS
                  </Text>
                }
              />
              {this.state.bs_status_frps_error.length > 0 ? (
                <div style={{ marginLeft: '20px' }}>
                  <Text
                    type="danger"
                    style={{ marginRight: '10px', fontSize: '11px' }}>
                    {this.state.bs_status_frps_error}
                  </Text>
                </div>
              ) : null}
            </div>
            <div>
              <Badge
                status={this.state.bs_status_s3_upload}
                text={
                  <Text style={{ fontSize: '11px' }}>
                    Base station connected to File Server (S3)
                  </Text>
                }
              />
              {this.state.bs_status_s3_upload_error?.length > 0 ? (
                <div style={{ marginLeft: '20px' }}>
                  <Text
                    type="danger"
                    style={{ marginRight: '10px', fontSize: '11px' }}>
                    {this.state.bs_status_s3_upload_error}
                  </Text>
                </div>
              ) : null}
            </div>
          </Form.Item>
        </Form>

        {/* <div> */}
        {/* <Button
            size="small"
            type="primary"
            onClick={() => {
              this.checkBaseStationStatus(bs_status_serial);
            }}>
            Check Status
          </Button> */}
        {/* </div> */}
      </>
    );
  }

  onFinishBasestationNetworkUpdateForm: FormProps<FieldType>['onFinish'] = (
    values,
  ) => {
    const { network_interface, ip_address, subnet, router } = values;
    doAppOp(
      this.props.appID,
      this.props.dispatch,
      InternalMonitoringApp.OPS.setBaseStationNetworkConfig,
      {
        customer_id: this.state.bs_customer_id,
        project_id: this.state.bs_project_id,
        interface: network_interface,
        ip_address: ip_address,
        subnet: subnet,
        router: router,
      },
      false,
    ).then((result) => {
      console.log(result);
    });
  };

  onFinishFailedBasestationNetworkUpdateForm: FormProps<FieldType>['onFinishFailed'] =
    (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

  renderNetworkUpdateForm() {
    // Regular expression for validating IPv4 addresses
    const ipRegex = new RegExp(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    );

    // Regular expression for validating subnets (e.g., 192.168.1.0/24)
    const subnetRegex = new RegExp(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[12][0-9]|3[0-2]))$/,
    );

    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          Update Basestation Network
        </Divider>
        <Form
          name="BasestationNetworkUpdate"
          labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          disabled={this.state?.bs_project_id <= 0}
          onFinish={this.onFinishBasestationNetworkUpdateForm}
          onFinishFailed={this.onFinishFailedBasestationNetworkUpdateForm}
          autoComplete="off">
          <Form.Item<FieldType>
            label="Network Interface"
            name="network_interface"
            rules={[{ required: true, message: 'Interface is missing.' }]}>
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="IP Address"
            name="ip_address"
            rules={[
              { pattern: ipRegex, message: 'Invalid IP address' },
              { required: true, message: 'IP address is missing.' },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Subnet"
            name="subnet"
            rules={[
              { pattern: subnetRegex, message: 'Invalid Subnet address' },
              { required: true, message: 'Subnet address is missing.' },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item<FieldType> label="Router" name="router">
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }

  onFinishBasestationZigbeeForm: FormProps<FieldType>['onFinish'] = () => {
    this.getConnectedZigbeeDevices(
      this.state.bs_customer_id,
      this.state.bs_project_id,
    ).then((data) => {
      const devices = _.get(data, 'Data') || {};
      this.setState({
        bs_zigbee_devices: devices,
      });
    });
  };

  onFinishFailedBasestationZigbeeForm: FormProps<FieldType>['onFinishFailed'] =
    (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

  renderBasestationZigbeeForm() {
    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          Check Zigbee Devices
        </Divider>
        <Form
          name="BasestationZigbeeDevices"
          labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={this.onFinishBasestationZigbeeForm}
          onFinishFailed={this.onFinishFailedBasestationZigbeeForm}
          disabled={this.state?.bs_project_id <= 0}
          autoComplete="off">
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Get Zigbee Devices
            </Button>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {this.renderZigbeeDevicesList(this.state.bs_zigbee_devices)}
          </Form.Item>
        </Form>
      </>
    );
  }

  renderBaseStationStatus() {
    return (
      <>
        <Row>
          <Col span={24}>{this.renderBasestationCheckForm()}</Col>
        </Row>
        <Row>
          <Col span={24}>{this.renderBasestationZigbeeForm()}</Col>
        </Row>
        <Row>
          <Col span={24}>{this.renderNetworkUpdateForm()}</Col>
        </Row>
      </>
    );
  }

  isDragonfruitUser(user) {
    const email = user?.currentUser?.Email;
    if (email.length <= 0) {
      return false;
    }
    if (email.endsWith('dragonfruit.ai')) {
      return true;
    }
    return false;
  }

  render() {
    const { loading, user } = this.props;

    return (
      <>
        <Modal
          width={450}
          title="Enter Channel Password for Probe"
          onOk={() => this.doProbe()}
          onCancel={() => this.setState({ setupProbeForChannel: null })}
          confirmLoading={loading.effects['apps/fetchApp']}
          destroyOnClose={true}
          open={this.state.setupProbeForChannel}>
          <Form
            ref={this.channelProbeForm}
            layout="vertical"
            requiredMark={false}
            onFinish={() => this.doProbe()}>
            <Form.Item label="Password" name="password">
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          width={450}
          title="Enter version number to target (Leave blank for latest prod version)"
          onOk={() => this.makeBaseStationUpdateRequest()}
          onCancel={() => this.setState({ setupBaseStationUpdate: null })}
          confirmLoading={loading.effects['apps/fetchApp']}
          destroyOnClose={true}
          open={this.state.setupBaseStationUpdate}>
          <Form
            ref={this.setBaseStationUpdateVersionForm}
            layout="vertical"
            requiredMark={false}
            onFinish={() => this.makeBaseStationUpdateRequest()}>
            <Form.Item name="updateToVersion">
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          width={450}
          title="Select"
          onOk={() => this.saveList()}
          onCancel={() => this.setState({ listSelectorVisibility: false })}
          confirmLoading={loading.effects['apps/fetchApp']}
          destroyOnClose={true}
          open={this.state.listSelectorVisibility}>
          <Select
            style={{ width: '100%' }}
            showArrow
            dropdownMatchSelectWidth={false}
            options={this.state.listSource || []}
            mode="multiple"
            value={this.state.listSelected}
            filterOption={(inputValue, option) =>
              option.Name.toLowerCase().includes(inputValue.toLowerCase()) ||
              option.ID.toString().includes(inputValue)
            }
            onChange={(values) => this.setState({ listSelected: values })}
          />
        </Modal>

        {loading.effects['apps/fetchApp'] && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.4)',
              zIndex: '2',
            }}>
            <LoadingSpinner />
          </div>
        )}
        <PageHeader title={this.props.appObj.Name} />
        <Tabs
          style={{ marginRight: '16px' }}
          onTabClick={(key) => {
            if (key === 'basestations') {
              this.getBaseStations();
            }
          }}
          tabBarExtraContent={
            <Button
              size="small"
              loading={
                loading.effects['apps/fetchApp'] ||
                loading.effects['apps/doAppOp']
              }
              onClick={() =>
                this.props.dispatch({
                  type: 'apps/fetchApp',
                  appID: this.props.appID,
                })
              }>
              Refresh
            </Button>
          }>
          {this.isDragonfruitUser(user) ? (
            <TabPane tab="Customers" key="customers">
              {this.renderCustomers()}
            </TabPane>
          ) : null}
          {this.isDragonfruitUser(user) ? (
            <TabPane tab="Basestations" key="basestations">
              {this.renderBaseStations()}
            </TabPane>
          ) : null}
          {this.isDragonfruitUser(user) ? (
            <TabPane tab="Imports" key="imports">
              {this.renderImports()}
            </TabPane>
          ) : null}
          <TabPane tab="Basestation Status" key="basestation_status">
            {this.renderBaseStationStatus()}
          </TabPane>
        </Tabs>
      </>
    );
  }

  static appID = 48;

  static OPS = {
    saveConfig: {
      name: 'save_config',
      requiredParams: ['config'],
    },
    probeChannel: {
      name: 'probe_channel',
      requiredParams: ['channel_id', 'password'],
    },
    uploadLogs: {
      name: 'upload_logs_from_base_station',
      requiredParams: ['customer_id', 'project_id'],
    },
    uploadLogFolder: {
      name: 'upload_log_folder_from_base_station',
      requiredParams: ['customer_id', 'project_id'],
    },
    getBaseStations: {
      name: 'get_base_stations',
    },
    getBaseStationForProject: {
      name: 'get_base_station_for_project',
    },
    getConnectedZigbeeDevices: {
      name: 'get_connected_zigbee_devices',
    },
    editBaseStation: {
      name: 'edit_base_station',
    },
    obliterate: {
      name: 'factory_reset_base_station',
    },
    updateBaseStaion: {
      name: 'update_base_station',
      requiredParams: ['customer_id', 'project_id', 'update_to_version'],
    },
    restartBaseStation: {
      name: 'restart_base_station',
      requiredParams: ['customer_id', 'project_id', 're_request'],
    },
    toggleFederation: {
      name: 'toggle_federation',
      requiredParams: ['customer_id', 'project_id', 'enable'],
    },
    importSites: {
      name: 'import_sites',
      requiredParams: ['customer_id', 'results'],
    },
    importChannels: {
      name: 'import_channels',
      requiredParams: ['customer_id', 'results'],
    },
    importBurglarAlarms: {
      name: 'import_burglar_alarms',
      requiredParams: ['customer_id', 'results'],
    },
    importBasestations: {
      name: 'import_basestations',
      requiredParams: ['customer_id', 'results'],
    },
    getBaseStationStatusJamf: {
      name: 'get_base_station_status_jamf',
      requiredParams: ['serial_number'],
    },
    getBaseStationStatusDFCloud: {
      name: 'get_base_station_status_df_cloud',
      requiredParams: ['serial_number'],
    },
    getBaseStationStatusFRPS: {
      name: 'get_base_station_status_frps',
      requiredParams: ['serial_number'],
    },
    getBaseStationStatusUploadLogs: {
      name: 'get_base_station_status_upload_logs',
      requiredParams: ['serial_number'],
    },
    getBaseStationStatusCheckUploadTask: {
      name: 'get_base_station_status_check_upload_task',
      requiredParams: ['task_id'],
    },
    setBaseStationNetworkConfig: {
      name: 'set_network_ip_address',
      requiredParams: [
        'customer_id',
        'project_id',
        'interface',
        'ip_address',
        'subnet',
        'router',
      ],
    },
  };
}
export default InternalMonitoringApp;
