import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import AlertTable from '@/components/ChannelComponents/alert-table';
import ChannelActions from '@/components/ChannelComponents/channel-actions';
import ChannelSettings from '@/components/ChannelSettings';
import PageHeader from '@/components/PageHeader2';
import SearchForm from '@/components/SearchForm2';
import SearchResults from '@/components/SearchResults';
import TimelinePlayer from '@/components/TimelinePlayer';
import UploadVideoToChannel from '@/components/UploadVideoToChannel';
import CameraControls from './controls';
import styles from './style.less';

import {
  BASE_STN_TYPE,
  ChannelNode,
  CH_GRP_TYPE,
  CH_TYPE,
  LocationNode,
  LOC_TYPE,
  MEDIA_TYPE,
} from '@/types/location';
import { RouteComponentProps } from '@/types/utils';
import { locationFilters } from '@/utils/filterModules';
import { entityHasLicenseOfType } from '@/utils/licenses';
import {
  displayTZ,
  // findChannelInLoc,
  META_VMS_PLUGIN_ID,
  resetSearchForm2,
} from '@/utils/utils';

import type { AccountsModelState } from '@/models/accounts';
import type { Search2ModelType } from '@/models/search2';
import withRouter from '@/utils/withRouter';

interface MatchParams {
  locationID: string;
  channelID: string;
}

interface MyProps extends RouteComponentProps<MatchParams> {
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  media?: MEDIA_TYPE;
  base_stn?: BASE_STN_TYPE;
  search2?: Search2ModelType;
  accounts?: AccountsModelState;
  appsAll?: Record<number, any>[];
  dispatch?: (_any: any) => Promise<any>;
}

type MyState = {
  currentLocation: LocationNode | undefined;
  currentChannel: ChannelNode | undefined;
  showCameraControls: boolean;
};

// @ts-expect-error
@connect(({ locations, search2, accounts, apps }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  media: locations.media,
  base_stn: locations.base_stn,
  search2,
  accounts,
  appsAll: apps.all,
}))
class ChannelPage extends React.Component<MyProps, MyState> {
  searchFormRef: React.RefObject<SearchForm>;
  channelActionsRef: React.RefObject<ChannelActions>;
  cameraControlsRef: React.RefObject<CameraControls>;

  constructor(props: MyProps) {
    super(props);
    this.searchFormRef = React.createRef();
    this.channelActionsRef = React.createRef();
    this.timelinePlayerRef = React.createRef();
    this.cameraControlsRef = React.createRef();

    this.state = {
      currentLocation: undefined,
      currentChannel: undefined,
      showCameraControls: false,
    };

    this.toggleCameraControls = this.toggleCameraControls.bind(this);
    this.fetchChannelDetails = this.fetchChannelDetails.bind(this);
  }

  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
    this.currentURLParamsDidUpdate();
  }

  componentDidUpdate(prevProps: MyProps) {
    this.currentURLParamsDidUpdate(prevProps);
  }

  currentURLParamsDidUpdate(prevProps: MyProps | null = null) {
    const { locationID, channelID } = this.props.match.params;
    const {
      locationID: prev_locationID = null,
      channelID: prev_channelID = null,
    } = _.get(prevProps, 'match.params', {});
    const currentLocation = this.props.loc?.byId[+locationID];
    const prevLocation = _.get(
      prevProps,
      `loc.byId[${+prev_locationID}]`,
      null,
    );
    const currentChannel = this.props.ch?.byId[+channelID];
    const prev_currentChannel = _.get(
      prevProps,
      `ch.byId[${+prev_channelID}]`,
      null,
    );

    const newState: Record<any, any> = {};

    if (
      currentLocation &&
      currentLocation instanceof LocationNode &&
      (!_.isEqual(
        locationID,
        _.get(prevProps, 'match.params.locationID', -1),
      ) ||
        !_.isEqual(prevLocation, currentLocation))
    ) {
      newState['currentLocation'] = currentLocation;
    }

    if (
      currentChannel &&
      currentChannel instanceof ChannelNode &&
      (!_.isEqual(channelID, _.get(prevProps, 'match.params.channelID', -1)) ||
        !_.isEqual(prev_currentChannel, currentChannel))
    ) {
      newState['currentChannel'] = currentChannel;
    }

    if (!_.isEqual(channelID, _.get(prevProps, 'match.params.channelID', -1))) {
      if (!currentChannel || !currentChannel.Fetched) {
        this.fetchChannelDetails(+channelID, +locationID);
      }
      this.setState({ showCameraControls: false });
    }

    if (!_.isEmpty(newState)) {
      this.setState(newState, () => {
        const { dispatch } = this.props;
        if (dispatch && prev_channelID != channelID) resetSearchForm2(dispatch);
      });
    }
  }

  fetchChannelDetails(channelID: number, locationID: number) {
    const { dispatch } = this.props;
    dispatch({
      type: 'locations/fetchChannel',
      payload: { locationID, channelID },
    });
  }

  disableUploadVideo(
    currentLocation: LocationNode,
    currentChannel: ChannelNode,
  ): boolean {
    const { accounts, appsAll } = this.props;
    const channelHasLPRLicense =
      accounts != undefined
        ? entityHasLicenseOfType(
            accounts,
            'LPR',
            currentChannel.ID,
            currentLocation.ID,
          )
        : null;

    // 51 TAT app id, 40 license scanner app id
    const channelHasTATApp =
      Array.isArray(appsAll) &&
      appsAll.reduce((r, a) => _.get(a, 'AppID', -1) == 40 || r, false);
    return !!channelHasLPRLicense && channelHasTATApp;
  }

  toggleCameraControls() {
    this.setState(
      { showCameraControls: !this.state.showCameraControls },
      () => {
        if (this.state.showCameraControls && this.cameraControlsRef.current) {
          this.cameraControlsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      },
    );
  }

  render() {
    const { accounts, search2 } = this.props;
    const { currentLocation, currentChannel } = this.state;
    const showCameraControlsBtn =
      _.get(
        currentChannel,
        [
          'ConfigProfiles',
          'fe_camera_controls_visibility',
          'values',
          'visibility',
        ],
        'off',
      ) === 'on';

    if (
      currentLocation &&
      currentLocation instanceof LocationNode &&
      currentChannel &&
      currentChannel instanceof ChannelNode
    ) {
      const inactive = currentChannel.RTSPconfig.monitor_status === 'inactive';
      const isFrontier = currentLocation.VMSPluginID === META_VMS_PLUGIN_ID;
      const channelHasDISLicense =
        accounts != undefined
          ? entityHasLicenseOfType(
              accounts,
              'DIS',
              currentChannel.ID,
              currentLocation.ID,
            )
          : null;

      const rightPanel = [];
      if (showCameraControlsBtn) {
        rightPanel.push(
          <div
            className={this.state.showCameraControls ? styles['ctrls'] : null}
            style={!this.state.showCameraControls ? { display: 'none' } : {}}>
            <CameraControls
              ref={this.cameraControlsRef}
              channelId={currentChannel.ID}
              key={currentChannel.ID}
            />
          </div>,
        );
      }
      if (!this.state.showCameraControls) {
        if (isFrontier) {
          rightPanel.push(
            <ChannelActions
              ref={this.channelActionsRef}
              searchFormRef={this.searchFormRef}
              channelID={currentChannel.ID}
              locationID={currentLocation.ID}
            />,
          );
        }
        if (channelHasDISLicense) {
          rightPanel.push(
            <SearchForm
              ref={this.searchFormRef}
              showFilters={locationFilters}
              channelID={_.get(this.props, 'match.params.channelID', null)}
            />,
          );
        }
      }

      return (
        <div id="loc-ch_grp-ch-title">
          <PageHeader
            title={currentChannel.Name}
            subtitle={
              <div style={{ display: 'flex' }}>
                {displayTZ(currentChannel.Timezone)}
                {inactive && (
                  <div className={styles['inactive-channel-label']}>
                    <WarningOutlined />
                    &nbsp;&nbsp;Inactive
                  </div>
                )}
              </div>
            }
            right={
              <>
                <UploadVideoToChannel channelID={currentChannel.ID}>
                  <Button
                    size="small"
                    type="default"
                    style={{ marginRight: '5px' }}
                    disabled={this.disableUploadVideo(
                      currentLocation,
                      currentChannel,
                    )}>
                    Upload Video
                  </Button>
                </UploadVideoToChannel>
                {showCameraControlsBtn && (
                  <Button
                    size="small"
                    type="default"
                    style={{ marginRight: '5px' }}
                    onClick={this.toggleCameraControls}>
                    Toggle Camera Controls
                  </Button>
                )}
                <ChannelSettings channelID={currentChannel.ID}>
                  <Button size="small" type="default">
                    Settings
                  </Button>
                </ChannelSettings>
              </>
            }
          />
          <div className={styles['top-ctn']}>
            <div className={styles['left-panel']}>
              {search2?.showSearchResults === true ? (
                <SearchResults searchForm={this.searchFormRef} />
              ) : (
                <>
                  <AlertTable
                    channelID={currentChannel.ID}
                    locationID={currentLocation.ID}
                  />
                  <TimelinePlayer
                    innerRef={this.timelinePlayerRef}
                    autoPlay={true}
                    channelIDs={[currentChannel.ID]}
                    searchForm={this.searchFormRef}
                    showShare={true}
                    showLive={true}
                  />
                </>
              )}
            </div>
            <div className={styles['right-panel']}>{rightPanel}</div>
          </div>
        </div>
      );
    }

    return <></>;
  }
}
export default withRouter(ChannelPage);
