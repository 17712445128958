import { EditFilled, StarFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const SceneCardContainer = styled.div(
  ({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 320px;
  border-radius: ${theme.token?.borderRadiusLG}px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  background-color: ${theme.token?.colorBgContainer};
  overflow: hidden;
`,
);

export const SceneDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  justify-content: space-between;
  height: 170px;
  width: 100%;
`;

export const SceneChannelThumbnailsContainer = styled.div`
  height: 150px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
`;

export const ScreenDetailsTitleText = styled(Text)`
  font-size: 16px;
  max-width: 85%;
`;

export const SceneDetailsTimestampText = styled(Text)(
  ({ theme }) => `
  font-size: 12px;
  color: ${theme.token?.colorTextSecondary};
  font-weight: 400;
`,
);

export const SceneStarContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const ThumbnailBackground = styled.div<{
  url: string;
  allThumbnailsCount: number;
}>`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  flex: 1;
  border: 0.5px solid #f0f0f0;

  min-width: ${(props) => {
    if (props.allThumbnailsCount < 5) {
      return '50%';
    }

    if (props.allThumbnailsCount < 7) {
      return '33.33%';
    }

    return '25%';
  }};
`;

export const SceneStarredIcon = styled(StarFilled)`
  color: #ff9301;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

export const SceneUnStarredIcon = styled(StarFilled)(
  ({ theme }) => `
  color: ${theme.token?.colorBgLayout};
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
  }
`,
);

export const SceneTitleNameEditIcon = styled(EditFilled)(
  ({ theme }) => `
  color: ${theme.token?.colorTextSecondary};
  cursor: pointer;
  margin-left: 4px;
  transform: translateY(2px);

  > svg {
    width: 14px;
    height: 14px;
  }
`,
);
