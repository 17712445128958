/**
 * @generated SignedSource<<fed5f4a7547ab8586cf497f5c14e6457>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type SceneFragment$data = {
  readonly anchorTimeStamp: string | null | undefined;
  readonly channelIDs: ReadonlyArray<string | null | undefined>;
  readonly id: string;
  readonly isFavorite: boolean | null | undefined;
  readonly name: string | null | undefined;
  readonly timestamp: string;
  readonly ' $fragmentType': 'SceneFragment';
};
export type SceneFragment$key = {
  readonly ' $data'?: SceneFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'SceneFragment'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'SceneFragment',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isFavorite',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'channelIDs',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timestamp',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'anchorTimeStamp',
      storageKey: null,
    },
  ],
  type: 'VMSPlusRecentChannels',
  abstractKey: null,
};

(node as any).hash = 'b8a56d40d0a1236af15b9d77b18b2011';

export default node;
