import type { ComponentType } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

/**
 * Hook to get media query breakpoints. This is needed for use-cases where we need to apply breakpoint based logic in JS
 * @returns {isMobile: boolean}
 */
const useDfMediaQuery = () => {
  const dfTheme = useTheme();

  // Can add more breakpoints here on need basis

  const isMobile = useMediaQuery({
    query: `(max-width: ${dfTheme.token?.screenSM}px)`,
  });

  return { isMobile };
};

/**
 * HOC to pass media query breakpoints as props to the wrapped component. This is needed for use-cases where we need to apply breakpoint based logic in JS
 */
const withDfMediaQuery = (Component: ComponentType) => {
  return (props: any) => {
    const mediaQuery = useDfMediaQuery();

    return <Component {...props} {...mediaQuery} />;
  };
};

export { useDfMediaQuery, withDfMediaQuery };
