import ChannelTile from '@/pages/locations/components/channel-tile-2';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Popover, theme as antdTheme, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  ChannelTileAndNameContainer,
  LivePreviewPopoverContainer,
  OverlayContainer,
  TileObserverContainer,
} from './styles';

const TileObserver = ({
  obj,
}: {
  obj: {
    ID: number;
  };
}) => {
  return (
    <TileObserverContainer>
      <ChannelTile
        key={obj.ID}
        channelID={obj.ID}
        showTime={false}
        showImagePreview={false}
      />
    </TileObserverContainer>
  );
};

const PopoverContent = ({
  channelID,
  channelName,
  siteName,
}: {
  channelID: number;
  channelName: string;
  siteName: string;
}) => {
  return (
    <LivePreviewPopoverContainer>
      <ChannelTile
        key={Number(channelID)}
        channelID={Number(channelID)}
        showTime={false}
        showImagePreview={true}
        hideFooter={true}
      />
      <Typography.Text style={{ fontSize: '16px', fontWeight: 800 }}>
        {channelName}
      </Typography.Text>
      <Typography.Text>{siteName}</Typography.Text>
      <Typography.Text strong style={{ color: 'red' }}>
        Live Now
      </Typography.Text>
    </LivePreviewPopoverContainer>
  );
};

type ChannelTileAndNameProps = {
  channelID: string;
  channelName: string;
  siteName: string;
  onClick: any;
  selected?: boolean;
};
const ChannelTileAndName = (props: ChannelTileAndNameProps) => {
  const { useToken } = antdTheme;
  const { token } = useToken();
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return (
    <Popover
      placement="leftBottom"
      arrow={false}
      content={
        <PopoverContent
          channelID={Number(props.channelID)}
          channelName={props.channelName}
          siteName={props.siteName}
        />
      }>
      <ChannelTileAndNameContainer
        key={props.channelID}
        onClick={() => {
          setSelected(!selected);
          props.onClick();
        }}>
        {/* Dirty dirty dirty. Fix ChannelTile! */}
        <div
          style={{ position: 'relative', height: '58px', aspectRatio: 12 / 7 }}>
          {props.channelID && (
            <TileObserver
              obj={{
                ID: Number(props.channelID),
              }}
            />
          )}
          {selected && (
            <OverlayContainer>
              <CheckCircleTwoTone twoToneColor={token.colorPrimary} />
            </OverlayContainer>
          )}
        </div>

        <Typography.Text style={{ fontWeight: 500 }}>
          {props.channelName}
        </Typography.Text>
      </ChannelTileAndNameContainer>
    </Popover>
  );
};

export default ChannelTileAndName;
