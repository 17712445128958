import { message } from 'antd';
import _ from 'lodash';

import { objectSearchMedia, searchMedia } from '@/services/search';
import { filterModulesInfo } from '@/utils/filterModules';
import {
  getCurrentCustomerID,
  populateObjectSearchPayload,
  populateSearch2Payload,
} from '@/utils/utils';

import { interpretClipData } from '@/utils/search-result';
import type { Model } from 'dva';

export const SEARCH_FILTER_DEFAULT = {
  showSearchResults: false,
  showObjectSearchResult: false,
  appliedFilterName: null,
  dateRange: [],
  locationID: [],
  channelIDs: [],
  investigationIDs: [],
  person_colors: {
    top_colors: [],
    bottom_colors: [],
  },
  vehicle_colors: [],
  lineFilters: {
    // channel_id: []
  },
  regionFilters: {
    // channel_id: []
  },
  pathFilters: {
    // channel_id: []
  },
  p_number: 1,
  p_size: 50,
  searchResults: {
    clips: [],
    search_result_clips: [],
    crossing_dict: {},
    filter_object_count: 0,
    p_number: 1,
    total_object_count: 0,
    total_pages: 0,
  },
  objectSearchResults: {
    clips: [],
    search_result_clips: [],
    crossing_dict: {},
    filter_object_count: 0,
    p_number: 1,
    total_object_count: 0,
    total_pages: 0,
  },
  resultHeight: 0,
  resultWidth: 0,
};

Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
  SEARCH_FILTER_DEFAULT[mKey] = info.stateDefault;
});

export type Search2ModelType = typeof SEARCH_FILTER_DEFAULT;

const Search2Model: Model = {
  namespace: 'search2',
  state: SEARCH_FILTER_DEFAULT,
  effects: {
    *changeSearchFilter(action, { put }) {
      const { payload } = action;
      yield put({
        type: 'setSearchFilters',
        payload: { ...payload },
      });
    },
    *changeSort(action, { put }) {
      const { sort } = action.payload;

      yield put({
        type: 'setSort',
        payload: { sort },
      });
    },
    *search(action, { call, put, select }) {
      const customerID = getCurrentCustomerID();
      const currentSearch = yield select((state: any) => state.search2);
      const searchPayload = {
        queryID: '1',
        Metadata: {
          CustomerID: customerID,
          ChannelID: _.get(currentSearch, 'channelIDs', []).map((_id: any) =>
            parseInt(_id),
          ),
        },
        p_number: currentSearch.p_number || 1,
        p_size: currentSearch.p_size || 50,
        sort: currentSearch.sort || 'confidence_asc',
      };

      if (currentSearch.dateRange && currentSearch.dateRange.length === 2) {
        searchPayload.ESVideoStartTime = `${currentSearch.dateRange[1].format(
          'YYYY-MM-DDTHH:mm:ss.000000',
        )}Z`;
        searchPayload.ESVideoEndTime = `${currentSearch.dateRange[0].format(
          'YYYY-MM-DDTHH:mm:ss.000000',
        )}Z`;
      }
      if (currentSearch.locationID.length > 0) {
        searchPayload.Metadata.ProjectID = currentSearch.locationID[0];
      }

      populateSearch2Payload(currentSearch, searchPayload);

      const response = yield call(searchMedia, searchPayload);

      if (response.success) {
        yield put({
          type: 'setSearchResults',
          payload: response.data,
        });
      } else {
        message.error(`Search Failed! ${_.get(response, 'data.message', '')}`);
      }
    },
    *searchApi(action, { call }) {
      const { payload } = action;
      const response = yield call(searchMedia, payload);
      return response.data;
    },
    *objectSearch(action, { call, put, select }) {
      const { payload } = action;
      const customerID = getCurrentCustomerID();
      const currentSearch = yield select((state: any) => state.search2);
      const objectSearchPayload = {
        queryID: '4',
        ESVideoStartTime: `${currentSearch.dateRange[1].format(
          'YYYY-MM-DDTHH:mm:ss.000000',
        )}Z`,
        ESVideoEndTime: `${currentSearch.dateRange[0].format(
          'YYYY-MM-DDTHH:mm:ss.000000',
        )}Z`,
        Metadata: {
          CustomerID: customerID,
          ChannelID: +currentSearch.channelIDs,
        },
        p_number: currentSearch.p_number || 1,
        p_size: currentSearch.p_size || 50,
      };
      if (currentSearch.locationID) {
        objectSearchPayload.Metadata.ProjectID = currentSearch.locationID;
      }
      populateObjectSearchPayload(payload, objectSearchPayload);

      const response = yield call(objectSearchMedia, objectSearchPayload);
      if (response.success) {
        yield put({
          type: 'setObjectSearchResults',
          payload: response.data,
        });
      } else {
        message.error(`Object Search Failed! ${response.data.message}`);
      }
    },
    *resetFields(action, { put }) {
      const { payload } = action;
      const _keys = _.get(payload, 'keys', []);
      const resetPayload = _keys.length > 0 ? {} : SEARCH_FILTER_DEFAULT;
      _keys.forEach((k: any) => {
        resetPayload[k] = SEARCH_FILTER_DEFAULT[k];
      });
      yield put({
        type: 'setResetFields',
        payload: resetPayload,
      });
    },
  },
  reducers: {
    setSearchFilters(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSort(state, action) {
      const { sort } = action.payload;

      return {
        ...state,
        sort,
      };
    },
    setSearchResults(state, action) {
      const clips = _.get(action, 'payload.clips', []);
      return {
        ...state,
        searchResults: {
          clips,
          search_result_clips: interpretClipData(_.cloneDeep(clips)),
          crossing_dict: _.get(action, 'payload.crossing_dict', {}),
          filter_object_count: _.get(action, 'payload.filter_object_count', 0),
          p_number: _.get(action, 'payload.p_number', 1),
          total_object_count: _.get(action, 'payload.total_object_count', 0),
          total_pages: _.get(action, 'payload.total_pages', 0),
        },
      };
    },
    setObjectSearchResults(state, action) {
      const clips = _.get(action, 'payload.clips', []);
      return {
        ...state,
        objectSearchResults: {
          clips,
          search_result_clips: interpretClipData(_.cloneDeep(clips)),
          crossing_dict: _.get(action, 'payload.crossing_dict', {}),
          filter_object_count: _.get(action, 'payload.filter_object_count', 0),
          p_number: _.get(action, 'payload.p_number', 1),
          total_object_count: _.get(action, 'payload.total_object_count', 0),
          total_pages: _.get(action, 'payload.total_pages', 0),
        },
      };
    },
    setResetFields(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

Object.entries(filterModulesInfo).forEach(([mKey, info]) => {
  Search2Model.state[mKey] = info.stateDefault;
});

export default Search2Model;
