/* eslint-disable no-nested-ternary */
import InfoSidebar from '@/components/InfoSidebar';
import LoadingSpinner from '@/components/LoadingSpinner';
import SearchClipTile from '@/components/SearchResults/SearchClipTile';
import ShortcutsInfo from '@/components/shortcuts-info';
import { Empty, Pagination, Table } from 'antd';
import _ from 'lodash';
import React from 'react';
import { HotKeys } from 'react-hotkeys';
import { connect } from 'umi';
// import SpatialFiltersInfo from '@/pages/investigations/investigation/event/summary/spatial-filters-info';
import TimelinePlayer from '@/components/TimelinePlayer';
import { _getChannelMenu_ } from '@/utils/searchFilterMenuNode';
import {
  convertSearchClipsIntoSearchEvents,
  getLocalVideoEndSecs,
  getLocalVideoStartSecs,
  interpretClipData,
} from '@/utils/utils';

import styles from './style.less';

const CLIP_SIZES = [
  { width: 230, height: 160 },
  { width: 185, height: 130 },
  { width: 80, height: 100 },
];

type State = any;
type Props = any;

// @ts-expect-error
@connect(({ search2, loading, locations }) => ({
  search2,
  loadingSearch: loading.effects['search2/search'],
  locations,
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
}))
class SearchResults extends React.Component<Props, State> {
  searchClipsRef: any;
  searchHeaderRef: any;
  infoSidebarRef: any;
  constructor(props: Props) {
    super(props);
    this.searchHeaderRef = React.createRef();
    this.searchClipsRef = React.createRef();
    this.infoSidebarRef = React.createRef();
    this.timelinePlayerRef = React.createRef();
    this.shortcutsInfoRef = React.createRef();
    this.state = {
      currentClip: null,
      searchResults: {
        clips: [],
      },
      channel_menu: _getChannelMenu_({
        locations: {
          loc: props.loc,
          ch_grp: props.ch_grp,
          ch: props.ch,
        },
      }),
    };
    this.setCurrentClipandSeek = this.setCurrentClipandSeek.bind(this);
  }

  componentDidMount() {
    this.initSearchResults();
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'search2.searchResults.clips') !==
        _.get(this.props, 'search2.searchResults.clips') ||
      (_.get(this.props, 'search2.showObjectSearchResult', false) &&
        _.get(prevProps, 'search2.objectSearchResults.clips') !==
          _.get(this.props, 'search2.objectSearchResults.clips'))
    ) {
      this.initSearchResults();
    }
  }

  initSearchResults() {
    let clipHeight = CLIP_SIZES[CLIP_SIZES.length - 1].height;
    let clipWidth = CLIP_SIZES[CLIP_SIZES.length - 1].width;
    const resultHeight = _.get(this.props, 'search2.resultHeight');
    const resultWidth = _.get(this.props, 'search2.resultWidth');
    let noOfResults = _.get(
      this.props,
      'search2.searchResults.clips.length',
      0,
    );
    if (_.get(this.props, 'search2.showObjectSearchResult', false)) {
      noOfResults = _.get(
        this.props,
        'search2.objectSearchResults.clips.length',
        0,
      );
    }
    for (let i = 0; i < CLIP_SIZES.length; i += 1) {
      if (
        noOfResults <=
        Math.floor(resultWidth / CLIP_SIZES[i].width) *
          Math.floor(resultHeight / CLIP_SIZES[i].height)
      ) {
        clipHeight = CLIP_SIZES[i].height;
        clipWidth = CLIP_SIZES[i].width;
        break;
      }
    }
    const clipsPerRow = Math.floor(resultWidth / clipWidth);
    const searchResults = _.get(
      this.props,
      `${
        _.get(this.props, 'search2.showObjectSearchResult', false)
          ? 'search2.objectSearchResults'
          : 'search2.searchResults'
      }`,
      { clips: [] },
    );
    const searchResultClips = _.get(searchResults, 'clips', []);

    // these are the events corresponding to each search result. we
    // use them to get the edges and bboxes
    let searchEvents = convertSearchClipsIntoSearchEvents(
      searchResultClips,
      this.props.locations,
      this.setCurrentClipandSeek,
    );
    // the actual video corresponding to those search results are rendered
    // on a separate track. extract those.
    let videoToEventMap = {};
    _.forEach(searchResultClips, (clip) => {
      if (!videoToEventMap[clip.UploadID]) {
        clip = _.cloneDeep(clip);
        let channel_obj = _.get(this.props.ch, `byId[${clip.ChannelID}]`);
        let timezone = channel_obj?.Timezone || 'UTC';
        videoToEventMap[clip.UploadID] = {
          clip,
          Channel: channel_obj,
          ChannelID: +clip.ChannelID,
          Timezone: timezone,
          Media: [clip],
          // EventStart|End are ES time (local time but written in UTC)
          EventStart: getLocalVideoStartSecs(clip, timezone) * 1000,
          EventEnd: getLocalVideoEndSecs(clip, timezone) * 1000,
        };
      }
    });
    searchEvents = [...searchEvents, ...Object.values(videoToEventMap)];

    this.setState(
      {
        clipHeight,
        clipWidth,
        clipsPerRow,
        searchResults,
        searchEvents,
        currentClip: null,
        focusIndex: 0,
      },
      () => this.resetClipSelection(),
    );
  }

  resetClipSelection() {
    if (this.infoSidebarRef.current) {
      this.infoSidebarRef.current.toggleInfoSidebar(false);
    }
    if (this.searchClipsRef.current) {
      this.searchClipsRef.current.focus();
    }
  }

  setCurrentClipandSeek(clip: any, clipIndex: any) {
    const { searchEvents } = this.state;

    if (!clip) return;
    if (this.searchClipsRef.current) {
      this.searchClipsRef.current.focus();
    }
    const focusIndex = clipIndex;
    const toUpdate = {
      currentClip: clip,
      focusIndex,
    };

    if (searchEvents) {
      // when a clip is selected, either from the results grid or
      // by clicking a bbox, we mark the bbox color to bright green
      // so it's distinguishable from other bboxes that might be on scene
      searchEvents.forEach((event) => {
        if (event.clip === clip) {
          _.forEach(event.bboxes, (bbox) => {
            bbox.border = '2px solid #0f0';
          });
        }
      });
      toUpdate.searchEvents = searchEvents;
    }

    this.setState(toUpdate, () => {
      if (this.infoSidebarRef.current) {
        this.infoSidebarRef.current.toggleInfoSidebar(true);
      }
    });
  }

  changePage(p_number: any) {
    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload: { p_number },
    });

    this.props.dispatch({
      type: 'search2/search',
    });
  }

  changeSort(sort: string) {
    this.setState({ sort });

    this.props.dispatch({
      type: 'search2/changeSort',
      payload: { sort },
    });

    this.props.dispatch({
      type: 'search2/search',
    });
  }

  cycleThroughObjects(direction: any) {
    const { clipsPerRow, focusIndex } = this.state;
    let currentIndex = focusIndex;
    switch (direction) {
      case 'RIGHT':
        currentIndex = Math.min(
          currentIndex + 1,
          _.get(this.state, 'searchResults.clips.length', 0) - 1,
        );
        break;
      case 'LEFT':
        currentIndex = Math.max(currentIndex - 1, 0);
        break;
      case 'TOP':
        if (currentIndex - clipsPerRow >= 0) {
          currentIndex -= clipsPerRow;
        }
        break;
      case 'BOTTOM':
        if (
          currentIndex + clipsPerRow <=
          _.get(this.state, 'searchResults.clips.length', 0) - 1
        ) {
          currentIndex += clipsPerRow;
        }
        break;
      default:
        break;
    }
    this.setState({ focusIndex: currentIndex });
  }

  isSelectedObject(clip: any) {
    const { currentClip } = this.state;
    if (!clip || !currentClip) {
      return false;
    }
    if (clip.ObjectID !== undefined) {
      return clip.ObjectID === currentClip.ObjectID;
    }
    if (clip.object_id !== undefined) {
      return clip.object_id === currentClip.object_id;
    }
    if (clip.UploadID !== undefined) {
      return clip.UploadID === currentClip.UploadID;
    }
    return false;
  }

  objectSearch(clip: any) {
    // we're starting off with object search being more
    // apperance search. this way we can get something real going,
    // and we can improve it with embeddings search later

    const { searchForm } = this.props;
    if (this.infoSidebarRef.current) {
      this.infoSidebarRef.current.toggleInfoSidebar(false);
    }
    if (searchForm.current) {
      searchForm.current.handleObjectSearch(clip);
    }

    /*
    let resultHeight = (window.innerHeight - 70) / 2 - 24;
    let resultWidth = getMainContentWidth();
    this.props.dispatch({
      type: 'search2/changeSearchFilter',
      payload: {
        resultHeight,
        resultWidth,
        p_number: 1,
        p_size: Math.floor(resultHeight / 100) * Math.floor(resultWidth / 80),
        showObjectSearchResult: true,
      },
    });
    this.props.dispatch({
      type: 'search2/objectSearch',
      payload: clip,
    });
    */
  }

  render() {
    const { loadingSearch, locations, event } = this.props;
    const {
      currentClip,
      focusIndex,
      clipWidth,
      clipHeight,
      searchResults,
      searchEvents,
      channel_menu = {},
    } = this.state;

    // if we don't have any clips at all, we show a table
    let showTable = false;
    let tableData = [];
    if (
      _.get(searchResults, 'clips', []).length &&
      !searchResults.clips.filter(
        (r: any) =>
          r.s3image || r.image || (r.Thumbnail && r.Thumbnail.SignedUrl),
      ).length
    ) {
      // showing Meta results as a table means you can't see the results
      // overlayed with the timeline, which might be useful if you have cloud
      // storage enabled e.g. trying this out - in a future checkin i'll add
      // a placeholder for the search result
      showTable = false;
      tableData = searchResults.clips.map((clip: any) => {
        const ch = _.get(
          this.props,
          `ch.byId[${+_.get(clip, 'ChannelID', 0)}]`,
          null,
        );
        return interpretClipData(
          clip,
          _.get(this.props, 'locations'),
          _.get(ch, 'Timezone'),
          searchResults.clips,
        );
      });
    }

    let startTime;
    if (currentClip) {
      const ch = _.get(
        this.props,
        `ch.byId[${+_.get(currentClip, 'ChannelID', 0)}]`,
        null,
      );
      let clipData = interpretClipData(
        currentClip,
        _.get(this.props, 'locations'),
        _.get(ch, 'Timezone'),
        searchResults.clips,
      );
      startTime = clipData['from'].valueOf() / 1000;
    }

    return (
      <div>
        {currentClip != null ? (
          <InfoSidebar
            event={event}
            clip={currentClip}
            searchResults={[currentClip]}
            ref={this.infoSidebarRef}
            locations={locations}
            channel_menu={channel_menu}
            objectSearch={(clip: any) => {
              this.objectSearch(clip);
            }}
          />
        ) : null}
        {showTable ? (
          <>
            <Table
              className={styles['table-container']}
              dataSource={tableData}
              size="small"
              pagination={false}
              rowClassName={(record) => {
                if (record.clip === currentClip) {
                  return styles['selected-row'];
                }
                return '';
              }}
              onRow={(record, clipIndex) => ({
                onClick: (e) => {
                  e.preventDefault();
                  if (this.infoSidebarRef.current) {
                    this.infoSidebarRef.current.toggleInfoSidebar(true);
                  }
                  this.setCurrentClipandSeek(record.clip, clipIndex);
                },
              })}>
              <Table.Column
                dataIndex="object_type_str"
                key="object_type_str"
                ellipsis={true}
                title={<span className={styles.header}>Type</span>}
              />
              <Table.Column
                dataIndex="channel"
                key="channel"
                ellipsis={true}
                title={<span className={styles.header}>Camera</span>}
              />
              <Table.Column
                dataIndex="info_title"
                key="info_title"
                ellipsis={true}
                title={<span className={styles.header}>At</span>}
              />
              <Table.Column
                dataIndex="elapsed_time_str"
                key="elapsed_time_str"
                ellipsis={true}
                title={<span className={styles.header}>Duration</span>}
              />
              <Table.Column
                dataIndex="score_str"
                align="center"
                key="score_str"
                ellipsis={true}
                title={<span className={styles.header}>Score</span>}
              />
            </Table>
            <Pagination
              className={styles['table-pagination-container']}
              size="small"
              showSizeChanger={false}
              onChange={(page) => this.changePage(page)}
              pageSize={searchResults.p_size || 50}
              current={searchResults.p_number}
              total={searchResults.total_pages * (searchResults.p_size || 50)}
            />
          </>
        ) : (
          <HotKeys
            style={{ width: '100%', height: '100%' }}
            keyMap={{
              FOCUS_RESULTS: ['meta+up', 'ctrl+up'],
              FOCUS_VIDEO: ['meta+down', 'ctrl+down'],
              SHOW_SHORTCUTS: ['/', 'shift+/'],
            }}
            handlers={{
              FOCUS_RESULTS: (e) => {
                e.preventDefault();
                if (this.searchClipsRef.current) {
                  this.searchClipsRef.current.focus();
                }
              },
              FOCUS_VIDEO: (e) => {
                e.preventDefault();
                if (this.timelinePlayerRef.current) {
                  this.timelinePlayerRef.current.focus();
                }
              },
              SHOW_SHORTCUTS: (e) => {
                e.preventDefault();
                this.shortcutsInfoRef.current?.toggleDrawer();
              },
            }}>
            <ShortcutsInfo
              ref={this.shortcutsInfoRef}
              showCTA={false}
              type="search"
              container={document.getElementById('root')}
              focusedElement={this.searchClipsRef.current}
            />
            <div className={styles.ctn}>
              <HotKeys
                className={styles['search-clips-container']}
                tabIndex={0}
                innerRef={this.searchClipsRef}
                keyMap={{
                  SELECT_CLIP: 'enter',
                  SELECT_RIGHT: 'right',
                  SELECT_LEFT: 'left',
                  SELECT_TOP: 'up',
                  SELECT_BOTTOM: 'down',
                  NEXT_PAGE: 'n',
                  PREV_PAGE: 'p',
                  HIDE_THUMBNAIL_INFO: 'escape',
                  FOCUS_VIDEO: ['meta+down', 'ctrl+down'],
                  SHOW_SHORTCUTS: ['/', 'shift+/'],
                }}
                handlers={{
                  SELECT_CLIP: (e) => {
                    const results = this.state.searchResults;
                    const index = this.state.focusIndex;
                    e.preventDefault();
                    this.setCurrentClipandSeek(results.clips[index], index);
                  },
                  SELECT_RIGHT: (e) => {
                    e.preventDefault();
                    this.cycleThroughObjects('RIGHT');
                  },
                  SELECT_LEFT: (e) => {
                    e.preventDefault();
                    this.cycleThroughObjects('LEFT');
                  },
                  SELECT_TOP: (e) => {
                    e.preventDefault();
                    this.cycleThroughObjects('TOP');
                  },
                  SELECT_BOTTOM: (e) => {
                    e.preventDefault();
                    this.cycleThroughObjects('BOTTOM');
                  },
                  HIDE_THUMBNAIL_INFO: (e) => {
                    e.preventDefault();
                    if (this.infoSidebarRef.current) {
                      this.infoSidebarRef.current.toggleInfoSidebar(false);
                    }
                  },
                  FOCUS_VIDEO: (e) => {
                    e.preventDefault();
                    if (this.timelinePlayerRef.current) {
                      this.timelinePlayerRef.current.focus();
                    }
                  },
                  NEXT_PAGE: (e) => {
                    e.preventDefault();
                    this.changePage(
                      Math.min(
                        searchResults.p_number + 1,
                        searchResults.total_pages,
                      ),
                    );
                  },
                  PREV_PAGE: (e) => {
                    e.preventDefault();
                    this.changePage(Math.max(searchResults.p_number - 1, 1));
                  },
                  SHOW_SHORTCUTS: (e) => {
                    e.preventDefault();
                    this.shortcutsInfoRef.current?.toggleDrawer();
                  },
                }}>
                <div className={styles['search-clip-results-ctn']}>
                  {loadingSearch ? (
                    <div style={{ height: '100%', width: '100%' }}>
                      <LoadingSpinner color="white" />
                    </div>
                  ) : (
                    <>
                      {_.get(this.state, 'searchResults.clips', []).length >
                      0 ? (
                        <div className={styles['search-clip-results']}>
                          {_.get(this.state, 'searchResults.clips', []).map(
                            (clip: any, clipIndex: any) => (
                              <SearchClipTile
                                key={
                                  clip.ObjectID ||
                                  clip.object_id ||
                                  clip.UploadID
                                }
                                invEvent={event}
                                width={clipWidth}
                                height={clipHeight}
                                onTileClick={() =>
                                  this.setCurrentClipandSeek(clip, clipIndex)
                                }
                                focussed={focusIndex === clipIndex}
                                selected={this.isSelectedObject(clip)}
                                clip={clip}
                                locations={{
                                  loc: this.props.loc,
                                  ch_grp: this.props.ch_grp,
                                  ch: this.props.ch,
                                }}
                                channel_menu={channel_menu}
                                objectSearch={() => {
                                  this.objectSearch(clip);
                                }}
                              />
                            ),
                          )}
                        </div>
                      ) : (
                        <div className={styles['empty-search-results']}>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                              <span style={{ color: 'white' }}>
                                No Results found. Try changing the filters.
                              </span>
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className={styles['pagination-container']}>
                    <div>
                      Sort:{' '}
                      {(this.props.search2.sort || 'confidence_desc') ==
                      'confidence_desc' ? (
                        <>
                          Sorted by Confidence |{' '}
                          <a
                            onClick={() =>
                              this.changeSort('chronological_desc')
                            }>
                            Sort by Time
                          </a>
                        </>
                      ) : (
                        <>
                          Sorted by Time |{' '}
                          <a onClick={() => this.changeSort('confidence_desc')}>
                            Sort by Confidence
                          </a>
                        </>
                      )}
                    </div>

                    {_.get(searchResults, 'clips', []).length > 0 && (
                      <Pagination
                        size="small"
                        showSizeChanger={false}
                        onChange={(page) => this.changePage(page)}
                        pageSize={searchResults.p_size || 50}
                        current={searchResults.p_number}
                        total={
                          searchResults.total_pages *
                          (searchResults.p_size || 50)
                        }
                      />
                    )}
                  </div>
                </div>
              </HotKeys>
              <div
                tabIndex={1}
                className={styles['video-container']}
                ref={this.searchHeaderRef}>
                {!currentClip || !currentClip.TranscodedVideo ? (
                  loadingSearch ||
                  _.get(this.state, 'searchResults.clips', []).length ===
                    0 ? null : (
                    <div className={styles['empty-video']}>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          <span style={{ color: 'white' }}>
                            Select a result above to view in context.
                          </span>
                        }
                      />
                    </div>
                  )
                ) : (
                  <TimelinePlayer
                    innerRef={this.timelinePlayerRef}
                    startTime={startTime}
                    fitEventsOnAxis={true}
                    events={searchEvents}
                    eventsOnly={true}
                  />
                )}
              </div>
            </div>
          </HotKeys>
        )}
      </div>
    );
  }
}

export default SearchResults;
