import { t_video_size_from_media } from '@/constants';
import { Button } from 'antd';
import _ from 'lodash';
import { Viewport } from 'pixi-viewport';
import * as PIXI from 'pixi.js';
import React from 'react';

import CalibrationControls from './CalibrationControls';
import styles from './style.less';

PIXI.utils.skipHello();
const isWebGLSupported = PIXI.utils.isWebGLSupported();

type State = any;
type Props = any;

class PathFilters extends React.Component<Props, State> {
  filterPixi: any;
  imageRef: any;
  pathRef: any;
  pixiMapVP: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      paths: _.get(this.props, 'defaultFilterValue', []),
      canvasPaths: [],
      activePath: 0,
      showCalibration: false,
    };
    if (isWebGLSupported) {
      this.filterPixi = new PIXI.Application({
        sharedLoader: true,
        antialias: true,
        backgroundAlpha: 0,
        height: 3000,
        width: 3000,
      });
    }
    this.imageRef = React.createRef({});
    this.pathRef = React.createRef({});
  }

  componentDidMount() {}

  onThumbnailLoaded() {
    this.pathRef.current.appendChild(this.filterPixi.view);
    this.initFilterPixi(this.pathRef.current);
  }

  componentWillUnmount() {
    this.filterPixi.destroy(true);
  }

  initFilterPixi(element: any) {
    const { media, baseStationVersion } = this.props;
    this.filterPixi.renderer.view.style.cursor = 'pointer';
    const { clientHeight, clientWidth } = element;
    if (clientHeight > 0 && clientWidth > 0) {
      // clear stage
      this.filterPixi.stage.removeChildren();

      // resize image to fit to div
      const thumbAspectRatio =
        this.imageRef.current.naturalWidth /
        this.imageRef.current.naturalHeight;

      const containerAspectRation = clientWidth / clientHeight;
      let height: any;
      let width: any;
      if (thumbAspectRatio <= containerAspectRation) {
        height = clientHeight;
        width = thumbAspectRatio * clientHeight;
      } else {
        width = clientWidth;
        height = clientWidth / thumbAspectRatio;
      }

      // resize canvas/view to resized image
      this.filterPixi.renderer.view.width = width;
      this.filterPixi.renderer.view.height = height;

      this.pixiMapVP = new Viewport({
        screenWidth: width,
        screenHeight: height,
      });
      this.filterPixi.stage.addChild(this.pixiMapVP);

      // this.filterPixi.stage.interactive = true;

      const pathFilterPath = _.get(
        this.state,
        ['paths', this.state.activePath],
        [],
      );
      let pathCordinates = [];
      if (pathFilterPath.length > 0) {
        const [transcodedVideoWidth, transcodedVideoHeight] =
          t_video_size_from_media(media, baseStationVersion);

        // transform to this coordinate system
        pathCordinates = pathFilterPath.map((p: any) => [
          (p[0] / transcodedVideoWidth) * width,
          (p[1] / transcodedVideoHeight) * height,
        ]);
      }

      this.pixiMapVP.on('pointerdown', this.onDragStart);
      this.pixiMapVP.on('pointerup', this.onDragEnd);
      this.pixiMapVP.on('pointerupoutside', this.onDragEnd);
      this.pixiMapVP.on('pointermove', this.onDragMove);

      const canvasPaths = [...this.state.canvasPaths];
      canvasPaths[this.state.activePath] = pathCordinates;
      this.setState({ canvasPaths });

      const lineGraphic = new PIXI.Graphics();
      this.setState({ lineGraphic }, () => {
        this.updatePath(false);
      });
      this.filterPixi.stage.addChild(lineGraphic);
    }
  }

  onDragStart = (e: any) => {
    const obj = e.currentTarget;
    obj.dragData = e.data;
    obj.dragging = 1;
    obj.dragObjStart = e.data.getLocalPosition(obj.parent);

    const canvasPaths = [...this.state.canvasPaths];
    canvasPaths[this.state.activePath] = [];
    this.setState({ canvasPaths });
  };

  onDragMove = (e: any) => {
    const obj = e.currentTarget;
    if (!obj.dragging) return;
    const data = obj.dragData; // it can be different pointer!
    if (obj.dragging === 1) {
      // click or drag?
      if (
        Math.abs(data.global.x - obj.dragObjStart.x) +
          Math.abs(data.global.y - obj.dragObjStart.y) >=
        3
      ) {
        // DRAG
        obj.dragging = 2;
      } else {
        // CLICK
      }
    }
    if (obj.dragging === 2) {
      const dragObjEnd = data.getLocalPosition(obj.parent);

      const canvasPaths = [...this.state.canvasPaths];
      const pathPts = _.get(canvasPaths, [this.state.activePath], []);
      // DRAG
      pathPts.push([
        obj.dragObjStart.x + (dragObjEnd.x - obj.dragObjStart.x),
        obj.dragObjStart.y + (dragObjEnd.y - obj.dragObjStart.y),
      ]);

      canvasPaths[this.state.activePath] = pathPts;
      this.setState({ canvasPaths }, () => {
        this.updatePath();
      });
    }
  };

  onDragEnd = (e: any) => {
    const obj = e.currentTarget;
    obj.dragging = 0;
    // set the interaction data to null
    obj.dragData = null;
    // possible race condition here. on drag, the updatePath() does update
    // this.state.paths, but it's possible that when onDragEnd() triggers,
    // the this.state.paths var hasn't been updated yet
    this.setState({}, () => {
      this.props.updateFilters(
        _.cloneDeep(
          this.state.paths.filter((path: any) => Array.isArray(path)),
        ),
      );
    });
  };

  midPointBtw = (p1: any, p2: any) => {
    return [p1[0] + (p2[0] - p1[0]) / 2, p1[1] + (p2[1] - p1[1]) / 2];
  };

  updatePath = (setValue = true) => {
    const { lineGraphic, canvasPaths, activePath } = this.state;
    const pathPts = canvasPaths[activePath];
    lineGraphic.clear();

    if (pathPts.length === 0) return;
    lineGraphic.beginFill(0xffffff, 0);

    let p1 = pathPts[0];
    let p2 = pathPts[1];
    lineGraphic.zIndex = 2;
    lineGraphic.lineStyle(1.5, 0xffffff, 1);
    lineGraphic.moveTo(p1[0], p1[1]);
    for (let i = 1, len = pathPts.length; i < len; i += 1) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      const midPoint = this.midPointBtw(p1, p2);
      lineGraphic.quadraticCurveTo(p1[0], p1[1], midPoint[0], midPoint[1]);
      p1 = pathPts[i];
      p2 = pathPts[i + 1];
    }

    lineGraphic.lineTo(p1[0], p1[1]);
    // draw arrow head
    if (pathPts.length > 4) {
      const from = pathPts[pathPts.length - 4];
      const to = pathPts[pathPts.length - 1];
      const x_center = to[0];
      const y_center = to[1];

      let angle;
      let x;
      let y;
      const radius = 4;

      angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;

      const arrowHead = [];

      // let start_x = x;
      // let start_y = y;
      arrowHead.push([x, y]);
      // lineGraphic.moveTo(x, y);

      angle += (1.0 / 3.0) * (2 * Math.PI);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;

      // lineGraphic.lineTo(x, y);
      arrowHead.push([x, y]);

      angle += (1.0 / 3.0) * (2 * Math.PI);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;

      // lineGraphic.lineTo(x, y);
      // lineGraphic.lineTo(start_x, start_y);
      arrowHead.push([x, y]);
      lineGraphic.endFill();
      lineGraphic.beginFill(0xffffff, 1);
      lineGraphic.drawPolygon(_.flatten(arrowHead));
      lineGraphic.endFill();
    }

    // transform to transcoded video coordinate system
    const { media, baseStationVersion } = this.props;
    const { width, height } = this.filterPixi.renderer.view;

    const [transcodedVideoWidth, transcodedVideoHeight] =
      t_video_size_from_media(media, baseStationVersion);

    const pathCordinates = pathPts.map((p: any) => [
      (p[0] / width) * transcodedVideoWidth,
      (p[1] / height) * transcodedVideoHeight,
    ]);

    const _paths = [...this.state.paths];
    _paths[this.state.activePath] = pathCordinates;
    if (setValue) this.setState({ paths: _paths });
  };

  resetPathFilter = () => {
    const canvasPaths = [...this.state.canvasPaths];
    canvasPaths[this.state.activePath] = [];
    const _paths = [...this.state.paths];
    _paths[this.state.activePath] = null;
    this.setState({ canvasPaths, paths: _paths }, () => {
      this.updatePath(false);
      this.props.updateFilters(
        _.cloneDeep(
          this.state.paths.filter((path: any) => Array.isArray(path)),
        ),
      );
    });
  };

  render() {
    const { media, channelNode } = this.props;
    const paths = _.get(this.state, 'paths', []).filter((path: any) =>
      Array.isArray(path),
    );

    return (
      <div className={styles['path-filter-ctn']}>
        <CalibrationControls
          imageRef={this.imageRef}
          onThumbnailLoaded={() => this.onThumbnailLoaded()}
          media={media}
          channelNode={channelNode}
          pathRef={this.pathRef}
          channelId={channelNode.ChannelID}
          showCalibration={this.state.showCalibration}
        />
        <div className={styles['canvas-path-container']}>
          <div className={styles['action-btn-ctn']}>
            <div>
              <Button
                block
                onClick={() => {
                  this.setState({
                    showCalibration: !this.state.showCalibration,
                  });
                }}>
                {this.state.showCalibration ? 'Hide' : 'Show'} Calibration
                Controls
              </Button>
            </div>
            <div className={styles['action-panel-ctn']} />
            <div className={styles['reset-btn']}>
              <Button block onClick={() => this.resetPathFilter()}>
                Reset Path Filters
              </Button>
            </div>
          </div>
          <div className={styles['region-list-ctn']}>
            {!paths.length && (
              <div className={styles['region-row']}>
                Drag to create a new path filter.
              </div>
            )}
            {paths.map((region: any, idx: any) => {
              return (
                <div
                  className={styles['region-row']}
                  key={`Region-${idx}`}
                  onClick={() => {}}>
                  {`Path ${idx + 1}`}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default PathFilters;
