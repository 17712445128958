import LoadingSpinner from '@/components/LoadingSpinner';
import PlayerModal from '@/components/PlayerModal';
import CreateEvent from '@/pages/investigations/components/create-event';
import { entityHasLicenseOfType } from '@/utils/licenses';
import { doNotificationOp, notificationOps } from '@/utils/notifications';
import { getActualTimeFromESTime, interpretClipData } from '@/utils/utils';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import type { Action } from '../../SidebarComponents/Actions';
import Actions from '../../SidebarComponents/Actions';
import ArchiveIncident from '../../SidebarComponents/Archive';
import Comments from '../../SidebarComponents/Comments';
import AddComments from '../../SidebarComponents/Comments/AddComment';
import { Camera, Time } from '../../SidebarComponents/ContextDetails';
import ExternalCaseManagement from '../../SidebarComponents/ExternalCaseManagement';
import { Assignee, Priority } from '../../SidebarComponents/FormItems';
import Share from '../../SidebarComponents/Share';

import { ARCHIVED_INCIDENT_PRIORITY } from '@/pages/apps/app/AlarmApp/constants';
import InfoSidebar, {
  SidebarItem,
  SidebarRow,
  SlidingInfoBar,
} from '../../InfoSidebarV2';

type AlertSidebarProps = {
  clip: any;
  searchResults?: any;
  onAfterClipUpdate?: Function;
  appId: number;
  isModal?: boolean;
};

const AlertSidebar: React.FC<AlertSidebarProps> = ({
  clip: originalClip,
  searchResults,
  onAfterClipUpdate,
  appId,
  isModal = true,
  ...other_props
}) => {
  //Read data from store
  const { loc, ch_grp, ch } = useSelector((state) => state.locations);
  const currentUser = useSelector((state) => state.user.currentUser);
  const accounts = useSelector((state) => state.accounts);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects['apps/doAppOp'];
  });

  //Initialise local state
  const [clip, setClip] = useState(originalClip);
  const clipInfo = useMemo(() => {
    const timezone = ch.byId[clip.ChannelID].Timezone;
    return interpretClipData(
      clip,
      { loc, ch_grp, ch },
      timezone,
      searchResults,
    );
  }, [clip, searchResults]);

  //Side effects
  useEffect(() => {
    setClip(originalClip);
  }, [originalClip]);

  //Define handlers
  const dispatch = useDispatch();
  const handlerWrapper = (handlerFunction: Function) => {
    return (...args) => {
      const newClip = { ...clip };
      handlerFunction(newClip, ...args);
      setClip(newClip);
      if (onAfterClipUpdate) onAfterClipUpdate(newClip);
    };
  };
  const onPriorityChange = handlerWrapper((clipOb, value) => {
    doNotificationOp(
      dispatch,
      notificationOps.setPriorityAlert,
      {
        alert_id: clipOb.id,
        value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.priority = value;
  });
  const onAssignmentChange = handlerWrapper((clipOb, value, label) => {
    doNotificationOp(
      dispatch,
      notificationOps.reassignToUserAlert,
      {
        alert_id: clipOb.id,
        owner_id: value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.statusOwnerID = value;
    clipOb.statusOwnerName = label ?? value;
  });
  const onAddNote = handlerWrapper((clipOb, value) => {
    doNotificationOp(
      dispatch,
      notificationOps.saveNoteAlert,
      {
        alert_id: clipOb.id,
        text: value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clip.log.push({
      action: 'note',
      text: value,
      timestamp: Math.floor(Date.now() / 1000),
      user: {
        id: currentUser.UserID,
        name: currentUser.FirstName,
      },
    });
  });
  const onDeleteNote = handlerWrapper((clipOb, index) => {
    doNotificationOp(
      dispatch,
      notificationOps.deleteNoteAlert,
      {
        alert_id: clipOb.id,
        note_index: index,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.log.splice(index, 1);
  });
  const onCaseChange = handlerWrapper((clipOb, info) => {
    doNotificationOp(
      dispatch,
      notificationOps.setExternalCaseInfo,
      {
        alert_id: clipOb.id,
        ...info,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.externalCase = { ...clipOb.externalCase, ...info };
  });
  const onArchive = handlerWrapper((clipOb, reason) => {
    clipOb.reason = reason;
    clipOb.isDeleted = true;
  });
  const onUnarchive = handlerWrapper((clipOb) => {
    clipOb.isDeleted = false;
    clipOb.isUnarchived = true;
  });

  const generatePublicUrl = handlerWrapper((clipOb) => {
    doNotificationOp(
      dispatch,
      notificationOps.generatePublicUrl,
      {
        alert_id: clipOb.id,
      },
      { showFeedbackOnFailureOnly: true },
    ).then((res) => {
      clipOb.externalCase.public_url = res?.Data.external_case.public_url;
    });
  });

  //Other vars
  const from = _.get(clipInfo, 'from');
  const to = _.get(clipInfo, 'to');
  const startTime = from && from.valueOf() / 1000;
  const endTime = to && to.valueOf() / 1000;
  const is_archived =
    clip.isDeleted || clip.priority === ARCHIVED_INCIDENT_PRIORITY;

  const actions: Action[] = [
    {
      name: 'View in Context',
      type: 'component',
      component: PlayerModal,
      componentProps: {
        autoPlay: true,
        showLive: true,
        fitEventsOnAxis: true,
        events: clipInfo.events,
        startTime: startTime,
        endTime: endTime,
        channelIDs: [clip.ChannelID],
      },
    },
    {
      name: 'Share',
      type: 'component',
      component: Share,
      componentProps: {
        url: `${BASE_URL}/apps/${appId}?alertID=${clip.id}`,
        publicUrl: clip.externalCase.public_url,
        title: 'Share Burglar Alarm Alert',
        generatePublicUrl: generatePublicUrl,
      },
    },
    {
      name: is_archived ? 'Unarchive' : 'Archive',
      type: 'component',
      component: ArchiveIncident,
      componentProps: {
        onArchive: onArchive,
        onUnarchive: onUnarchive,
        is_archived,
      },
    },
    {
      name: 'Add To Investigation',
      type: 'component',
      component: CreateEvent,
      componentProps: {
        clip: clip,
        event: clipInfo.events,
        disabled: !entityHasLicenseOfType(
          accounts,
          'DIS',
          +_.get(clip, 'ChannelID', 0),
          +_.get(clip, 'locationID', 0),
        ),
        style: { display: 'none' },
      },
    },
  ];
  const CameraProps = {
    location: {
      id: clipInfo.location_obj.ID,
      name: clipInfo.location_obj.Name,
    },
    channelGroup: clipInfo.channel_obj.ChannelGroupID && {
      id: clipInfo.channel_obj.ChannelGroupID,
      name: clipInfo.channel_obj.ChannelGroupName,
    },
    channel: {
      id: clipInfo.channel_obj.ID,
      name: clipInfo.channel_obj.Name,
    },
  };
  const TimeProps = {
    from: clipInfo.from,
    to: clipInfo.to,
    timezone: clipInfo.timezone,
  };
  let content = null;
  if (!clip && isLoading) {
    //This shouldn't ideally happen since this component is not supposed to be rendered before
    //a clip if fetched
    content = <LoadingSpinner />;
  } else {
    content = (
      <>
        <SidebarItem>
          <Actions actions={actions} />
        </SidebarItem>
        <SidebarItem title="Camera">
          <Camera {...CameraProps} />
        </SidebarItem>
        <SidebarItem title="Time">
          <Time {...TimeProps} />
        </SidebarItem>
        {!is_archived && (
          <SidebarRow>
            <SidebarItem title="Priority" width="20%">
              <Priority priority={clip.priority} onChange={onPriorityChange} />
            </SidebarItem>
            <SidebarItem title="Assignee" width="75%">
              <Assignee
                assignee={clip.statusOwnerID}
                onChange={onAssignmentChange}
              />
            </SidebarItem>
          </SidebarRow>
        )}
        {clip.log.length > 0 && (
          <SidebarItem title="Comments">
            <Comments comments={clip} onDelete={onDeleteNote} />
          </SidebarItem>
        )}
        <SidebarItem title="External Case Management">
          <ExternalCaseManagement
            externalCase={clip.externalCase}
            onChange={onCaseChange}
          />
        </SidebarItem>
      </>
    );
  }
  if (isModal) {
    return (
      <InfoSidebar
        content={content}
        title="Details"
        footer={<AddComments onChange={onAddNote} />}
        {...other_props}
      />
    );
  } else {
    return (
      <SlidingInfoBar
        content={content}
        title="Details"
        footer={<AddComments onChange={onAddNote} />}
      />
    );
  }
};

export function PublicAlertSidebar({ sidebar }) {
  const { timeframe, status } = sidebar;
  const CameraProps = {
    location: {
      id: sidebar.location?.id,
      name: sidebar.location?.name,
    },
    channelGroup: sidebar.channel.ChannelGroupID && {
      id: sidebar.channel.ChannelGroupID,
      name: sidebar.channel.ChannelGroupID,
    },
    channel: {
      id: sidebar.channel?.id,
      name: sidebar.channel?.name,
    },
  };
  const from = getActualTimeFromESTime(timeframe.start, timeframe.timezone);
  const to = getActualTimeFromESTime(timeframe.end, timeframe.timezone);
  const TimeProps = {
    from: from,
    to: to,
    timezone: timeframe.timezone,
  };
  const content = (
    <>
      <SidebarItem title="Camera">
        <Camera {...CameraProps} />
      </SidebarItem>
      <SidebarItem title="Time">
        <Time {...TimeProps} />
      </SidebarItem>
      <SidebarRow>
      {status.priority !== ARCHIVED_INCIDENT_PRIORITY ? (
    <SidebarItem title="Priority" width="20%">
      <Priority priority={status.priority} />
    </SidebarItem>
      ) : <></>}
        <SidebarItem title="Assignee" width="75%">
          <Assignee assignee={status.owner?.name} />
        </SidebarItem>
      </SidebarRow>

      {sidebar.log.length > 0 && (
        <SidebarItem title="Comments">
          <Comments comments={sidebar} />
        </SidebarItem>
      )}
      <SidebarItem title="External Case Management">
        <ExternalCaseManagement externalCase={sidebar.external_case} />
      </SidebarItem>
    </>
  );
  return <SlidingInfoBar content={content} title={status.priority === ARCHIVED_INCIDENT_PRIORITY ? "Archived Incident":"Incident"} />;
}

export default AlertSidebar;
