import {
  createLocation,
  createOnvifChannels,
  createStorageServer,
  deleteChannel,
  deleteLocation,
  doReindexVideo,
  editStorageServer,
  getBaseStations,
  getChannel,
  getChannelGroups,
  getDeleteChannelDependencies,
  getDeleteLocationDependencies,
  getInstallation,
  getLocation,
  getLocationConnectionCode,
  getLocationLogs,
  getLocations,
  // getBaseStation,
  getONVIFDiscoveredDevices,
  getUploadMedia,
  getVMSList,
  sendInstructionsEmail,
  setAckLocationLogs,
  setUpdateRequest,
  updateChannel,
  updateChannelDetails,
  updateChannelMonitorConfig,
  updateLocation,
} from '@/services/location';
import _ from 'lodash';

import {
  createBaseStationsNode,
  createDiscoveredChannelsNode,
  createNodesFromLocations,
} from '@/utils/location';

import { ChannelNode, updateLocChGrpCh, updateMedia } from '@/types/location';
import { getMapChannelMedia } from '@/utils/utils';
import type { Model } from 'dva';

import type {
  BASE_STN_TYPE,
  ChannelGroupNode,
  CH_GRP_TYPE,
  CH_TYPE,
  DISCOVERED_CH_TYPE,
  LocationNode,
  LOC_TYPE,
  MEDIA_TYPE,
} from '@/types/location';

type RemoveNodeOBJ = {
  type: 'LOCATION' | 'CHANNELGROUP' | 'CHANNEL';
  id: number;
};

export type LocationsModalState = {
  all: any[];
  byID: Object;
  uploads: Object;
  loc: LOC_TYPE;
  ch_grp: CH_GRP_TYPE;
  ch: CH_TYPE;
  base_stn: BASE_STN_TYPE;
  discovered_ch: DISCOVERED_CH_TYPE;
  media: MEDIA_TYPE;
  currentLocationCode?: string;
  installationsByID: Record<number, any>;
  vmsListByID: Record<any, any>;
};

const eventTarget = (() => {
  let handle: any;
  const off = () => {
    handle = undefined;
    return handle;
  };
  return {
    on(fn: any) {
      handle = fn;
      return off;
    },
    off,
    trigger(e: any) {
      if (handle) {
        handle(e);
      }
    },
  };
})();

const inProgressLocationCalls = new Map();

const LocationModal: Model & { state: LocationsModalState } = {
  namespace: 'locations',
  state: {
    all: [],
    byID: {},
    installationsByID: {},
    vmsListByID: {},
    uploads: {},
    loc: {
      byId: {},
      allIds: [],
    },
    ch_grp: {
      byId: {},
      allIds: [],
    },
    ch: {
      byId: {},
      allIds: [],
      loc_ch_map: {},
    },
    base_stn: {
      byId: {},
      allIds: [],
      map_loc_baseStn: new Map<number, number>(),
    },
    discovered_ch: {
      byId: {},
      allIds: [],
    },
    media: {
      byId: {},
      ch_media_map: {},
    },
    currentLocationCode: undefined,
  },
  subscriptions: {
    setup({ dispatch }) {
      return eventTarget.on(dispatch);
    },
  },
  effects: {
    *createLocation(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createLocation(payload));
      if (response.success) {
        yield put({
          type: 'addLocation',
          payload: response.data,
          locationID: response.data.ProjectID,
        });
      }
      return response;
    },
    *updateLocation(action, { call, put }) {
      const { payload, locationID } = action;
      const response = yield call(() => updateLocation(locationID, payload));
      if (response.success) {
        yield put({
          type: 'modifyLocation',
          payload: response.data,
          locationID,
        });
        yield put({ type: 'fetchLocationsNoLoader' });
      }
    },
    *getLocations(action, { select }) {
      const {} = action;
      return yield select((state) => state.locations);
    },
    *fetchLocations(action, { call, put }) {
      const response = yield call(getLocations);
      if (response.success) {
        const node_tuple = createNodesFromLocations(response.data);
        yield put({
          type: 'saveLocations',
          payload: response.data,
          node_tuple,
        });
        response.data.forEach((loc) => {
          if (loc.ProjectStatus !== null) {
            eventTarget.trigger({
              type: 'fetchInstallation',
              payload: { locationID: loc.ProjectID },
            });
          }
        });
      }
      return response;
    },
    *fetchLocationsNoLoader(action, { call, put }) {
      const response = yield call(getLocations);
      if (response.success) {
        const node_tuple = createNodesFromLocations(response.data);
        yield put({
          type: 'saveLocations',
          payload: response.data,
          node_tuple,
        });
      }
      return response;
    },
    *fetchLocation(action, { call, put }) {
      const { payload } = action;
      const locationID = payload.locationID;
      if (inProgressLocationCalls.has(locationID)) {
        return inProgressLocationCalls.get(locationID);
      }
      const apiCall = call(getLocation, locationID);
      inProgressLocationCalls.set(locationID, apiCall);
      const response = yield apiCall;
      if (response.success && response.data) {
        const node_tuple = createNodesFromLocations([response.data]);
        yield put({
          type: 'saveLocation',
          payload: response.data,
          locationID: payload.locationID,
          node_tuple,
        });
        if (_.get(response.data, 'BaseStation')) {
          const bs_node_tuple = createBaseStationsNode([
            response.data.BaseStation,
          ]);
          yield put({
            type: 'saveBaseStations',
            node_tuple: bs_node_tuple,
          });
        }
      }
      inProgressLocationCalls.delete(locationID);
      return response;
    },
    *fetchChannel(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() =>
        getChannel(payload.locationID, payload.channelID),
      );
      if (response.success && response.data) {
        yield put({
          type: 'saveChannel',
          payload: response.data,
          locationID: payload.locationID,
        });
      }
      return response;
    },
    *fetchLocationNoLoader(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => getLocation(payload.locationID));
      if (response.success && response.data) {
        const node_tuple = createNodesFromLocations([response.data]);
        yield put({
          type: 'saveLocation',
          payload: response.data,
          locationID: payload.locationID,
          node_tuple,
        });
        if (_.get(response.data, 'BaseStation')) {
          const bs_node_tuple = createBaseStationsNode([
            response.data.BaseStation,
          ]);
          yield put({
            type: 'saveBaseStations',
            node_tuple: bs_node_tuple,
          });
        }
      }
      return response;
    },
    *fetchUploadMedia(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() =>
        getUploadMedia(payload.locationID, payload.uploadID),
      );
      if (response.success) {
        yield put({
          type: 'saveUploadMedia',
          payload: response.data,
          locationID: payload.locationID,
          uploadID: payload.uploadID,
        });
      }
      return response;
    },
    *fetchLocationConnectionCode(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() =>
        getLocationConnectionCode(payload.locationID),
      );
      if (response.success) {
        yield put({
          type: 'saveLocationCode',
          payload: response.data,
          locationID: payload.locationID,
        });
      }
      return response;
    },
    *fetchBaseStations(action, { call, put }) {
      const response = yield call(() => getBaseStations());
      if (response.success) {
        // console.log('got fetch for', response.data);
        const node_tuple = createBaseStationsNode(response.data);
        yield put({
          type: 'saveBaseStations',
          node_tuple,
          overwrite: true,
        });
      }
      return response;
    },
    *fetchDiscoveredDevices(action, { call, put }) {
      const { locationID } = action;
      const response = yield call(() => getONVIFDiscoveredDevices(locationID));
      if (response.success) {
        const node_tuple = createDiscoveredChannelsNode(
          response.data.DiscoveredDevices,
          response.data.ProjectID,
        );
        yield put({
          type: 'saveDiscoveredChannels',
          node_tuple,
        });
      }
      return response;
    },
    *fetchInstallation(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => getInstallation(payload.locationID));
      if (response.success) {
        yield put({
          type: 'saveInstallation',
          payload: response.data,
          locationID: payload.locationID,
        });
      }
      return response;
    },
    *fetchInstallationNoLoader(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => getInstallation(payload.locationID));
      if (response.success) {
        yield put({
          type: 'saveInstallation',
          payload: response.data,
          locationID: payload.locationID,
        });
      }
      return response;
    },
    *setUpdateRequest(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => setUpdateRequest(payload.locationID));
      if (response.success) {
        yield put({
          type: 'saveInstallation',
          payload: response.data,
          locationID: payload.locationID,
        });
      }
      return response;
    },
    *emailInstructions(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => sendInstructionsEmail(payload));
      if (response.success) {
        yield put({
          type: 'sentEmail',
          payload: response.data,
        });
      }
      return response;
    },
    *deleteLocation(action, { call, put }) {
      const { locationID } = action;
      const response = yield call(() => deleteLocation(locationID));
      if (response.success) {
        yield put({
          type: 'removeLocation',
          payload: response.data,
          locationID: response.data.ProjectID,
        });
        yield put({
          type: 'removeCameraNode',
          remove_node_obj: {
            type: 'LOCATION',
            id: +locationID,
          },
        });
      }
      return response;
    },
    *addStorageServer(action, { call, put }) {
      const { locationID, payload } = action;
      const response = yield call(() =>
        createStorageServer(locationID, payload),
      );
      if (response.success) {
        yield put({
          type: 'addEditedStorageServer',
          payload: response.data,
        });
        yield put({ type: 'fetchLocationsNoLoader' });
      }
      return response;
    },
    *updateStorageServer(action, { call, put }) {
      const { locationID, channelGroupID, payload } = action;
      const response = yield call(() =>
        editStorageServer(locationID, channelGroupID, payload),
      );
      if (response.success) {
        yield put({
          type: 'addEditedStorageServer',
          payload: response.data,
        });
      }
      return response;
    },
    *addChannelServer(action, { call, put }) {
      const { locationID, payload } = action;
      const response = yield call(() =>
        createOnvifChannels(locationID, payload),
      );
      if (response.success) {
        yield put({
          type: 'addEditedChannelServer',
          payload: response.data,
        });
        yield put({ type: 'fetchLocationNoLoader', payload: { locationID } });
      }
      return response;
    },
    *deleteChannel(action, { call, put }) {
      const { locationID, channelID } = action;
      const response = yield call(() => deleteChannel(locationID, channelID));
      if (response.success) {
        yield put({
          type: 'removeCameraNode',
          remove_node_obj: {
            type: 'CHANNEL',
            id: +channelID,
          },
        });
      }
      return response;
    },
    *updateChannel(action, { call }) {
      const { locationID, channelID, payload } = action;
      const response = yield call(() =>
        updateChannel(locationID, channelID, payload),
      );
      if (response.success) {
        // yield put({
        //   type: '',
        //   payload: response.data,
        // });
      }
      return response;
    },
    *updateChannelDetails(action, { call, put }) {
      const { locationID, channelID, payload } = action;
      const response = yield call(() =>
        updateChannelDetails(locationID, channelID, payload),
      );
      if (response.success) {
        yield put({
          type: 'addEditedChannelServer',
          payload: response.data,
        });
      }
      return response;
    },
    *setChannelMonitorConfig(action, { call, put }) {
      const { locationID, payload } = action;
      const response = yield call(() =>
        updateChannelMonitorConfig(locationID, payload),
      );
      if (response.success) {
        yield put({
          type: 'updatedChannelMonitorConfig',
          payload: response.data,
        });
      }
      return response;
    },
    *fetchVMSList(action, { call, put }) {
      const response = yield call(() => getVMSList());
      if (response.success) {
        yield put({
          type: 'saveVMSList',
          payload: response.data,
        });
        return response;
      }
      return {};
    },
    *fetchAllChannelGroups(action, { call }) {
      const { locationID } = action;
      const response = yield call(() => getChannelGroups(locationID));
      if (response.success) {
        return response;
      }
      return {};
    },
    *fetchDeleteChannelDependencies(action, { call }) {
      const { locationID, channelID } = action;
      const response = yield call(() =>
        getDeleteChannelDependencies(locationID, channelID),
      );
      if (response.success) {
        return response.data;
      }
      return {};
    },
    *fetchDeleteLocationDependencies(action, { call }) {
      const { locationID } = action;
      const response = yield call(() =>
        getDeleteLocationDependencies(locationID),
      );
      if (response.success) {
        return response;
      }
      return {};
    },
    *fetchGetLocationLogs(action, { call }) {
      const { locationID } = action;
      const response = yield call(() => getLocationLogs(locationID));
      if (response.success) {
        return response;
      }
      return {};
    },
    *setAckLocationLogs(action, { call, put }) {
      const { locationID, payload } = action;
      const response = yield call(() =>
        setAckLocationLogs(locationID, payload),
      );
      if (response.success) {
        yield put({
          type: 'acknowledgeLogs',
          payload: response.data,
        });
      }
    },
    *reindexVideo(action, { call }) {
      const { payload } = action;
      const response = yield call(() => doReindexVideo(payload));
      if (response.success) {
        return response;
      }
      return false;
    },
    *checkVMSpluginID(action, { select }) {
      const { locationID, channelGroupID, channelID } = action;
      const thisState = yield select((state: any) => state.locations);
      const { loc, ch_grp, ch } = thisState;
      if (locationID) {
        return _.get(loc, `byId[${locationID}].VMSPluginID`, null);
      } else if (channelGroupID) {
        return _.get(
          loc,
          `byId[${_.get(
            ch_grp,
            `byId[${channelGroupID}].ProjectID`,
            null,
          )}].VMSPluginID`,
          null,
        );
      } else if (channelID) {
        return _.get(
          loc,
          `byId[${_.get(
            ch,
            `byId[${channelID}].ProjectID`,
            null,
          )}].VMSPluginID`,
          null,
        );
      }
      return null;
    },
  },
  reducers: {
    // add modify remove save
    addLocation(state, action) {
      const { all } = state;
      // @ts-expect-error
      all.push(action.payload);
      return { ...state, all };
    },
    saveLocations(state, action) {
      const { loc, ch_grp, ch } = _.cloneDeep(state);
      const media: {
        byId: Record<number, any>;
        ch_media_map: Record<number, number[]>;
      } = state.media;

      // @ts-expect-error
      const [_loc, _ch_grp, _ch, _media] = action.node_tuple;
      updateLocChGrpCh({ loc, ch_grp, ch, media }, [
        _loc,
        _ch_grp,
        _ch,
        _media,
      ]);

      const newState = {
        ...state,
        // @ts-expect-error
        all: action.payload,
        loc: { ...loc },
        ch_grp: { ...ch_grp },
        ch: { ...ch },
        media,
      };
      if (_.isEqual(newState, state)) {
        return state;
      }
      return newState;
    },
    saveMedia(state, action) {
      const media: {
        byId: Record<number, any>;
        ch_media_map: Record<number, number[]>;
      } = state.media;
      updateMedia(media, _.get(action, 'medias', null));
      return {
        ...state,
        media,
      };
    },
    saveBaseStations(state, action) {
      const { base_stn } = _.cloneDeep(state);
      // @ts-expect-error
      const base_stn_obj = action.node_tuple;

      const new_base_stn = action.overwrite
        ? {
            byId: {},
            allIds: [],
            map_loc_baseStn: new Map<number, number>(),
          }
        : { ...base_stn };
      // console.log('saving info', action, base_stn_obj, base_stn);
      Object.keys(base_stn_obj).forEach((key) => {
        const bs_stn = base_stn_obj[key];
        if (key in new_base_stn.byId) {
          new_base_stn.byId[key].update(bs_stn);
        } else {
          new_base_stn.byId[key] = bs_stn;
          new_base_stn.allIds.push(+key);
        }
        new_base_stn.map_loc_baseStn.set(bs_stn.ProjectID, bs_stn.ID);
      });
      const newState = {
        ...state,
        base_stn: new_base_stn,
      };
      if (_.isEqual(newState, state)) {
        return state;
      }
      return newState;
    },
    saveDiscoveredChannels(state, action) {
      const { discovered_ch } = state;
      const new_discovered_ch = { ...discovered_ch };
      // @ts-expect-error
      const discovered_ch_obj = action.node_tuple;
      Object.keys(discovered_ch_obj).forEach((key) => {
        const dis_ch = discovered_ch_obj[key];
        if (key in discovered_ch.byId) {
          new_discovered_ch.byId[key].update(dis_ch);
        } else {
          new_discovered_ch.byId[key] = dis_ch;
          new_discovered_ch.allIds.push(key);
        }
      });
      return {
        ...state,
        discovered_ch: new_discovered_ch,
      };
    },
    saveVMSList(state, action) {
      const vmsListByID = {};
      action.payload.forEach((vms) => {
        vmsListByID[vms.ID] = vms;
      });
      return {
        ...state,
        vmsListByID,
      };
    },
    saveLocation(state, action) {
      const { byID, loc, ch_grp, ch } = _.cloneDeep(state);
      const media: {
        byId: Record<number, any>;
        ch_media_map: Record<number, number[]>;
      } = state.media;

      byID[action.locationID] = action.payload;

      // @ts-expect-error
      const [_loc, _ch_grp, _ch, _media] = action.node_tuple;
      updateLocChGrpCh({ loc, ch_grp, ch, media }, [
        _loc,
        _ch_grp,
        _ch,
        _media,
      ]);

      const newState = {
        ...state,
        byID,
        loc: { ...loc },
        ch_grp: { ...ch_grp },
        ch: { ...ch },
        media,
      };
      if (_.isEqual(newState, state)) {
        return state;
      }
      return newState;
    },
    saveChannel(state, action) {
      const { ch } = state;
      const { payload } = action;
      const channelID = payload['ChannelID'];
      const node = new ChannelNode(payload);

      if (ch.byId[channelID]) {
        ch.byId[channelID].update(node);
      } else {
        ch.byId[channelID] = node;
      }

      const newState = {
        ...state,
        ch: { ...ch },
      };

      return newState;
    },
    modifyLocation(state, action) {
      const { byID } = state;
      byID[action.locationID] = {
        ...byID[action.locationID],
        ...action.payload,
      };
      return {
        ...state,
        byID,
      };
    },
    saveUploadMedia(state, action) {
      const { uploads } = state;
      uploads[action.uploadID] = action.payload;
      return {
        ...state,
        uploads,
      };
    },
    saveLocationCode(state, action) {
      return {
        ...state,
        currentLocationCode: action.payload.Code,
      };
    },
    saveInstallation(state, action) {
      const { installationsByID } = _.cloneDeep(state);
      installationsByID[action.locationID] = action.payload;
      const newState = {
        ...state,
        installationsByID,
      };
      if (_.isEqual(newState, state)) {
        return state;
      }
      return newState;
    },
    sentEmail(state) {
      return state;
    },
    removeLocation(state, action) {
      let { all } = state;
      all = all.filter(
        (location: any) => location.ProjectID !== action.payload.locationID,
      );
      return { ...state, all };
    },
    removeCameraNode(state, action: { type; remove_node_obj: RemoveNodeOBJ }) {
      const { loc, ch_grp, ch } = state;
      const { remove_node_obj } = action;

      // Remove Node and Its Children
      const node_queue: RemoveNodeOBJ[] = [];

      node_queue.push(remove_node_obj);
      while (node_queue.length > 0) {
        const rm_node_obj = node_queue.shift();
        let node: LocationNode | ChannelGroupNode | null = null;
        if (rm_node_obj?.type === 'LOCATION') {
          node = _.get(loc, `byId[${rm_node_obj.id}]`);
          delete loc.byId[rm_node_obj.id];
          loc.allIds = loc.allIds.filter((id: number) => id !== rm_node_obj.id);
        } else if (rm_node_obj?.type === 'CHANNELGROUP') {
          node = _.get(ch_grp, `byId[${rm_node_obj.id}]`);
          delete ch_grp.byId[rm_node_obj.id];
          ch_grp.allIds = ch_grp.allIds.filter(
            (id: number) => id !== rm_node_obj.id,
          );
        } else if (rm_node_obj?.type === 'CHANNEL') {
          delete ch.byId[rm_node_obj.id];
          ch.allIds = ch.allIds.filter((id: number) => id !== rm_node_obj.id);
        }

        if (node) {
          const more_remove_node_obj: RemoveNodeOBJ[] = node.ChannelGroups.map(
            (ch_grp_id) => {
              return {
                type: 'CHANNELGROUP',
                id: ch_grp_id,
              };
            },
          );
          node_queue.push(...more_remove_node_obj);
        }
      }

      return {
        ...state,
        loc,
        ch_grp,
        ch,
      };
    },
    addEditedStorageServer(state) {
      return state;
    },
    addEditedChannelServer(state) {
      return state;
    },
    updatedChannelMonitorConfig(state) {
      return state;
    },
    acknowledgeLogs(state) {
      return state;
    },
    updateChannelMedia(state, action: { type; medias: any[] }) {
      const { ch } = state;
      const { medias } = action;

      const mapChannelMedia: Record<number, any[]> = getMapChannelMedia(medias);
      Object.entries(mapChannelMedia).forEach(([chID, media_s]) => {
        if (+chID in ch.byId) {
          ch.byId[+chID].updateMedia(media_s);
        }
      });

      return state;
    },
  },
};

export default LocationModal;
