/* eslint-disable  max-classes-per-file */

import { camera_json } from '@/utils/camera-details';
import _ from 'lodash';

const onvifScopeRE = /^onvif:\/\/www.onvif.org\/(.*)\/(.*)$/;

// INTERFACES
export interface ConfigProfile {
  profile_name: string;
  values: Record<string, any>;
}

export interface Location {
  ID: number;
  ProjectID: number;
  Name: string;
  ChannelGroups: number[];
  ChannelGroupsCount: number;
  Channels: number[];
  ChannelsCount: number;
  ConfigProfiles: Record<string, ConfigProfile>;
  // Status:                null | string;
  ProjectStatus: null | string;
  // SourceConfigUpdatedAt: null | string;
  SystemStatus: null | any[];
  Timezone: null | string;
  VMSPluginID: null | number;
  IsConnected: boolean;
}

export interface ChannelGroup {
  ID: number;
  Name: string;
  ChannelGroupID: null | number;
  ProjectID: number;
  ChannelGroups: number[];
  ChannelGroupsCount: number;
  Channels: number[];
  ChannelsCount: number;
  // MonitorStatus:          string;
}

export interface RTSPConfig {
  username: string | undefined;
  password: string | undefined;
  ip_address: string | undefined;
  port_number: string | undefined;
  server_url: string | undefined;
  monitor_status: string | undefined;
}

export interface Channel {
  ID: number;
  ChannelID: number;
  Name: string;
  ChannelGroupID: null | number;
  ProjectID: null | number;
  ConfigProfiles: Record<string, ConfigProfile>;
  // Thumbnail:              string;
  // HasData:                Boolean;
  // Media:                  Media[];
  // ChannelDetails:         ChannelDetails;
  // CreatedAt:              string;
  // CreatedFrom:            null | string;
  // DeviceSerialID:         null | string;
  // MonitorStatus:          string;
  Timezone: string;
  MediaIDs: number[];
  RTSPconfig: RTSPConfig;
}

type BS_STATUS = 'InStock' | 'Allocated' | null;
export enum DELIVERY_STATUS {
  BOOKED = 'BOOKED',
  DISPATCHED = 'DISPATCHED',
  DELIVERED = 'DELIVERED',
}
export interface BaseStation {
  ID: number;
  ProjectID: number;
  SerialNumber: string;
  Status: BS_STATUS;
  DeliveryStatus: DELIVERY_STATUS | null;
  TrackingNumber: string;
  TrackingUrl: String;
  MiscInfo: {};
}

export interface DiscoveredDevice {
  ID: string;
  ProjectID: number;
  IP: string;
  Port: string;
  ServerURL: string;
  Manufacturer: string;
  Model: string;
  Hardware: string;
  Username: string;
  Password: string;
}

// TYPE
export type NodeTuple = [
  Record<number, LocationNode>,
  Record<number, ChannelGroupNode>,
  Record<number, ChannelNode>,
  Record<number, any>,
];

// CLASS
export class LocationNode implements Location {
  readonly ID: number;
  ProjectID: number;
  Name: string;
  ChannelGroups: number[];
  ChannelGroupsCount: number;
  Channels: number[];
  ChannelsCount: number;
  ConfigProfiles: Record<string, ConfigProfile>;
  ProjectStatus: null | string;
  SystemStatus: null | any[];
  Timezone: null | string;
  VMSPluginID: number | null;
  IsConnected: boolean;

  constructor(node: Object) {
    this.ID = +_.get(node, 'ProjectID', -1);
    this.ProjectID = +_.get(node, 'ProjectID', -1);
    this.Name = _.get(node, 'Name', '');
    this.ChannelGroups = _.get(node, 'ChannelGroups', []).map(
      (_ch_gp: { ChannelGroupID: string }) => +_ch_gp['ChannelGroupID'],
    );
    this.ChannelGroupsCount = _.get(
      node,
      'ChannelGroupsCount',
      this.ChannelGroups.length,
    );
    this.Channels = _.get(node, 'Channels', []).map(
      (_ch: { ChannelID: string }) => +_ch['ChannelID'],
    );
    this.ChannelsCount = _.get(node, 'ChannelsCount', this.Channels.length);
    this.ConfigProfiles = _.get(node, 'ConfigProfiles', {});
    this.ProjectStatus = _.get(node, 'ProjectStatus', null);
    this.SystemStatus = _.get(node, 'SystemStatus', null);
    this.Timezone = _.get(node, 'Timezone', null);
    this.VMSPluginID = _.get(node, 'VMSPluginID', null);
    this.IsConnected = _.get(node, 'IsConnected', false);
  }

  update(node: LocationNode): void {
    if (node.Name !== '') this.Name = node.Name;
    if (node.ChannelGroups.length > 0) {
      this.ChannelGroups = node.ChannelGroups;
      this.ChannelGroupsCount = node.ChannelGroupsCount;
    }
    if (node.Channels.length > 0) {
      this.Channels = node.Channels;
      this.ChannelsCount = node.ChannelsCount;
    }
    if (node.ConfigProfiles && Object.keys(node.ConfigProfiles).length > 0) {
      this.ConfigProfiles = node.ConfigProfiles;
    }
    if (node.ProjectStatus) {
      this.ProjectStatus = node.ProjectStatus;
    }
    if (node.SystemStatus) {
      this.SystemStatus = node.SystemStatus;
    }
    if (node.Timezone) {
      this.Timezone = node.Timezone;
    }
    if (node.VMSPluginID) this.VMSPluginID = node.VMSPluginID;
    this.IsConnected = node.IsConnected;
  }
}

export class ChannelGroupNode implements ChannelGroup {
  readonly ID: number;
  Name: string;
  ChannelGroupID: number | null;
  ProjectID: number;
  ChannelGroups: number[];
  ChannelGroupsCount: number;
  Channels: number[];
  ChannelsCount: number;

  constructor(node: Object) {
    this.ID = +_.get(node, 'ChannelGroupID', -1);
    this.Name = _.get(node, 'Name', '');
    this.ChannelGroupID = _.get(node, 'ParentID', -1);
    this.ProjectID = _.get(node, 'ProjectID', -1);
    this.ChannelGroups = _.get(node, 'ChannelGroups', []).map(
      (_ch_gp: { ChannelGroupID: string }) => +_ch_gp['ChannelGroupID'],
    );
    this.ChannelGroupsCount = _.get(
      node,
      'ChannelGroupsCount',
      this.ChannelGroups.length,
    );
    this.Channels = _.get(node, 'Channels', []).map(
      (_ch: { ChannelID: string }) => +_ch['ChannelID'],
    );
    this.ChannelsCount = _.get(node, 'ChannelsCount', this.Channels.length);
  }

  update(node: ChannelGroupNode): void {
    if (node.Name !== '') this.Name = node.Name;
    if (node.ChannelGroupID !== -1) this.ChannelGroupID = node.ChannelGroupID;
    if (node.ProjectID !== -1) this.ProjectID = node.ProjectID;
    if (node.ChannelGroups.length > 0) {
      this.ChannelGroups = node.ChannelGroups;
      this.ChannelGroupsCount = node.ChannelGroupsCount;
    }
    if (node.Channels.length > 0) {
      this.Channels = node.Channels;
      this.ChannelsCount = node.ChannelsCount;
    }
  }
}

export class ChannelNode implements Channel {
  readonly ID: number;
  ChannelID: number;
  Name: string;
  ChannelGroupID: number | null;
  ProjectID: number | null;
  Timezone: string;
  ConfigProfiles: Record<string, ConfigProfile>;
  ChannelDetails: any | null;
  LatestMedia: any[];
  Media: any[];
  MediaIDs: number[];
  RTSPconfig: RTSPConfig;
  Fetched?: boolean;

  constructor(node: Object) {
    this.ID = +_.get(node, 'ChannelID', -1);
    this.ChannelID = +_.get(node, 'ChannelID', -1);
    this.Name = _.get(node, 'Name', '');
    this.ChannelGroupID = _.get(node, 'ChannelGroupID', -1);
    this.ProjectID = _.get(node, 'ProjectID', -1);
    this.Timezone = _.get(node, 'Timezone', 'UTC');
    this.ConfigProfiles = _.get(node, 'ConfigProfiles', {});
    this.ChannelDetails = _.get(node, 'ChannelDetails', {});
    this.LatestMedia = _.get(node, 'LatestMedia', []);
    this.Media = _.get(node, 'Media', []);
    this.MediaIDs = [
      ..._.get(node, 'Media', []),
      ..._.get(node, 'LatestMedia', []),
    ]
      .map((media: any) => +_.get(media, 'id', null))
      .filter((id: number) => id);
    this.RTSPconfig = this.createRTSPconfig(node);
  }

  private createRTSPconfig(node: Object): RTSPConfig {
    return {
      username: _.get(node, 'ChannelDetails.Username', undefined),
      password: _.get(node, 'ChannelDetails.Password', undefined),
      ip_address: _.get(node, 'ChannelDetails.IPAddress', undefined),
      port_number: _.get(node, 'ChannelDetails.RTSPPort', undefined),
      server_url: _.get(node, 'ChannelDetails.ServerURL', undefined),
      monitor_status: _.get(node, 'MonitorStatus', undefined),
    };
  }

  private updateRTSPconfig(RTSPconfig: RTSPConfig): void {
    if (RTSPconfig.username !== undefined)
      this.RTSPconfig.username = RTSPconfig.username;
    if (RTSPconfig.password !== undefined)
      this.RTSPconfig.password = RTSPconfig.password;
    if (RTSPconfig.ip_address !== undefined)
      this.RTSPconfig.ip_address = RTSPconfig.ip_address;
    if (RTSPconfig.port_number !== undefined)
      this.RTSPconfig.port_number = RTSPconfig.port_number;
    if (RTSPconfig.server_url !== undefined)
      this.RTSPconfig.server_url = RTSPconfig.server_url;
    if (RTSPconfig.monitor_status !== undefined)
      this.RTSPconfig.monitor_status = RTSPconfig.monitor_status;
  }

  update(node: ChannelNode): void {
    if (node.Name !== '') this.Name = node.Name;
    if (node.ChannelGroupID !== -1) this.ChannelGroupID = node.ChannelGroupID;
    if (node.ProjectID !== -1) this.ProjectID = node.ProjectID;
    if (node.Timezone !== '') this.Timezone = node.Timezone;
    if (node.ConfigProfiles && Object.keys(node.ConfigProfiles).length > 0) {
      this.ConfigProfiles = node.ConfigProfiles;
    }
    if (node.ChannelDetails && Object.keys(node.ChannelDetails).length > 0) {
      this.ChannelDetails = _.merge(this.ChannelDetails, node.ChannelDetails);
    }
    if (node.LatestMedia) this.LatestMedia = node.LatestMedia;
    if (node.Media && node.Media.length > 0) {
      this.Media = _.values(
        _.merge(_.keyBy(this.Media, 'id'), _.keyBy(node.Media, 'id')),
      );
    }
    if (node.MediaIDs.length > 0) {
      this.MediaIDs = _.uniq([...this.MediaIDs, ...node.MediaIDs]);
    }
    this.updateRTSPconfig(node.RTSPconfig);
  }

  updateMedia(medias: any[]) {
    this.Media = _.values(
      _.merge(_.keyBy(this.Media, 'id'), _.keyBy(medias, 'id')),
    );
    this.MediaIDs = this.Media.map(
      (media: any) => +_.get(media, 'id', null),
    ).filter((id: number) => id);
  }
}

export class BaseStationNode implements BaseStation {
  readonly ID: number;
  ProjectID: number;
  SerialNumber: string;
  Status: BS_STATUS;
  DeliveryStatus: DELIVERY_STATUS | null;
  TrackingNumber: string;
  TrackingUrl: string;
  Latitude: string;
  Longitude: string;
  MiscInfo: string;

  constructor(node: Object) {
    this.ID = +_.get(node, 'BaseStationID', -1);
    this.ProjectID = +_.get(node, 'ProjectID', -1);
    this.SerialNumber = _.get(node, 'SerialNumber', '');
    this.Status = _.get(node, 'Status', null);
    this.DeliveryStatus = _.get(
      node,
      'StatusDetails.shipment_details.delivery_status',
      null,
    );
    this.TrackingNumber = _.get(
      node,
      'StatusDetails.shipment_details.tracking_number',
      '',
    );
    this.TrackingUrl = _.get(
      node,
      'StatusDetails.shipment_details.tracking_url',
      '',
    );
    this.Latitude = _.get(node, 'MiscInfo.latitude', '');
    this.Longitude = _.get(node, 'MiscInfo.longitude', '');
    this.MiscInfo = _.get(node, 'MiscInfo', {});
  }

  update(node: BaseStationNode): void {
    if (node.ProjectID !== -1) this.ProjectID = node.ProjectID;
    if (node.SerialNumber !== '') this.SerialNumber = node.SerialNumber;
    if (node.Status !== null) this.Status = node.Status;
    if (node.DeliveryStatus) this.DeliveryStatus = node.DeliveryStatus;
    if (node.TrackingNumber !== '') this.TrackingNumber = node.TrackingNumber;
    if (node.TrackingUrl !== '') this.TrackingUrl = node.TrackingUrl;
    if (node.Latitude !== '') this.Latitude = node.Latitude;
    if (node.Longitude !== '') this.Longitude = node.Longitude;
    if (node.MiscInfo !== '') this.MiscInfo = node.MiscInfo;
  }
}

export class DiscoveredDeviceNode implements DiscoveredDevice {
  readonly ID: string;
  ProjectID: number;
  IP: string;
  Port: string;
  ServerURL: string;
  Manufacturer: string;
  Model: string;
  Hardware: string;
  Username: string;
  Password: string;

  constructor(node: Object) {
    this.ID = _.get(node, 'UUID', '');
    this.ProjectID = +_.get(node, 'ProjectID', -1);

    this.IP = _.get(node, 'XAddr');

    // parse info out from scope. example:
    //         "Scope": "onvif://www.onvif.org/manufacturer/Hanwha%20Techwin onvif://www.onvif.org/type/video_encoder onvif://www.onvif.org/type/Network_Video_Transmitter onvif://www.onvif.org/hardware/QND-6011 onvif://www.onvif.org/Profile/Streaming onvif://www.onvif.org/Profile/G onvif://www.onvif.org/Profile/T onvif://www.onvif.org/location/country/korea onvif://www.onvif.org/location/city/seoul onvif://www.onvif.org/name/QND-6011 ",
    let scopeInfo = DiscoveredDeviceNode.parseScope(
      _.get(node, 'Scope') || (_.get(node, 'Scopes', []) || []).join(' ') || '',
    );

    // override if we have more structured information directly from camera
    this.Port = _.get(node, 'DeviceInfo.Port') || '';
    this.Hardware = _.get(scopeInfo, 'hardware');
    this.Manufacturer =
      _.get(node, 'DeviceInfo.Manufacturer') ||
      _.get(scopeInfo, 'manufacturer') ||
      _.get(scopeInfo, 'name') ||
      '';
    this.Model =
      _.get(node, 'DeviceInfo.Model') ||
      this.Hardware ||
      _.get(scopeInfo, 'name') ||
      '';

    let modelInfo = DiscoveredDeviceNode.getModelInfo(this.Model);
    this.ServerURL = _.get(modelInfo, 'serverUrl', '');
    this.Username = _.get(modelInfo, 'defaultUsername', '');
    this.Password = _.get(modelInfo, 'defaultPassword', '');
  }

  update(node: DiscoveredDeviceNode): void {
    if (node.ProjectID !== -1) this.ProjectID = node.ProjectID;
    if (node.IP !== '') this.IP = node.IP;
    if (node.Port !== '') this.Port = node.Port;
    if (node.ServerURL !== '') this.ServerURL = node.ServerURL;
    if (node.Username !== '') this.Username = node.Username;
    if (node.Password !== '') this.Password = node.Password;
    if (node.Manufacturer !== '') this.Manufacturer = node.Manufacturer;
    if (node.Model !== '') this.Model = node.Model;
    if (node.Hardware !== '') this.Hardware = node.Hardware;
  }

  static parseScope = (scope = '') => {
    let obj: Record<string, any> = {};
    scope.split(' ').forEach((url) => {
      let components = onvifScopeRE.exec(url);
      if (components) {
        let key = components[1];
        let val = decodeURI(components[2]);
        // ah, this is a list!
        let existing = _.get(obj, key);
        if (existing) {
          if (typeof existing === 'string') {
            obj[key] = [existing];
          }
          obj[key].push(val);
        } else {
          obj[key] = val;
        }
      }
    });
    return obj;
  };

  static getModelInfo = (model: any) => {
    if (!model) {
      return null;
    }
    let modelInfo: any;
    Object.entries(camera_json).forEach(([_manufacturer, models]) => {
      if (modelInfo) {
        return;
      }
      modelInfo = _.get(models, model.toLowerCase());
    });
    return modelInfo;
  };
}

// Location Modal Types
export type LOC_TYPE = {
  byId: Record<number, LocationNode>;
  allIds: number[];
};
export type CH_GRP_TYPE = {
  byId: Record<number, ChannelGroupNode>;
  allIds: number[];
};
export type CH_TYPE = {
  byId: Record<number, ChannelNode>;
  allIds: number[];
  loc_ch_map: Record<number, number[]>;
};
export type BASE_STN_TYPE = {
  byId: Record<number, BaseStationNode>;
  allIds: number[];
  map_loc_baseStn: Map<number, number>;
};
export type DISCOVERED_CH_TYPE = {
  byId: Record<string, DiscoveredDeviceNode>;
  allIds: string[];
};
export type MEDIA_TYPE = {
  byId: Record<number, any>;
  ch_media_map: Record<number, number[]>;
};

// Media
export const updateMedia = (media: MEDIA_TYPE, new_media: any[]) => {
  if (Array.isArray(new_media) && new_media.length > 0) {
    const { byId, ch_media_map } = media;

    new_media.forEach((m) => {
      const mId = m['id'];
      const chId = m['ChannelID'];
      if (mId && chId) {
        byId[+mId] = m;
        if (!(chId in ch_media_map)) {
          ch_media_map[chId] = [];
        }
        ch_media_map[chId].push(mId);
      }
    });
  }
};

// loc, ch_grp, ch
export const updateLocChGrpCh = (
  {
    loc,
    ch_grp,
    ch,
    media,
  }: {
    loc: LOC_TYPE;
    ch_grp: CH_GRP_TYPE;
    ch: CH_TYPE;
    media: MEDIA_TYPE;
  },
  newLocChGpCh: NodeTuple,
) => {
  const [_loc, _ch_grp, _ch, _media] = newLocChGpCh;

  // Merge Location Nodes
  Object.keys(_loc).forEach((key) => {
    if (key in loc.byId) {
      loc.byId[+key].update(_loc[+key]);
    } else {
      loc.byId[+key] = _loc[+key];
      loc.allIds.push(+key);
    }
  });
  // SORT LOCATION
  loc.allIds = _.orderBy(
    loc.allIds,
    [(id) => loc?.byId[id].Name.toLowerCase()],
    ['asc'],
  );

  // Merge ChannelGroup Nodes
  Object.keys(_ch_grp).forEach((key) => {
    if (key in ch_grp.byId) {
      ch_grp.byId[+key].update(_ch_grp[+key]);
    } else {
      ch_grp.byId[+key] = _ch_grp[+key];
      ch_grp.allIds.push(+key);
    }
  });

  // Merge Channel Nodes
  Object.keys(_ch).forEach((key) => {
    if (key in ch.byId) {
      ch.byId[+key].update(_ch[+key]);
    } else {
      ch.byId[+key] = _ch[+key];
      ch.allIds.push(+key);
    }
  });
  ch.loc_ch_map = Object.entries(ch.byId).reduce(
    (map: Record<number, number[]>, [id, ch_val]) => {
      if (ch_val.ProjectID) {
        if (ch_val.ProjectID in map) {
          map[+ch_val.ProjectID].push(+id);
        } else {
          map[+ch_val.ProjectID] = [+id];
        }
      }

      return map;
    },
    {},
  );

  // Merge Media
  updateMedia(media, Object.values(_media));
  Object.entries(media.ch_media_map).forEach(([key, value]) => {
    media.ch_media_map[+key] = _.uniq(value);
  });
};
