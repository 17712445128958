import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import InsightChart from '@/components/Insight/insight-chart';
import { generateInsight } from '@/utils/insight';

import type { InsightClass } from '@/types/insights';

interface MyProps {
  insightID: string;
  playhead: number;
  vizSpec: any;

  insightObj?: InsightClass;
  dispatch?: (_any: any) => Promise<any>;
}

interface MyState {}

// @ts-expect-error
@connect(({ insights }, { insightID }) => ({
  insightObj: _.get(insights, `byID[${insightID}]`),
}))
class ViewInsightTile extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const { insightID, dispatch } = this.props;
    generateInsight(dispatch, insightID, null, true);
  }

  render() {
    const { playhead, vizSpec, insightObj } = this.props;
    if (insightObj) {
      const label = _.get(vizSpec, 'label');
      const insightName = _.get(insightObj, 'Name', '');
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            background: 'white',
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div style={{ margin: '4px 0', fontSize: 12, fontWeight: 500 }}>
            {label || insightName}
          </div>
          <div
            style={{
              width: '100%',
              height: 'calc( 100% - 10px )',
            }}>
            <InsightChart
              height="100%"
              insight={insightObj}
              isEmbed={true}
              playhead={playhead}
              vizSpec={vizSpec}
            />
          </div>
        </div>
      );
    }
    return <></>;
  }
}
export default ViewInsightTile;
