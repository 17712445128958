import {
  deleteAlarm,
  duplicateAlarm,
  getActivityLog,
  getAlarms,
  getCurrentStatus,
  getHistoricalStatus,
  getIncidentFilters,
  getIncidents,
  setupAlarm,
  toggleAlarmStatus,
  updateIncidentPriority,
} from '@/services/alarm';
import { notification } from 'antd';
import _ from 'lodash';
import { ARCHIVED_INCIDENT_PRIORITY } from './constants';
import { formatEvent } from './formatter';

export type AlarmState = {
  alarms: Object;
  stats: Object;
  historicalStats: Object;
};

const eventTarget = (() => {
  let handle: any;
  const off = () => {
    handle = undefined;
    return handle;
  };
  return {
    on(fn: any) {
      handle = fn;
      return off;
    },
    off,
    trigger(e: any) {
      if (handle) {
        handle(e);
      }
    },
  };
})();

const getAlarmBaseModel = (namespace: string, app_id: number) => {
  const fetchIncidents = function* (action, { call, put }) {
    const response = yield call(() => getIncidents(action.payload, app_id));
    const incidents = _.get(response, 'data.Data');
    if (!action?.payload?.skipStoreUpdate) {
      yield put({
        type: 'saveIncidents',
        payload: incidents,
      });
    }
    return response;
  };

  return {
    namespace,
    appId: app_id,
    state: {
      alarms: {
        list: [],
      },
      incidents: {
        list: [],
      },
      log: {
        list: [],
      },
      stats: {},
      historicalStats: [],
      app_id,
    },
    subscriptions: {
      setup({ dispatch }) {
        return eventTarget.on(dispatch);
      },
    },
    effects: {
      *fetchStats(action, { call, put }) {
        const response = yield call(() =>
          getCurrentStatus(action.payload, app_id),
        );
        if (!response.success) {
          return {};
        }
        const stats = response.data.Data;
        yield put({
          type: 'saveStats',
          payload: stats,
        });
        return response;
      },
      *fetchHistoricalStats(action, { call, put }) {
        const response = yield call(() =>
          getHistoricalStatus(action.payload, app_id),
        );
        if (!response.success) {
          return {};
        }
        const stats = response.data.Data;
        yield put({
          type: 'saveHistoricalStats',
          payload: stats,
        });
        return response;
      },
      *fetchAlarms(action, { call, put }) {
        const response = yield call(() => getAlarms(action.params, app_id));
        const alarms = _.get(response, 'data.Data');
        yield put({
          type: 'saveAlarms',
          payload: alarms,
        });
        return response;
      },
      *toggleAlarmStatus(action, { call, put }) {
        const response = yield call(() =>
          toggleAlarmStatus(action.payload, app_id),
        );
        const alarm = response.data.Data[0];
        yield put({
          type: 'updateAlarm',
          payload: alarm,
        });
        eventTarget.trigger({
          type: 'fetchStats',
        });
        return response;
      },
      *setupAlarm({ payload }, { call, put }) {
        const response = yield call(() => setupAlarm(payload, app_id));
        if (!response.success || response.data.Data.error) {
          notification.open({
            message: response.data.message || response.data.Data.error,
            className: 'df-notification',
            placement: 'bottomRight',
          });
          if (!response.success) {
            return {};
          }
        }
        const alarm = response.data.Data;
        if (payload.alarm_id) {
          yield put({
            type: 'updateAlarm',
            payload: alarm,
          });
        } else {
          yield put({
            type: 'addAlarm',
            payload: alarm,
          });
        }
        return response;
      },
      *deleteAlarm(action, { call, put }) {
        const { alarm_id } = action.payload;
        const response = yield call(() => deleteAlarm(action.payload, app_id));
        yield put({
          type: 'removeAlarm',
          payload: { alarm_id },
        });
        return response;
      },
      *duplicateAlarm(action, { call, put }) {
        const response = yield call(() =>
          duplicateAlarm(action.payload, app_id),
        );
        if (!response.success || response.data.Data.error) {
          notification.open({
            message: response.data.message || response.data.Data.error,
            className: 'df-notification',
            placement: 'bottomRight',
          });
          if (!response.success) {
            return {};
          }
        } else {
          notification.open({
            message: 'Duplicated Alarm Successfully',
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
        const alarm = response.data.Data;
        yield put({
          type: 'addAlarm',
          payload: alarm,
        });
        return response;
      },
      fetchIncidents,
      fetchIncidentsNoLoader: fetchIncidents,
      *refreshIncidents(action, { call, put }) {
        const response = yield call(() => getIncidents(action.payload, app_id));
        const incidents = _.get(response, 'data.Data');
        yield put({
          type: 'updateIncidents',
          payload: incidents,
        });
        return response;
      },
      *archiveIncident(action, { call, put }) {
        const incident = action.payload;
        const response = yield call(() =>
          updateIncidentPriority(
            incident.id,
            app_id,
            ARCHIVED_INCIDENT_PRIORITY,
            incident.reason,
          ),
        );
        if (response.success) {
          yield put({
            type: 'removeIncident',
            payload: incident,
          });
          notification.open({
            message: `Incident archival succeeded.`,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
        return response;
      },
      *unarchiveIncident(action, { call, put }) {
        const incident = action.payload;
        const response = yield call(() =>
          updateIncidentPriority(incident.id, app_id),
        );
        if (response.success) {
          const incidents = _.get(response, 'data.Data');
          yield put({
            type: 'updateIncidents',
            payload: incidents,
          });
          notification.open({
            message: `Incident restored`,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
        return response;
      },
      *fetchActivityLog(action, { call, put }) {
        const response = yield call(() =>
          getActivityLog(action.payload, app_id),
        );
        if (response.success) {
          yield put({
            type: 'saveActivityLog',
            payload: response.data.Data,
          });
        }
        return response;
      },
      *fetchIncidentFilters(action, { call }) {
        const response = yield call(() => getIncidentFilters(app_id));
        const incidentFilters = _.get(response, 'data.Data');
        return incidentFilters;
      },
    },
    reducers: {
      saveAlarms(state, action) {
        return { ...state, alarms: action.payload };
      },
      addAlarm(state, action) {
        const alarms = {
          ...state.alarms,
          list: [action.payload, ...state.alarms.list],
          p_size: state.alarms.p_size + 1,
        };
        return { ...state, alarms };
      },
      removeAlarm(state, action) {
        const { alarms } = state;
        alarms.list = alarms.list.filter(
          (alarm) => alarm.alarm_id !== action.payload.alarm_id,
        );
        return { ...state, alarms };
      },
      updateAlarm(state, action) {
        let { alarms } = state;
        alarms.list = alarms.list.map((alarm: any) =>
          alarm.alarm_id === action.payload.alarm_id ? action.payload : alarm,
        );
        return { ...state, alarms };
      },
      saveIncidents(state, action) {
        return { ...state, incidents: action.payload };
      },
      updateIncidents(state, action) {
        let updatedIncidentsById = {};
        action.payload.list.forEach((incident) => {
          updatedIncidentsById[incident.id] = incident;
        });
        return {
          ...state,
          incidents: {
            ...state.incidents,
            list: state.incidents.list.map((incident) => {
              if (updatedIncidentsById[incident.id]) {
                return { ...incident, ...updatedIncidentsById[incident.id] };
              }
              return incident;
            }),
          },
        };
      },
      removeIncident(state, action) {
        return {
          ...state,
          incidents: {
            ...state.incidents,
            list: state.incidents.list.filter((incident) => {
              return incident.id !== action.payload.id;
            }),
          },
        };
      },
      saveStats(state, action) {
        return { ...state, stats: action.payload };
      },
      saveHistoricalStats(state, action) {
        return { ...state, historicalStats: action.payload };
      },
      saveActivityLog(state, action) {
        let list = action.payload.list.map((obj) => formatEvent(obj));
        return { ...state, log: { ...action.payload, list } };
      },
    },
  };
};

export default getAlarmBaseModel;
