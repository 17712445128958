import _ from 'lodash';

const refreshStructure = (dispatch: (_any: any) => Promise<any>) => {
  const promises = [];
  promises.push(
    dispatch({
      type: 'locations/fetchLocationsNoLoader',
    }),
  );
  promises.push(
    dispatch({
      type: 'locations/fetchBaseStations',
    }),
  );
  return Promise.all(promises);
};

const updateLocation = (
  dispatch: (_any: any) => Promise<any>,
  locationID: number,
) => {
  const promises = [];
  promises.push(
    dispatch({
      type: 'locations/fetchLocationNoLoader',
      payload: { locationID },
    }),
  );
  promises.push(
    dispatch({
      type: 'locations/fetchInstallationNoLoader',
      payload: { locationID },
    }),
  );
  return Promise.all(promises);
};

const ACCESS_CONTROL_APP_IDS = [52, 55];

const processAppEvent = (
  appID: number,
  wsMessage: any,
  dispatch: (_any: any) => Promise<any>,
) => {
  let promiseObj = null;
  if (ACCESS_CONTROL_APP_IDS.includes(+appID)) {
    promiseObj = dispatch({
      type: 'apps/addAccessControlActivityFromWSmessage',
      appID,
      activity: _.get(wsMessage, 'data.activity', []),
    });
  } else {
    promiseObj = dispatch({
      type: 'apps/fetchApp',
      appID,
    });
  }

  promiseObj.then(({ data, success }) => {
    if (!success) {
      return;
    }
    dispatch({
      type: 'storeUserNotification',
      payload: {
        // standard

        type: 'app/websocket',
        readState: false,

        // type specific
        appInfo: data,
        wsMessage,
      },
    });
  });
};

const videoProcessingEvents = function (
  wsMessage: any,
  dispatch: (_any: any) => Promise<any>,
) {
  const channelID = _.get(wsMessage, 'data.channel_id', null);
  const media_db_item = _.get(wsMessage, 'data.media_db_item', null);
  if (channelID && media_db_item) {
    // console.log('wsMessage', wsMessage.data);
    dispatch({
      type: 'locations/saveMedia',
      medias: [media_db_item],
    });

    // update channel
    dispatch({
      type: 'locations/updateChannelMedia',
      medias: [media_db_item],
    });
    // update event
    dispatch({
      type: 'investigations/updateEventMedia',
      medias: [media_db_item],
    });

    return dispatch({
      type: 'setVideoProcessingUpdate',
      channelID,
      message: wsMessage,
    });
  }
  return null;
};

const processChannelTreeUpdate = (
  wsMessage: any,
  dispatch: (_any: any) => Promise<any>,
) => {
  const data = _.get(wsMessage, 'data.data', null);
  if (
    _.includes(
      ['channel', 'channel_group'],
      _.get(data, 'entity_type', null),
    ) &&
    typeof _.get(data, 'entity_id', null) === 'number' &&
    _.get(data, 'action', null) === 'delete'
  ) {
    const entity_type = _.get(data, 'entity_type');
    return dispatch({
      type: 'locations/removeCameraNode',
      remove_node_obj: {
        type: entity_type === 'channel' ? 'CHANNEL' : 'CHANNELGROUP',
        id: _.get(data, 'entity_id'),
      },
    });
  }
  refreshStructure(dispatch);
};

const PubSubMessageChannels: {
  name: string;
  regex: RegExp;
  processFn: (wsMessage: any, dispatch: (_any: any) => Promise<any>) => any;
}[] = [
  {
    name: 'apps',
    regex: /\/apps\/([^\/]+)\//,
    processFn: function (
      wsMessage: any,
      dispatch: (_any: any) => Promise<any>,
    ) {
      const channel = _.get(wsMessage, 'channel', '');
      const pattern = _.get(wsMessage, 'pattern', '');
      let appID;
      const found = channel.match(this.regex);
      if (found) {
        appID = +found[1];
      }

      if (appID && pattern) {
        // if (channel.indexOf('webhook') != -1) {
        //   return;
        // }
        return processAppEvent(appID, wsMessage, dispatch);
      }
    },
  },
  {
    name: 'structure',
    regex: /\/locations\/[^\/]+\/(create|delete)/,
    processFn: function (
      _wsMessage: any,
      dispatch: (_any: any) => Promise<any>,
    ) {
      return refreshStructure(dispatch);
    },
  },
  {
    name: 'locationUpdate',
    regex: /\/locations\/([^\/]+)\/update(.*$)/,
    processFn: function (
      wsMessage: any,
      dispatch: (_any: any) => Promise<any>,
    ) {
      const channel = _.get(wsMessage, 'channel', '');
      const locationUpdateFound = channel.match(this.regex);

      const updatedLocationID = +locationUpdateFound[1];
      const path = locationUpdateFound[2];
      // we're ignoring constant pings from locations for now, in the future
      // we can use these to show liveness status.
      if (path === '/ping') {
        return null;
      }
      return updateLocation(dispatch, updatedLocationID);
    },
  },
  {
    name: 'insightProgress',
    regex: /\/customer\/([0-9]*)\/insights\/([a-z0-9\-]*)\/update/,
    processFn: function (
      wsMessage: any,
      dispatch: (_any: any) => Promise<any>,
    ) {
      dispatch({
        type: 'insights/processInsightPercentageCompleteEvent',
        wsMessage,
      });
    },
  },
  {
    name: 'videoProcessingProgress',
    regex:
      /\/customer\/([0-9]*)\/channel\/([0-9]*)\/video\/([0-9]*)\/video_processing_update/,
    processFn: videoProcessingEvents,
  },
  {
    name: 'channelTreeUpdate',
    regex:
      /\/customer\/([0-9]*)\/locations\/([0-9]*)\/(channel|channel_group)\/([0-9]*)\/(create|update|delete)/,
    processFn: processChannelTreeUpdate,
  },
];

export const processPubSubMessage = (
  wsMessage: any,
  dispatch: (_any: any) => Promise<any>,
) => {
  const channel = _.get(wsMessage, 'channel', '');

  for (let message_channel of PubSubMessageChannels) {
    if (channel.match(message_channel.regex)) {
      message_channel.processFn(wsMessage, dispatch);
    }
  }

  return null;
};

// functions for Media Updates
// to check how the functions should be used check
// src/pages/investigations/components/investigation-event/index.tsx
export const channelsMediaUpdates = (
  videoProcessingUpdate: Record<number, any>,
  channelIDs: number[],
) => {
  const updates: Record<number, any> = {};
  if (channelIDs && Array.isArray(channelIDs)) {
    for (const chID of channelIDs) {
      const update = _.get(videoProcessingUpdate, [+chID], null);
      if (update) {
        updates[+chID] = update;
      }
    }
  }
  return updates;
};

export const checkVideoProcessingUpdate = (
  prevProps: any,
  props: any,
  cb: (msg: any) => void = () => {},
) => {
  const videoProcessingUpdate = _.get(props, 'videoProcessingUpdate', null);
  const prev_videoProcessingUpdate = _.get(
    prevProps,
    'videoProcessingUpdate',
    null,
  );
  let isUpdated = false;
  if (videoProcessingUpdate) {
    Object.entries(videoProcessingUpdate).forEach(([chId, message]) => {
      if (
        !_.isEqual(message, _.get(prev_videoProcessingUpdate, [chId], null))
      ) {
        isUpdated = true;
        cb(message);
      }
    });
  }

  return isUpdated;
};
