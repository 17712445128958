import request from '@/utils/request';
import { getIdToken } from '@/utils/utils';
import type { FetchFunction } from 'relay-runtime';

declare const DF_SERVICES_API_ENDPOINT: string;

const relayGraphQlFetchFunction: FetchFunction = async (params, variables) => {
  const token = await getIdToken();

  const response = await request(`${DF_SERVICES_API_ENDPOINT}/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ['Content-Type']: 'application/json',
    },
    data: {
      query: params.text,
      variables,
    },
  });

  return response;
};

export { relayGraphQlFetchFunction };
