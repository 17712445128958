import { recordTransaction } from '@/monitoring';
import { getCurrentCustomerID } from '@/utils/utils';
import _ from 'lodash';
import { useSelector } from 'umi';

// Track signaling times via our monitoring system
const SIGNALING_SUCCESS = 'webrtc_signaling_success';
const SIGNALING_FAILURE = 'webrtc_signaling_failure';

export const negotiate = async (
  pc: RTCPeerConnection,
  baseStationID: string,
  channelID: string,
  timer: null | number,
) => {
  pc.addTransceiver('video', { direction: 'recvonly' });

  try {
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    await new Promise<void>((resolve) => {
      const checkState = () => {
        if (pc.iceGatheringState === 'complete') {
          pc.removeEventListener('icegatheringstatechange', checkState);
          resolve();
        }
      };
      pc.addEventListener('icegatheringstatechange', checkState);
      //Check if the state is already complete
      checkState();
    });

    const signaling_url = `https://${baseStationID}.${PROXY_SUB_DOMAIN}.dragonfruit.ai/webrtc_signaling?channel_id=${channelID}`;

    const response = await fetch(signaling_url, {
      body: JSON.stringify({
        sdp: pc.localDescription!.sdp,
        type: pc.localDescription!.type,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!response.ok) {
      const text = await response.text();
      //Track signaling failure time
      if (timer) {
        const duration = Date.now() - timer;
        recordTransaction(SIGNALING_FAILURE, duration);
      }
      throw new Error(`Status ${response.status}: ${text}`);
    }

    //Track signaling success time
    if (timer) {
      const duration = Date.now() - timer;
      recordTransaction(SIGNALING_SUCCESS, duration);
    }

    const answer = await response.json();
    await pc.setRemoteDescription(answer);
  } catch (e: any) {
    throw new Error(
      `Negotiation failed ${_.get(e, 'message') ? ': ' + e.message : ''}`,
    );
  }
};

export const STUN_SERVER_URL =
  ENVIRONMENT === 'production'
    ? 'stun:coturn.dragonfruit.ai:3478'
    : 'stun:stage-coturn.dragonfruit.ai:3478';

export const TURN_SERVER_URL =
  ENVIRONMENT === 'production'
    ? 'turn:coturn.dragonfruit.ai:3478'
    : 'turn:stage-coturn.dragonfruit.ai:3478';

export const useStunServerConfig = () => {
  return {
    urls: STUN_SERVER_URL,
  };
};

export const useTurnServerConfig = () => {
  const customerID = getCurrentCustomerID();
  const currentUser = useSelector((state) => state.user.currentUser);
  const config = _.get(currentUser, `Customers[${customerID}].Customer.Config`);

  if (!config || !config.TurnServerCredential || !config.TurnServerUsername) {
    return null;
  }

  return {
    urls: TURN_SERVER_URL,
    username: config.TurnServerUsername,
    credential: config.TurnServerCredential,
  };
};
