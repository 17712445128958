import type { DependencyList } from 'react';
import { useEffect } from 'react';
import { useMonitorContext } from './MonitorContext';

const useMonitorAppSubHeaderAuxControls = (
  auxControls: JSX.Element,
  deps: DependencyList,
) => {
  const { setSubHeaderAuxControls } = useMonitorContext();

  useEffect(() => {
    setSubHeaderAuxControls(auxControls);

    return () => {
      setSubHeaderAuxControls(undefined);
    };
  }, [setSubHeaderAuxControls, ...deps]);
};

export { useMonitorAppSubHeaderAuxControls };
