import { AppstoreOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Pagination, Radio, Row, Space, Table } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { downloadCSV, getCurrentCustomerID } from '@/utils/utils';
import LoadingSpinner from '../LoadingSpinner';
import {
  convertToCSV,
  DataListProps,
  filterDataList,
  getAppliedFiltersFromColumns,
  getAppliedSortFromColumns,
  getFormattedColumns,
  getPersistenceHelpers,
  paginateDataList,
  sortDataList,
} from './utils';

const TableView = ({
  columns,
  dataList,
  onChange,
  isLoading = false,
  ...other_props
}) => {
  return (
    <Table
      size="small"
      dataSource={dataList}
      columns={columns}
      pagination={false}
      loading={
        isLoading
          ? {
              indicator: <LoadingSpinner />,
            }
          : false
      }
      onChange={onChange}
      {...other_props}
    />
  );
};

const CardView = ({ cardMap, dataList, isLoading = false }) => {
  if (isLoading) {
    return <LoadingSpinner minHeight="200px" />;
  }
  if (dataList.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <Row gutter={16}>
      {dataList.map((item, index) => (
        <Col key={item.key} style={{ minWidth: '160px', padding: '4px' }}>
          {cardMap.content(item, index)}
        </Col>
      ))}
    </Row>
  );
};

const DefaultViewSwitcher = ({
  handleViewChange,
  currentView,
  canApplyFilters,
  areFiltersApplied,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <>
        {canApplyFilters &&
          currentView == 'card' &&
          (areFiltersApplied ? (
            <span className="df-warn-text" style={{ margin: '5px' }}>
              Filters applied. Switch to Table View to change filters.
            </span>
          ) : (
            <span style={{ margin: '5px' }}>
              Switch to Table View to apply filters.
            </span>
          ))}
      </>

      <Radio.Group
        defaultValue={currentView}
        buttonStyle="solid"
        onChange={handleViewChange}>
        <Radio.Button value="table">
          <TableOutlined />
        </Radio.Button>
        <Radio.Button value="card">
          <AppstoreOutlined />
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

const DataList: React.FC<DataListProps> = ({
  cardMap,
  columns,
  dataList = [],
  isLoading = false,
  isHidden = false, //this is required to keep the component mounted, but hidden when required
  name = null,
  pagination,
  onChange,
  ViewSwitcher = DefaultViewSwitcher,
  isControlled = false,
  exportedFilename,
  ...other_props
}) => {
  const peristenceHelpers = useMemo(
    () => getPersistenceHelpers(getCurrentCustomerID(), name),
    [],
  );

  //Local states
  const [view, setView] = useState(() => {
    return peristenceHelpers.getView() || 'table';
  });
  const [filters, setFilters] = useState(() => {
    return {
      ...getAppliedFiltersFromColumns(columns),
      ...peristenceHelpers.getAppliedFilters(),
    };
  });
  const [sort, setSort] = useState(() => {
    return {
      ...getAppliedSortFromColumns(columns),
    };
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(null);

  //Local computations
  let paginationInfo = {
    p_size: _.get(pagination, 'p_size', pageSize || dataList.length),
    p_number: _.get(pagination, 'p_number', currentPage),
    position: _.get(pagination, 'position', ['bottomLeft']),
  };

  const antdColumns = useMemo(() => {
    return getFormattedColumns(columns, dataList, filters, sort);
  }, [columns, dataList, filters, sort, isControlled]);

  const [currentDatalist, filteredListSize] = useMemo(() => {
    if (isControlled) {
      // Filtering and pagination handled by parent component
      return [dataList, dataList.length];
    } else {
      let sortedAndFilteredList = sortDataList(
        filterDataList(dataList, antdColumns),
        antdColumns,
      );
      let currentList = sortedAndFilteredList;
      if (pagination) {
        currentList = paginateDataList(
          sortedAndFilteredList,
          paginationInfo.p_number,
          paginationInfo.p_size,
        );
      }
      return [currentList, sortedAndFilteredList.length];
    }
  }, [dataList, antdColumns, pagination, currentPage, pageSize]);

  paginationInfo['total_pages'] = _.get(
    pagination,
    'total_pages',
    paginationInfo.p_size
      ? Math.ceil(filteredListSize / paginationInfo.p_size)
      : 0,
  );

  //Handlers
  const fetchData = (newPagination = {}, newFilters = {}, newSorter = null) => {
    setFilters({ ...filters, ...newFilters });
    if (newSorter) {
      setSort(newSorter);
    }
    if (newPagination.p_number) {
      setCurrentPage(newPagination.p_number);
    }
    if (newPagination.p_size) {
      setPageSize(newPagination.p_size);
    }

    if (onChange) {
      onChange(
        {
          p_size: newPagination.p_size || pagination?.p_size || pageSize,
          p_number:
            newPagination.p_number || pagination?.p_number || currentPage,
        },
        { ...filters, ...newFilters },
        newSorter || sort,
      );
    }
  };

  //Effects
  useEffect(() => {
    if (isControlled) {
      fetchData({}, filters);
    }
  }, []);

  //Persist selections
  useEffect(() => {
    peristenceHelpers.saveAppliedFilters(filters);
  }, [peristenceHelpers, filters]);
  useEffect(() => {
    peristenceHelpers.saveView(view);
  }, [peristenceHelpers, view]);

  if (isHidden) {
    return null;
  }

  // If the current filteredValues are not all null, it means filters are active
  const hasActiveFilters = (curFilteredValues: any) => {
    return !Object.values(curFilteredValues).every((value) => value === null);
  };

  const handleViewChange = (e) => {
    setView(e.target.value);
  };

  const paginationHtml = pagination && (
    <Pagination
      current={paginationInfo.p_number}
      total={paginationInfo.total_pages * paginationInfo.p_size}
      pageSize={paginationInfo.p_size}
      showSizeChanger={_.get(pagination, 'showSizeChanger', false)}
      pageSizeOptions={pagination.pageSizeOptions}
      onChange={(p_number, p_size) => {
        fetchData({ p_number, p_size });
      }}
    />
  );

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: '100%', verticalAlign: 'top' }}>
        {
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {paginationHtml && paginationInfo.position.includes('topLeft')
              ? paginationHtml
              : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '8px',
              }}>
              {cardMap && (
                <ViewSwitcher
                  handleViewChange={handleViewChange}
                  currentView={view}
                  canApplyFilters={false}
                  areFiltersApplied={hasActiveFilters(filters)}
                />
              )}
              {exportedFilename && (
                <Button
                  type="default"
                  disabled={!currentDatalist?.length}
                  onClick={() => {
                    const csvData = convertToCSV(currentDatalist, columns);
                    downloadCSV(csvData, `${exportedFilename}.csv`);
                  }}>
                  Download CSV
                </Button>
              )}
            </div>
          </div>
        }
        {view === 'table' ? (
          <TableView
            columns={antdColumns}
            dataList={currentDatalist}
            onChange={(_pagination, newFilters, sorter) => {
              setFilters(newFilters);
              setSort(sorter);
              fetchData({ p_number: 1 }, newFilters, sorter);
            }}
            isLoading={isLoading}
            {...other_props}
          />
        ) : (
          <CardView
            cardMap={cardMap}
            dataList={currentDatalist}
            isLoading={isLoading}
          />
        )}
        {paginationHtml && paginationInfo.position.includes('bottomLeft') ? (
          <div style={{ textAlign: 'left' }}>{paginationHtml}</div>
        ) : null}
      </Space>
    </>
  );
};

export default DataList;
