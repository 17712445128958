import { LeftOutlined } from '@ant-design/icons';
import { Divider, Flex } from 'antd';
import React from 'react';
import {
  BackPathLink,
  DfSubHeaderContainer,
  SubHeaderTabTitle,
} from './styles';

interface SubHeaderProps {
  backPath?: string;
  title?: React.ReactNode;
  tabs?: React.ReactNode;
  auxControls?: React.ReactNode;
}

interface SubHeaderTab {
  title: string;
  onClick: () => void;
}

interface SubHeaderTabsProps {
  tabs: SubHeaderTab[];
  activeTabIndex?: number;
}

const SubHeaderTabs = ({ tabs, activeTabIndex = 0 }: SubHeaderTabsProps) => {
  return (
    <Flex gap={20} align="center">
      {tabs.map(({ title, onClick }, index) => {
        return (
          <SubHeaderTabTitle
            onClick={onClick}
            key={title}
            active={activeTabIndex == index}>
            {title}
          </SubHeaderTabTitle>
        );
      })}
    </Flex>
  );
};

const SubHeader = ({
  backPath = '/home',
  title,
  tabs,
  auxControls,
}: SubHeaderProps) => {
  const hasHeaderControls = !!tabs || !auxControls;
  return (
    <DfSubHeaderContainer>
      <Flex gap={20} align="center">
        <Flex gap={18}>
          <BackPathLink to={backPath}>
            <LeftOutlined />
          </BackPathLink>
          {title}
        </Flex>
        {hasHeaderControls && (
          <Divider type="vertical" style={{ height: '28px' }} />
        )}
        <Flex justify="space-between" flex={1}>
          {tabs}
          {auxControls}
        </Flex>
      </Flex>
    </DfSubHeaderContainer>
  );
};

export { SubHeader, SubHeaderTabs };
