import LoadingSpinner from '@/components/LoadingSpinner';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Layout, Modal, Typography } from 'antd';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { usePaginationFragment } from 'react-relay';
import type {
  SceneFragment$data,
  SceneFragment$key,
} from '../components/Scene/__generated__/SceneFragment.graphql';
import { useMonitorContext } from '../MonitorContext';
import { useSceneAddActions, useSceneUpdateActions } from '../MonitorMutations';
import { TeamScenesFragment } from '../TeamScenes';
import type { TeamScenesFragment$key } from '../TeamScenes/__generated__/TeamScenesFragment.graphql';
import { useMonitorAppSubHeaderAuxControls } from '../useMonitorAppSubHeaderAuxControls';
import { ChannelsSelection } from './ChannelsSelection';
import { SceneTimeline } from './SceneTimeline';
import { ActiveSceneContainer } from './styles';

const { Sider } = Layout;

const ActiveSceneContent = () => {
  const {
    appId,
    selectedChannels,
    selectedSceneData,
    isSidebarHidden,
    toggleSidebarVisibility,
    monitorAppQueryData,
    setSelectedSceneRef,
  } = useMonitorContext();
  const timelinePlayerRef = useRef();

  const getAnchorTimestamp = () => {
    // @ts-expect-error
    let timestamp = timelinePlayerRef.current?.state.currentPlayTime;
    if (timestamp) {
      // db doesn't like floats...
      timestamp = Math.floor(timestamp) + '';
    }

    return timestamp;
  };

  const [saveSceneModalOpen, setSaveSceneModalOpen] = useState(false);

  const areNewChannelsAddToScene = useMemo(() => {
    if (!!selectedSceneData) {
      return (
        selectedSceneData.channelIDs.length !== (selectedChannels ?? []).length
      );
    }

    return (selectedChannels ?? []).length > 0;
  }, [selectedChannels, selectedSceneData]);

  const viewingExistingScene = !!selectedSceneData;

  const { data: fragmentData } = usePaginationFragment(
    TeamScenesFragment,
    monitorAppQueryData.vmsPlusConfig?.scenesData as TeamScenesFragment$key,
  );

  const newSceneNode = useRef(
    fragmentData.scenes?.edges?.[0]?.node as SceneFragment$key,
  );

  useEffect(() => {
    newSceneNode.current = fragmentData.scenes?.edges?.[0]
      ?.node as SceneFragment$key;
  }, [fragmentData.scenes?.edges]);

  useMonitorAppSubHeaderAuxControls(
    <Flex gap={20}>
      {areNewChannelsAddToScene && (
        <Button onClick={() => setSaveSceneModalOpen(true)} type="primary">
          {viewingExistingScene ? 'Save Scene' : 'Create Scene'}
        </Button>
      )}
      {isSidebarHidden ? (
        <MenuFoldOutlined
          onClick={toggleSidebarVisibility}
          style={{ fontSize: '24px' }}
        />
      ) : (
        <MenuUnfoldOutlined
          onClick={toggleSidebarVisibility}
          style={{ fontSize: '24px' }}
        />
      )}
    </Flex>,
    [areNewChannelsAddToScene, isSidebarHidden],
  );

  const { updateSceneNameAndChannels } = useSceneUpdateActions({
    scene: selectedSceneData as SceneFragment$data,
    appId,
  });

  const { addScene } = useSceneAddActions({
    appId,
    sceneConnectionData: {
      id: monitorAppQueryData.vmsPlusConfig?.scenesData?.__id as string,
      key: 'VMSPlusConfig_scenes',
    },
  });

  const [updatedSceneName, setUpdatedSceneName] = useState(
    selectedSceneData?.name || 'New scene',
  );

  const handleSceneCreateUpdateViaModal = () => {
    if (viewingExistingScene) {
      updateSceneNameAndChannels(
        updatedSceneName,
        selectedChannels as string[],
        getAnchorTimestamp(),
      );
    } else {
      addScene({
        channelIds: selectedChannels as string[],
        name: updatedSceneName,
        anchorTimeStamp: getAnchorTimestamp(),
        onSceneAdded: () => {
          setSelectedSceneRef(newSceneNode.current);
        },
      });
    }
    setSaveSceneModalOpen(false);
  };

  const saveSceneModalContent = (
    <Modal
      open={saveSceneModalOpen}
      onCancel={() => setSaveSceneModalOpen(false)}
      title={viewingExistingScene ? 'Save Scene' : 'Create Scene'}
      footer={null}
      centered>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography.Text>
          Save this scene for quick viewing later under the ‘My Scenes’ tab.
        </Typography.Text>
        <Input
          value={updatedSceneName}
          onChange={(e) => setUpdatedSceneName(e.target.value)}
        />
        <div style={{ display: 'flex', gap: '20px' }}>
          <Button
            onClick={() => setSaveSceneModalOpen(false)}
            style={{ width: '100%' }}>
            Discard Changes
          </Button>
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={handleSceneCreateUpdateViaModal}>
            {viewingExistingScene ? 'Save Scene' : 'Create Scene'}
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <ActiveSceneContainer>
      <SceneTimeline timelinePlayerRef={timelinePlayerRef} />
      <Sider
        trigger={null}
        breakpoint="md"
        collapsedWidth="0"
        collapsible={true}
        collapsed={isSidebarHidden}
        width={282}
        style={{ background: 'transparent' }}>
        <ChannelsSelection />
      </Sider>
      {saveSceneModalContent}
    </ActiveSceneContainer>
  );
};

const ActiveScene = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ActiveSceneContent />
    </Suspense>
  );
};

export default ActiveScene;
