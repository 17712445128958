import { Button, Divider, Form, Input, Select } from 'antd';
import _ from 'lodash';
import { connect } from 'umi';
import ExternalDataApp from './ExternalDataApp';
import styles from './style.less';

// @ts-expect-error
@connect(({ loading, locations }) => {
  const { loc, ch } = locations;
  return {
    loadingApp: loading.effects['apps/doAppOp'] || false,
    loadingFetch: loading.effects['apps/fetchApp'],
    locations,
    loc,
    ch,
  };
})
class POSApp extends ExternalDataApp {
  static appID = 53;

  static dataSpec = {
    ReceiptNumber: {
      name: 'Transaction ID',
      align: 'center',
      responsive: ['xl'],
    },
    StoreID: { name: 'Store ID', isFilterable: true, align: 'center' },
    WorkstationID: {
      name: 'Terminal ID',
      align: 'center',
      isFilterable: true,
      responsive: ['xl'],
    },
    OperatorIDs: { name: 'Cashier ID', align: 'center', isFilterable: true },
    ReceiptDateTime: { name: 'Timestamp' },
    TransactionType: {
      name: 'Transaction Type',
      align: 'center',
      isFilterable: true,
    },
    TransactionStatus: {
      name: 'Transaction Status',
      align: 'center',
      isFilterable: true,
      responsive: ['xl'],
    },
    PaymentType: { name: 'Payment Type', align: 'center', isFilterable: true },
    LineItemsTotal: {
      name: 'Line Items Total',
      align: 'right',
      render: (m, t) =>
        new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: t.CurrencyCode || 'USD',
        }).format(m),
    },
    TransactionTotal: {
      name: 'Transaction Total',
      align: 'right',
      isFilterable: false,
      render: (m, t) =>
        new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: t.CurrencyCode || 'USD',
        }).format(m),
    },
    TaxAmount: {
      name: 'Tax Amount',
      align: 'right',
      render: (m, t) =>
        new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: t.CurrencyCode || 'USD',
        }).format(m),
    },
    SalesAssociateID: {
      name: 'Sales Associate ID',
      align: 'center',
      isFilterable: true,
    },
    Category: { name: 'Category', align: 'center', isFilterable: true },
    CurrencyCode: { name: 'Currency Code', align: 'center' },
    DivisionID: { name: 'Division ID', align: 'center', isFilterable: true },
    TransactionSubType: {
      name: 'Transaction Subtype',
      align: 'center',
      isFilterable: true,
    },
    VoidedReceiptNumber: { name: 'Voided Transaction ID', align: 'center' },
  };

  constructor(props) {
    super(props);

    this.state.columnList = [
      'eye',
      'ReceiptNumber',
      'StoreID',
      'WorkstationID',
      'OperatorIDs',
      'TransactionType',
      'PaymentType',
      'TransactionTotal',
      'sourceProjectName',
      'sourceChannelName',
      'timeframeStartMoment',
    ];
  }

  // sidebar display
  getExtraActions(row) {
    // transaction info is in the row itself
    let t = row;
    let lineItems = _.get(t, 'lineItems') || [];

    let formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: t.CurrencyCode || 'USD',
    });

    let total = t.TransactionTotal;
    if (total < 0) {
      total = (
        <span style={{ color: 'red' }}>
          ({formatter.format(Math.abs(total))})
        </span>
      );
    } else {
      total = <span style={{ color: 'green' }}>{formatter.format(total)}</span>;
    }

    return (
      <>
        <div className={styles['receipt-ctn']}>
          <Divider plain="true" orientation="left" orientationMargin="0">
            {t.Category && <span>{t.Category} </span>}
            Transaction
            {t.TransactionSubType && <span> ({t.TransactionSubType})</span>}
          </Divider>

          <div className={styles['receipt']}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{t.StoreID}</div>
              {t.Division && <span> ({t.Division}) </span>}
              <div>{t.WorkstationID}</div>
            </div>

            <div style={{ fontSize: 14, fontWeight: 'bold', margin: '8px 0' }}>
              {total}
            </div>

            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>Receipt: {t.ReceiptNumber}</div>
                  <div>{t.ReceiptDateTime}</div>
                </div>
                {t.VoidedReceiptNumber && (
                  <div>Voided Receipt: {t.VoidedReceiptNumber}</div>
                )}
                <div>Cashier: {t.OperatorIDs}</div>
                {t.SalesAssociateID && (
                  <div>Sales Associate: {t.SalesAssociateID}</div>
                )}
              </div>
            </div>

            <table
              style={{
                width: '100%',
                margin: '8px 0',
                borderCollapse: 'separate',
                borderSpacing: 2,
                borderTop: '1px solid #ced4da',
                borderBottom: '1px solid #ced4da',
              }}>
              <tbody>
                <tr
                  style={{
                    fontWeight: 500,
                    borderBottom: '1px solid #ced4da',
                  }}>
                  <td style={{ textAlign: 'center' }}>Qty</td>
                  <td>
                    <div style={{ margin: '0 8px' }}>Description</div>
                  </td>
                  <td>Amount</td>
                </tr>
                {lineItems.map((item, i) => (
                  <tr key={i} style={{ margin: '2px 0' }}>
                    <td style={{ textAlign: 'right' }}>{item.Quantity}</td>
                    <td style={{ width: '100%' }}>
                      <div style={{ margin: '0 8px 0' }}>
                        <div>{item.Description}</div>
                        <div>{item.SKU}</div>
                      </div>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <nobr>{formatter.format(item.Price)}</nobr>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <table style={{ textAlign: 'right' }}>
                <tbody>
                  {!!t.LineItemsTotal && (
                    <tr>
                      <td style={{ width: '100%' }}>Subtotal:&nbsp;</td>
                      <td>{formatter.format(t.LineItemsTotal)}</td>
                    </tr>
                  )}
                  {!!t.TaxAmount && (
                    <tr>
                      <td style={{ width: '100%' }}>Tax:&nbsp;</td>
                      <td>
                        <nobr>{formatter.format(t.TaxAmount)}</nobr>
                      </td>
                    </tr>
                  )}
                  {!!t.TransactionTotal && (
                    <tr>
                      <td>Total:&nbsp;</td>
                      <td>
                        <nobr>{formatter.format(t.TransactionTotal)}</nobr>
                      </td>
                    </tr>
                  )}
                  {!!t.PaymentType && (
                    <tr>
                      <td>Paid {t.PaymentType}:&nbsp;</td>
                      <td>{formatter.format(t.TransactionTotal)}</td>
                    </tr>
                  )}
                  {!!t.TransactionStatus && (
                    <tr>
                      <td>Transaction Status:&nbsp;</td>
                      <td>{t.TransactionStatus}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderConfigurationImpl() {
    return (
      <Form
        layout="vertical"
        colon={false}
        requiredMark={false}
        ref={this.configForm}
        initialValues={this.state.config}
        onSubmit={(e) => this.handleConfigSave(e)}>
        <Divider orientation="left" orientationMargin="0">
          <div>Data</div>
        </Divider>
        <div>Upload point-of-sale data.</div>
        <div style={{ margin: '16px 0', maxWidth: '350px' }}>
          <Form.Item name="transactions" label="Transactions">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="line_items" label="Line Items">
            <Input.TextArea rows={4} />
          </Form.Item>
        </div>

        <Divider orientation="left" orientationMargin="0">
          <div>Settings</div>
        </Divider>
        <div />
        <div style={{ margin: '16px 0', maxWidth: '350px' }}>
          <Form.Item name="table_columns" label="Table Columns">
            <Select placeholder="Select Columns" mode="multiple">
              {Object.entries(this.constructor.dataSpec).map(([type, info]) => (
                <Select.Option key={type} value={type}>
                  {info.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            loading={this.props.loadingApp}
            type="primary"
            onClick={(e) => this.handleConfigSave(e)}>
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default POSApp;
