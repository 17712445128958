import styled from 'styled-components';

export const MonitorAppContainer = styled.div(
  ({ theme }) => `
  padding: 18px;
  background-color: ${theme.token?.colorBgLayout};
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`,
);
