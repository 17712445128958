import Timeline from '@/components/Timeline';
import {
  CueFactory,
  getArchiveVideoRetentionPeriod,
  getDefaultCueRange,
  SKIP_END_OVERRIDE,
} from '@/utils/timeline';
import TIMINGSRC from '@/utils/timingsrc/timingsrc-v3';
import {
  CUE_TYPE,
  getActualTimeFromESTime,
  getActualVideoEndSecs,
  getActualVideoStartSecs,
  getPreciseInterval,
  getStreamTypeFromKey,
  isInternalUser,
  META_VMS_PLUGIN_ID,
} from '@/utils/utils';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';
import withChannelMediaFetching from './withChannelMediaManagement';

type MyProps = {
  dispatch?: any;
  autoPlay?: boolean;
  ch?: any;
  loc?: any;
  base_stn?: any;
  installationsByID?: any;
  videoProcessingUpdate?: any;
  channelIDs?: number[];
  channelStreamTypeKeys?: any;
  currentUser?: any;
  endTime?: number;
  events?: any;
  //===== ChannelID
  //===== type
  //===== EventStart
  //===== EventEnd
  //===== AnchorTime
  //===== bboxes
  //===== Media
  eventsOnly?: any;
  fitEventsOnAxis?: any;
  innerRef?: any;
  layoutSpec?: any;
  restrictHistoryToStartEnd?: boolean;
  searchForm?: any;
  showHideControls?: boolean;
  showLink?: boolean;
  showLive?: boolean;
  showShare?: boolean;
  showTimelineActions?: boolean;
  startTime?: number;
  streamChangeCallback?: any;
  channelMediaMap: Record<number, { ChannelID: number; Media: any[] }>;
  isMediaLoading: boolean;
  isMediaLoadingQueued: boolean;
  fetchMedia: Function;
};

type MyState = {
  channelTasksMap: any;
  cues: any;
  cuesRange: [] | undefined;
  events: [];
  shareContext: any;
  showLive: any;
  startTime: any;
  timezone: any;
};

// @ts-expect-error
@connect(({ locations, user }) => {
  const connect_props: any = {
    currentUser: user.currentUser,
    ch: locations.ch,
    loc: locations.loc,
    base_stn: locations.base_stn,
    installationsByID: locations.installationsByID,
  };
  return connect_props;
})
class TimelinePlayer extends React.Component<MyProps, MyState> {
  public static defaultProps = {
    innerRef: React.createRef(),
  };
  constructor(props: MyProps) {
    super(props);
    this.state = {
      startTime: this.props.startTime,
      cues: undefined,
      cuesRange: undefined,
      events: [],
      channelTasksMap: {},
      showLive: false,
      timezone: undefined,
      shareContext: {},
    };
    this.loadHistory = this.loadHistory.bind(this);
  }

  componentDidMount() {
    this.setupEvents();
  }

  componentDidUpdate(prevProps: MyProps) {
    const { events, channelIDs, startTime, channelMediaMap } = this.props;
    if (
      !_.isEqual(events, prevProps.events) ||
      !_.isEqual(channelIDs, prevProps.channelIDs)
    ) {
      this.setupEvents();
    }
    if (!_.isEqual(channelIDs, prevProps.channelIDs)) {
      // if channel changes go to live
      this.setState({ startTime: Date.now() / 1000 });
      this.initCues();
    }
    if (startTime && startTime !== prevProps.startTime) {
      this.setState({ startTime }, () => this.setupEvents());
    }
    if (!_.isEqual(channelMediaMap, prevProps.channelMediaMap)) {
      this.initCues();
    }
  }

  setupEvents() {
    const { startTime, endTime, channelIDs } = this.props;
    // if channelids are passed in explicitly, load them up
    if (!_.isEmpty(channelIDs)) {
      channelIDs.forEach((channelID: any) => {
        // kick off history discovery. startTime and endTime can be null,
        // in which case we get some recent history to anchor our initial timeline
        this.props.fetchMedia(channelID, startTime, endTime);
      });
    } else {
      this.initCues();
    }
  }

  loadHistory(axisRange: []) {
    const { channelIDs, startTime, endTime, restrictHistoryToStartEnd } =
      this.props;

    // we need to have channels to load history
    if (_.isEmpty(channelIDs)) return;

    // do we have bounds for an axis yet
    if (_.get(axisRange, 'length', 0) !== 2) return;

    // if we've already loaded some cues, and if the cues cover both
    // the start and end intervals, we assume that there are no more
    // cues to load

    channelIDs.forEach((channelID: any) => {
      let start = axisRange[0];
      let end = axisRange[1];

      if (restrictHistoryToStartEnd) {
        start = startTime;
        end = endTime;
      }
      this.props.fetchMedia(channelID, start, end);
    });
  }

  initCues() {
    // Create Cues form events
    const cues: any = [];
    const mediaDates: any[] = [];

    const {
      showTimelineActions,
      channelIDs,
      showLive,
      eventsOnly,
      ch,
      loc,
      channelStreamTypeKeys = {},
      streamChangeCallback,
      events,
      currentUser,
      base_stn,
      installationsByID,
      channelMediaMap,
    } = this.props;
    const { channelTasksMap } = this.state;

    let startTime: number;
    let tlTimezone: string | undefined;

    const relevantChannelMedia: any = {};
    const relevantChannelTasks: any = {};

    // channelids lists might change over time
    _.forEach(channelIDs, (channelID) => {
      relevantChannelMedia[channelID] = channelMediaMap[channelID];
      relevantChannelTasks[channelID] = channelTasksMap[channelID];
    });

    const allEvents = [
      ...(events || []),
      ...Object.values(relevantChannelMedia).filter((x) => x),
      ..._.flatten(Object.values(relevantChannelTasks || {})).filter((x) => x),
    ];

    const allChannelIDs = new Set(channelIDs);
    const shareContext: { channelIDs: any[] } = { channelIDs: [] };
    allEvents
      .filter((a) => a)
      .forEach((event) => {
        // Event types and cue types are same as they are one to one mapping
        const channelID = event.ChannelID;
        const channel = _.get(ch, `byId[${channelID}]`);
        // if timezone is available
        let timezone =
          _.get(event, 'Timezone') ||
          _.get(event, 'Channel.Timezone') ||
          _.get(channel, 'Timezone');

        // Timeline supports multiple tracks, and it's possible that each channel
        // will be in a different timezone. if so, we should just use UTC as the
        // common timezone, else we whould try to use the one that corresponds to
        // the channels
        if (tlTimezone === undefined) {
          // set it the first time
          tlTimezone = timezone;
        } else if (timezone && tlTimezone !== timezone) {
          // if we have multiple events with different timezones,
          // use the current timezone as preference
          tlTimezone = moment.tz.guess();
        }
        if (channelID) {
          // some events e.g. heatmaps might not have channels
          allChannelIDs.add(channelID);

          // it might not be provided, then get it from ch object
          // however, this will only be available if we're logged in - so won't
          // work when we have a public endpoint.
          if (!timezone) timezone = _.get(channel, 'Timezone');

          // this is used by the share widget. the timezone is important since
          // it determines how the shared url will displayed
          if (
            !shareContext.channelIDs.find(
              (info: any) => info.channelID === channelID,
            )
          ) {
            shareContext.channelIDs.push({
              channelID,
              timezone,
            });
          }
        }

        let eventStartEpoch: number;
        let eventEndEpoch: number;

        // event.type;
        //  SEARCH_RESULT, HEATMAP, INSIGHT, PENDING_UPLOAD, INVESTIGATION_EVENTS, CHANNEL_MEDIA, CHANNEL_TASKS

        // tweak the start/end times based on events, but skip some events
        // that shouldn't be messing with overall start/end times
        if (event.EventStart && !_.includes(SKIP_END_OVERRIDE, event.type)) {
          eventStartEpoch =
            getActualTimeFromESTime(event.EventStart, timezone).valueOf() /
            1000;
          eventEndEpoch =
            getActualTimeFromESTime(event.EventEnd, timezone).valueOf() / 1000;

          mediaDates.push(eventStartEpoch, eventEndEpoch);

          let newStartUTC = eventStartEpoch;
          if (event.AnchorTime) {
            newStartUTC =
              getActualTimeFromESTime(event.AnchorTime, timezone).valueOf() /
              1000;
          }
          if (!startTime || newStartUTC < startTime) {
            startTime = newStartUTC;
          }
        }

        const bboxes = (event.bboxes || []).sort(
          (a: { timestamp: number }, b: { timestamp: number }) =>
            a.timestamp < b.timestamp ? -1 : 1,
        );

        // optimization: From/To are in seconds, and the bbox seek is
        // in milliseconds. so, the first bbox we'll get will invariably be
        // later than the From. so, if the video starts of at the From second
        // boundary, the bbox is guaranteed to be a bit off. so we tweak the
        // eventStart to correspond to the bbox boundary, if we're only off
        // by a bit
        const earliestTimestamp = bboxes.length && bboxes[0].timestamp;
        if (earliestTimestamp && earliestTimestamp - startTime < 2) {
          startTime = earliestTimestamp;
        }

        if (event.type == CUE_TYPE.JOURNEY) {
          cues.push(
            CueFactory.getCue({
              interval: new TIMINGSRC.Interval(0, Infinity),
              data: {
                type: event.type,
                journeyData: event.data.journeyDetails,
                mapId: event.data.mapId,
              },
            }),
          );
        }

        // if there is no Media, apply to the whole interval if it's the
        // kind of cuetype that supports full tracks
        if (!event.Media && _.includes(SKIP_END_OVERRIDE, event.type)) {
          cues.push(
            CueFactory.getCue({
              interval: new TIMINGSRC.Interval(0, Infinity),
              data: {
                type: event.type,
                container: this,
                event,
              },
            }),
          );
        }

        event.Media?.forEach((media: { bboxes: any }) => {
          const actualVideoStartSecs = getActualVideoStartSecs(media, timezone);
          const actualVideoEndSecs = getActualVideoEndSecs(media, timezone);
          if (!actualVideoStartSecs) return;

          let startEdge = actualVideoStartSecs;
          let endEdge = actualVideoEndSecs;

          if (event.EventStart) {
            startEdge = Math.max(actualVideoStartSecs, eventStartEpoch);
            endEdge = Math.min(actualVideoEndSecs, eventEndEpoch);
          }

          if (startEdge > endEdge) return;

          // do not consider media from task for cuesRange calculation
          if (event.type === undefined) {
            mediaDates.push(startEdge, endEdge);
          }

          const videoFile =
            _.get(media, 'TranscodedVideo') || _.get(media, 'UploadedVideo');
          // signed url might not exist e.g. when a clip is shared publicly
          // as a result of an event occuring, where we get the search results
          // but those results don't contain video with signed urls
          // Show uploaded video until transcding is happening
          const videoURL = _.get(videoFile, 'SignedUrl');
          const transcodedVideoDimension = {
            width: _.get(videoFile, 'Width'),
            height: _.get(videoFile, 'Height'),
          };

          media.bboxes = bboxes.filter((bbox: { timestamp: number }) => {
            return bbox.timestamp <= endEdge && bbox.timestamp >= startEdge;
          });
          let cueType = event.type;
          const fileInterval = getPreciseInterval(startEdge, endEdge);
          if (cueType === undefined) {
            if (videoURL === undefined) {
              cueType = CUE_TYPE.CLOUD_THUMBNAIL;
            } else {
              cueType = CUE_TYPE.CLOUD_VIDEO;
            }
          }
          cues.push(
            CueFactory.getCue({
              interval: fileInterval,
              data: {
                type: cueType,
                container: this,
                event,
                channel,
                channelID,
                startEdge,
                endEdge,
                eventsOnly,
                media,
                showTimelineActions,
                timezone,
                transcodedVideoDimension,
                actualVideoStartSecs,
                actualVideoEndSecs,
                videoURL,
              },
            }),
          );
        });
      });

    // This code will conver each track from a segment-by-segment video playback
    // to an HLS based playback, which will work natively on Safari. It needs
    // more debugging.
    //
    //     let trackInfo = {};
    //     _.forEach(cues, cue => {
    //       let info = trackInfo[cue.data.track] || { cues: [] };
    //       info.startEdge = Math.min(info.startEdge || Infinity, cue.data.startEdge);
    //       info.endEdge = Math.max(info.endEdge || 0, cue.data.endEdge);
    //       info.cues.push(cue);
    //       trackInfo[cue.data.track] = info;
    //     });
    //
    //     let maxDuration = 0;
    //     _.forEach(Object.entries(trackInfo), ([track, info]) => {
    //       let segments = '';
    //       let sortedCues = _.sortBy(info.cues, cue => cue.data.startEdge);
    //       _.forEach(sortedCues, (cue, i) => {
    //         let duration = cue.data.endEdge - cue.data.startEdge;
    //         maxDuration = Math.max(maxDuration, duration);
    //         segments += `#EXTINF:${duration},\n${cue.data.videoURL}\n`;
    //       });
    //
    //       info.m3u = `#EXTM3U
    // #EXT-X-PLAYLIST-TYPE:VOD
    // #EXT-X-TARGETDURATION:${maxDuration}
    // #EXT-X-VERSION:4
    // #EXT-X-MEDIA-SEQUENCE:1
    // ${segments.trim()}
    // #EXT-X-ENDLIST`;
    //
    //       info.dataURI = `data:application/vnd.apple.mpegURL;base64,${btoa(info.m3u)}`;
    //     });
    //
    // _.forEach(cues, cue => {
    //   cue.data.m3uURL = trackInfo[cue.data.track].dataURI;
    //   cue.data.actualVideoStartSecs = trackInfo[cue.data.track].startEdge;
    // });

    // set the cues range to start from the beginning of the first
    // cue to the end of the last cue...
    mediaDates.sort();
    const cuesRange = mediaDates.length
      ? [mediaDates[0], mediaDates[mediaDates.length - 1]]
      : getDefaultCueRange();

    // ======= ADD STREAMING CUES =======
    let hasLiveChannels = false;

    // for each channel, add a track to take care of live and archive viewing.
    // it provides a backing track for the entire timeline, and is used to display
    // either the live feed (present and future) or messaging on how to view the
    // archive video (past).
    // currently, the archive video can be viewed by triggering a 'pull' from the
    // VMSs, either Frontier or not
    allChannelIDs.forEach((channelID) => {
      // we show different things depending on user being logged in or not
      const isLoggedIn = _.get(currentUser, 'Email', false);

      const channel = _.get(ch, `byId[${channelID}]`);

      // this code should work even if you're not logged in - but the messaging
      // and display will be vague or precise based on what state we're in
      let vmsID;
      const locationID = _.get(ch, `byId[${channelID}].ProjectID`, null);
      if (locationID) vmsID = _.get(loc, `byId[${locationID}].VMSPluginID`);

      // note that if we don't get a locationid, this could be because we're not
      // logged in. assume it's a frontier channel for this case too.
      if (!locationID || vmsID === META_VMS_PLUGIN_ID) {
        hasLiveChannels = true;
      }

      const baseStnID = base_stn?.map_loc_baseStn.get(+locationID);
      const baseStn = base_stn?.byId[+(baseStnID || 0)];
      const installation = installationsByID[locationID];
      const appVersion = _.get(installation, 'AppVersion');
      const streamType = getStreamTypeFromKey(channelStreamTypeKeys[channelID]);

      // if we're restricted to showing only events, this cue for streaming is
      // restricted to the event time range. this is to avoid the possibility that
      // a bad actor uses this pathway to get to live streaming of the channel
      const allTime = getPreciseInterval(
        ...(eventsOnly ? cuesRange : [0, Infinity]),
      );

      const c = CueFactory.getCue({
        interval: allTime,
        data: {
          type: CUE_TYPE.STREAMING,
          container: this,
          channel,
          locationID,
          baseStationID: _.get(baseStn, 'SerialNumber'),
          baseStationWifiMAC: _.get(baseStn, 'WifiMAC'),
          channelID,
          eventsOnly,
          hasLiveChannels,
          appVersion,
          isLoggedIn,
          showLive,
          vmsID,
          streamChangeCallback,
          streamType,
          retentionPeriod: getArchiveVideoRetentionPeriod(ch.byId, channelID),
        },
      });
      cues.push(c);
    });

    // if we don't have a timezone set, use the current user's timezone
    if (!tlTimezone) tlTimezone = moment.tz.guess();

    this.setState({
      // if there's an explicit start time provided, use that in preference.
      startTime: this.props.startTime || startTime,
      timezone: tlTimezone,
      cues,
      cuesRange,
      shareContext,
      showLive: showLive && hasLiveChannels,
    });
  }

  render() {
    const { startTime, timezone, cues, cuesRange, shareContext, showLive } =
      this.state;

    const {
      autoPlay,
      eventsOnly,
      fitEventsOnAxis,
      layoutSpec,
      searchForm,
      showHideControls,
      showShare,
      showLink,
      currentUser,
      innerRef,
      isMediaLoading,
      isMediaLoadingQueued,
    } = this.props;

    const internalUser = isInternalUser(currentUser);

    if (eventsOnly && _.isEmpty(cues)) {
      return (
        <div>
          {isMediaLoading || isMediaLoadingQueued
            ? 'Loading...'
            : 'No relevant media found to display'}
        </div>
      );
    }

    return (
      <Timeline
        autoPlay={autoPlay}
        cues={cues}
        cuesRange={cuesRange}
        eventsOnly={eventsOnly}
        fitEventsOnAxis={fitEventsOnAxis}
        internalUser={internalUser}
        layoutSpec={layoutSpec}
        loadHistory={this.loadHistory}
        loadingHistory={isMediaLoading}
        ref={innerRef}
        searchForm={searchForm}
        shareContext={shareContext}
        showHideControls={showHideControls}
        showLink={showLink}
        showLive={showLive}
        showShare={showShare}
        startTime={startTime}
        timezone={timezone}
      />
    );
  }
}

export default withChannelMediaFetching(TimelinePlayer);
