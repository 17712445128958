/**
 * @generated SignedSource<<20046f54635abb9c4cec8ca870aed462>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MonitorMutations_SceneUpdate_Mutation$variables = {
  anchorTimeStamp?: string | null | undefined;
  app_id: number;
  channel_ids: ReadonlyArray<string>;
  customer_id: number;
  isFavorite?: boolean | null | undefined;
  name?: string | null | undefined;
  timestamp: string;
};
export type MonitorMutations_SceneUpdate_Mutation$data = {
  readonly createOrUpdateVmsPlusRecentChannels:
    | {
        readonly recentEdge:
          | {
              readonly node:
                | {
                    readonly anchorTimeStamp: string | null | undefined;
                    readonly channelIDs: ReadonlyArray<
                      string | null | undefined
                    >;
                    readonly id: string;
                    readonly isFavorite: boolean | null | undefined;
                    readonly name: string | null | undefined;
                    readonly timestamp: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MonitorMutations_SceneUpdate_Mutation = {
  response: MonitorMutations_SceneUpdate_Mutation$data;
  variables: MonitorMutations_SceneUpdate_Mutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'anchorTimeStamp',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'app_id',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'channel_ids',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'customer_id',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'isFavorite',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'name',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'timestamp',
    },
    v7 = [
      {
        alias: null,
        args: [
          {
            fields: [
              {
                kind: 'Variable',
                name: 'anchorTimeStamp',
                variableName: 'anchorTimeStamp',
              },
              {
                kind: 'Variable',
                name: 'appId',
                variableName: 'app_id',
              },
              {
                kind: 'Variable',
                name: 'channelIDs',
                variableName: 'channel_ids',
              },
              {
                kind: 'Variable',
                name: 'customerId',
                variableName: 'customer_id',
              },
              {
                kind: 'Variable',
                name: 'isFavorite',
                variableName: 'isFavorite',
              },
              {
                kind: 'Variable',
                name: 'name',
                variableName: 'name',
              },
              {
                kind: 'Variable',
                name: 'timestamp',
                variableName: 'timestamp',
              },
            ],
            kind: 'ObjectValue',
            name: 'input',
          },
        ],
        concreteType: 'CreateOrUpdateVMSPlusRecentChannelsPayload',
        kind: 'LinkedField',
        name: 'createOrUpdateVmsPlusRecentChannels',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'VMSPlusRecentChannelsEdge',
            kind: 'LinkedField',
            name: 'recentEdge',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'VMSPlusRecentChannels',
                kind: 'LinkedField',
                name: 'node',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'name',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'channelIDs',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'timestamp',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'isFavorite',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'anchorTimeStamp',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'MonitorMutations_SceneUpdate_Mutation',
      selections: v7 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v3 /*: any*/,
        v2 /*: any*/,
        v6 /*: any*/,
        v5 /*: any*/,
        v4 /*: any*/,
        v0 /*: any*/,
      ],
      kind: 'Operation',
      name: 'MonitorMutations_SceneUpdate_Mutation',
      selections: v7 /*: any*/,
    },
    params: {
      cacheID: '2a297b8725ef9d24470689cbdb2a50f6',
      id: null,
      metadata: {},
      name: 'MonitorMutations_SceneUpdate_Mutation',
      operationKind: 'mutation',
      text: 'mutation MonitorMutations_SceneUpdate_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $channel_ids: [String!]!\n  $timestamp: String!\n  $name: String\n  $isFavorite: Boolean\n  $anchorTimeStamp: String\n) {\n  createOrUpdateVmsPlusRecentChannels(input: {appId: $app_id, customerId: $customer_id, channelIDs: $channel_ids, timestamp: $timestamp, name: $name, isFavorite: $isFavorite, anchorTimeStamp: $anchorTimeStamp}) {\n    recentEdge {\n      node {\n        id\n        name\n        channelIDs\n        timestamp\n        isFavorite\n        anchorTimeStamp\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '853f2b0ff07ab3e12e36f1136b8614c4';

export default node;
