import _ from 'lodash';

class ThumbnailObj {
  FileFormat: string | null;
  FileName: string | null;
  FileSize: number | null;
  FileType = 'Thumbnail';
  Width: number | null;
  Height: number | null;
  SignedUrl: string | null;
  StorageClass: string | null;

  constructor(obj: any) {
    this.FileFormat = _.get(obj, 'FileFormat', null);
    this.FileName = _.get(obj, 'FileName', null);
    this.FileSize = _.get(obj, 'FileSize', null);
    this.Width = _.get(obj, 'Width', null);
    this.Height = _.get(obj, 'Height', null);
    this.SignedUrl = _.get(obj, 'SignedUrl', null);
    this.StorageClass = _.get(obj, 'StorageClass', null);
  }
}

class ThumbnailStripObj {
  FileFormat: string | null;
  FileName: string | null;
  FileSize: number | null;
  FileType = 'ImageStrip';
  Width: number | null;
  Height: number | null;
  NumImages: number | null;
  SignedUrl: string | null;
  StorageClass: string | null;

  constructor(obj: any) {
    this.FileFormat = _.get(obj, 'FileFormat', null);
    this.FileName = _.get(obj, 'FileName', null);
    this.FileSize = _.get(obj, 'FileSize', null);
    this.Width = _.get(obj, 'Width', null);
    this.Height = _.get(obj, 'Height', null);
    this.NumImages = _.get(obj, 'NumImages', null);
    this.SignedUrl = _.get(obj, 'SignedUrl', null);
    this.StorageClass = _.get(obj, 'StorageClass', null);
  }
}

class BBox {
  bbox: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  };
  frame_id: number;

  constructor(bbox_obj: any) {
    this.bbox = {
      x1: _.get(bbox_obj, 'bbox.x1'),
      x2: _.get(bbox_obj, 'bbox.x2'),
      y1: _.get(bbox_obj, 'bbox.y1'),
      y2: _.get(bbox_obj, 'bbox.y2'),
    };
    this.frame_id = _.get(bbox_obj, 'frame_id', 0);
  }
}

type PERSON_FILTER = {
  centroid: [number, number];
  top_color: string[];
  bottom_color: string[];
  facemask_correct: number | null;
  facemask_none: number | null;
  slip_fall: boolean | null;
  slip_fall_confidence: number | null;
  is_fighting: boolean | null;
  is_fighting_confidence: number | null;
  wearing_hardhat: boolean | null;
  took_off_hardhat: boolean | null;
  wearing_hardhat_confidence: number | null;
  wearing_safetyvest: boolean | null;
  took_off_safetyvest: boolean | null;
  wearing_safetyvest_confidence: number | null;
  slope: number | null;
};
type VEHICLE_FILTER = {
  vehicle_color: string[];
  vehicle_type: string | null;
  license_custom_top1: string | null;
  license_custom_top5: string[] | null;
  license_top5: string[] | null;
  slope: number | null;
};
type ILLUMINATION_FILTER = {
  change_type: 'dark-bright' | 'bright-dark' | null;
};
type SPILL_FILTER = {
  high_conf_spill: boolean | null;
  spill: boolean | null;
  spill_confidence: number | null;
};
type FEED_STATUS_FILTER = {
  feedStatus: string | null;
};
type SCENE_CHANGE_FILTER = {
  scene_change: boolean | null;
  scene_change_percent: number | null;
};
type FILTERS_TYPE =
  | PERSON_FILTER
  | VEHICLE_FILTER
  | ILLUMINATION_FILTER
  | SPILL_FILTER
  | FEED_STATUS_FILTER
  | SCENE_CHANGE_FILTER
  | {};

class SearchResult {
  readonly UserID: number;
  readonly CustomerID: number;
  readonly ChannelID: number;
  readonly ProjectID: number;

  CustomerConfig: Record<string, any>;

  AIPipelineState_CarPerson_Model: 'initial' | 'done' | null;
  // AIPipeline_CarPerson_Model_msg: string;
  // AIPipeline_CarPerson_Model_status: "OK" | "FAILED" | null;
  CarPerson_ModelEstimatedProcessTimeSec: number | null;
  ClientDirName: string;
  ESVideoStartTime: string | number | null;
  ESVideoEndTime: string | number | null;
  Fps: number | null;
  S3Bucket: 'df-prod' | 'df-stage' | null;

  // NumSegments: number | null;
  // ParentNumSegments: number | null;
  // ParentUploadID: string | null;
  // ParentVideoDurationMsec: number | null;
  // ParentVideoStartTime: number | null;
  // ParentVideoEndTime: number | null;
  // SegmentNum: number | null;
  // TimeZoneOffsetHours: number | null;
  // Version: number | null;
  // VideoStartEpochMilli: : number | null;

  UploadID: string | null;
  VideoID: string | null;
  UploadedVideoFileName: string | null;
  VideoDurationMsec: number;
  VideoStartTime: string | number | null;
  VideoEndTime: string | number | null;
  background_static: boolean | null;
  Thumbnail: ThumbnailObj | null;
  ThumbnailStrip: ThumbnailStripObj | null;

  /* MetadataDF: object | null;
      "ChannelID"
      "ClientVersionNo"
      "Error"
      "OriginalFileName"
      "TaskID"
      "VideoChunkStartTime"
      "VideoEndTime"
      "VideoSource"
      "VideoStartTime"
  */
  /* UploadedFiles
      "ClientFileName"
      "ClientFileSize"
      "ContentType"
      "FileFormat"
      "FileName"
      "FileSize"
      "FileType"
      "Fingerprint"
      "SentToPreprocessor"
      "StorageClass"
      "UploadTime"
      "VideoCodec"

      "AudioCodec"
      "DurationMsec"
      "F`gerprint"
      "Fps"
      "Height"
      "NumChannels"
      "NumFrames"
      "Width"
  */
  /* TranscodedVideo: object | null;
      "AudioCodec"
      "DurationMsec"
      "FileFormat"
      "FileName"
      "FileSize"
      "FileType"
      "Fps"
      "Height"
      "NumChannels"
      "NumFrames"
      "NumImages"
      "StorageClass"
      "VideoCodec"
      "Width"
  */
  // InferredVideoStartTime: string | null;
  /* ModelDetails: object | null;
      "agnostic-nms"
      "conf-thres"
      "datatype"
      "detail_available"
      "image_input_size"
      "iou-thres"
      "model_type"
      "nms"
      "topk_per_class"
      "train_date"
  */
  // confidence_count: number;
  // confidence_method: string;
  // confidence_sum: number;
  // endpoint: string | null;
  // milvus_collection: string | null;
  // milvus_id: [number];

  doc_type:
    | 'person'
    | 'vehicle'
    | 'illumination'
    | 'scene_change'
    | 'spill'
    | 'bicycle'
    | 'motorbike'
    | 'bus'
    | 'train'
    | 'feedStatus'
    | null;
  ObjectID: string | null;
  object_id_str: string | null;
  OnPremInference: boolean;
  s3image: string;
  primary_thumbnails: string[];
  secondary_thumbnails: Record<string, { name: string; values: string[] }>;
  labelConfidence: number;
  latest_frame: number | null;
  latest_time: number | null;
  objectDuration: number | null;
  frame_h_w: [number, number] | null;
  bboxs: BBox[];
  event_time: number | null;
  start: number | null;
  end: number | null;
  start_time: number | null;
  start_frame: number | null;
  end_frame: number | null;
  filters: FILTERS_TYPE;
  // OriginalVideoStartTime: string | number | null;

  constructor(search_result: any) {
    this.UserID = +_.get(search_result, 'UserID');
    this.CustomerID = +_.get(search_result, 'CustomerID');
    this.ChannelID = +_.get(search_result, 'ChannelID');
    this.ProjectID = +_.get(search_result, 'ProjectID');

    this.CustomerConfig = _.get(search_result, 'CustomerConfig', {});

    this.AIPipelineState_CarPerson_Model = _.get(
      search_result,
      'AIPipelineState_CarPerson_Model',
      null,
    );
    this.CarPerson_ModelEstimatedProcessTimeSec = +_.get(
      search_result,
      'CarPerson_ModelEstimatedProcessTimeSec',
      null,
    );
    this.ClientDirName = _.get(search_result, 'ClientDirName', null);
    this.ESVideoStartTime = _.get(search_result, 'ESVideoStartTime', null);
    this.ESVideoEndTime = _.get(search_result, 'ESVideoEndTime', null);

    this.Fps = _.get(search_result, 'Fps', null);
    if (this.Fps) this.Fps = +this.Fps;

    this.S3Bucket = _.get(search_result, 'S3Bucket', null);

    this.UploadID = _.get(search_result, 'UploadID', null);
    this.VideoID = _.get(search_result, 'VideoID', null);
    this.UploadedVideoFileName = _.get(
      search_result,
      'UploadedVideoFileName',
      null,
    );
    this.VideoDurationMsec = _.get(search_result, 'VideoDurationMsec', null);
    this.VideoStartTime = _.get(search_result, 'VideoDurationMsec', null);
    this.VideoEndTime = _.get(search_result, 'VideoDurationMsec', null);
    this.background_static = _.get(search_result, 'VideoDurationMsec', null);

    this.Thumbnail =
      'Thumbnail' in search_result
        ? new ThumbnailObj(search_result['Thumbnail'])
        : null;
    this.ThumbnailStrip =
      'ThumbnailStrip' in search_result
        ? new ThumbnailStripObj(search_result['ThumbnailStrip'])
        : null;

    this.doc_type = _.get(search_result, 'doc_type', null);
    this.ObjectID = _.get(search_result, 'ObjectID', null);
    this.object_id_str = _.get(search_result, 'object_id_str', null);
    this.OnPremInference =
      _.get(search_result, 'OnPremInference', null) ||
      _.get(search_result, 'on_prem_inference', null) ||
      false;

    this.s3image = _.get(search_result, 's3image', null);
    this.primary_thumbnails = _.get(search_result, 'primary_thumbnails', []);
    this.secondary_thumbnails = _.get(
      search_result,
      'secondary_thumbnails',
      {},
    );
    this.labelConfidence = _.get(search_result, 'labelConfidence', 0);
    this.latest_frame = _.get(search_result, 'latest_frame', null);
    this.latest_time = _.get(search_result, 'latest_time', null);
    this.objectDuration = _.get(search_result, 'objectDuration', null);
    this.frame_h_w = _.get(search_result, 'frame_h_w', null);
    this.bboxs = SearchResult.getBBox(_.get(search_result, 'bbox', null));
    this.event_time = _.get(search_result, 'event_time', null);
    this.start = _.get(search_result, 'start', null);
    this.end = _.get(search_result, 'end', null);
    this.start_time = _.get(search_result, 'start_time', null);
    this.start_frame = _.get(search_result, 'start_frame', null);
    this.end_frame = _.get(search_result, 'end_frame', null);
    this.filters = SearchResult.getFilters(this.doc_type, search_result);
  }

  static getBBox(bboxs: any[] | null) {
    if (bboxs) {
      return bboxs.map((bx) => new BBox(bx));
    }
    return [];
  }

  static getColorWithPrefix(obj: any, prefix: string): string[] {
    return Object.keys(obj)
      .filter((key) => key.indexOf(prefix) != -1)
      .map((key) => key.replace(prefix, '').trim());
  }

  static getPersonFilters(obj: any): PERSON_FILTER {
    return {
      centroid: _.get(obj, 'centroid', null),
      top_color: SearchResult.getColorWithPrefix(obj, 'top_color_'),
      bottom_color: SearchResult.getColorWithPrefix(obj, 'bottom_color_'),
      facemask_correct: _.get(obj, 'facemask_correct', null),
      facemask_none: _.get(obj, 'facemask_none', null),
      slip_fall: _.get(obj, 'slip_fall', null),
      slip_fall_confidence: _.get(obj, 'slip_fall_confidence', null),
      is_fighting: _.get(obj, 'is_fighting', null),
      is_fighting_confidence: _.get(obj, 'is_fighting_confidence', null),
      wearing_hardhat: _.get(obj, 'wearing_hardhat', null),
      took_off_hardhat: _.get(obj, 'took_off_hardhat', null),
      wearing_hardhat_confidence: _.get(
        obj,
        'wearing_hardhat_confidence',
        null,
      ),
      wearing_safetyvest: _.get(obj, 'wearing_safetyvest', null),
      took_off_safetyvest: _.get(obj, 'took_off_safetyvest', null),
      wearing_safetyvest_confidence: _.get(
        obj,
        'wearing_safetyvest_confidence',
        null,
      ),
      slope: _.get(obj, 'slope', null),
    };
  }
  static getVehicleFilters(obj: any): VEHICLE_FILTER {
    return {
      vehicle_color: SearchResult.getColorWithPrefix(obj, 'vehicle_color_'),
      vehicle_type: _.get(obj, 'vehicle_type', null),
      license_custom_top1: _.get(obj, 'license_custom_top1', null),
      license_custom_top5: _.get(obj, 'license_custom_top5', null),
      license_top5: _.get(obj, 'license_top5', null),
      slope: _.get(obj, 'slope', null),
    };
  }
  static getIlluminationFilters(obj: any): ILLUMINATION_FILTER {
    return {
      change_type: _.get(obj, 'change_type', null),
    };
  }
  static getSpillFilters(obj: any): SPILL_FILTER {
    return {
      high_conf_spill: _.get(obj, 'high_conf_spill', null),
      spill: _.get(obj, 'spill', null),
      spill_confidence: _.get(obj, 'spill_confidence', null),
    };
  }
  static getFeedStatusFilters(obj: any): FEED_STATUS_FILTER {
    return {
      feedStatus: _.get(obj, 'feedStatus', null),
    };
  }
  static getSceneChangeFilters(obj: any): SCENE_CHANGE_FILTER {
    return {
      scene_change: _.get(obj, 'scene_change', null),
      scene_change_percent: _.get(obj, 'scene_change_percent', null),
    };
  }

  static getFilters(doc_type: string | null, obj: any): FILTERS_TYPE {
    let filters = {};
    if (doc_type) {
      switch (doc_type) {
        case 'person':
          filters = SearchResult.getPersonFilters(obj);
          break;
        case 'vehicle':
          filters = SearchResult.getVehicleFilters(obj);
          break;
        case 'illumination':
          filters = SearchResult.getIlluminationFilters(obj);
          break;
        case 'spill':
          filters = SearchResult.getSpillFilters(obj);
          break;
        case 'feedStatus':
          filters = SearchResult.getFeedStatusFilters(obj);
          break;
        case 'scene_change':
          filters = SearchResult.getSceneChangeFilters(obj);
          break;
        // case "motorbike":
        //   break;
        // case "bus":
        //   break;
        // case "train":
        //   break;
      }
    }
    return filters;
  }
}

export const interpretClipData = function (clip_objs: any[]) {
  return clip_objs.map((clip) => new SearchResult(clip));
};

// ai filters
/*

"""person
centroid

"""person
top_color_black
top_color_blue
top_color_brown
top_color_gray
top_color_green
top_color_orange
top_color_pink
top_color_purple
top_color_red
top_color_white
top_color_yellow

"""person
bottom_color_black
bottom_color_blue
bottom_color_brown
bottom_color_gray
bottom_color_green
bottom_color_orange
bottom_color_pink
bottom_color_purple
bottom_color_red
bottom_color_white
bottom_color_yellow

"""person
facemask_correct
facemask_none

"""person
slip_fall
slip_fall_confidence

"""person
"""vehicle
is_fighting
is_fighting_confidence

"""person
wearing_hardhat
took_off_hardhat
wearing_hardhat_confidence

"""person
wearing_safetyvest
took_off_safetyvest
wearing_safetyvest_confidence


"""vehicle
vehicle_color_black
vehicle_color_blue
vehicle_color_count
vehicle_color_gray
vehicle_color_green
vehicle_color_red
vehicle_color_silver
vehicle_color_white
vehicle_color_yellow

"""vehicle
vehicle_type

"""vehicle
license_custom_top1
license_custom_top5
license_top5

"""illumination
change_type


"""feedStatus
feedStatus

"""spill
high_conf_spill
spill
spill_confidence

"""person
"""vehicle
"""train
slope


"""scene_change
scene_change
scene_change_percent


"""corporate_app
"""45
current_occupancy

*/
