import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  HiddenTypography,
  NameAndIconContainer,
  NameIconAndCheckContainer,
  StyledAntdInput,
} from './styles';

interface EditableNameProps {
  name: string;
  onNameChange: (name: string) => void;
}

const EditableName = ({ name, onNameChange }: EditableNameProps) => {
  // Need to have localName to calculate width
  const [localName, setLocalName] = useState(name);
  const [isEditing, setIsEditing] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const textRef = useRef<HTMLSpanElement>(null); // Ref for measuring Typography width

  useEffect(() => {
    const hiddenSpan = document.getElementById('hidden-typo');
    if (hiddenSpan) {
      const spanWidth = hiddenSpan.offsetWidth;
      setInputWidth(spanWidth + 40); // Some padding for smooth typing
    }
  }, [localName]);

  useEffect(() => {
    setLocalName(name);
  }, [name]);

  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(false);
    setLocalName(name);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalName(e.target.value); // Update the name as the user types
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <NameIconAndCheckContainer>
      <NameAndIconContainer onClick={handleEditClick} isEditing={isEditing}>
        {/* Hidden span to calculate input width dynamically */}
        <HiddenTypography id="hidden-typo">{localName}</HiddenTypography>
        {isEditing ? (
          <>
            <StyledAntdInput
              inputWidth={inputWidth}
              value={localName}
              onChange={handleNameChange}
              autoFocus
            />
            <CloseOutlined
              onClick={handleCancel}
              style={{ fontSize: '20px' }}
            />
          </>
        ) : (
          <>
            <Typography.Text
              ref={textRef}
              style={{ fontSize: '24px', fontWeight: 800 }}
              className="editable-text">
              {localName}
            </Typography.Text>
            <EditOutlined className="icon" style={{ fontSize: '20px' }} />
          </>
        )}
      </NameAndIconContainer>
      {isEditing && (
        <CheckOutlined
          style={{ fontSize: '20px' }}
          onClick={() => {
            onNameChange(localName);
            setIsEditing(false);
          }}
        />
      )}
    </NameIconAndCheckContainer>
  );
};

export { EditableName };
