import { SiteSelectionCascader } from '@/components/SiteSelectionCascader';
import { APPS } from '@/pages/apps/app/constants/appList';
import { dispatchWithFeedback } from '@/utils/utils';
import { Form, Modal, Select } from 'antd';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch } from 'umi';

const getAppsWithCapabilities = (apps: any[]) => {
  return apps.filter((app: any) => APPS[app.AppID]?.CAPABILITIES);
};

const AddAccessRule = ({
  children,
  addingAccessRule,
  access_rule,
  apps,
  users,
  sites,
  siteGroups,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedApp, setApp] = useState(
    access_rule ? access_rule.App.Id : undefined,
  );

  const [form] = Form.useForm();

  let accessFormRef = useRef();
  const toggleModal = () => {
    setShowModal((_showModal) => !_showModal);
  };
  const dispatch = useDispatch();

  const addOrUpdateAccessRule = () => {
    accessFormRef.current.validateFields().then(
      (values) => {
        if (values?.scopes?.sites) {
          values.scopes.sites = values.scopes.sites.map(
            (site) => site[site.length - 1],
          );
        }

        dispatchWithFeedback(
          dispatch,
          'Add Access Rule',
          {
            type: access_rule
              ? 'access_rules/updateAccessRule'
              : 'access_rules/createAccessRule',
            access_rule_id: access_rule && access_rule.Id,
            payload: values,
          },
          true,
        ).then((_response) => {
          toggleModal();
          if (accessFormRef.current) {
            accessFormRef.current.resetFields();
          }
        });
      },
      (err) => console.log('err', err),
    );
  };

  return (
    <>
      <Modal
        width={400}
        title={access_rule ? 'Update App Role' : 'Add App Role'}
        open={showModal}
        onOk={(e) => addOrUpdateAccessRule(e)}
        confirmLoading={addingAccessRule}
        onCancel={() => {
          accessFormRef?.current?.resetFields();
          toggleModal();
        }}>
        <Form
          ref={accessFormRef}
          requiredMark={false}
          layout="vertical"
          form={form}
          initialValues={
            access_rule && {
              user_id: access_rule.User.UserID,
              app_id: access_rule.App.Id,
              capabilities: access_rule.Capabilities,
              scopes: {
                sites: _.get(access_rule, 'Scopes.Sites', []).map((s) => [
                  'all',
                  s.SiteID,
                ]),
                labels: _.get(access_rule, 'Scopes.Labels', []).map(
                  (l) => l.LabelID,
                ),
              },
            }
          }>
          <Form.Item
            label="User"
            name="user_id"
            rules={[
              {
                required: true,
                message: 'Please select the user',
              },
            ]}>
            <Select>
              {users.map((user) => (
                <Select.Option key={user.User.UserID} value={user.User.UserID}>
                  {`${user.User.FirstName} ${user.User.LastName} (${user.User.Email})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="App"
            name="app_id"
            rules={[
              {
                required: true,
                message: 'Please select the app',
              },
            ]}>
            <Select
              onChange={(val) => {
                form.setFieldValue('capabilities', []);
                setApp(val);
              }}>
              {getAppsWithCapabilities(apps).map((app) => (
                <Select.Option key={app.AppID} value={app.AppID}>
                  {app.Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Sites" name={['scopes', 'sites']}>
            <SiteSelectionCascader
              sites={sites}
              siteGroups={siteGroups}
              existingSelectedSites={_.get(access_rule, 'Scopes.Sites', []).map(
                (s: any) => s.SiteID,
              )}
              onChange={(valueToConsider) => {
                form.setFieldsValue({ scopes: { sites: valueToConsider } });
              }}
            />
          </Form.Item>
          <Form.Item label="Site Groups" name={['scopes', 'labels']}>
            <Select mode="multiple">
              {siteGroups.map((label: any) => (
                <Select.Option key={label.LabelID} value={label.LabelID}>
                  {label.Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Capabilities"
            name="capabilities"
            rules={[
              {
                required: true,
                message: 'Please select the capabilities',
              },
            ]}>
            <Select mode="multiple">
              {selectedApp &&
                Object.values(_.get(APPS[selectedApp], 'CAPABILITIES', [])).map(
                  (cap) => (
                    <Select.Option
                      key={cap}
                      value={cap}
                      style={{ textTransform: 'capitalize' }}>
                      {APPS[selectedApp]?.CAPABILITIES_LABEL_MAP[cap] || cap}
                    </Select.Option>
                  ),
                )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <span onClick={() => toggleModal()}>{children}</span>
    </>
  );
};

export default AddAccessRule;
